export const DateFormat = (d) =>{
    const targetDate = new Date(d);
    const year = targetDate.getFullYear();
    let date = targetDate.getDate();
    let month = targetDate.getMonth() + 1;
    if (month < 10) {
        month = 0 + '' + month
    }
    if(date < 10){
        date = 0+ ''+date
    }
    
    return  date + '/' + month + '/' + year;
}