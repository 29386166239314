import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios';
import { Audio } from 'react-loader-spinner';
import { AiFillDelete, AiOutlineDelete, AiOutlinePhone } from 'react-icons/ai';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';

const UserRequests = () => {
    const [loading, setloading] = useState(true);
    const [showWaitModal, setshowWaitModal] = useState(false)
    const [fetchedData, setfetchedData] = useState([])
    const base_url = baseUrl();
    const authToken = getToken();

    const fetchData = async () => {

        try {
            const res = await axios.get(`${base_url}/get_user_requests`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
            setloading(false)
            setfetchedData(res.data)
        } catch (error) {
            console.log(error)
        }
    }
    const deleteRequset = async (i) => {
        setshowWaitModal(true)
        try {
            const res = await axios.delete(`${base_url}/delete_user_request/${i}`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
            setshowWaitModal(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                fetchData()
            }
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
        }
    }

    const handleDelete = (id, name) => {
        Swal.fire({
            icon: 'warning',
            text: `Delete request of ${name} ?`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRequset(id)
            }
        })
    }

    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className='master_admin_wrapper'>
            {showWaitModal ? <Modal data='Deleting request..' title='wait' /> : <></>}
            <h2>Registration requests</h2>
            {loading ? <Audio color='cadetblue' height={30} /> : <>
                {fetchedData.length < 1 ? <h3 className='failed' >No request found !</h3> : <>
                    <div className="x_overflow">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Address</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fetchedData.map(el =>
                                    <tr key={el.id}>
                                        <td>{el.name}</td>
                                        <td>{el.email}</td>
                                        <td>{el.phone}</td>
                                        <td>{el.address}</td>
                                        <td>{el.registration_type}</td>
                                        <td className='btnRow'>
                                            <a href={`tel:${el.phone}`} ><AiOutlinePhone color='green' size={18} /> </a>
                                            <button className="no_border_btn" onClick={() => handleDelete(el.id, el.name)}>
                                                <AiFillDelete color='tomato' size={18} />
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </div>
                </>}
            </>}
        </div>
    )
}

export default UserRequests