import './doctorPrescriptionStyle.css'
import { useAppContext } from '../../contex/appContext'
import { useEffect, useReducer, useRef, useState } from 'react';
import { GetDate } from '../../services/Date'
import { DateFormat } from '../../services/DateFormat'
import { usePrescriptionContext } from '../../contex/prescriptionContext';
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import { TailSpin } from 'react-loader-spinner'
import PrescriptionLeftCol from './doctorPrescriptionInner/PrescriptionLeftCol';
import PrescriptionDrugs from './doctorPrescriptionInner/PrescriptionDrugs';
import { BsPrinterFill } from 'react-icons/bs'
import ReactToPrint from 'react-to-print'
import Swal from 'sweetalert2'
import QRCode from "react-qr-code";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import BlankProfileImage from '../../Images/prescription.webp'

const DoctorPrescription = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const componentRef = useRef()
  const base_url = baseUrl();
  const authToken = getToken()
  const { prescriptionData, loading } = usePrescriptionContext()
  const today = GetDate()
  const { userData } = useAppContext();
  const [showNewPatientForm, setShowNewPatientForm] = useState(false);
  const [showPatientHeading, setShowPatientHeading] = useState(false);
  const [stat, setStat] = useState(1);
  const [waitMsg, setwaitMsg] = useState(false);

  const [formData, setFormData] = useState({
    'patient_name': '',
    'patient_age': '',
    'patient_age_unit': 'Year(s)',
    'patient_sex': 'F',
    'patient_phone': '',
    'patient_address': '',
    'patient_type': 'new',
    'doctor_name': 'abc',
    'doctor_name': '',
    'patient_date': today
  })



  // const newPatientClickHandler = () => {
  //   localStorage.removeItem('rahmatwebdev_doctors_history')
  //   localStorage.removeItem('rahmatwebdev_doctors_cc')
  //   localStorage.removeItem('rahmatwebdev_doctor_o_e')
  //   localStorage.removeItem('rahmatwebdev_doctors_dd')
  //   localStorage.removeItem('rahmatwebdev_doctors_test')
  //   localStorage.removeItem('rahmatwebdev_doctors_diagnosis')
  //   localStorage.removeItem('rahmatwebdev_doctors_advice')
  //   localStorage.removeItem('rahmatewbdev_prescription_drugs')

  //   setFormData({
  //     'patient_name': '',
  //     'patient_age': '',
  //     'patient_age_unit': 'Year(s)',
  //     'patient_sex': 'F',
  //     'patient_phone': '',
  //     'patient_address': '',
  //     'patient_type': 'new',
  //     'doctor_name': prescriptionData.user_name,
  //     'patient_date': today

  //   })
  //   setShowNewPatientForm(true)
  // }
  const qr_code_data = {
    'p_n': formData.patient_name,
    'age': formData.patient_age,
    'age_u': formData.patient_age_unit,
    'sex': formData.patient_sex,
    'phon': formData.patient_phone,
    'typ': formData.patient_type,
    'd_n': formData.doctor_name,
    'date': formData.patient_date
  }
  const handleChange = e => {
    setFormData({
      ...formData,
      'doctor_name': prescriptionData.user_name,
      [e.target.name]: e.target.value
    })
  }




  const [addedHistory, setAddedHistory] = useState([]);
  const showAddedHistory = () => {
    if (JSON.parse(localStorage.getItem('rahmatwebdev_doctors_history')) != null) {
      setAddedHistory(JSON.parse(localStorage.getItem('rahmatwebdev_doctors_history')))
    }
  }

  const removeHistoryHandler = (i) => {
    let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_history'));
    arry.splice(i, 1);
    localStorage.setItem('rahmatwebdev_doctors_history', JSON.stringify(arry));
    showAddedHistory()
  }

  const [addedCC, setAddedCC] = useState([]);
  const showAddedCC = () => {
    if (JSON.parse(localStorage.getItem('rahmatwebdev_doctors_cc')) != null) {
      setAddedCC(JSON.parse(localStorage.getItem('rahmatwebdev_doctors_cc')))
    }
  }
  const removeCCHandler = (i) => {
    let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_cc'));
    arry.splice(i, 1);
    localStorage.setItem('rahmatwebdev_doctors_cc', JSON.stringify(arry));
    showAddedCC()
  }

  const [addedOE, setAddedOE] = useState([]);
  const showAddedOE = () => {
    if (JSON.parse(localStorage.getItem('rahmatwebdev_doctor_o_e')) != null) {
      setAddedOE(JSON.parse(localStorage.getItem('rahmatwebdev_doctor_o_e')))
    }
  }
  const removeOEHandler = (i) => {
    let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctor_o_e'));
    arry.splice(i, 1);
    localStorage.setItem('rahmatwebdev_doctor_o_e', JSON.stringify(arry));
    showAddedOE()
  }

  const [addedDD, setAddedDD] = useState([]);
  const showAddedDD = () => {
    if (JSON.parse(localStorage.getItem('rahmatwebdev_doctors_dd')) != null) {
      setAddedDD(JSON.parse(localStorage.getItem('rahmatwebdev_doctors_dd')))
    }
  }
  const removeDDHandler = (i) => {
    let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_dd'));
    arry.splice(i, 1);
    localStorage.setItem('rahmatwebdev_doctors_dd', JSON.stringify(arry));
    showAddedDD()
  }

  const [addedTest, setAddedTest] = useState([]);
  const showAddedTest = () => {
    if (JSON.parse(localStorage.getItem('rahmatwebdev_doctors_test')) != null) {
      setAddedTest(JSON.parse(localStorage.getItem('rahmatwebdev_doctors_test')))
    }
  }
  const removeTestHandler = (i) => {
    let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_test'));
    arry.splice(i, 1);
    localStorage.setItem('rahmatwebdev_doctors_test', JSON.stringify(arry));
    showAddedTest()
  }


  const [addedDiagnosis, setAddedDiagnosis] = useState([]);
  const showAddedDiagnosis = () => {
    if (JSON.parse(localStorage.getItem('rahmatwebdev_doctors_diagnosis')) != null) {
      setAddedDiagnosis(JSON.parse(localStorage.getItem('rahmatwebdev_doctors_diagnosis')))
    }
  }
  const removeDiagnosisHandler = (i) => {
    let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_diagnosis'));
    arry.splice(i, 1);
    localStorage.setItem('rahmatwebdev_doctors_diagnosis', JSON.stringify(arry));
    showAddedDiagnosis()
  }
  const [addedAdvice, setaddedAdvice] = useState([])
  const showAddedAdvice = () => {
    if (JSON.parse(localStorage.getItem('rahmatwebdev_doctors_advice')) != null) {
      setaddedAdvice(JSON.parse(localStorage.getItem('rahmatwebdev_doctors_advice')))
    }
  }
  const removeAdviceHandler = (i) => {
    let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_advice'));
    arry.splice(i, 1);
    localStorage.setItem('rahmatwebdev_doctors_advice', JSON.stringify(arry));
    showAddedAdvice()
  }
  //Drug Starts-------------------------------------------==============
  const afterPrintPrescription = async () => {
    setwaitMsg(true)
    try {
      const res = await axios.post(`${base_url}/add_doctor_prescription_data`, {
        'patient_details': JSON.stringify(formData),
        'history': localStorage.getItem('rahmatwebdev_doctors_history'),
        'c_c': localStorage.getItem('rahmatwebdev_doctors_cc'),
        'o_e': localStorage.getItem('rahmatwebdev_doctor_o_e'),
        'tests': localStorage.getItem('rahmatwebdev_doctors_test'),
        'd_d': localStorage.getItem('rahmatwebdev_doctors_dd'),
        'diagnosis': localStorage.getItem('rahmatwebdev_doctors_diagnosis'),
        'advice': localStorage.getItem('rahmatwebdev_doctors_advice'),
        'drugs': localStorage.getItem('rahmatewbdev_prescription_drugs'),
        'patient_date': today
      }, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      setwaitMsg(false)
      localStorage.removeItem('rahmatwebdev_doctors_history')
      localStorage.removeItem('rahmatwebdev_doctors_cc')
      localStorage.removeItem('rahmatwebdev_doctor_o_e')
      localStorage.removeItem('rahmatwebdev_doctors_test')
      localStorage.removeItem('rahmatwebdev_doctors_dd')
      localStorage.removeItem('rahmatwebdev_doctors_diagnosis')
      localStorage.removeItem('rahmatwebdev_doctors_advice')
      localStorage.removeItem('rahmatewbdev_prescription_drugs')
      Swal.fire({
        icon: res.data.status,
        text: res.data.message
      })
      navigate('/reload')
    } catch (error) {
      setwaitMsg(false)
      console.log(error.response)
    }
  }
  //Drug Ends------------------- => 
  const handleCancelBtn = () => {
    setShowPatientHeading(false);
    setShowNewPatientForm(false)
  }
  const handleSubmit = e => {
    e.preventDefault();
    setShowNewPatientForm(false);
    setShowPatientHeading(true);
    showAddedHistory()
    showAddedCC()
    showAddedTest()
    showAddedDD()
    showAddedOE()
    showAddedDiagnosis()
    showAddedAdvice()
  }
  const patientHeadingClickHandler = () => {
    setShowNewPatientForm(true);
    setShowPatientHeading(false)
  }

  const overWritePrescription = async (id) => {
    try {
      const res = await axios.delete(`${base_url}/over_write_prescription/${id}`,{
        headers: {
          Accept: 'application/json',
            Authorization: `Bearer ${authToken}`
        }
      });
      console.log(res.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (location.state) {
      if (!loading) {
        if (location.state.waitingPatientData) {
          setFormData({
            ...formData,
            'patient_name': location.state.waitingPatientData.patient_name,
            'patient_age': location.state.waitingPatientData.patient_age,
            'patient_age_unit': location.state.waitingPatientData.patient_age_unit,
            'patient_phone': location.state.waitingPatientData.patient_phone,
            'patient_address': location.state.waitingPatientData.patient_address,
            'patient_type': location.state.waitingPatientData.patient_type,
          })          
          if(location.state.waitingPatientData.overwrite){
            overWritePrescription(location.state.waitingPatientData.overwrite)
          }
          setShowPatientHeading(true)
        }
        if (location.state.prevData) {
          localStorage.setItem('rahmatwebdev_doctors_history', location.state.prevData.history)
          localStorage.setItem('rahmatwebdev_doctors_cc', location.state.prevData.c_c)
          localStorage.setItem('rahmatwebdev_doctor_o_e', location.state.prevData.o_e)
          localStorage.setItem('rahmatwebdev_doctors_dd', location.state.prevData.d_d)
          localStorage.setItem('rahmatwebdev_doctors_test', location.state.prevData.tests)
          localStorage.setItem('rahmatwebdev_doctors_diagnosis', location.state.prevData.diagnosis)
          localStorage.setItem('rahmatwebdev_doctors_advice', location.state.prevData.advice)
          localStorage.setItem('rahmatewbdev_prescription_drugs', location.state.prevData.drugs)
        } else {
          localStorage.removeItem('rahmatwebdev_doctors_history')
          localStorage.removeItem('rahmatwebdev_doctors_cc')
          localStorage.removeItem('rahmatwebdev_doctor_o_e')
          localStorage.removeItem('rahmatwebdev_doctors_dd')
          localStorage.removeItem('rahmatwebdev_doctors_test')
          localStorage.removeItem('rahmatwebdev_doctors_diagnosis')
          localStorage.removeItem('rahmatwebdev_doctors_advice')
          localStorage.removeItem('rahmatewbdev_prescription_drugs')
        }
      }
    }
    showAddedHistory()
    showAddedCC()
    showAddedTest()
    showAddedDD()
    showAddedOE()
    showAddedDiagnosis()
    showAddedAdvice()
  }, [stat])
  return (
    <div className='prescriptionMain'>

      <div className="newPatientDiv">
        <Link className="addBtn my_btn" to={'/doctor/add_patients_by_doctor'}>New patient</Link>
        {showNewPatientForm ?
          <form className="addPatientByDoctorForm" onSubmit={handleSubmit}>
            <input type="text" name='patient_name' required placeholder='Patient name'
              value={formData.patient_name} onChange={handleChange} />
            <input style={{ 'width': '3rem' }} type="text" required name='patient_age' placeholder='Age'
              value={formData.patient_age} onChange={handleChange} />
            <select name="patient_age_unit" onChange={handleChange} value={formData.patient_age_unit}>
              <option value="Year(s)">Year(s)</option>
              <option value="Month(s)">Month(s)</option>
              <option value="Day(s)">Day(s)</option>
            </select>
            <select name="patient_sex" onChange={handleChange} value={formData.patient_sex}>
              <option value="F">Female</option>
              <option value="M">Male</option>
              <option value="Other">Other</option>
            </select>
            <input type="text" style={{ 'width': '7rem' }} name='patient_phone' placeholder='Phone no.'
              value={formData.patient_phone} onChange={handleChange} />
            <input type="text" style={{ 'width': '16rem' }} name='patient_address' placeholder='Address'
              value={formData.patient_address} onChange={handleChange} />
            <select name="patient_type" onChange={handleChange} value={formData.patient_type}>
              <option value="new">New</option>
              <option value="f_up">Follow-Up</option>
            </select>
            <input type="submit" className='submit_btn' value="Enter" />
            <input type="button" value="Cancel" onClick={handleCancelBtn} className='cancel_btn' />
          </form>
          : ''}
        {showPatientHeading && !showNewPatientForm ?
          <>
            <div className='patient_heading' onClick={patientHeadingClickHandler}>
              <div>Name: {formData.patient_name}</div>
              <div>Age: {formData.patient_age} {formData.patient_age_unit}</div>
              <div>Sex: {formData.patient_sex} </div>
              <div>Phone: {formData.patient_phone} </div>
              <div>Address: {formData.patient_address} </div>
              <div>Date: {DateFormat(today)} </div>
            </div>

          </> : <></>}
      </div>

      {showPatientHeading && !showNewPatientForm ?
        <div className="patient_prescription">
          <PrescriptionLeftCol
            setwaitMsg={setwaitMsg}
            showAddedHistory={showAddedHistory}
            showAddedCC={showAddedCC}
            showAddedOE={showAddedOE}
            showAddedDD={showAddedDD}
            showAddedTest={showAddedTest}
            showAddedDiagnosis={showAddedDiagnosis}
            showAddedAdvice={showAddedAdvice}
          />
          <div className="pp_right_col prescriptionPrintDiv" ref={componentRef} >
            <table className="prescriptionPrintHeader">
              <tbody>
                <tr>
                  <td>Name: {formData.patient_name}</td>
                  <td>Age: {formData.patient_age} {formData.patient_age_unit}</td>
                  <td>{formData.patient_sex} </td>
                </tr>
                <tr>
                  <td>Phone: {formData.patient_phone}</td>
                  <td>Address: {formData.patient_address}</td>
                  <td>Date: {DateFormat(today)} </td>
                </tr>
              </tbody>
            </table>

            {waitMsg ? <div className="wait_div">
              <TailSpin
                height={30} color='lime' />
            </div> : <></>}
            <div className="pp_right_col_body">
              <div className="prescriotionPrintLeft ">

                {addedHistory.length > 0 ? <>
                  <div className='prescriotionPrintLeft_divs'>
                    <h4><u>History:</u></h4>
                    <ul>
                      {

                        addedHistory.map((el, i) =>
                          <li key={i}>{el}
                            <button onClick={() => removeHistoryHandler(i)} className='remove_btn'>&times;</button></li>
                        )
                      }
                    </ul>
                  </div>
                </> : <></>}
                {addedCC.length > 0 ? <>
                  <div className='prescriotionPrintLeft_divs'>
                    <h4><u>Chief complaints:</u></h4>
                    <ul>
                      {addedCC.map((el, i) =>
                        <li key={i}>{el}
                          <button onClick={() => removeCCHandler(i)} className='remove_btn'>&times;</button></li>

                      )}
                    </ul>
                  </div>
                </> : <></>}
                {addedOE.length > 0 ? <>
                  <div className='prescriotionPrintLeft_divs'>
                    <h4><u>On examination:</u></h4>
                    <ul>
                      {addedOE.map((el, i) =>
                        <li key={i}>{el}
                          <button onClick={() => removeOEHandler(i)} className='remove_btn'>&times;</button></li>

                      )}
                    </ul>
                  </div>
                </> : <></>}
                {addedDD.length > 0 ? <>
                  <div className='prescriotionPrintLeft_divs'>
                    <h4><u>D/D:</u></h4>
                    <ul>
                      {
                        addedDD.map((el, i) =>
                          <li key={i}>{el}
                            <button onClick={() => removeDDHandler(i)} className='remove_btn'>&times;</button></li>
                        )
                      }
                    </ul>
                  </div>
                </> : <></>}
                {addedTest.length > 0 ? <>
                  <div className='prescriotionPrintLeft_divs'>
                    <h4><u>Tests:</u></h4>
                    <ul>
                      {
                        addedTest.map((el, i) =>
                          <li key={i}>{el}
                            <button onClick={() => removeTestHandler(i)} className='remove_btn'>&times;</button></li>
                        )
                      }
                    </ul>
                  </div>
                </> : <></>}
                {addedDiagnosis.length > 0 ? <>
                  <div className='prescriotionPrintLeft_divs'>
                    <h4><u>Diagnosis:</u></h4>
                    <ul>
                      {
                        addedDiagnosis.map((el, i) =>
                          <li key={i}>{el}
                            <button onClick={() => removeDiagnosisHandler(i)} className='remove_btn'>&times;</button></li>
                        )
                      }
                    </ul>
                  </div>
                </> : <></>}
                {/* {addedAdvice.length > 0 ? <>
  <div className='prescriotionPrintLeft_divs banglaFont adviceDiv'>
    <h4><u>উপদেশঃ</u></h4>
    <ul>
      {
        addedAdvice.map((el, i) =>
          <li key={i}>{el}
            <button onClick={() => removeAdviceHandler(i)} className='remove_btn'>&times;</button></li>
        )
      }
    </ul>
  </div>
</> : <></>} */}
                <div className="qR_code_div">
                  <QRCode
                    size={25}
                    style={{ height: "auto", maxWidth: "60%", width: "60%", marginLeft: '8%' }}
                    value={JSON.stringify(qr_code_data)}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
              <div className="prescriotionPrintRIght">
                <PrescriptionDrugs
                  setwaitMsg={setwaitMsg}
                  stat={new Date().getMilliseconds()}
                  advices={addedAdvice}
                  removeAdviceHandler={removeAdviceHandler}
                />


                <ReactToPrint
                  trigger={() => <button className="no_border_btn printPrescriptionBtn"><BsPrinterFill size={26} /></button>}
                  content={() => componentRef.current}
                  onAfterPrint={() => {
                    setShowPatientHeading(false)
                    afterPrintPrescription();
                  }}
                />

              </div>
            </div> 
            {/* End of pp right col body */}

            <div className="doctor_details">
              {userData.name}
            </div>

          </div>

        </div>

        : <div className='doctor_banner'>
          <img className="doctor_banner_image" src={BlankProfileImage} alt="blank profile" />
          <div className='doctor_banner_footer'> {userData.name} </div>
        </div>}
    </div>
  )
}

export default DoctorPrescription