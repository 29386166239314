import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useCLinicDetailsContext } from '../../contex/clinicDetailsContext';
import { baseUrlMain } from '../../services/baseUrl';
import './clinicHeadingStyle.css';

const ClinicHeading = () => {
    const base_url_main = baseUrlMain();
    const { clinicDetailsData, loading } = useCLinicDetailsContext();

    return (
        <>
       {!loading ? <Link to={'/'} className='clinicHeading'>
            <div className="logo_div"><img className='logo_image' src={`${base_url_main}/${clinicDetailsData.logo_image}`} /></div>
            <div className="heading_div"><h3>{clinicDetailsData.clinic_name}</h3></div>
        </Link> : <></>}        
       
        </>
    )
}

export default ClinicHeading