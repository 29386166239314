import { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { logOutReq } from '../auth/logout';
import { GiHamburgerMenu } from 'react-icons/gi'
import { useAppContext } from '../../contex/appContext';
import logo from '../../Images/logo.PNG'

const DoctorMenuBar = () => {
    const { userData } = useAppContext();
    const navigate = useNavigate()
    const [showMenu, setShowMenu] = useState(false);
    const logout = () => {
        logOutReq();
        localStorage.removeItem('rahmatLabSolutionAuthToken')
        navigate('/login')
    }
    return (
        <div className='doctorMenuBar'>
            <div className="menuIconDiv">
                <span onClick={() => setShowMenu(!showMenu)} className={showMenu ? 'menuBtn menuBtnWhite' : 'menuBtn'}><GiHamburgerMenu /></span>
            </div>
            <div className="doctorMenuBarLeft">
                <Link to='/'>
                    <img className='logoImage' alt='logoImage' src={logo} />
                </Link>
            </div>
            <div className={showMenu ? 'menuBarShow doctorMenuBarRight' : 'doctorMenuBarRight'}>

                <ul>
                    <li><NavLink to={'/doctor'} onClick={() => setShowMenu(!showMenu)}>Home</NavLink></li>
                    {userData.user_type === 'doctor' ?
                        <li><NavLink to={'/doctor/waiting_patients'} onClick={() => setShowMenu(!showMenu)}>Waiting patients</NavLink></li> : <>
                            <li><NavLink to={'/doctor/single_doctor_waiting_patients'} onClick={() => setShowMenu(!showMenu)}>Waiting Patinets</NavLink></li>
                            <li><NavLink to={'/doctor/single_doctor_assistant'} onClick={() => setShowMenu(!showMenu)}>Assistants</NavLink></li>
                            <li><NavLink to={'/doctor/add_schedule'} onClick={() => setShowMenu(!showMenu)}>Schedules</NavLink></li>
                        </>}
                    <li><NavLink to={'/doctor/search_patients'} onClick={() => setShowMenu(!showMenu)}>Search Patients</NavLink></li>
                    <li><NavLink to={'/doctor/keywords'} onClick={() => setShowMenu(!showMenu)}>Key words</NavLink></li>
                    <li><NavLink to={'/doctor/profile'} onClick={() => setShowMenu(!showMenu)}>Profile</NavLink></li>
                    <li><button onClick={() => logout()} className='logoutBtn' >Log Out</button></li>
                </ul>
            </div>
        </div>
    )
}

export default DoctorMenuBar