import React, { useEffect, useState } from 'react'
import PatientForm from './PatientForm'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import Swal from 'sweetalert2'
import Modal from'../modal/Modal'

const AddPatient = () => {
    const navigate = useNavigate()
    const {state} = useLocation();
    const [showWaitModal, setshowWaitModal] = useState(false)
    const base_url = baseUrl();
    const authToken = getToken();
    const [formData, setFormData] = useState({
        'patient_name': '',
        'patient_age': '',
        'patient_age_unit': 'Year(s)',
        'patient_sex': 'F',
        'patient_phone': '',
        'patient_address': '',
        'patient_type': 'new',
        'schedule_date': ''
    })
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/add_single_doctor_serial`, formData,{
                headers : {
                    Accept : 'application/json',
                    Authorization : `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            if(res.data.status === 'success'){
                navigate(-1)
            }
            Swal.fire({
                icon : res.data.status,
                text : res.data.message
            })
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if(error.response.status === 422){
                Swal.fire({
                    icon : 'info',
                    text : error.response.data.message
                })
            }
        }
        
    }
    useEffect(()=>{
        setFormData({
            ...formData,
            'schedule_date' : state.date
        })
    },[])

    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Adding patient' title='wait' /> : <></>}
            <PatientForm
            formData={formData}
            handleChange={handleChange}
            title='Add'
            btnValue='Add'
            handleSubmit={handleSubmit}
            date = {state.date}/>
        </div >
    )
}

export default AddPatient