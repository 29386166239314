import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import { Audio } from 'react-loader-spinner';
import ReactToPrint from 'react-to-print';
import { BsPrinterFill } from 'react-icons/bs'
import axios from 'axios';

const SelectedReport = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id ,patient_id_no } = useParams();
    const base_url = baseUrl();
    const authToken = getToken();
    const [report, setreport] = useState();
    const [loading, setloading] = useState(true);
    const componentRef = useRef()

    const fetchReport = async () => {
        try {
            const res = await axios.get(`${base_url}/get_selected_report/${state}/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            setreport(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchReport()
    }, [])
    return (
        <div className='mainWindow'>
            {loading ? <Audio color='cadetblue' height={30} /> : <>
               
                <h2 style={{ 'width': '8.2in', 'margin': '0 auto 0.2rem' }}>
                    <ReactToPrint
                        trigger={() => <button className='h2_left no_border_btn'><BsPrinterFill size={20} color='gray' /></button>}
                        content={() => componentRef.current}
                        onAfterPrint={() => {
                            navigate(-1)
                        }} />
                        Report for ID : {patient_id_no}
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                <div className="allReportWrapper">
                    <div className='reportPrintDiv' ref={componentRef}>
                        <div className="" dangerouslySetInnerHTML={{ __html: report }}></div>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default SelectedReport