import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import { DateFormat } from '../../services/DateFormat';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2'
import './refferrerStyle.css'
import { GetDate } from '../../services/Date';

const ReffererPayment = () => {
    const date = GetDate();
    const navigate = useNavigate();
    const base_Url = baseUrl();
    const authToken = getToken();
    const { id } = useParams();
    const { refferer_name } = useParams();
    const [loading, setLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState({});
    const [submitBtnValue, setSubmitBtnValue] = useState('Proceed to pay');

    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_Url}/refferer_payment_data/${id}`, {
                headers: {
                    "Accept": 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            if (res.data.status === 'success') {
                setFetchedData(res.data)
                setLoading(false)
            } else if (res.data.status === 'info') {
                swal.fire({
                    'title': '',
                    'text': res.data.message,
                    'icon': res.data.status
                })
                navigate('/refferer_manage')
            }

        } catch (error) {
            console.log(error)
        }
    }
    const confirmPayment = async () => {
        setSubmitBtnValue('Please wait..')
        try {
            const res = await axios.post(`${base_Url}/make_ref_payment/${id}`, {
                'pay_status': 1,
                'payment_amount': fetchedData.total_due,
                'from_date': fetchedData.from_date,
                'to_date': fetchedData.to_date,
                'payment_date': date
            }, {
                headers: {
                    "Accept": 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setSubmitBtnValue('Proceed to pay')
            swal.fire({
                'title': '',
                'text': res.data.message,
                'icon': res.data.status
            })
            if (res.data.status === 'success') {
                navigate(`/refferer_payment_token/${res.data.token}`)
            } else {
                alert('not success')
            }

        } catch (error) {
            setSubmitBtnValue('Proceed to pay')
            console.log(error)
        }
    }
    const handlePayBtn = () => {
        swal.fire({
            'title': 'Do you want to save the changes.?',
            'showDenyButton': true,
            'showCancelButton': true,
            'confirmButtonText': 'Save',
            'denyButtonText': "Don't save",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmPayment();
            } else if (result.isDenied) {
                swal.fire('Payment not completed.!', 'info')
            }
        })

    }
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className='mainWindow'>
            <div className="my_container">
                <h2>Refferer Payment - {refferer_name}
                    <Link to={-1} className='h2_right_btn cancelBtn'> &times;</Link>
                </h2>
                {loading ? 'Loading...' : <>
                    <div className="reffererPaymentWrapper">
                        <h3><u>From {DateFormat(fetchedData.from_date)} to {DateFormat(fetchedData.to_date)}</u></h3>
                        <p><strong>Total due : {fetchedData.total_due} /-</strong></p>
                        <button className='my_btn submit_btn' onClick={handlePayBtn}>{submitBtnValue}</button>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default ReffererPayment