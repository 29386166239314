import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import DoctorForm from './DoctorForm'

const EditDoctor = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    const { id } = useParams()
    const base_url = baseUrl();
    const auth_token = getToken()
    const [doctorData, setDoctorData] = useState({
        'id': id,
        'doctor_name': '',
        'doctor_degree': '',
        'doctor_details': '',
        'doctor_new_fee': '',
        'doctor_f_up_fee': '',
    });
    const [submitBtnValue, setSubmitBtnValue] = useState('Update');


    const handleChange = (e) => {
        setDoctorData({
            ...doctorData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitBtnValue('Please wait...')
        try {
            const res = await axios.post(`${base_url}/update_doctor/${id}`, doctorData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${auth_token}`
                }
            })
            setSubmitBtnValue('Update')
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            navigate(-1)
        } catch (error) {
            setSubmitBtnValue('Update')
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'error',
                    text: error.response.data.message
                })
            } else {
                console.log(error)
            }
        }

    }
    useEffect(() => {
        if (state) {
            setDoctorData(
                {
                    ...doctorData,
                    'doctor_name': state.doctor_name,
                    'doctor_degree': state.doctor_degree,
                    'doctor_details': state.doctor_details,
                    'doctor_new_fee': state.new_patient_fee,
                    'doctor_f_up_fee': state.f_up_patient_fee,
                }
            )
        }
    }, [])
    return (
        <div className='mainWindow'>
            <DoctorForm
                title='Edit doctor'
                doctorData={doctorData}
                submitBtnValue={submitBtnValue}
                handleChange={(e) => handleChange(e)}
                handleSubmit={(e) => handleSubmit(e)}
            />
        </div>
    )
}

export default EditDoctor