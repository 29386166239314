import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import Swal from 'sweetalert2';
import './refferrerStyle.css'
import { DateFormat } from '../../services/DateFormat';
import { Audio } from 'react-loader-spinner';
import Pagination from '../../services/Pagination';

const ReffererPaymentHistory = () => {
    const { id } = useParams();
    const { refferer_name } = useParams();
    const [fetchedData, setFetchedData] = useState([]);
    const [noPayment, setNoPayment] = useState(false);
    const [loading, setLoading] = useState(true);
    const base_url = baseUrl();
    const auth_token = getToken();

    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 15;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedData = fetchedData.slice(firstElementIndex, lastElementIndex)
    ///Pagination --------------------End

    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/show_refferer_payment/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${auth_token}`
                }
            })
            setLoading(false)
            if (res.data.status === 'success') {
                setFetchedData(res.data.db_data)
                if (res.data.db_data.length < 1) {
                    setNoPayment(true)
                }
            } else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className='mainWindow'>
            <div className="my_container">
                <h2>Payment History For {refferer_name}
                    <Link to={-1} className='h2_right_btn cancelBtn'>&times;</Link>
                </h2>
                <div className="paymentHistoryWrapper">
                    {loading ? <Audio color='cadetblue' height={30} /> : <>
                        {fetchedData.length < 1 ? <p className='notFound'>No history found</p> : <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Payment Date</th>
                                        <th>Payment Period</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map((elem) =>
                                        <tr key={elem.id}>
                                            <td>{DateFormat(elem.payment_date)}</td>
                                            <td>{DateFormat(elem.from_date)} -- {DateFormat(elem.to_date)}</td>
                                            <td>{elem.payment_amount} /-</td>
                                        </tr>)}
                                </tbody>
                            </table>
                            <Pagination
                            elementsPerPage={elementsPerPage}
                            totaElements={fetchedData.length}
                            pageNo={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                        </>}

                    </>}
                </div>
            </div>
        </div>
    )
}

export default ReffererPaymentHistory