import React, { useEffect, useState } from 'react'
import './expenditureStyle.css'
import { GetDate } from '../../services/Date'
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner';
import Modal from '../modal/Modal';
import { TiDeleteOutline } from "react-icons/ti";
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineScreenSearchDesktop } from "react-icons/md"
import { useAppContext } from '../../contex/appContext';

const AddExpense = () => {
    const {userData} = useAppContext()
    const navigate = useNavigate()
    const base_url = baseUrl();
    const authToken = getToken();
    const today = GetDate();
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [loading, setloading] = useState(true)
    const [todaysExpenses, settodaysExpenses] = useState([])
    const [addFormData, setaddFormData] = useState({
        'date': today,
        'expenditure_type': '',
        'amount': '',
        'remarks': '',
    })
    const fetchTodaysExpenses = async () => {
        try {
            const res = await axios.get(`${base_url}/show_todays_expenses/`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            settodaysExpenses(res.data)
            setloading(false)
        } catch (error) {
            console.log(error)
        }
    }
    const handleAddChange = e => {
        setaddFormData({
            ...addFormData,
            [e.target.name]: e.target.value
        })
    }
    const addExpense = async e => {
        e.preventDefault();
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/add_expense`, addFormData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                fetchTodaysExpenses();
                setaddFormData({
                    'date': today,
                    'expenditure_type': '',
                    'amount': '',
                    'remarks': '',
                })
            }
            setshowWaitModal(false)
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'info',
                    text: error.response.data.message
                })
            }
        }
    }
    const deleteRequest = async i => {
        setshowWaitModal(true)
        try {
            const res = await axios.delete(`${base_url}/delete_expense/${i}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            fetchTodaysExpenses()
            Swal.fire({
                icon: res.data.status,
                text: res.data.message,
            })
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
        }
    }
    const handleDelete = i => {
        Swal.fire({
            icon: 'warning',
            text: 'Delete this expense..?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: 'tomato',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRequest(i)
            }
        })
    }

    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager') {
            navigate('/')
        }
        fetchTodaysExpenses();
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Please wait...' title='wait' /> : <></>}
            <div className="my_container flexDiv">
                <div className="addExpenseDiv">
                    <form className="expenditureForm" onSubmit={addExpense}>
                        <h2>Add Expense</h2>
                        <label htmlFor="date">Date : </label><br />
                        <input type="date" name="date" id="date" className="my_field"
                            value={addFormData.date} onChange={handleAddChange} />
                        <br />
                        <label htmlFor="expenditure_type">Expenditure type : </label>
                        <input type="text" name="expenditure_type" id="expenditure_type" className="my_field w_100"
                            value={addFormData.expenditure_type} onChange={handleAddChange} />
                        <br />
                        <label htmlFor="amount"> Amount: </label><br />
                        <input type="number" name="amount" id="amount" className="my_field w_100"
                            value={addFormData.amount} onChange={handleAddChange} />
                        <label htmlFor="remarks"> Remarks: </label><br />
                        <textarea name="remarks" id="remarks" className='my_field w_100' rows="4"
                            value={addFormData.remarks} onChange={handleAddChange} >
                        </textarea>
                        <br />
                        <input type="submit" value="Add" className="my_btn submit_btn w_100 py_4" />
                    </form>
                </div>
                <div className="todaysExpenseDiv">
                    <h2> <span className="h2_left">
                        {todaysExpenses.reduce((a, b) => {
                            return a + parseInt(b.amount)
                        }, 0)}
                    </span>
                        Today's Expenses
                        <Link className="h2_right_btn" to='/search_expenditure'>
                            <MdOutlineScreenSearchDesktop size={20} color='grey' />
                        </Link>
                    </h2>
                    {loading ? <Audio color='cadetblue' height={30} /> : <>
                        {todaysExpenses.length < 1 ? <h3>No expense found</h3> : <>
                            <div className="expenseTableWrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Expenditure type</th>
                                            <th>Amount</th>
                                            <th>Remarks</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {todaysExpenses.map(el =>
                                            <tr key={el.id}>
                                                <td>{el.expenditure_type}</td>
                                                <td>{el.amount}</td>
                                                <td>{el.remarks}</td>
                                                <td>
                                                    {el.auto ? '' :
                                                        <button className='no_border_btn' onClick={i => handleDelete(el.id)}>
                                                            <TiDeleteOutline color='tomato' size={20} />
                                                        </button>}
                                                </td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </>}

                    </>}
                </div>

            </div>
        </div>
    )
}

export default AddExpense