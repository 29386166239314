import { useAppContext } from "../../contex/appContext"
import { Link } from 'react-router-dom'
import './profileStyle.css'
import BlankProfileImage from '../../Images/blankProfile.png'
import { DateFormat } from '../../services/DateFormat'
import { GetDate } from '../../services/Date'
import { baseUrlMain } from "../../services/baseUrl"

const Profile = () => {
    const base_url_main = baseUrlMain();
    const today = GetDate()
    const { userData, loading } = useAppContext();

    return (
        <div className="mainWindow">

            <div className="profileWrapper">
                <div className="profileUpperRow">
                    <div className="profileUpperRowLeft">
                        {userData.profile_pic != null ?
                            <img className="profileImage" src={`${base_url_main}/${userData.profile_pic}`} alt="blank profile" /> :
                            <img className="profileImage" src={BlankProfileImage} alt="blank profile" />
                        }
                    </div>
                    <div className="profileUpperRowRight">
                        <h3>{userData.name}</h3>
                        <h4>{userData.user_type}</h4>
                    </div>
                    <div className="btnDiv">
                        {userData.user_type !== 'doctor' && userData.user_type !== 'single_doctor' ?
                            <Link className="my_btn addBtn" to={'/change_password'}>Change password</Link>
                            : <Link className="my_btn addBtn" to={'/doctor/change_password'}>Change password</Link>}
                        {userData.user_type !== 'doctor' && userData.user_type !== 'single_doctor' ?
                            <Link className="my_btn blueBtn" to={'/change_profile_pic'}>Change picture</Link>
                            : <Link className="my_btn blueBtn" to={'/doctor/change_profile_pic'}>Change picture</Link>}
                    </div>
                </div>
                <br />
                <hr />
                <div className="profileLowerRow">
                    <table className="profileTable">
                        <tbody>
                            <tr>
                                <td>Name : </td>
                                <td>{userData.name}</td>
                            </tr>
                            <tr>
                                <td>Email : </td>
                                <td>{userData.email}</td>
                            </tr>
                            <tr>
                                <td>Phone : </td>
                                <td>{userData.phone}</td>
                            </tr>
                            {userData.user_type === 'admin' ? <>
                                <tr>
                                    <td>Validity : </td>
                                    <td className={today > userData.validity ? 'failed' : 'success'}>{DateFormat(userData.validity)}</td>
                                </tr>
                                <tr>
                                    <td>Fee : </td>
                                    <td>BDT {userData.subscription_fee} / month</td>
                                </tr>
                                <tr>
                                    <td>Max doctor user : </td>
                                    <td>{userData.max_doctor_user}</td>
                                </tr>
                            </> : <>
                                {userData.user_type !== 'single_doctor' ? <>
                                    <tr>
                                        <td>Admin Email : </td>
                                        <td>{userData.admin_email}</td>
                                    </tr>
                                </> : <></>}
                            </>}
                        </tbody>
                    </table>
                </div>


            </div>



        </div>
    )
}

export default Profile