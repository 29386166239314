import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getToken } from '../../localStorageManage/localStore'
import { baseUrl } from '../../services/baseUrl';
import Swal from 'sweetalert2';
import { GrFormView } from "react-icons/gr";
import { FaRegEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
    const base_url = baseUrl();
    const authToken = getToken();
    const navigate = useNavigate()
    const [submitBtnValue, setSubmitBtnValue] = useState('Change');
    const [formData, setFormData] = useState({
        'existing_password': '',
        'password': '',
        'password_confirmation': ''
    })
    const [showPasswordPrev, setshowPasswordPrev] = useState(false)
    const [showPassword, setshowPassword] = useState(false)
    const [showPassword2, setshowPassword2] = useState(false)
    const [resMsg, setResMsg] = useState('')
    const [resAlert, setResAlert] = useState('')
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setSubmitBtnValue('Please wait..')
        try {
            const res = await axios.post(`${base_url}/change_password`, formData,
                {
                    headers: {
                        'Content-Type': 'application/json', 'accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                });
            setSubmitBtnValue('Change')
            Swal.fire({
                icon: res.data.status,
                text: res.data.message,
                timer: 2000
            })
            setTimeout(() => {
                if (res.data.status === 'success') {
                    navigate('/')
                }
            }, 1500)
        } catch (error) {
            setSubmitBtnValue('Change')
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'info',
                    text: error.response.data.message,
                })
            }
        }

    }

    return (
        <div className='mainWindow'>
            <form className="changePasswordForm" onSubmit={e => handleSubmit(e)}>
                <h2>MT Lab, BD</h2>
                <h3>Change password</h3>
                <div className="passFormGroup">
                    <input type={showPasswordPrev ? "text" : "password"}  className='my_field font_sm' style={{ width: '16rem' }} name='existing_password' onChange={e => handleChange(e)}
                        placeholder='Existing password' value={formData.existing_password} />
                    <label className="viewPasslabel" htmlFor="showPassCB">{showPasswordPrev ? < FaRegEyeSlash size={18} color="black" /> : <GrFormView size={20} color="gray" />}</label>
                    <input type="checkbox" className="hidden" id="showPassCB" onChange={() => setshowPasswordPrev(!showPasswordPrev)} />
                </div>

                <br />
                <div className="passFormGroup">
                    <input type={showPassword ? "text" : "password"} className='my_field font_sm' style={{ width: '16rem' }} name='password' onChange={e => handleChange(e)}
                        value={formData.password} placeholder='Password' />
                    <label className="viewPasslabel" htmlFor="showPassCB">{showPassword ? < FaRegEyeSlash size={18} color="black" /> : <GrFormView size={20} color="gray" />}</label>
                    <input type="checkbox" className="hidden" id="showPassCB" onChange={() => setshowPassword(!showPassword)} />
                </div>
                <br />
                <div className="passFormGroup">
                    <input type={showPassword2 ? "text" : "password"} className='my_field font_sm' style={{ width: '16rem' }} name='password_confirmation' onChange={e => handleChange(e)}
                        value={formData.password_confirmation} placeholder='Confirm new password' />
                    <label className="viewPasslabel" htmlFor="showPassCB2">{showPassword2 ? < FaRegEyeSlash size={18} color="black" /> : <GrFormView size={22} color="gray" />}</label>
                    <input type="checkbox" className="hidden" id="showPassCB2" onChange={() => setshowPassword2(!showPassword2)} />
                </div>
                <br />
                <small className='nb'> Your password must contain at least 8 characters (at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character i.e: @, #, $, %, &)</small>
                <br />
                <input type="submit" value={submitBtnValue} style={{ marginTop: "0.5rem" }} className='my_btn submit_btn' />
                <Link to={-1} className='my_btn blueBtn' style={{ marginTop: "0.5rem" }} >Cancel</Link>
            </form>
        </div>
    )
}

export default ChangePassword