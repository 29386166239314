import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2'
import { useAppContext } from '../../contex/appContext';
import Swal from 'sweetalert2';

const EditPatient = () => {
    const { state } = useLocation();
    const app_context = useAppContext()
    const { userData } = useAppContext();
    const navigate = useNavigate()
    const authToken = getToken();
    const base_url = baseUrl();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        'patient_id_no': '',
        'patient_date': '',
        'patient_name': '',
        'patient_age': '',
        'patient_age_unit': 'Years',
        'patient_sex': 'F',
        'patient_ref_by': '',
        'patient_phone': '',
    });
    const [fieldErrors, setFieldErrors] = useState({
        'patient_id_no': '',
        'patient_date': '',
        'patient_name': '',
        'patient_age': '',
        'patient_ref_by': '',
    })
    const [resAlert, setResAlert] = useState('');
    const [refferers, setRefferers] = useState([]);
    const [submitBtnValue, setSubmitBtnValue] = useState('Update');
    const [showRefferers, setShowRefferers] = useState(false);


    const handleSelectedRefferer = (name) => {
        setFormData({
            ...formData,
            'patient_ref_by': name
        })
        setShowRefferers(false)
    }
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitBtnValue('Please wait..')
        setResAlert('');
        setFieldErrors({
            'patient_id_no': '',
            'patient_date': '',
            'patient_name': '',
            'patient_age': '',
            'patient_ref_by': '',
        })

        try {
            const res = await axios.post(`${base_url}/update_patient/${id}`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setSubmitBtnValue('Update')
            if (res.data.status === 'success') {
                swal.fire({
                    'icon': res.data.status,
                    'text': res.data.message,
                })
                navigate( state.redir_form , { state: { searchKey: state.searchKey } })
            } else {
                swal.fire({
                    'icon': res.data.status,
                    'text': res.data.message,
                })
            }

        } catch (error) {
            setSubmitBtnValue('Add')
            if (error.response.status === 422) {
                setResAlert('failed')
                setFieldErrors({
                    'patient_id_no': error.response.data.errors.patient_id_no,
                    'patient_date': error.response.data.errors.patient_date,
                    'patient_name': error.response.data.errors.patient_name,
                    'patient_age': error.response.data.errors.patient_age,
                    'patient_ref_by': error.response.data.errors.patient_ref_by,
                })
            }
        }
    }
    const handleFocusRefferer = () => {
        setShowRefferers(true)
    }
    const handleReffererChange = async e => {
        setFormData({
            ...formData,
            'patient_ref_by': e.target.value
        })
        try {
            const res = await axios.post(`${base_url}/show_refferers`, { data: e.target.value }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setRefferers(res.data)
        } catch (error) {
            console.log(error)

        }
    }
    useEffect(() => {
        if (userData.user_type === 'admin' | userData.user_type === 'manager' | userData.user_type === 'reception') {
            setFormData({
                'patient_id_no': state.data.patient_id_no.slice(4),
                'patient_date': state.data.patient_date,
                'patient_name': state.data.patient_name,
                'patient_age': state.data.patient_age,
                'patient_age_unit': state.data.patient_age_unit,
                'patient_sex': state.data.patient_sex,
                'patient_ref_by': state.data.refferer_name,
                'patient_phone': state.data.patient_phone,
            })
        } else {
            Swal.fire({
                text: 'You are not authorized..',
                icon: 'error'
            })
            navigate('/')

        }

    }, [])
    return (
        <div className='mainWindow'>
            <div className="my_container">
                <h2>Edit Patient
                    <Link className='h2_right_btn cancelBtn' state={{ searchKey: state.searchKey }} to={-1}>&times;</Link>
                </h2>
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="my_form_row">
                        <div className="my_input_group">
                            <label htmlFor="patient_id_no"> Id:</label>
                            <input type="text" className="my_field" id="patient_id_no" name='patient_id_no'
                                onChange={e => handleChange(e)} value={formData.patient_id_no} />
                            {fieldErrors.patient_id_no !== '' && fieldErrors.patient_id_no ?
                                <div className={resAlert}>{fieldErrors.patient_id_no}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="patient_date"> Date:</label>
                            <input type="date" className="my_field" id="patient_date" name='patient_date'
                                onChange={e => handleChange(e)} value={formData.patient_date} />
                            {fieldErrors.patient_date !== '' && fieldErrors.patient_date ?
                                <div className={resAlert}>{fieldErrors.patient_date}</div> : ''}
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="my_form_row">
                        <div className="my_input_group">
                            <label htmlFor="patient_name"> Patient name:</label>
                            <input type="text" className="my_field" id="patient_name" name='patient_name'
                                onChange={e => handleChange(e)} value={formData.patient_name} />
                            {fieldErrors.patient_name !== '' && fieldErrors.patient_name ?
                                <div className={resAlert}>{fieldErrors.patient_name}</div> : ''}
                        </div>

                        <div className="my_input_group">
                            <label htmlFor="patient_age"> Age:</label>
                            <input type="text" className="my_field " id="patient_age" name='patient_age'
                                onChange={e => handleChange(e)} value={formData.patient_age} />
                            {fieldErrors.patient_age !== '' && fieldErrors.patient_age ?
                                <div className={resAlert}>{fieldErrors.patient_age}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="patient_age_unit"> Age unit:</label>
                            <select className="my_field " id="patient_age_unit" name='patient_age_unit'
                                onChange={e => handleChange(e)} value={formData.patient_age_unit} >
                                <option value="Years">Years</option>
                                <option value="Months">Months</option>
                                <option value="Days">Days</option>
                                <option value="Year">Years</option>
                                <option value="Month">Months</option>
                                <option value="Day">Days</option>
                            </select>
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="patient_sex"> Sex:</label>
                            <select className="my_field " id="patient_sex" name='patient_sex'
                                onChange={e => handleChange(e)} value={formData.patient_sex} >
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="3rd">3rd</option>
                            </select>
                        </div>
                    </div>
                    <div className="my_form_row">
                        <div className="my_input_group">
                            <label htmlFor="patient_ref_by"> Refferd by:</label>
                            <input type="text" className="my_field " id="patient_ref_by" name='patient_ref_by'
                                onChange={e => handleReffererChange(e)} value={formData.patient_ref_by}
                                onFocus={handleFocusRefferer} autoComplete='off' />
                            {fieldErrors.patient_ref_by !== '' && fieldErrors.patient_ref_by ?
                                <div className={resAlert}>{fieldErrors.patient_ref_by}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="patient_phone"> Patient phone:</label>
                            <input type="text" className="my_field " id="patient_phone" name='patient_phone'
                                onChange={e => handleChange(e)} value={formData.patient_phone}
                            />
                        </div>
                        <div className='my_input_group'>
                            <br />
                            <input type="submit" style={{ 'marginTop': '0.2rem' ,'padding': '0.5rem' }} value={submitBtnValue} className='my_btn submit_btn' />
                        </div>
                    </div>
                    <div className='my_form_row'>
                        <div className={showRefferers ? 'refferersDiv showRefferers' : 'refferersDiv hideRefferers'}>
                            {refferers.map(el =>
                                <div key={el.id} onClick={() => { handleSelectedRefferer(el.refferer_name) }}>
                                    {el.refferer_name}
                                </div>)}
                        </div>
                        <div>

                        </div>

                    </div>

                </form>
            </div>
        </div>
    )
}

export default EditPatient