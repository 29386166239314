import './App.css';
import { Routes, Route} from 'react-router-dom';
import ResetPassword from './components/auth/ResetPassword';
import Home from './components/routes/Home';
import Login from './components/auth/Login';
import Profile from './components/profile/Profile';
import ChangePassword from './components/auth/ChangePassword';
import ForgetPassword from './components/auth/ForgetPassword';
import User from './components/user/User';
import IndexPage from './components/IndexPage';
import AddUser from './components/user/AddUser';
import NewPatient from './components/routes/NewPatient';
import AddServicesForPatients from './components/routes/AddServicesForPatients';
import IncludeServices from './components/include services/IncludeServices';
import ShowIncludedServices from './components/include services/ShowIncludedServices';
import EditIncludedService from './components/include services/EditIncludedService';
import PatientManagement from './components/routes/PatientManagement';
import TodaysPatientsAll from './components/allDepts/TodaysPatientsAll';
import EditPatient from './components/routes/EditPatient';
import ShowClinicDetails from './components/clinicDetails/ShowClinicDetails';
import EditClinicDetails from './components/clinicDetails/EditClinicDetails';
import TodaysTransaction from './components/routes/transaction/TodaysTransaction';
import SearchTransaction from './components/routes/transaction/SearchTransaction';
import Invoice from './components/invoice/Invoice';
import ReffererList from './components/refferer/ReffererList';
import ReffererPatients from './components/refferer/ReffererPatients';
import ReffererPayment from './components/refferer/ReffererPayment';
import ReffererPaymentToken from './components/refferer/ReffererPaymentToken';
import ReffererPaymentHistory from './components/refferer/ReffererPaymentHistory';
import SingleField from './components/resultForms/pathology/SingleField';
import SingleSelect from './components/resultForms/pathology/SingleSelect';
import DoctorHome from './components/doctorAll/DoctorHome';
import DoctorSearchPatients from './components/doctorAll/DoctorSearchPatients';
import DoctorPrescription from './components/doctorAll/DoctorPrescription';
import DoctorsList from './components/doctorManagement/DoctorsList';
import EditDoctor from './components/doctorManagement/EditDoctor';
import AddDoctor from './components/doctorManagement/AddDoctor';
import DoctorSchedule from './components/doctorManagement/DoctorSchedule';
import AssignDoctorAsUser from './components/doctorManagement/AssignDoctorAsUser';
import DoctorPatientSerial from './components/doctorManagement/DoctorPatientSerial';
import Loading from './components/Loading';
import Reload from './components/routes/Reload';
import DoctorWaitingPatients from './components/doctorAll/DoctorWaitingPatients';
import EditUser from './components/user/EditUser';
import SearchPatients from './components/allDepts/SearchPatients';
import BgRh from './components/resultForms/pathology/BgRh';
import MasterAdminHome from './components/master_admin/MasterAdminHome';
import MasterAdminDashboard from './components/master_admin/MasterAdminDashboard';
import ClientsAdmins from './components/master_admin/ClientsAdmins';
import SingleDoctors from './components/master_admin/SingleDoctors';
import AddClientAdmin from './components/master_admin/AddClientAdmin';
import EditClientAdmin from './components/master_admin/EditClientAdmin';
import CustomMail from './components/master_admin/CustomMail';
import AddSingleDoctor from './components/master_admin/AddSingleDoctor';
import PrescriptionData from './components/master_admin/prescriptionData/PrescriptionData';
import Cbc from './components/resultForms/pathology/Cbc';
import InactiveUser from './components/inactiveUser/InactiveUser';
import InactiveAdmin from './components/inactiveUser/InactiveAdmin';
import ClinicDoctors from './components/master_admin/ClinicDoctors';
import Others from './components/resultForms/others/Others';
import UrineRE from './components/resultForms/pathology/UrineRE';
import ChangeProfile from './components/profile/ChangeProfile';
import UserRequests from './components/master_admin/UserRequests';
import Electrolytes from './components/resultForms/pathology/Electrolytes';
import StoolRE from './components/resultForms/pathology/StoolRE';
import SemenAnalysis from './components/resultForms/pathology/SemenAnalysis';
import Report from './components/report/Report';
import AddReporter from './components/reporter manage/AddReporter';
import Reporters from './components/reporter manage/Reporters';
import EditReporter from './components/reporter manage/EditReporter';
import Widal from './components/resultForms/pathology/Widal';
import CrossMatch from './components/resultForms/pathology/CrossMatch';
import SelectedReport from './components/report/SelectedReport';
import AddExpense from './components/expenditure/AddExpense';
import SearchExpenditure from './components/expenditure/SearchExpenditure';
import SearchSummary from './components/summary/SearchSummary';
import EditSiteDetails from './components/master_admin/EditSiteDetails';
import AddMasterUser from './components/master_admin/AddMasterUser';
import DueCollection from './components/dueCollectionRefund/DueCollection';
import Refund from './components/dueCollectionRefund/Refund';
import SingleDoctorAssistant from './components/doctorAll/SingleDoctorAssistant';
import AddAssistant from './components/doctorAll/AddAssistant';
import DoctorAssistantHome from './components/doctorAssistant/DoctorAssistantHome';
import TodaysPatients from './components/doctorAssistant/TodaysPatients';
import AddPatient from './components/doctorAssistant/AddPatient';
import SingleDoctorSchedule from './components/doctorAll/SingleDoctorSchedule';
import EditSingleDoctorPatient from './components/doctorAssistant/EditSingleDoctorPatient';
import ShowAssistantSchedules from './components/doctorAssistant/ShowAssistantSchedules';
import AssistantSchedulePatients from './components/doctorAssistant/AssistantSchedulePatients';
import SingleDoctorWatingPatients from './components/doctorAll/SingleDoctorWatingPatients';
import DoctorKeywords from './components/doctorAll/DoctorKeywords';
import AddPatientByDoctor from './components/doctorAll/AddPatientByDoctor';
import NotFound from './components/routes/NotFound';

function App() {
  return (
    <Routes>

      <Route path='*' element={<NotFound />} /> 
      <Route path='/' element={<Home />} > 
        <Route index element={<IndexPage />} />
        <Route path='profile' element={<Profile />} />
        <Route path='change_profile_pic' element={<ChangeProfile />} />
        <Route path='change_password' element={<ChangePassword />} />
        <Route path='user' element={<User />} />
        <Route path='add_user' element={<AddUser />} />
        <Route path='edit_user/:id' element={<EditUser />} />
        <Route path='new_patient' element={<NewPatient />} />
        <Route path='edit_patient/:id' element={<EditPatient />} />
        <Route path='report/:id/:patient_id_no' element={<Report />} />
        <Route path='print_selected_report/:id/:patient_id_no' element={<SelectedReport />} />
        <Route path='services_for_patients/:id' element={<AddServicesForPatients />} />
        <Route path='included_services' element={<ShowIncludedServices />} />
        <Route path='include_service' element={<IncludeServices />} />
        <Route path='edit_included_service/:id' element={<EditIncludedService />} />
        <Route path='patient_management' element={<PatientManagement />} />
        <Route path='todays_patients' element={<TodaysPatientsAll />} />
        <Route path='search_patient' element={<SearchPatients />} />
        <Route path='show_clinic_details' element={<ShowClinicDetails />} />
        <Route path='edit_clinic_details' element={<EditClinicDetails />} />
        <Route path='todays_transaction' element={<TodaysTransaction />} />
        <Route path='search_transaction' element={<SearchTransaction />} />
        <Route path='invoice/:id' element={<Invoice />} />
        <Route path='refferer_manage' element={<ReffererList />} />
        <Route path='refferer_patients/:id/:refferer_name' element={<ReffererPatients />} />
        <Route path='refferer_payment/:id/:refferer_name' element={<ReffererPayment />} />
        <Route path='refferer_payment_token/:token_no' element={<ReffererPaymentToken />} />
        <Route path='payment_history/:id/:refferer_name' element={<ReffererPaymentHistory />} />
        <Route path='expenditure' element={<AddExpense />} />
        <Route path='search_expenditure' element={<SearchExpenditure />} />
        <Route path='search_summary' element={<SearchSummary />} />
        <Route path='due_collection' element={<DueCollection />} />
        <Route path='refund' element={<Refund />} />

        <Route path='field/:id/:patient_id' element={<SingleField />} />
        <Route path='select/:id/:patient_id' element={<SingleSelect />} />
        <Route path='cbc/:id/:patient_id' element={<Cbc />} />
        <Route path='bg_rh/:id/:patient_id' element={<BgRh />} />
        <Route path='cross_match/:id/:patient_id' element={<CrossMatch />} />
        <Route path='widal/:id/:patient_id' element={<Widal />} />
        <Route path='mt/:id/:patient_id' element={<Others />} />
        <Route path='lipid_profile/:id/:patient_id' element={<Others />} />
        <Route path='c_s/:id/:patient_id' element={<Others />} />
        <Route path='others/:id/:patient_id' element={<Others />} />
        <Route path='usg_pp/:id/:patient_id' element={<Others />} />
        <Route path='usg_wa_female/:id/:patient_id' element={<Others />} />
        <Route path='usg_wa_male/:id/:patient_id' element={<Others />} />
        <Route path='usg_la_female/:id/:patient_id' element={<Others />} />
        <Route path='usg_la_male/:id/:patient_id' element={<Others />} />
        <Route path='usg_pp/:id/:patient_id' element={<Others />} />
        <Route path='usg_ua/:id/:patient_id' element={<Others />} />
        <Route path='cxr_p_a/:id/:patient_id' element={<Others />} />
        <Route path='cxr_ap/:id/:patient_id' element={<Others />} />
        <Route path='cxr_bv/:id/:patient_id' element={<Others />} />
        <Route path='kub_xray/:id/:patient_id' element={<Others />} />
        <Route path='ls_spine_xray/:id/:patient_id' element={<Others />} />
        <Route path='urine_re/:id/:patient_id' element={<UrineRE />} />
        <Route path='stool_re/:id/:patient_id' element={<StoolRE />} />
        <Route path='semen_analysis/:id/:patient_id' element={<SemenAnalysis />} />
        <Route path='electrolytes/:id/:patient_id' element={<Electrolytes />} />

        <Route path='doctor_management' element={<DoctorsList />} />
        <Route path='edit_doctor/:id' element={<EditDoctor />} />
        <Route path='add_doctor' element={<AddDoctor />} />
        <Route path='reporters' element={<Reporters />} />
        <Route path='add_reporter' element={<AddReporter />} />
        <Route path='edit_reporter/:id' element={<EditReporter />} />
        <Route path='doctor_schedule/:id/:doctor_name' element={<DoctorSchedule />} />
        <Route path='assign_doctor_as_user/:id/:doctor_name' element={<AssignDoctorAsUser />} />
        <Route path='doctor_patient_serial/:id/:doctor_name/:date' element={<DoctorPatientSerial />} />
      </Route>

      <Route path='/doctor' element={<DoctorHome />}  >
        <Route index element={<DoctorPrescription />} />
        <Route path='search_patients' element={<DoctorSearchPatients />} />
        <Route path='add_patients_by_doctor' element={<AddPatientByDoctor />} />
        <Route path='waiting_patients' element={<DoctorWaitingPatients />} />
        <Route path='change_password' element={<ChangePassword />} />
        <Route path='profile' element={<Profile />} />
        <Route path='change_profile_pic' element={<ChangeProfile />} />
        <Route path='change_password' element={<ChangePassword />} />
        <Route path='single_doctor_assistant' element={<SingleDoctorAssistant />} />
        <Route path='add_assistant' element={<AddAssistant />} />
        <Route path='add_schedule' element={<SingleDoctorSchedule />} />
        <Route path='keywords' element={<DoctorKeywords />} />
        <Route path='single_doctor_waiting_patients' element={<SingleDoctorWatingPatients />} />
      </Route>

      <Route path='/single_doctor_assistant' element={<DoctorAssistantHome />}  >
        <Route index element={<TodaysPatients />} />
        <Route path='profile' element={<Profile />} />
        <Route path='add_new' element={<AddPatient />} />
        <Route path='change_password' element={<ChangePassword />} />
        <Route path='schedules' element={<ShowAssistantSchedules />} />
        <Route path='schedule_patients/:date' element={<AssistantSchedulePatients />} />
        <Route path='edit_patient/:serial_id' element={<EditSingleDoctorPatient/>} />
      </Route>

      <Route path='/master_admin' element={<MasterAdminHome />}  >
        <Route index element={<MasterAdminDashboard />} />
        <Route path='client_admins' element={<ClientsAdmins />} />
        <Route path='single_doctors' element={<SingleDoctors />} />
        <Route path='clinic_doctors' element={<ClinicDoctors />} />
        <Route path='add_client_admin' element={<AddClientAdmin />} />
        <Route path='add_single_doctor' element={<AddSingleDoctor />} />
        <Route path='edit_client_admin/:id' element={<EditClientAdmin />} />
        <Route path='send_custom_mail/:email/:name' element={<CustomMail />} />
        <Route path='prescription_data' element={<PrescriptionData />} />
        <Route path='registration_requests' element={<UserRequests />} />
        <Route path='edit_site_details' element={<EditSiteDetails />} />
        <Route path='add_master_user' element={<AddMasterUser />} />
        <Route path='change_password' element={<ChangePassword />} />
      </Route>

      <Route path='/reset_password/:token' element={<ResetPassword />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forget_password' element={<ForgetPassword />} />
      <Route path='/loading' element={<Loading />} />
      <Route path='/reload' element={<Reload />} />
      <Route path='/inactive_user' element={<InactiveUser />} />
      <Route path='/inactive_admin' element={<InactiveAdmin />} />
      {/* <Route path='*' element={<Error />} /> */}
    </Routes>
  );
}

export default App;
