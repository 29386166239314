import React, { useState } from 'react'
import { usePrescriptionContext } from '../../../contex/prescriptionContext';
import axios from 'axios';
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';

const PrescriptionLeftCol = (props) => {
    const base_url = baseUrl();
    const authToken = getToken();
    const { prescriptionData, loading } = usePrescriptionContext()
    const [searchHistoryKeyword, setSearchHistoryKeyword] = useState('');
    const [searchOE_Keyword, setSearchOE_Keyword] = useState('');
    const [searchCCKeyword, setSearchCCKeyword] = useState('');
    const [searchDDKeyword, setSearchDDKeyword] = useState('');
    const [searchTestKeyword, setSearchTestKeyword] = useState('');
    const [searchDiagnosisKeyword, setSearchDiagnosisKeyword] = useState('');
    const [searchAdviceKeyword, setSearchAdviceKeyword] = useState('');
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [filteredDD, setFilteredDD] = useState([]);
    const [filteredTest, setFilteredTest] = useState([]);
    const [filteredOE, setFilteredOE] = useState([]);
    const [filteredCC, setFilteredCC] = useState([]);
    const [filteredDiagnosis, setFilteredDiagnosis] = useState([]);
    const [filteredAdvice, setFilteredAdvice] = useState([]);

    // History Starts*****************************************************

    const handle_history_change = e => {
        setSearchHistoryKeyword(e.target.value)
        let filtered_history = prescriptionData.history.filter((el) => {
            return el.history.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredHistory(filtered_history)
        if (e.target.value === '' || e.target.value === null) {
            setFilteredHistory([])
        }
    }
    const handleSelectHistory = (el) => {
        setSearchHistoryKeyword(el)
        setFilteredHistory([])
    }

    const addHistory = async () => {
        let includes = prescriptionData.history.filter((el) => {
            return el.history.toLowerCase().includes(searchHistoryKeyword.toLowerCase())
        })
        if (includes.length == 0) {
            props.setwaitMsg(true)
            try {
                const res = await axios.post(`${base_url}/add_doctor_history`, {
                    'data': searchHistoryKeyword
                }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
                props.setwaitMsg(false)
                // console.log(res.data)
            } catch (error) {
                props.setwaitMsg(false)
                console.log(error)
            }
        }
        if (localStorage.getItem('rahmatwebdev_doctors_history') === null) {
            localStorage.setItem('rahmatwebdev_doctors_history', JSON.stringify([]))
        }
        let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_history'));
        arry.push(searchHistoryKeyword)
        localStorage.setItem('rahmatwebdev_doctors_history', JSON.stringify(arry))
        props.showAddedHistory()
        setSearchHistoryKeyword('')
        setFilteredHistory([])
    }

    // History Ends*****************************************************
    // ---------------------------------------------------------------------------
    // CC Starts*****************************************************

    const handle_cc_change = e => {
        setSearchCCKeyword(e.target.value)
        let filtered_cc = prescriptionData.c_c.filter((el) => {
            return el.c_c.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredCC(filtered_cc)
        if (e.target.value === '' || e.target.value === null) {
            setFilteredCC([])
        }
    }
    const handleSelectCC = (el) => {
        setSearchCCKeyword(el)
        setFilteredCC([])
    }

    const addCC = async () => {
        let includes = prescriptionData.c_c.filter((el) => {
            return el.c_c.toLowerCase().includes(searchCCKeyword.toLowerCase())
        })
        if (includes.length === 0) {
            props.setwaitMsg(true)
            try {
                const res = await axios.post(`${base_url}/add_doctor_cc`, {
                    'data': searchCCKeyword
                }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
                props.setwaitMsg(false)
                console.log(res.data)
            } catch (error) {
                props.setwaitMsg(false)
                console.log(error)
            }
        }
        if (localStorage.getItem('rahmatwebdev_doctors_cc') === null) {
            localStorage.setItem('rahmatwebdev_doctors_cc', JSON.stringify([]))
        }
        let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_cc'));
        arry.push(searchCCKeyword)
        localStorage.setItem('rahmatwebdev_doctors_cc', JSON.stringify(arry))
        props.showAddedCC()
        setSearchCCKeyword('')
        setFilteredCC([])
    }

    // CC Ends*****************************************************

    // ---------------------------------------------------------------------------
    // Test Starts*****************************************************

    const handle_test_change = e => {
        setSearchTestKeyword(e.target.value)
        let filtered_test = prescriptionData.advice_tests.filter((el) => {
            return el.advice_test_name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredTest(filtered_test)
        if (e.target.value === '' || e.target.value === null) {
            setFilteredTest([])
        }
    }
    const handleSelectTest = (el) => {
        setSearchTestKeyword(el)
        setFilteredTest([])
    }

    const addTest = async () => {
        let includes = prescriptionData.advice_tests.filter((el) => {
            return el.advice_test_name.toLowerCase().includes(searchTestKeyword.toLowerCase())
        })
        if (includes.length === 0) {
            props.setwaitMsg(true)
            try {
                const res = await axios.post(`${base_url}/add_doctor_test`, {
                    'data': searchTestKeyword
                }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
                props.setwaitMsg(false)
                console.log(res.data)
            } catch (error) {
                props.setwaitMsg(false)
                console.log(error)
            }
        }
        if (localStorage.getItem('rahmatwebdev_doctors_test') === null) {
            localStorage.setItem('rahmatwebdev_doctors_test', JSON.stringify([]))
        }
        let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_test'));
        arry.push(searchTestKeyword)
        localStorage.setItem('rahmatwebdev_doctors_test', JSON.stringify(arry))
        props.showAddedTest()
        setSearchTestKeyword('')
        setFilteredTest([])
    }

    //Test Ends*****************************************************
    //----------------------------------------------------------------------------------
    //----------------------------------------------------------------------------------


    // DD Starts*****************************************************

    const handle_dd_change = e => {
        setSearchDDKeyword(e.target.value)
        let filtered_dd = prescriptionData.d_d.filter((el) => {
            return el.d_d.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredDD(filtered_dd)
        if (e.target.value === '' || e.target.value === null) {
            setFilteredDD([])
        }
    }
    const handleSelectDD = (el) => {
        setSearchDDKeyword(el)
    }

    const addDD = async () => {
        let includes = prescriptionData.d_d.filter((el) => {
            return el.d_d.toLowerCase().includes(searchDDKeyword.toLowerCase())
        })
        if (includes.length === 0) {
            props.setwaitMsg(true)
            try {
                const res = await axios.post(`${base_url}/add_doctor_dd`, {
                    'data': searchDDKeyword
                }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
                props.setwaitMsg(false)
                console.log(res.data)
            } catch (error) {
                props.setwaitMsg(false)
                console.log(error)
            }
        }
        if (localStorage.getItem('rahmatwebdev_doctors_dd') === null) {
            localStorage.setItem('rahmatwebdev_doctors_dd', JSON.stringify([]))
        }
        let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_dd'));
        arry.push(searchDDKeyword)
        localStorage.setItem('rahmatwebdev_doctors_dd', JSON.stringify(arry))
        props.showAddedDD()
        setSearchDDKeyword('')
        setFilteredDD([])
    }

    // DD Ends*****************************************************
    //----------------------------------------------------------------------------------
    //O/E starts------------------------------------------
    const handle_o_e_change = e => {
        setSearchOE_Keyword(e.target.value)
        let filtered_o_e = prescriptionData.o_e.filter((el) => {
            return el.exam.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredOE(filtered_o_e)
        if (e.target.value === '' || e.target.value === null) {
            setFilteredOE([])
        }

    }


    const handleOEBlur = (i) => {
        let text = document.getElementById(`o_e_row${i}`).innerText
        let val = document.getElementById(`o_e${i}`).value
        let splitted_array = text.split(/:/);
        let final_text = (splitted_array[0] + ': ' + val + " " + splitted_array[1]);
        let prev_data = localStorage.getItem('rahmatwebdev_doctor_o_e');
        if (prev_data === null) {
            localStorage.setItem('rahmatwebdev_doctor_o_e', JSON.stringify([]));
        }
        let parsed_array = JSON.parse(localStorage.getItem('rahmatwebdev_doctor_o_e'))
        if (val != '') {
            parsed_array.push(final_text);
            localStorage.setItem('rahmatwebdev_doctor_o_e', JSON.stringify(parsed_array));
        }
        setFilteredOE([])
        setSearchOE_Keyword('')
        props.showAddedOE()
    }


    /// O/E Ends*******************************************************

    ///Diagnosis stARt******************************************************

    const handle_Diagnosis_change = e => {
        setSearchDiagnosisKeyword(e.target.value)
        let filtered_dd = prescriptionData.diagnosis.filter((el) => {
            return el.diagnosis.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredDiagnosis(filtered_dd)
        if (e.target.value === '' || e.target.value === null) {
            setFilteredDiagnosis([])
        }
    }
    const handleSelectDiagnosis = (el) => {
        setSearchDiagnosisKeyword(el)
        setFilteredDiagnosis([])
    }

    const addDiagnosis = async () => {
        let includes = prescriptionData.diagnosis.filter((el) => {
            return el.diagnosis.toLowerCase().includes(searchDiagnosisKeyword.toLowerCase())
        })
        if (includes.length === 0) {
            props.setwaitMsg(true)
            try {
                const res = await axios.post(`${base_url}/add_doctor_diagnosis`, {
                    'data': searchDiagnosisKeyword
                }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
                props.setwaitMsg(false)
                console.log(res.data)
            } catch (error) {
                props.setwaitMsg(false)
                console.log(error)
            }
        }
        if (localStorage.getItem('rahmatwebdev_doctors_diagnosis') === null) {
            localStorage.setItem('rahmatwebdev_doctors_diagnosis', JSON.stringify([]))
        }
        let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_diagnosis'));
        arry.push(searchDiagnosisKeyword)
        localStorage.setItem('rahmatwebdev_doctors_diagnosis', JSON.stringify(arry))
        props.showAddedDiagnosis()
        setSearchDiagnosisKeyword('')
        setFilteredDiagnosis([])
    }

    ///Diagnosis End******************************************************

    ///Advice Starts-------------------------------------------------------------
    const handleAdviceChange = (e) => {
        setSearchAdviceKeyword(e.target.value)
        let filtered_advice = prescriptionData.advices.filter((el) => {
            return el.advice.includes(e.target.value) ||
                el.advice_english.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredAdvice(filtered_advice)
        if (e.target.value === '' || e.target.value === null) {
            setFilteredAdvice([])
        }
    }
    const handleSelectAdvice = (d) => {
        setSearchAdviceKeyword(d)
        setFilteredAdvice([])
    }

    const addAdvice = async () => {
        let includes = prescriptionData.advices.filter((el) => {
            return el.advice.includes(searchAdviceKeyword)
        })
        if (includes.length === 0) {
            props.setwaitMsg(true)
            try {
                const res = await axios.post(`${base_url}/add_doctor_advice`, {
                    'data': searchAdviceKeyword
                }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
                props.setwaitMsg(false)
                console.log(res.data)
            } catch (error) {
                props.setwaitMsg(false)
                console.log(error)
            }
        }
        if (localStorage.getItem('rahmatwebdev_doctors_advice') === null) {
            localStorage.setItem('rahmatwebdev_doctors_advice', JSON.stringify([]))
        }
        let arry = JSON.parse(localStorage.getItem('rahmatwebdev_doctors_advice'));
        arry.push(searchAdviceKeyword)
        localStorage.setItem('rahmatwebdev_doctors_advice', JSON.stringify(arry))
        props.showAddedAdvice()
        setSearchAdviceKeyword('')
        setFilteredAdvice([])
    }


    ///Advice Ends-------------------------------------------------------------


    return (
        <div className='pres_left_col'>
            <div className="flexDiv">
                <div className="left_col_divs">
                    <h2>History</h2>
                    <input type="text" className='my_field' value={searchHistoryKeyword} onChange={handle_history_change} />
                    <button style={{ 'width': '10%' }} className='submit_btn' onClick={addHistory}>+</button>
                    <div className="filteredResultDiv">
                        {filteredHistory.map((el, i) =>
                            <div className="filteredRow" id={`o_e_row${i}`} key={i}>
                                <div className='filteredRow' onClick={() => handleSelectHistory(el.history)}>{el.history}</div>

                            </div>
                        )}
                    </div>
                </div>

                <div className="left_col_divs">
                    <h2>C/C</h2>
                    <input type="text" className='my_field' value={searchCCKeyword} onChange={handle_cc_change} />
                    <button style={{ 'width': '10%' }} className='submit_btn' onClick={addCC}>+</button>
                    <div className="filteredResultDiv">
                        {filteredCC.map((el, i) =>
                            <div className="filteredRow" key={i}>
                                <div className='filteredRow' onClick={() => handleSelectCC(el.c_c)}>{el.c_c}</div>

                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flexDiv">
                <div className="left_col_divs">
                    <h2>O/E</h2>
                    <input type="text" className='my_field' value={searchOE_Keyword} onChange={handle_o_e_change} />
                    <div className="filteredResultDiv">
                        {filteredOE.map((el, i) =>
                            <div className="filteredRow" id={`o_e_row${i}`} key={i}>
                                <label htmlFor={`o_e${i}`}>{el.exam}:</label>
                                <input type="text" id={`o_e${i}`} onBlur={() => (handleOEBlur(i))} />
                                <span>{el.unit}</span>
                            </div>
                        )}
                    </div>

                </div>
                <div className="left_col_divs">
                    <h2>D/D</h2>
                    <input type="text" className='my_field' value={searchDDKeyword} onChange={handle_dd_change} />
                    <button style={{ 'width': '10%' }} className='submit_btn' onClick={addDD}>+</button>
                    <div className="filteredResultDiv">
                        {filteredDD.map((el, i) =>
                            <div className="filteredRow" key={i}>
                                <div className='filteredRow' onClick={() => handleSelectDD(el.d_d)}>{el.d_d}</div>

                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flexDiv">
                <div className="left_col_divs">
                    <h2>Tests</h2>
                    <input type="text" className='my_field' value={searchTestKeyword} onChange={handle_test_change} />
                    <button style={{ 'width': '10%' }} className='submit_btn' onClick={addTest}>+</button>
                    <div className="filteredResultDiv">
                        {filteredTest.map((el, i) =>
                            <div className="filteredRow" key={i}>
                                <div className='filteredRow' onClick={() => handleSelectTest(el.advice_test_name)}>{el.advice_test_name}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="left_col_divs">
                    <h2>Diagnosis</h2>
                    <input type="text" className='my_field' value={searchDiagnosisKeyword} onChange={handle_Diagnosis_change} />
                    <button style={{ 'width': '10%' }} className='submit_btn' onClick={addDiagnosis}>+</button>
                    <div className="filteredResultDiv">
                        {filteredDiagnosis.map((el, i) =>
                            <div className="filteredRow" key={i}>
                                <div className='filteredRow' onClick={() => handleSelectDiagnosis(el.diagnosis)}>{el.diagnosis}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="left_col_divs banglaFont">
                <h2>উপদেশ</h2>
                <input type="text" onChange={handleAdviceChange} value={searchAdviceKeyword} className='my_field' />
                <button style={{ 'width': '10%' }} className='submit_btn' onClick={addAdvice}>+</button>
                <div className="filteredResultDiv">
                    {filteredAdvice.map((el, i) =>
                        <div className="filteredRow" key={i}>
                            <div className='filteredRow' onClick={() => handleSelectAdvice(el.advice)}>{el.advice}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PrescriptionLeftCol