import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Line_Chart = ({data}) => {
    
    return (
        <LineChart
            width={300}
            height={190}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis style={{fontSize : '12px'}} dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Payable" stroke="#299dcf" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="Paid" stroke="green" />
        </LineChart>
    )
}

export default Line_Chart
