import React, { useEffect, useState } from 'react'
import './prescriptionDataStyle.css'
import DrugLists from './DrugLists';
import DosesLists from './DosesLists';
import RulesLists from './RulesLists';
import OELists from './OELists';
import UpodeshLists from './UpodeshLists';
import DurationsLists from './DurationsLists';
import DefaultResults from '../deafultResults/DefaultResults';
import { useAppContext } from '../../../contex/appContext';
import { logOutReq } from '../../auth/logout';
import { useNavigate } from 'react-router-dom';

const PrescriptionData = () => {
    const appContext = useAppContext();
    const { userData } = appContext;
    const navigate = useNavigate();
    const [showDrugList, setshowDrugList] = useState(true);
    const [showDosesList, setshowDosesList] = useState(false);
    const [showDurationList, setshowDurationList] = useState(false);
    const [showRulesList, setshowRulesList] = useState(false);
    const [showOEList, setshowOEList] = useState(false);
    const [showUpodeshList, setshowUpodeshList] = useState(false)
    const [showResultsList, setshowResultsList] = useState(false)

    const handleDrugsClick = () => {
        setshowDrugList(true)
        setshowDosesList(false)
        setshowDurationList(false)
        setshowRulesList(false)       
        setshowOEList(false)       
        setshowUpodeshList(false)
        setshowResultsList(false)

    }
    const handleDosesClick = () => {
        setshowDrugList(false)
        setshowDosesList(true)
        setshowDurationList(false)
        setshowRulesList(false)       
        setshowOEList(false)     
        setshowUpodeshList(false)
        setshowResultsList(false)
    }
    const handleDurationsClick = () => {
        setshowDrugList(false)
        setshowDosesList(false)
        setshowDurationList(true)
        setshowRulesList(false)        
        setshowOEList(false)       
        setshowUpodeshList(false)
        setshowResultsList(false)
    }
    const handleRulesClick = () => {
        setshowDrugList(false)
        setshowDosesList(false)
        setshowDurationList(false)
        setshowRulesList(true)       
        setshowOEList(false)        
        setshowUpodeshList(false)
        setshowResultsList(false)
    }   
   
    const handleOEClick = () => {
        setshowDrugList(false)
        setshowDosesList(false)
        setshowDurationList(false)
        setshowRulesList(false)
        setshowOEList(true)
        setshowUpodeshList(false)
        setshowResultsList(false)
    }
   
    const handleUpodeshClick = () => {
        setshowDrugList(false)
        setshowDosesList(false)
        setshowDurationList(false)
        setshowRulesList(false)
        setshowOEList(false)
        setshowUpodeshList(true)
        setshowResultsList(false)
    }

    const handleResultsClick = () => {
        setshowDrugList(false)
        setshowDosesList(false)
        setshowDurationList(false)
        setshowRulesList(false)
        setshowOEList(false)
        setshowUpodeshList(false)
        setshowResultsList(true)
    }
    const logout = () => {
        logOutReq();
        localStorage.removeItem('rahmatLabSolutionAuthToken')
        navigate('/login')
    }
    useEffect(()=> {
        if (!appContext.loading) {
            if (userData.user_type !== 'master_admin' && userData.user_type !== 'master_admin_assist'
                && userData.user_type !== 'prescription_entry') {
                logout()
            }
        }
    },[])
    return (
        <div className='master_admin_wrapper'>
            <div className="prescriptionDataHeader">
                <button onClick={handleDrugsClick} className={showDrugList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    Drugs
                </button>
                <button onClick={handleDosesClick} className={showDosesList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    Doses
                </button>
                <button onClick={handleDurationsClick} className={showDurationList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    Durations
                </button>
                <button onClick={handleRulesClick} className={showRulesList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    Rules
                </button>
                
                
                <button onClick={handleOEClick} className={showOEList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    O/E
                </button>          
               
                
                <button onClick={handleUpodeshClick} className={showUpodeshList ? "targetBtn banglaFont targetBtn_active" : 'banglaFont targetBtn'}>
                    উপদেশ
                </button>

                <button onClick={handleResultsClick} className={showResultsList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    Results
                </button>
            </div>
            <div className="prescriptionDataMain">
                {showDrugList ? <DrugLists /> : showDosesList ? <DosesLists /> : showRulesList ? <RulesLists />
                    : showDosesList ? <DosesLists /> : showDurationList ? <DurationsLists /> :
                        showOEList ? <OELists /> : showUpodeshList ? <UpodeshLists /> : showResultsList ? <DefaultResults />: <></>}
            </div>

        </div>
    )
}

export default PrescriptionData