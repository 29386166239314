import {AiFillHome, AiOutlineDeliveredProcedure} from 'react-icons/ai'
import {BsSearch} from 'react-icons/bs'
import {MdOutlineAttachMoney} from 'react-icons/md'
import {FaClinicMedical, FaSearch, FaStethoscope} from 'react-icons/fa'
import {FaUser , FaDirections } from 'react-icons/fa'
import {FaWheelchair} from 'react-icons/fa'
import { RiTestTubeFill } from "react-icons/ri";
import { GiTakeMyMoney } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { MdContentPasteSearch } from "react-icons/md";
import { MdMedicalServices } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { RiRefund2Fill } from "react-icons/ri";
export const commonSidebarData = [
    {
        'title': 'Home',
        'icon': <AiFillHome size={19}/>,
        'link': '/',
    },
    {
        'title': 'Clinic Details',
        'icon': <FaClinicMedical/>,
        'link': '/show_clinic_details',
    },
    {
        'title': 'Reporters',
        'icon': <AiOutlineDeliveredProcedure />,
        'link': '/reporters',
    }

]
export const adminSidebarData = [
    {
        'title': 'Patient Management',
        'icon': <FaWheelchair/>,
        'link': '/patient_management',
    },
    {
        'title': 'Search Patient',
        'icon': <FaSearch/>,
        'link': '/search_patient',
    },
    {
        'title': 'Doctor Management',
        'icon': <FaStethoscope/>,
        'link': '/doctor_management',
    },
    {
        'title': 'User management',
        'icon': <FaUser/>,
        'link': '/user',
    },
    {
        'title': 'Services',
        'icon':  <MdMedicalServices />,
        'link': '/included_services',
    },    
   
    {
        'title': 'Expenditure',
        'icon': <GiTakeMyMoney />,
        'link': '/expenditure',
    },
    {
        'title': 'Refferer manage',
        'icon': <FaDirections />,
        'link': '/refferer_manage',
    },
    {
        'title': 'Transaction',
        'icon': <GrTransaction />,
        'link': '/todays_transaction',
    },
    {
        'title': 'Due collection',
        'icon' : <GiMoneyStack size={20}/> ,
        'link' : '/due_collection'
    },
    {
        'title': 'Refund',
        'icon' : <RiRefund2Fill size={20}/>,
        'link' : '/refund'
    },
    {
        'title': 'Search Summary',
        'icon':  <MdContentPasteSearch />,
        'link': '/search_summary',
    },
    

]
export const managerSidebarData = [
    {
        'title': 'Patient Management',
        'icon': <FaWheelchair/>,
        'link': '/patient_management',
    },
     {
        'title': 'Search Patient',
        'icon': <FaSearch/>,
        'link': '/search_patient',
    },
    {
        'title': 'Doctor Management',
        'icon': <FaStethoscope/>,
        'link': '/doctor_management',
    },
    {
        'title': 'Services',
        'icon':  <MdMedicalServices />,
        'link': '/included_services',
    },
    {
        'title': 'Refferer manage',
        'icon': <FaDirections />,
        'link': '/refferer_manage',
    },    
   
    {
        'title': 'Transaction',
        'icon':  <GrTransaction />,
        'link': '/todays_transaction',
    },
    {
        'title': 'Due collection',
        'icon' : <GiMoneyStack size={20}/> ,
        'link' : '/due_collection'
    },
    {
        'title': 'Refund',
        'icon' : <RiRefund2Fill size={20}/>,
        'link' : '/refund'
    },
    {
        'title': 'Expenditure',
        'icon': <GiTakeMyMoney />,
        'link': '/expenditure',
    },
    {
        'title': 'Search summary',
        'icon': <MdContentPasteSearch />,
        'link': '/search_summary',
    }
]

export const pathologySidebarData = [
    {
        'title': 'Search patient',
        'icon':  <BsSearch size={19}/>,
        'link': '/search_patient',
    }
]
export const receptionSidebarData = [
    
    {
        'title': 'Todays patients',
        'icon': <FaWheelchair/>,
        'link': '/todays_patients',
    },
    {
        'title': 'Search patient',
        'icon': <BsSearch size={20}/>,
        'link': '/search_patient',
    },
    {
        'title': 'Doctor Management',
        'icon': <FaStethoscope/>,
        'link': '/doctor_management',
    },
    
    {
        'title': 'Transaction',
        'icon': <GrTransaction />,
        'link': '/todays_transaction',
    },
    {
        'title': 'Due collection',
        'icon' : <GiMoneyStack size={20}/> ,
        'link' : '/due_collection'
    },
    {
        'title': 'Refund',
        'icon' : <RiRefund2Fill size={20}/>,
        'link' : '/refund'
    },
    {
        'title': 'Search summary',
        'icon': <MdContentPasteSearch />,
        'link': '/search_summary',
    }
]
