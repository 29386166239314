import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppContext } from '../../contex/appContext';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import './userStyle.css'
import Modal from '../modal/Modal';
import Swal from 'sweetalert2';

const AddUser = () => {
    const navigate = useNavigate()
    const appContext = useAppContext();
    const authToken = getToken();
    const base_url = baseUrl();
    const [showWaitModal, setshowWaitModal] = useState(false)
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        user_type: 'manager',
    });
    const [feildErrors, setFieldErrors] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        user_type: '',
    });
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        setshowWaitModal(true)
        e.preventDefault();
        setFieldErrors({
            name: '',
            email: '',
            phone: '',
            password: '',
            user_type: '',
        })
        try {
            const res = await axios.post(`${base_url}/register`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            Swal.fire({
                icon : res.data.status,
                text : res.data.message
            })
            if(res.data.status === 'success'){
                navigate('/user')
            }

        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if (error.response.status === 422) {
                setFieldErrors({
                    name: error.response.data.errors.name,
                    email: error.response.data.errors.email,
                    phone: error.response.data.errors.phone,
                    password: error.response.data.errors.password,
                })
            }
        }

    }
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Adding user..' title='wait'/> : <></> }
            <form className="add_user_form" onSubmit={e => handleSubmit(e)} id='addUserForm'>
                <h2>Add user
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                <div className="my_form_group">
                    <label htmlFor="name">Name:</label><br />
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="name"
                        name='name' value={formData.name} onChange={e => handleChange(e)} /><br />
                    {feildErrors.name !== '' && feildErrors.name ? <div className='failed'>{feildErrors.name}</div> : ''}

                </div>
                <div className="my_form_group">
                    <label htmlFor="email">Email:</label><br />
                    <input type="email" className="my_field" style={{ width: '16rem' }} id="email"
                        name='email' value={formData.email} onChange={e => handleChange(e)} /><br />
                    {feildErrors.email !== '' && feildErrors.email ? <div className='failed'>{feildErrors.email}</div> : ''}

                </div>
                <div className="my_form_group">
                    <label htmlFor="phone">Phone no.:</label><br />
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="phone"
                        name='phone' value={formData.phone} onChange={e => handleChange(e)} /><br />
                    {feildErrors.phone !== '' && feildErrors.phone ? <div className='failed'>{feildErrors.phone}</div> : ''}
                </div>
                <div className="my_form_group">
                    <label htmlFor="password">Password:</label><br />
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="password"
                        name='password' value={formData.password} onChange={e => handleChange(e)} /><br />
                    {feildErrors.password !== '' && feildErrors.password ? <div className='failed'>{feildErrors.password}</div> : ''}
                </div>
                <div className="my_form_group">
                    <label htmlFor="password_confirmation">Confirm Password:</label><br />
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="password_confirmation"
                        name='password_confirmation' value={formData.password_confirmation} onChange={e => handleChange(e)} /><br />

                </div>
                {appContext.userData.user_type === 'admin' ?
                    <div className="my_form_group">
                        <label htmlFor="user_type">User role:</label><br />
                        <select className="my_field" style={{ width: '16rem' }} id="user_type"
                            name='user_type' value={formData.user_type} onChange={e => handleChange(e)} >
                            <option value="manager">Manager</option>
                            <option value="reception">Reception</option>
                            <option value="pathology">Pathology</option>
                            <option value="x_ray">X-ray</option>
                            <option value="usg">USG</option>
                            <option value="doctor">Doctor</option>
                        </select><br />
                    </div> : ''}
                
                <div className="my_form_group" >
                    <input type="submit" value='Add' className="my_btn submit_btn" />
                </div>
            </form>
        </div>
    )
}

export default AddUser