export const GetDate = () =>{
    const today = new Date();
    const year = today.getFullYear();
    let date = today.getDate();
    let month = today.getMonth() + 1;
    if (month < 10) {
        month = 0 + '' + month
    }
    if(date < 10){
        date = 0+ ''+date
    }
    
    return  year + '-' + month + '-' + date;
}