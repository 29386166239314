import React, { useEffect } from 'react'
import MasterAdminMenu from './MasterAdminMenu'
import { useAppContext } from '../../contex/appContext'
import { Outlet, useNavigate } from 'react-router-dom'
import { logOutReq } from '../auth/logout'
import { getToken } from '../../localStorageManage/localStore'

const MasterAdminHome = () => {
    const auth_token = getToken()
    const navigate = useNavigate()
    const logout = () => {
        logOutReq();
        localStorage.removeItem('rahmatLabSolutionAuthToken')
        navigate('/login')
    }
    const { loading, userData } = useAppContext()
    useEffect(() => {
        if (auth_token === null) {
            navigate('/login')
        }
        if (!loading) {
            if (userData.user_type !== 'master_admin' && userData.user_type !== 'master_admin_assist'
                && userData.user_type !== 'prescription_entry') {
                logout()
            }
        }

    }, [loading])
    return (
        <div className='fullScreen'>
            <span></span>
            <MasterAdminMenu />
            <Outlet />
        </div>
    )
}

export default MasterAdminHome