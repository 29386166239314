import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { logOutReq } from '../auth/logout'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useAppContext } from '../../contex/appContext'
import logo from '../../Images/logo.PNG'


const MasterAdminMenu = () => {
    const navigate = useNavigate()
    const { userData } = useAppContext()
    const logout = () => {
        logOutReq();
        localStorage.removeItem('rahmatLabSolutionAuthToken')
        navigate('/login')
    }
    const [showMenu, setShowMenu] = useState(false)
    return (
        <div className='doctorMenuBar'>
            <div className="menuIconDiv">
                <span onClick={() => setShowMenu(!showMenu)} className={showMenu ? 'menuBtn menuBtnWhite' : 'menuBtn'}><GiHamburgerMenu /></span>
            </div>
            <div className="doctorMenuBarLeft">
                <Link to='/'>
                    <img className='logoImage' alt='logoImage' src={logo} />
                </Link>
            </div>
            <div className={showMenu ? 'menuBarShow doctorMenuBarRight' : 'doctorMenuBarRight'}>
                <ul>
                    <li><NavLink to={'/master_admin'} onClick={() => setShowMenu(!showMenu)}>Home</NavLink></li>
                    <li><NavLink to={'/master_admin/client_admins'} onClick={() => setShowMenu(!showMenu)}>Client Admins</NavLink></li>
                    <li><NavLink to={'/master_admin/single_doctors'} onClick={() => setShowMenu(!showMenu)}>Single Doctors</NavLink></li>
                    <li><NavLink to={'/master_admin/clinic_doctors'} onClick={() => setShowMenu(!showMenu)}>Clinic Doctors</NavLink></li>
                    <li><NavLink to={'/master_admin/prescription_data'} onClick={() => setShowMenu(!showMenu)}>Prescription data</NavLink></li>
                    {userData.user_type === 'master_admin' ?
                        <li><NavLink to={'/master_admin/registration_requests'} onClick={() => setShowMenu(!showMenu)}>User requests</NavLink></li>
                        : <></>}
                    <li><NavLink to={'/master_admin/change_password'} onClick={() => setShowMenu(!showMenu)}>Change password</NavLink></li>
                    <li><button className='logoutBtn' onClick={() => logout()} >Log Out</button></li>
                </ul>

            </div>
        </div>
        //
    )
}

export default MasterAdminMenu