import React, { useState } from 'react'
import RulesLists from '../master_admin/prescriptionData/RulesLists';
import DurationsLists from '../master_admin/prescriptionData/DurationsLists';
import DosesLists from '../master_admin/prescriptionData/DosesLists';
import OELists from '../master_admin/prescriptionData/OELists';
import UpodeshLists from '../master_admin/prescriptionData/UpodeshLists';

const DoctorKeywords = () => {
    const [showDosesList, setshowDosesList] = useState(true);
    const [showDurationList, setshowDurationList] = useState(false);
    const [showRulesList, setshowRulesList] = useState(false);
    const [showOEList, setshowOEList] = useState(false);
    const [showUpodeshList, setshowUpodeshList] = useState(false)

    const handleDosesClick = () => {
        setshowDosesList(true)
        setshowDurationList(false)
        setshowRulesList(false)       
        setshowOEList(false)     
        setshowUpodeshList(false)
    }
    const handleDurationsClick = () => {
        setshowDosesList(false)
        setshowDurationList(true)
        setshowRulesList(false)        
        setshowOEList(false)       
        setshowUpodeshList(false)
    }
    const handleRulesClick = () => {
        setshowDosesList(false)
        setshowDurationList(false)
        setshowRulesList(true)       
        setshowOEList(false)        
        setshowUpodeshList(false)
    }   
   
    const handleOEClick = () => {
        setshowDosesList(false)
        setshowDurationList(false)
        setshowRulesList(false)
        setshowOEList(true)
        setshowUpodeshList(false)
    }
   
    const handleUpodeshClick = () => {
        setshowDosesList(false)
        setshowDurationList(false)
        setshowRulesList(false)
        setshowOEList(false)
        setshowUpodeshList(true)
    }
    return (
        <div className='prescriptionMain'>
            <div className="prescriptionDataHeader my_container">
                <button onClick={handleDosesClick} className={showDosesList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    Doses
                </button>
                <button onClick={handleDurationsClick} className={showDurationList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    Durations
                </button>
                <button onClick={handleRulesClick} className={showRulesList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    Rules
                </button>


                <button onClick={handleOEClick} className={showOEList ? "targetBtn targetBtn_active" : 'targetBtn'}>
                    O/E
                </button>
                <button onClick={handleUpodeshClick} className={showUpodeshList ? "targetBtn banglaFont targetBtn_active" : 'banglaFont targetBtn'}>
                    উপদেশ
                </button>
            </div>
            <div className="prescriptionDataMain my_container">
                {showDosesList ? <DosesLists /> : showRulesList ? <RulesLists />
                    : showDosesList ? <DosesLists /> : showDurationList ? <DurationsLists /> :
                        showOEList ? <OELists /> : showUpodeshList ? <UpodeshLists /> : <></>}
            </div>
        </div>
    )
}

export default DoctorKeywords