import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore'
import { useAppContext } from '../../contex/appContext'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import Modal from '../modal/Modal'

const EditSiteDetails = () => {
    const navigate = useNavigate()
    const { userData } = useAppContext();
    const base_url = baseUrl();
    const authToken = getToken();
    const { state } = useLocation();
    const [showWaitMsg, setshowWaitMsg] = useState(false)
    const [formData, setformData] = useState({});

    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitMsg(true);
        try {
            const res = await axios.post(`${base_url}/update_site_details`, formData,{
                headers : {
                    Accept : 'application/json',
                    Authorization : `Bearer ${authToken}`
                }
            })
            setshowWaitMsg(false)
            Swal.fire({
                icon : res.data.status,
                text : res.data.message
            })
            if(res.data.status === 'success'){
                navigate(-1)
            }
        } catch (error) {
            setshowWaitMsg(false)
            console.log(error)
        }
    }

    useEffect(() => {
        if(userData.user_type !== 'master_admin'){
            navigate(-1)
        }
        if (state) {
            setformData(state)
        }
    }, [])
    return (
        <div className='master_admin_wrapper'>
            {showWaitMsg ? <Modal data='Updating site details' title='wait' /> : <></>}
            <form className="editSiteDetailsForm" onSubmit={handleSubmit}>
                <h2>Edit Site Details
                    <Link to={-1} className='h2_right_btn cancelBtn'>&times;</Link>
                </h2>
                <label htmlFor="address">Address:</label>
                <textarea name="address" className='my_field' onChange={handleChange}
                    id="address" value={formData.address} rows={3}></textarea>
                <label htmlFor="phone_no">Phone:</label>
                <input type='text' name="phone_no" className='my_field' onChange={handleChange}
                    id="phone_no" value={formData.phone_no} />
                <label htmlFor="email">Email:</label>
                <input type='email' name="email" className='my_field' onChange={handleChange}
                    id="email" value={formData.email} />
                <label htmlFor="facebook">Facebook:</label>
                <input type='text' name="facebook" className='my_field' onChange={handleChange}
                    id="facebook" value={formData.facebook} />
                <label htmlFor="message">Message:</label>
                <input type='text' name="message" className='my_field' onChange={handleChange}
                    id="message" value={formData.message} />
                <br />
                <input type="submit" value="Update" className="my_btn submit_btn" />
            </form>
        </div>
    )
}

export default EditSiteDetails