import React, { useEffect } from 'react'
import { useAppContext } from '../contex/appContext'
import NewPatient from '../components/routes/NewPatient'
import TodaysPatientsAll from './allDepts/TodaysPatientsAll';
import AdminDashboard from './dashboard/AdminDashboard';
import { useNavigate } from 'react-router-dom';


const IndexPage = () => {
  const navigate = useNavigate()

  const { userData, loading } = useAppContext();
  useEffect(() => {
    if (loading === false) {      
        if (userData.user_type === 'doctor' || userData.user_type === 'single_doctor') {
          navigate('/doctor')
        } else if (userData.user_type === 'master_admin' || userData.user_type === 'master_admin_assist'
        || userData.user_type === 'prescription_entry') {
          navigate('/master_admin')
        }else if(userData.user_type === 'single_doctor_assistant' ){
          navigate('/single_doctor_assistant')
        }
      

    }
  }, [loading])
  return (
    <>
      {userData.user_type === 'pathology' || userData.user_type === 'x_ray' || userData.user_type === 'usg' ? <TodaysPatientsAll /> : <></>}
      {userData.user_type === 'reception' ? <NewPatient /> : <></>}
      {userData.user_type === 'admin' ? <AdminDashboard /> : <></>}
      {userData.user_type === 'manager' ? <AdminDashboard /> : <></>}
    </>
  )
}

export default IndexPage