import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import { GetDate } from '../../services/Date';
import { DateFormat } from '../../services/DateFormat'
import { TailSpin, Audio } from 'react-loader-spinner';
import { AiOutlineDelete } from 'react-icons/ai';
import { useAppContext } from '../../contex/appContext';

const DoctorSchedule = () => {
    const { userData } = useAppContext()
    const navigate = useNavigate()
    const today = GetDate();
    const authToken = getToken();
    const base_url = baseUrl();
    const { id, doctor_name } = useParams();
    const [addedSchedules, setAddedSchedules] = useState([])
    const [loading, setLoading] = useState(true);
    const [showWaitMsg, setShowWaitMsg] = useState(false);
    const [formData, setFormData] = useState({
        'id': id,
        'schedule_date': today
    });
    const handleChange = e => {
        setFormData({
            ...formData,
            'schedule_date': e.target.value
        })
    }
    const fetchAddedSchedules = async () => {
        try {
            const res = await axios.get(`${base_url}/show_added_schedule/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setLoading(false)
            if (res.data.status === 'success') {
                setAddedSchedules(res.data.dbData)
                let old_schedules = res.data.dbData.filter(el => el.schedule_date < today)
                if (old_schedules.length > 0) {
                    Swal.fire({
                        icon: 'question',
                        showCancelButton: true,
                        showDenyButton: true,
                        confirmButtonText: 'Yes',
                        text: 'Delete past schedules of this doctor..?',
                        confirmButtonColor: 'tomato'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            deleteDoctorOldShedules()
                        }
                    })
                }
            } else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
        } catch (error) {
            console.log(error.response.data)
        }
    }
    const handleSubmit = async e => {
        e.preventDefault();
        if (formData.schedule_date < today) {
            Swal.fire({
                icon: 'info',
                text: 'Schedule date can not be less than today.!'
            })
            return false;
        }
        setShowWaitMsg(true)
        try {
            const res = await axios.post(`${base_url}/add_doctor_schedule`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setShowWaitMsg(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            fetchAddedSchedules();
        } catch (error) {
            setShowWaitMsg(false)
            console.log(error)
        }
    }
    const deleteSchedule = async (i) => {
        setShowWaitMsg(true)
        try {
            const res = await axios.delete(`${base_url}/delete_doctor_schedule/${i}`, {

                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }

            })
            setShowWaitMsg(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })

            if (res.data.status === 'success') {
                fetchAddedSchedules();
            }

        } catch (error) {
            console.log(error.response)
        }
    }

    const deleteDoctorOldShedules = async () => {
        setShowWaitMsg(true)
        try {
            const res = await axios.delete(`${base_url}/delete_doctor_old_schedules/${id}`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
            setShowWaitMsg(false)
            fetchAddedSchedules()
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleDelete = (i) => {
        Swal.fire({
            icon: 'question',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            text: 'Delete this schedule ..?',
            confirmButtonColor: 'tomato'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSchedule(i);
            } else if (result.isDenied) {
                Swal.fire({
                    icon: 'info',
                    text: 'Not Deleted'
                })
            }
        })
    }


    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager'
            && userData.user_type !== 'reception') {
            navigate('/')
        }
        fetchAddedSchedules()
    }, [])
    return (
        <div className='mainWindow'>
            {loading ? <Audio
                height="30"
                width="40"
                radius="5"
                color="cadetblue"
                ariaLabel=""
                wrapperStyle
                wrapperClass
            /> :
                <div className="doctorScheduleWrapper">
                    {showWaitMsg ?
                        <div className="loader" >
                            <TailSpin
                                height="30"
                                width="40"
                                radius="5"
                                color="cadetblue"
                                ariaLabel=""
                                wrapperStyle
                                wrapperClass
                            />
                        </div> : <></>}

                    <h2>Schedule for {doctor_name}
                        <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link></h2>
                    <form className="doctorScheduleForm" onSubmit={handleSubmit}>
                        <input type="date" className="my_field" name='schedule_date' value={formData.schedule_date} onChange={handleChange} />
                        <input type="submit" value="Add" className="my_btn submit_btn" />
                    </form>
                    <div className="addedScheduleDiv">
                        <h3>Added schedules</h3>
                        {addedSchedules.length < 1 ? <h1 className='failed'>No schedule found</h1> :
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {addedSchedules.map((elem, i) =>
                                        <tr key={elem.id}>
                                            <td>{i + 1}</td>
                                            <td>{DateFormat(elem.schedule_date)}</td>
                                            <td className='action_td'>
                                                <Link to={`/doctor_patient_serial/${elem.id}/${doctor_name}/${elem.schedule_date}`}>Serial</Link>


                                                <button className='no_border_btn' onClick={() => handleDelete(elem.id)}><AiOutlineDelete size={20} color='tomato' /></button>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            }
        </div >
    )
}

export default DoctorSchedule