import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore';
import { GetDate } from '../../services/Date';
import axios from 'axios';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';

const AddClientAdmin = () => {
    const navigate = useNavigate()
    const today = GetDate()
    const base_url = baseUrl();
    const authToken = getToken();
    const [showWaitmodal, setshowWaitmodal] = useState(false)
    const [formData, setformData] = useState({
        'name': '',
        'email': '',
        'phone': '',
        'password': '',
        'password_confirmation': '',
        'validity': today
    })
    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitmodal(true)
        try {
            const res = await axios.post(`${base_url}/register`, formData, {
                headers : {
                    Accept : 'application/json',
                    Authorization : `Bearer ${authToken}`
                }
            })
            setshowWaitmodal(false)
            navigate('/master_admin/client_admins')
            Swal.fire({
                icon :res.data.status,
                text : res.data.message
            })
            
        } catch (error) {      
            setshowWaitmodal(false)      
            console.log(error.response)
            if(error.response.status === 422){
                Swal.fire({
                    icon : 'error',
                    text: error.response.data.message
                })
            }
        }
    }
    return (
        <div >
            {showWaitmodal ? < Modal data='Creating new user' title='wait' />: <></>}
            <form className="master_admin_form" onSubmit={handleSubmit}>
                <h2>Add Client Admin
                    <Link className='h2_right_btn cancelBtn' to={-1} >&times;</Link>
                </h2>
                <input type="text" className='my_field font_sm' style={{ width: '16rem' }} name='name' onChange={e => handleChange(e)}
                    placeholder='Name' value={formData.name} />
                <br />
                <input type="email" className='my_field font_sm' style={{ width: '16rem' }} name='email' onChange={e => handleChange(e)}
                    placeholder='Email' value={formData.email} />
                <br />
                <input type="text" className='my_field' style={{ width: '16rem' }} name='phone' onChange={e => handleChange(e)}
                    value={formData.phone} placeholder='Phone' />
                <input type="text" className='my_field font_sm' style={{ width: '16rem' }} name='password' onChange={e => handleChange(e)}
                    placeholder='Password' value={formData.password} />
                <br />
                <input type="text" className='my_field font_sm' style={{ width: '16rem' }} name='password_confirmation' onChange={e => handleChange(e)}
                    placeholder='Confirm password' value={formData.password_confirmation} />                
                <br /><br />
                <input type="submit" value='Add' style={{ 'width': '16rem', 'padding': '0.5rem' }} className='my_btn submit_btn' />
                <br />
            </form>

        </div>
    )
}

export default AddClientAdmin