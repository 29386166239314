import { adminSidebarData, managerSidebarData, commonSidebarData, pathologySidebarData, receptionSidebarData } from './sidebarData';
import './sideBarStyle.css';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import { logOutReq } from '../auth/logout';
import { useAppContext } from '../../contex/appContext';
import arrowDown from '../../Images/arrow-down.png';
import { AiOutlineMenu } from 'react-icons/ai'
import { FaLock } from 'react-icons/fa';
import { ImProfile } from "react-icons/im";
import { CiLogout } from "react-icons/ci";
const SideBar = () => {
    const app_context = useAppContext()
    const navigate = useNavigate()
    const [showSideBar, setShowSidebar] = useState(false)
    const [showUserMenu, setShowUserMenu] = useState(false)


    const logout = () => {
        logOutReq();
        localStorage.removeItem('rahmatLabSolutionAuthToken')
        navigate('/login')
    }

    return (
        <>
            {showSideBar ? <></> :
                <button className="toggleIcon my_btn" onClick={() => setShowSidebar(!showSideBar)}> <AiOutlineMenu /></button>
            }

            <div className={showSideBar ? 'sideBar shownSideBar' : 'sideBar'}>
                <button className='closeIcon my_btn' onClick={() => setShowSidebar(false)}>&times;</button>
                <ul className='sideBarUl'>
                    {commonSidebarData.map((elem, index) =>
                        <li key={index}> <NavLink className='nav_link' onClick={() => setShowSidebar(!showSideBar)} to={elem.link}> <div className='sidebarLink'> <span className='link_icon_span'>{elem.icon}</span><span className='sideBarLinkTitle'>{elem.title}</span>  </div>  </NavLink></li>
                    )}
                    {app_context.userData.user_type === 'admin' ? <>
                        {adminSidebarData.map((elem, index) =>
                            <li key={index}> <NavLink className='nav_link' onClick={() => setShowSidebar(!showSideBar)} to={elem.link}> <div className='sidebarLink'> <span className='link_icon_span'>{elem.icon}</span><span className='sideBarLinkTitle'>{elem.title}</span>  </div>  </NavLink></li>
                        )}
                    </> : ''}
                    {app_context.userData.user_type === 'manager' ? <>
                        {managerSidebarData.map((elem, index) =>
                            <li key={index}> <NavLink className='nav_link' onClick={() => setShowSidebar(!showSideBar)} to={elem.link}> <div className='sidebarLink'> <span className='link_icon_span'>{elem.icon}</span><span className='sideBarLinkTitle'>{elem.title}</span>  </div>  </NavLink></li>
                        )}
                    </> : ''}
                    {app_context.userData.user_type === 'pathology' || app_context.userData.user_type === 'x-ray' ||
                        app_context.userData.user_type === 'usg' ? <>
                        {pathologySidebarData.map((elem, index) =>
                            <li key={index}> <NavLink className='nav_link' onClick={() => setShowSidebar(!showSideBar)} to={elem.link}> <div className='sidebarLink'> <span className='link_icon_span'>{elem.icon}</span><span className='sideBarLinkTitle'>{elem.title}</span>  </div>  </NavLink></li>
                        )}
                    </> : ''}
                    {app_context.userData.user_type === 'reception' ? <>
                        {receptionSidebarData.map((elem, index) =>
                            <li key={index}> <NavLink className='nav_link' onClick={() => setShowSidebar(!showSideBar)} to={elem.link}> <div className='sidebarLink'> <span className='link_icon_span'>{elem.icon}</span><span className='sideBarLinkTitle'>{elem.title}</span>  </div>  </NavLink></li>
                        )}
                    </> : ''}
                    <hr />
                    {app_context.loading ? '' : <li onMouseEnter={() => setShowUserMenu(true)} onMouseLeave={() => setShowUserMenu(false)}>
                        <div className="userNameShow">{app_context.userData.name}
                            <img src={arrowDown} alt='^' className={showUserMenu ? 'shownArrow arrowDown' : 'arrowDown'} />
                        </div>
                        {showUserMenu ?
                            <ul className='userMenu'>
                                <li>
                                    <Link to='/profile' onClick={() => setShowSidebar(!showSideBar)}>
                                        <div className='sidebarLink' >
                                            <span className='link_icon_span'><ImProfile /></span>
                                            <span className='sideBarLinkTitle'>Profile</span>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/change_password' onClick={() => setShowSidebar(!showSideBar)}>
                                        <div className='sidebarLink'>
                                            <span className='link_icon_span'><FaLock /></span>
                                            <span className='sideBarLinkTitle'>Change password</span>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <div onClick={() => logout()} style={{ cursor: 'pointer' }}>
                                        <div className='sidebarLink' style={{ color: '#fff' }}>
                                            <span className='link_icon_span'><CiLogout /></span>
                                            <span className='sideBarLinkTitle'>Logout</span>
                                        </div>
                                    </div>
                                </li>

                            </ul> : ''}
                    </li>}

                </ul>



            </div>
        </>
    )
}

export default SideBar