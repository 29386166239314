import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import './clinicDetailsStyle.css';
import swal from 'sweetalert2';
import Modal from '../modal/Modal';
import { useAppContext } from '../../contex/appContext';

const EditClinicDetails = () => {
    const {loading,userData} = useAppContext()
    const navigate = useNavigate();
    const base_url = baseUrl();
    const authToken = getToken();
    const { state } = useLocation();
    const [showWaitModal, setshowWaitModal] = useState(false)
    const [formData, setFormData] = useState({
        'clinic_name': state.fetchedData.clinic_name,
        'clinic_address': state.fetchedData.clinic_address,
        'slogan': state.fetchedData.slogan,
    });
    const [imageData, setImageData] = useState('');
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleImage = e => {
        setImageData(e.target.files[0])
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitModal(true)
        const form_data = new FormData();
        form_data.append('clinic_name', formData.clinic_name);
        form_data.append('clinic_address', formData.clinic_address);
        form_data.append('slogan', formData.slogan);
        form_data.append('logo_image', imageData);
        try {
            const res = await axios.post(`${base_url}/edit_clinic_details`,
                form_data,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                })

            setshowWaitModal(false)
            swal.fire({
                'icon': res.data.status,
                'text': res.data.message
            })
            navigate('/show_clinic_details')
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if (error.response.status === 422) {
                Swal.fire({
                    'icon': 'info',
                    'text': error.response.data.message
                })
            }
        }
    }
    useEffect(()=> {
        if(userData.user_type !== 'admin'){
            navigate(-1)
        }
    },[loading])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Updating clinic details' title='wait' /> : <></>}
            <form className="editClinicDetailsForm" encType='multipart/form-data' onSubmit={handleSubmit}>
                <h2>Edit Clinic Details
                    <Link className='h2_right_btn cancelBtn' to='/show_clinic_details'>&times;</Link>
                </h2>
                <label htmlFor="clinic_name">Clinic Name:</label><br />
                <textarea name='clinic_name' className="my_field" id='clinic_name' value={formData.clinic_name} onChange={handleChange} ></textarea>
                <label htmlFor="clinic_address">Address:</label><br />
                <textarea name='clinic_address' className="my_field" id='clinic_address' value={formData.clinic_address} rows={4} onChange={handleChange} ></textarea>
                <label htmlFor="slogan">Slogan:</label><br />
                <textarea name='slogan' className="my_field" id='slogan' value={formData.slogan} rows={3} onChange={handleChange} ></textarea>
                <label htmlFor="logo">Logo:</label><br />
                <input type="file" name="logo" id="logo" onChange={handleImage} />
                <input type="submit" style={{ marginLeft: '72%' }} className='my_btn submit_btn' value='Update' />
            </form>
        </div>
    )
}

export default EditClinicDetails