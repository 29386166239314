import './patientTiles.css';
import { useAppContext } from '../../contex/appContext'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import Swal from 'sweetalert2';
import { FiEdit } from 'react-icons/fi'
import { AiFillDelete } from 'react-icons/ai'
import { DateFormat } from '../../services/DateFormat'
import { BsPrinterFill } from "react-icons/bs";
import { RiTestTubeFill } from "react-icons/ri";

const PatientTiles = ({ data, fetchPatients, redir_form, searchKey, searchPatients, setshowWaitModal }) => {
  const base_url = baseUrl()
  const auth_token = getToken()
  const { userData } = useAppContext()

  const deletePatient = async (id) => {
    setshowWaitModal(true)
    try {
      const res = await axios.delete(`${base_url}/delete_patient/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${auth_token}`
        }
      })
      setshowWaitModal(false)
      if (res.data.status === 'success') {
        Swal.fire({
          icon: res.data.status,
          text: res.data.message
        })
        if (fetchPatients) {
          fetchPatients()
        }
        if (searchKey) {
          searchPatients(searchKey)
        }
      } else {
        Swal.fire({
          icon: res.data.status,
          text: res.data.message
        })
      }
    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
    }
  }
  const handleDelete = (i) => {
    Swal.fire({
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      text: 'Delete this patient..?',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        deletePatient(i)
      }
    })
  }
  return (
    <div className='singlePatientDiv'>
      <p>ID : <span className={data.patient_seen_by_user == 0 ? 'failed' : ''}> {data.patient_id_no}</span> &nbsp; &nbsp; Phone: {data.patient_phone}</p>
      <h4>{data.patient_name}</h4>
      <p className='flexDiv'> <span>{data.patient_age} {data.patient_age_unit}</span> ( {data.patient_sex} )<span>{DateFormat(data.patient_date)}</span>  </p>
      <p className='smallFont'>Ref. by : {data.refferer_name}</p>
      <hr />
      <p className="tiles_btn_row">
        {userData.user_type === 'manager' || userData.user_type === 'admin' || userData.user_type === 'reception' ?
          <Link to={`/edit_patient/${data.id}`} state={{ redir_form: redir_form, searchKey: searchKey, data: data }}><FiEdit color='black' size={18} /></Link> : ''}
        {userData.user_type === 'manager' || userData.user_type === 'admin' || userData.user_type === 'reception' ?
          <button className='no_border_btn' onClick={() => handleDelete(data.id)}><AiFillDelete size={18} color='red' /></button> : ''}
        <Link to={`/services_for_patients/${data.id}`} state={{ redir_form: redir_form, searchKey: searchKey }}><RiTestTubeFill color='#000' /></Link>
        <Link to={`/report/${data.id}/${data.patient_id_no}`} state={{ redir_form: redir_form, searchKey: searchKey }}><BsPrinterFill color='#000' /></Link>

      </p>
    </div>
  )
}

export default PatientTiles