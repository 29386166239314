import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { AiOutlineEdit } from 'react-icons/ai'
import { Audio } from 'react-loader-spinner'
import { baseUrl } from '../../../services/baseUrl'
import { getToken } from '../../../localStorageManage/localStore'
import Pagination from '../../../services/Pagination'
import JoditEditor from 'jodit-react'
import Modal from '../../modal/Modal'
import Swal from 'sweetalert2'

const DefaultResults = () => {
    const authToken = getToken();
    const base_url = baseUrl();
    const [fetchedData, setfetchedData] = useState([])
    const [loading, setloading] = useState(true)
    const [editorHeader, seteditorHeader] = useState('')
    const [showWaitModal, setshowWaitModal] = useState(false)


    const editor = useRef(null);
    const [data, setdata] = useState('')

    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 12;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedData = fetchedData.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End

    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/get_default_results`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            setfetchedData(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleNotNullEdit = (input, result) => {
        seteditorHeader(input)
        setdata(result)
    }
    const handleNullEdit = (input) => {
        seteditorHeader(input)
        setdata('')
    }

    const handleSubmit = async () => {
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/update_default_result`, {
                'input_type' : editorHeader,
                'result' : data
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            Swal.fire({
                icon : res.data.status,
                text: res.data.message
            })
            if(res.data.status === 'success'){
                fetchData()
                setdata('')
                seteditorHeader('')
            }
           
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className='prescriptionDataInner flexDiv'>
            {showWaitModal ? <Modal data='Updating default result' title='wait'/> : <></> } 
            <div className="defaultResultLeft">
                {loading ? <Audio color='cadetblue' height={30} /> : <>
                    {fetchedData.length < 0 ? <h3>No data found..</h3> : <>
                        <table className='smallFont'>
                            <thead>
                                <tr>
                                    <th>Input Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData.map((el, i) =>
                                    <tr key={el.id}
                                     className={el.input_type == editorHeader ? 'active_row' : ''}>
                                        <td>{el.input_type}</td>
                                        <td>
                                            {el.result != null ?
                                                <button className="my_btn" onClick={() => handleNotNullEdit(el.input_type, el.result)}>
                                                    <AiOutlineEdit color='indigo' />  </button> :
                                                <button className="my_btn" onClick={() => handleNullEdit(el.input_type)}>
                                                    <AiOutlineEdit color='indigo' />  </button>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <Pagination
                            elementsPerPage={elementsPerPage}
                            totaElements={fetchedData.length}
                            pageNo={currentPage}
                            setCurrentPage={setCurrentPage} />
                    </>}
                </>}
            </div>
            <div className="defaultResultRight">
                <div className='editorWrapper'>
                    <h2>Edit default "{editorHeader}" result</h2>
                    <JoditEditor
                        ref={editor}
                        value={data}
                        tabIndex={1}
                        onChange={(content) => setdata(content)}
                    />
                </div>

            </div>
            <div>
                {editorHeader !== '' ?
                    <button className='my_btn submit_btn' onClick={()=> handleSubmit()}>
                        Update
                    </button> :
                    <></>}
            </div>
        </div>
    )
}

export default DefaultResults