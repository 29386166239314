import React from 'react'
import DoctorAssistantMenubar from './DoctorAssistantMenubar'
import { Outlet } from 'react-router-dom'

const DoctorAssistantHome = () => {
  return (
    <div className='doctor_full_page'>
      <DoctorAssistantMenubar />
      <Outlet />
    </div>
  )
}

export default DoctorAssistantHome