import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import { DateFormat } from '../../services/DateFormat';
import './refferrerStyle.css'
import ReactToPrint from 'react-to-print'
import { BsPrinterFill } from 'react-icons/bs'

const ReffererPaymentToken = () => {
    const navigate = useNavigate()
    const componentRef = useRef();
    const base_url = baseUrl();
    const authToken = getToken();
    const { token_no } = useParams();
    const [tokenDetails, setTokenDetails] = useState();
    const [loading, setLoading] = useState(true);
    const fetchPaymentDetails = async () => {
        try {
            const res = await axios.get(`${base_url}/payment_token_details/${token_no}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setTokenDetails(res.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchPaymentDetails();
    }, [])
    return (
        <div className='mainWindow'>
            <div className="reffererPaymentTokenWrapper">
                <h2>Refferer Payment Token

                </h2>
                <div className='invoiceBtnDiv'>
                    <ReactToPrint trigger={() => <button className='invoice_print_btn'><BsPrinterFill size={20} color='gray' /></button>}
                        content={() => componentRef.current}
                        onAfterPrint={() => {
                            navigate('/refferer_manage')
                        }} />
                    <Link to='/refferer_manage'>&times;</Link>
                </div>


                {loading ? 'Loading...' : <>
                    <div className="reffererPaymentPrintDiv" ref={componentRef}>
                        <div className="reffererPaymentTokenBothCopy" >
                            <div className="cornerDiv">
                                Clinic copy
                            </div>
                            <p>Token No : {tokenDetails.id} &nbsp; &nbsp; &nbsp; Date : {DateFormat(tokenDetails.payment_date)}</p>
                            <br />
                            <p><b>Refferer : </b>{tokenDetails.refferer_name}</p>
                            <br />
                            <p>From {DateFormat(tokenDetails.from_date)} to {DateFormat(tokenDetails.to_date)}</p>
                            <br />
                            Total amount : <strong>{tokenDetails.payment_amount}</strong> /-
                            <p className="reffererTokenFooter">
                                Paid by
                            </p>
                        </div>
                        <div className="reffererPaymentTokenBothCopy">
                            <div className="cornerDiv">
                                Refferer copy
                            </div>
                            <p>Token No : {tokenDetails.id} &nbsp; &nbsp; &nbsp; Date : {DateFormat(tokenDetails.payment_date)}</p>
                            <br />
                            <p><b>Refferer : </b>{tokenDetails.refferer_name}</p>
                            <br />
                            <p>From {DateFormat(tokenDetails.from_date)} to {DateFormat(tokenDetails.to_date)}</p>
                            <br />
                            Total amount : <strong>{tokenDetails.payment_amount}</strong> /-
                            <p className="reffererTokenFooter">
                                Paid by
                            </p>
                        </div>
                    </div>
                </>}

            </div>
        </div>
    )
}

export default ReffererPaymentToken