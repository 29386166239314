import axios from 'axios';
import { createContext, useContext, useEffect,  useReducer } from 'react';
import { getToken } from '../localStorageManage/localStore';
import { baseUrl } from '../services/baseUrl';

const PrescriptionContext = createContext();

//use of reducer
const initialState = {
    loading: true,
    prescriptionData: [],
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'LOADED':
            return {
                ...state,
                loading: false,
                prescriptionData: action.payload.data,
            }
        default:
            return {
                state
            }
    }
}

//use of reducer

const PrescriptionProvider = ({ children }) => {
    const [value, dispatch] = useReducer(reducer, initialState);

    const authToken = getToken();
    const base_url = baseUrl();

   
    const fetchPrescriptionData = async () => {
        try {
            const res = await axios.get(`${base_url}/show_doctors_prescription_data`, {headers:{
                'Accept': 'application/json',
                "Content-Type" : 'application/json',
                'Authorization' : `Bearer ${authToken}`
            }})
            
            dispatch({
                type: "LOADED", payload: res
            })
           
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (authToken) {
            fetchPrescriptionData()
        }
    },[])

    return <PrescriptionContext.Provider value={ value }>{children}</PrescriptionContext.Provider>


}
const usePrescriptionContext = () => {
   return useContext(PrescriptionContext)
}
export { PrescriptionContext, PrescriptionProvider, usePrescriptionContext }


