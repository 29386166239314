import React, { useEffect, useLayoutEffect, useState } from 'react'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios';
import { useAppContext } from '../../contex/appContext'
import Swal from 'sweetalert2'
import Modal from '../../components/modal/Modal'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

const EditReporter = () => {
    const { id } = useParams();
    const { state } = useLocation();
    const navigate = useNavigate()
    const { userData } = useAppContext();
    const { user_type } = userData;
    const base_url = baseUrl();
    const authToken = getToken();

    const [formData, setformData] = useState({
        'reporter_name': '',
        'reporter_type': '',
        'line_2': '',
        'line_3': '',
        'line_4': '',
        'line_5': '',
        'line_6': '',
    });
    const [showWaitModal, setshowWaitModal] = useState(false)

    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault(); setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/update_reporter/${id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate(-1)
            }
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'error',
                    text: error.response.data.message
                })
            }
        }
    }
    
    const pathologyEditable = ['haematology', 'biochemistry', 'serology',
        'microbiology', 'clinical_pathology'];
    const ecg_usg_xryEditable = ['usg', 'x_ray', 'ecg']
    useEffect(() => {
        if (state) {
            setformData(state.elem)
        }
        if(user_type === 'pathology'){
            let d = pathologyEditable.filter(el=>el===state.elem.reporter_type)
            if(d.length < 1){
                Swal.fire({
                    icon : 'error',
                    text : 'You can not edit this reporter.',
                    timer : 1000
                })
                navigate(-1)
            }
        }
        if(user_type === 'usg' || user_type === 'ecg' || user_type === 'x_ray'){
            let d = ecg_usg_xryEditable.filter(el=>el===state.elem.reporter_type)
            if(d.length < 1){
                Swal.fire({
                    icon : 'error',
                    text : 'You can not edit this reporter.',
                    timer : 1000
                })
                navigate(-1)
            }
        }
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal title='wait' data='Updating reporter' /> : <></>}
            <form className="my_card" style={{ marginTop: '1rem' }} onSubmit={handleSubmit}>
                <h2>Edit Reporter
                    <Link to='/reporters' className='h2_right_btn cancelBtn'>&times;</Link>
                </h2>
                <label htmlFor="reporter_name">Reporter name:</label>
                <input style={{ 'width': '98%' }} type="text" className="my_field" onChange={handleChange}
                    value={formData.reporter_name} id='reporter_name' name='reporter_name' />
                <label htmlFor="reporter_type">Reporter type:</label>
                <select style={{ 'width': '98%' }} type="text" className="my_field" onChange={handleChange}
                    value={formData.reporter_type} id='reporter_type' name='reporter_type' >
                    <option value="">Select reporter type</option>
                    {user_type === 'pathology' ? <>
                        <option value="haematology">Haematology</option>
                        <option value="biochemistry">Biochemistry</option>
                        <option value="serology">Serology</option>
                        <option value="microbiology">Microbiology</option>
                        <option value="clinical_pathology">Clinical pathology</option>
                    </> : user_type === 'admin' ? <>
                        <option value="haematology">Haematology</option>
                        <option value="biochemistry">Biochemistry</option>
                        <option value="serology">Serology</option>
                        <option value="microbiology">Microbiology</option>
                        <option value="clinical_pathology">Clinical pathology</option>
                        <option value="usg">USG</option>
                        <option value="x_ray">X-Ray</option>
                        <option value="ecg">ECG</option>
                    </> : user_type === 'usg' || user_type === 'x_ray' || user_type === 'ecg' ? <>
                        <option value="usg">USG</option>
                        <option value="x_ray">X-Ray</option>
                        <option value="ecg">ECG</option>
                    </> : <></>}

                </select>
                <label htmlFor="line_2">Line 2:</label>
                <input style={{ 'width': '98%' }} type="text" className="my_field" onChange={handleChange}
                    value={formData.line_2} id='line_2' name='line_2' />
                <label htmlFor="line_3">Line 3:</label>
                <input style={{ 'width': '98%' }} type="text" className="my_field" onChange={handleChange}
                    value={formData.line_3} id='line_3' name='line_3' />
                <label htmlFor="line_4">Line 4:</label>
                <input style={{ 'width': '98%' }} type="text" className="my_field" onChange={handleChange}
                    value={formData.line_4} id='line_4' name='line_4' />
                <label htmlFor="line_5">Line 5:</label>
                <input style={{ 'width': '98%' }} type="text" className="my_field" onChange={handleChange}
                    value={formData.line_5} id='line_5' name='line_5' />
                <label htmlFor="line_6">Line 6:</label>
                <input style={{ 'width': '98%' }} type="text" className="my_field" onChange={handleChange}
                    value={formData.line_6} id='reporter_name' name='line_6' /> <br /><br />
                <input style={{ 'width': '98%' }} type="submit" value="Update" className="my_btn submit_btn" />
            </form>
        </div>
    )
}

export default EditReporter