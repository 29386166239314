import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios';
import { useAppContext } from '../../contex/appContext'
import { Audio } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Pagination from '../../services/Pagination'


const Reporters = () => {
    const [fetchedReporetes, setfetchedReporetes] = useState([]);
    const [loading, setloading] = useState(true)
    const { userData } = useAppContext();
    const base_url = baseUrl();
    const authToken = getToken();
    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 8;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedData = fetchedReporetes.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End
    const fetchReporters = async () => {
        try {
            const res = await axios.get(`${base_url}/get_reporters`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            setfetchedReporetes(res.data)
        } catch (error) {
            console.log(error.response)
        }
    }
    useEffect(() => {
        fetchReporters();
    }, [])

    return (
        <div className='mainWindow'>
            <div className="my_container_fulid">
                {loading ? <Audio color='cadetblue' height={30} /> : <>
                    <h2>
                        <Link to='/add_reporter' className='h2_left addBtn my_btn' >Add new</Link>
                        Reporters list
                    </h2>
                    {fetchedReporetes.length < 1 ? <h3>No record found</h3> : <>
                        <div className="patient_tiles_wrapper">
                            {paginatedData.map(elem =>
                                <div style={{ textAlign: 'center' }} className="masterAdminTiles" key={elem.id}>
                                    <h4 style={{ textTransform: 'capitalize' }}>{elem.reporter_type}</h4>
                                    <b>{elem.reporter_name}</b><br />
                                    {elem.line_2} <br />
                                    {elem.line_3} <br />
                                    {elem.line_4} <br />
                                    {elem.line_5} <br />
                                    {elem.line_6} <hr />
                                    <div className="tiles_btn_row">
                                        <Link to={`/edit_reporter/${elem.id}`} state={{elem}}>Edit</Link>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Pagination
                            elementsPerPage={elementsPerPage}
                            totaElements={fetchedReporetes.length}
                            pageNo={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </>}
                </>}
            </div>

        </div>
    )
}

export default Reporters