import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl, baseUrlMain } from '../../services/baseUrl';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contex/appContext';
import Modal from '../modal/Modal';
import { BiMessageAltEdit } from 'react-icons/bi'
import { Audio } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import Pagination from '../../services/Pagination'
import BlankProfileImage from '../../Images/blankProfile.png'

const User = () => {
    const base_url_main = baseUrlMain()
    const navigate = useNavigate()
    const user_data = useAppContext()
    const base_url = baseUrl();
    const authToken = getToken();
    const [fetched_users, setFetchedUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showWaitModal, setshowWaitModal] = useState(false)
    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 10;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedTests = fetched_users.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End


    const fetchUsers = async () => {
        try {
            const res = await axios.get(`${base_url}/show_users`, { headers: { 'Authorization': `Bearer ${authToken}` } })
            setFetchedUsers(res.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {

        if (user_data.userData.user_type === 'master_admin' || user_data.userData.user_type === 'admin') {
            fetchUsers();
        } else {
            navigate('/')
        }

    }, [])
    const changeUserStatus = async (us, id) => {
        setshowWaitModal(true)
        try {
            const res = await axios.get(`${base_url}/change_user_status/${id}/${us}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            fetchUsers()
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
        }
    }
    const handleChangeUserStatus = (us, id) => {
        Swal.fire({
            icon: 'warning',
            text: 'Change status of this user..?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Change'
        }).then((result) => {
            if (result.isConfirmed) {
                changeUserStatus(us, id)
            }
        })
    }
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Please wait' title='wait' /> : <></>}
            {loading ? <Audio height={40} color='cadetblue' /> :
                <div className="my_container">
                    <h2>Registered Users
                        <Link className='h2_left my_btn addBtn' to={'/add_user'}>Add new</Link>
                    </h2>
                    {fetched_users.length > 0 ? <>
                        <table className='user_table'>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedTests.map((elem) =>
                                    <tr key={elem.id}>
                                        <td> {elem.profile_pic != null ?
                                            <img className="userImage" src={`${base_url_main}/${elem.profile_pic}`} alt="blank profile" /> :
                                            <img className="userImage" src={BlankProfileImage} alt="blank profile" />
                                        }</td>
                                        <td>{elem.name}</td>
                                        <td>{elem.email}</td>
                                        <td>{elem.phone}</td>
                                        <td style={{ textTransform: 'capitalize' }}>{elem.user_type}</td>
                                        <td className={elem.user_status == 1 ? 'success' : 'failed'}>{elem.user_status == 1 ? 'Active' : 'Not active'}</td>
                                        <td>
                                            {elem.user_status == 1 ?
                                                <> <button className='my_btn failed' onClick={() => handleChangeUserStatus(0, elem.id)}>Disable</button> </> :
                                                <> <button className='my_btn success' onClick={() => handleChangeUserStatus(1, elem.id)}>Enable</button> </>}
                                            <Link to={`/edit_user/${elem.id}`} state={elem}><BiMessageAltEdit size='18' color='black' /></Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <Pagination
                            elementsPerPage={elementsPerPage}
                            totaElements={fetched_users.length}
                            pageNo={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </> : <h3 className='failed'>No user added..</h3>}

                </div>
            }

        </div>
    )
}

export default User