import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner';

const UrineRE = () => {
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const [waitMsg, setWaitMsg] = useState('');
    const [reporters, setreporters] = useState([]);
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleChecked = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked
        })
    }
    const [formData, setFormData] = useState({
        'test_performed_id': id,
        'quantity': '',
        'color': '',
        'appearence': '',
        'sediment': '',
        'specific_gravity': 'ND',
        'reaction': 'Acidic',
        'protein': '',
        'sugar': '',
        'phosphate': '',
        'ph': '',
        'more_chemical_cb': false,
        'ketones': 'ND',
        'bilirubin': 'ND',
        'urobilinogen': 'ND',
        'nitrite': 'ND',
        'leukocyte_ester': 'ND',
        'other_chemical_1': 'ND',
        'other_chemical_2': 'ND',
        'epi_cell': '',
        'pus_cell': '',
        'rbc': '',
        'crystals_cb': false,
        'calc_oxal': '',
        'calc_carb': '',
        'uric_acid': '',
        'am_phos': '',
        'tripple_phos': '',
        'casts_cb': false,
        'epi_cast': '',
        'wbc_cast': '',
        'rbc_cast': '',
        'cgranu_cast': '',
        'fgranu_cast': '',
        'hyaline_cast': '',
        'waxy_cast': '',
        'fatty_cast': '',
        'sperm_cb': false,
        'sperm': '',
        'yeast_cb': false,
        'yeast': '',
        'others_cb': false,
        'other_1': '',
        'other_2': '',
        'test_result': 'urine_re'

    });
    const fetchTestResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_urine_r_e_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setLoading(false)
            if(res.data.db_data.reporter_id == null){
                if(res.data.reporters.filter(el=>el.id == reporter).length < 1){
                    setReporter('')
                }
            }else{
                setReporter(res.data.db_data.reporter_id)
            }
            if(res.data.db_data.reporter_2_id == null){
                if(res.data.reporters.filter(el=>el.id == reporter2).length < 1){
                    setReporter2('')
                }
            }else{
                setReporter2(res.data.db_data.reporter_2_id)
            }
            setreporters(res.data.reporters)
            setFormData(res.data.db_data)
        } catch (error) {
            console.log(error)
        }
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon : 'info',
            text : text
        })
    }
    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!formData.other_chemical_1.includes(':')){
            autoAlert("Other chemical 1 field is missing a ':' symbol..! ")
            return false
        }
        if(!formData.other_chemical_2.includes(':')){
            autoAlert("Other chemical 2 field is missing a ':' symbol..! ")
            return false
        }
        if(!formData.other_1.includes(':')){
            autoAlert("Other microscopic 1 field is missing a ':' symbol..! ")
            return false
        }
        if(!formData.other_2.includes(':')){
            autoAlert("Other microscopic 2 field is missing a ':' symbol..! ")
            return false
        }
        if(reporter == '' || reporter === null || reporter.bh){
            autoAlert("Please select a reporter")
            return false
        }
        if(reporter == reporter2){
            autoAlert("2 reporters can not be same..!")
            return false
        }
        setWaitMsg('Please wait...')
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        try {
            const res = await axios.post(`${base_url}/update_urine_r_e_result/${id}`, 
            {...formData, 'reporter_id': reporter, 'reporter_2_id': reporter2}, {
                headers: {
                    Accept: 'application/json',
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,

                }
            })
            if (res.data.status === 'success') {
                navigate(-1)
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status,
                    timer: 800
                })
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
            }
        } catch (error) {
            console.log(error.response.data.message)
        }
    }
    useEffect(() => {
        fetchTestResult()
    }, [])
    return (
        <div className='mainWindow'>
            <form className="urine_re_form">
                {loading ? <Audio color='cadetblue' height={30} /> : <>
                    <h2>Urine R/E for Id : {patient_id} <Link to={-1} className='h2_right_btn cancelBtn'>&times;</Link></h2>
                    <span><b><u>Physical:</u></b></span>
                    <div className="urineFormRow">
                        <label htmlFor="quantity">Quantity:</label>
                        <select name="quantity" id="quantity" className="urineInput"
                            value={formData.quantity} onChange={e => handleChange(e)}>
                            <option value="Sufficient">Sufficient</option>
                            <option value="5 ml">5 ml</option>
                            <option value="10 ml">10 ml</option>
                            <option value="15 ml">15 ml</option>
                            <option value="20 ml">20 ml</option>
                            <option value="30 ml">30 ml</option>
                        </select>
                        <label htmlFor="color">Color:</label>
                        <select name="color" id="color" className="urineInput"
                            value={formData.color} onChange={e => handleChange(e)}>
                            <option value="Straw">Straw</option>
                            <option value="Yellow">Yellow</option>
                            <option value="Red">Red</option>
                            <option value="Reddish">Reddish</option>
                            <option value="Milky White">Milky White</option>
                            <option value="Greenish">Greenish</option>
                        </select>
                        <label htmlFor="appearence">Appear:</label>
                        <select name="appearence" id="appearence" className="urineInput"
                            value={formData.appearence} onChange={e => handleChange(e)}>
                            <option value="Clear">Clear</option>
                            <option value="Hazy">Hazy</option>
                            <option value="Cloudy">Cloudy</option>
                        </select>
                        <label htmlFor="sediment">Sediment</label>
                        <select name="sediment" id="sediment" className="urineInput"
                            value={formData.sediment} onChange={e => handleChange(e)}>
                            <option value="Nil">Nil</option>
                            <option value="Present">Present</option>
                        </select>
                        <label htmlFor="specific_gravity">Spec. gravity:</label>
                        <input type='text' name="specific_gravity" id="specific_gravity" className="urineInput urineSmInput"
                            value={formData.specific_gravity} onChange={e => handleChange(e)} />

                    </div>

                    <hr />
                    <span><b><u>Chemical:</u></b></span>
                    <div className="urineFormRow">
                        <label htmlFor="reaction">Reaction:</label>
                        <select name="reaction" id="reaction" className="urineInput"
                            value={formData.reaction} onChange={e => handleChange(e)}>
                            <option value="Acidic">Acidic</option>
                            <option value="Alkaline">Alkaline</option>
                        </select>
                        <label htmlFor="protein">Protein:</label>
                        <select name="protein" id="protein" className="urineInput urineSmInput"
                            value={formData.protein} onChange={e => handleChange(e)}>
                            <option value="Nil">Nil</option>
                            <option value="Trace">Trace</option>
                            <option value="(+)">(+)</option>
                            <option value="(++)">(++)</option>
                            <option value="(+++)">(+++)</option>
                            <option value="(++++)">(++++)</option>
                        </select>
                        <label htmlFor="sugar">Sugar:</label>
                        <select name="sugar" id="sugar" className="urineInput urineSmInput"
                            value={formData.sugar} onChange={e => handleChange(e)}>
                            <option value="Nil">Nil</option>
                            <option value="Trace">Trace</option>
                            <option value="(+)">(+)</option>
                            <option value="(++)">(++)</option>
                            <option value="(+++)">(+++)</option>
                            <option value="(++++)">(++++)</option>
                        </select>
                        <label htmlFor="phosphate">Phosphate:</label>
                        <select name="phosphate" id="phosphate" className="urineInput urineSmInput"
                            value={formData.phosphate} onChange={e => handleChange(e)}>
                            <option value="Nil">Nil</option>
                            <option value="Trace">Trace</option>
                            <option value="(+)">(+)</option>
                            <option value="(++)">(++)</option>
                            <option value="(+++)">(+++)</option>
                            <option value="(++++)">(++++)</option>
                        </select>
                        <label htmlFor="ph">p<sup>H</sup>:</label>
                        <input type='text' name="ph" id="ph" className="urineInput urineSmInput"
                            value={formData.ph} onChange={e => handleChange(e)} />

                    </div>
                    &nbsp;<input type="checkbox" name="more_chemical_cb" id="more_chemical_cb"
                        checked={formData.more_chemical_cb != 0} onChange={e => handleChecked(e)} />
                    &nbsp;<label htmlFor="more_chemical_cb"><u>More:</u></label>
                    {formData.more_chemical_cb != 0?
                        <>
                            <div className="urineFormRow">
                                <label htmlFor="ketones">Ketones:</label>
                                <select name="ketones" id="ketones" className="urineInput urineSmInput"
                                    value={formData.ketones} onChange={e => handleChange(e)}>
                                    <option value="ND">N/D</option>
                                    <option value="Negative">Negative</option>
                                    <option value="Positive">Positive</option>
                                </select>
                                <label htmlFor="bilirubin">Bilirubin:</label>
                                <select name="bilirubin" id="bilirubin" className="urineInput urineSmInput"
                                    value={formData.bilirubin} onChange={e => handleChange(e)}>
                                    <option value="ND">N/D</option>
                                    <option value="Negative">Negative</option>
                                    <option value="Positive">Positive</option>
                                </select>
                                <label htmlFor="urobilinogen">Urobilinogen:</label>
                                <select name="urobilinogen" id="urobilinogen" className="urineInput urineSmInput"
                                    value={formData.urobilinogen} onChange={e => handleChange(e)}>
                                    <option value="ND">N/D</option>
                                    <option value="Negative">Negative</option>
                                    <option value="Positive">Positive</option>
                                </select>
                                <label htmlFor="nitrite">Nitrite:</label>
                                <select name="nitrite" id="nitrite" className="urineInput urineSmInput"
                                    value={formData.nitrite} onChange={e => handleChange(e)}>
                                    <option value="ND">N/D</option>
                                    <option value="Negative">Negative</option>
                                    <option value="Positive">Positive</option>
                                </select>
                                <label htmlFor="leukocyte_ester">Leukocyte Ester:</label>
                                <select name="leukocyte_ester" id="leukocyte_ester" className="urineInput urineSmInput"
                                    value={formData.leukocyte_ester} onChange={e => handleChange(e)}>
                                    <option value="ND">N/D</option>
                                    <option value="Negative">Negative</option>
                                    <option value="Positive">Positive</option>
                                </select>
                            </div>
                            <div className="urineFormRow">
                                <label htmlFor="other_chemical_1">Other  1:</label>
                                <input type='text' name="other_chemical_1" id="other_chemical_1" className="urineInput urineLgInput"
                                    value={formData.other_chemical_1} onChange={e => handleChange(e)} placeholder='Substance name : value' />
                                <label htmlFor="other_chemical_2">Other  2:</label>
                                <input type='text' name="other_chemical_2" id="other_chemical_2" className="urineInput urineLgInput"
                                    value={formData.other_chemical_2} onChange={e => handleChange(e)} placeholder='Substance name : value' />
                            </div>


                        </>
                        : <></>}
                    <hr />
                    <div className="urineFormRow">
                        <span><b>Microscopic:</b></span>
                        <label htmlFor="epi_cell">Epi Cell:</label>
                        <input autoFocus type="text" className="urineInput" name='epi_cell' id="epi_cell"
                            onChange={e => handleChange(e)} value={formData.epi_cell} />
                        <label htmlFor="pus_cell">Pus Cell:</label>
                        <input type="text" className="urineInput" name='pus_cell' id="pus_cell"
                            onChange={e => handleChange(e)} value={formData.pus_cell} />
                        <label htmlFor="rbc">RBC:</label>
                        <input type="text" className="urineInput" name='rbc' id="rbc"
                            onChange={e => handleChange(e)} value={formData.rbc} />
                    </div>

                    &nbsp; <input type="checkbox" name="crystals_cb" id="crystals_cb"
                        checked={formData.crystals_cb != 0} onChange={e => handleChecked(e)} />
                    <label htmlFor="crystals_cb"> <u>Crystals:</u>  </label>

                    {formData.crystals_cb != 0 ? (
                        <div className="urineFormRow cast_crystal_row">
                            <label htmlFor="calc_oxal">Calc. Oxal.:</label>
                            <select name="calc_oxal" id="calc_oxal" className="urineInput urineSmInput"
                                value={formData.calc_oxal} onChange={e => handleChange(e)}>
                                <option value="NF">Nil</option>
                                <option value="A few">A few</option>
                                <option value="(+)">(+)</option>
                                <option value="(++)">(++)</option>
                                <option value="(+++)">(+++)</option>
                                <option value="(++++)">(++++)</option>
                            </select>
                            <label htmlFor="calc_carb">Calc. Carb.:</label>
                            <select name="calc_carb" id="calc_carb" className="urineInput urineSmInput"
                                value={formData.calc_carb} onChange={e => handleChange(e)}>
                                <option value="NF">Nil</option>
                                <option value="A few">A few</option>
                                <option value="(+)">(+)</option>
                                <option value="(++)">(++)</option>
                                <option value="(+++)">(+++)</option>
                                <option value="(++++)">(++++)</option>
                            </select>
                            <label htmlFor="uric_acid">Uric Acid:</label>
                            <select name="uric_acid" id="uric_acid" className="urineInput urineSmInput"
                                value={formData.uric_acid} onChange={e => handleChange(e)}>
                                <option value="NF">Nil</option>
                                <option value="A few">A few</option>
                                <option value="(+)">(+)</option>
                                <option value="(++)">(++)</option>
                                <option value="(+++)">(+++)</option>
                                <option value="(++++)">(++++)</option>
                            </select>
                            <label htmlFor="am_phos">Am. Phos.:</label>
                            <select name="am_phos" id="am_phos" className="urineInput urineSmInput"
                                value={formData.am_phos} onChange={e => handleChange(e)}>
                                <option value="NF">Nil</option>
                                <option value="A few">A few</option>
                                <option value="(+)">(+)</option>
                                <option value="(++)">(++)</option>
                                <option value="(+++)">(+++)</option>
                                <option value="(++++)">(++++)</option>
                            </select>
                            <label htmlFor="tripple_phos">Tripple Phos.:</label>
                            <select name="tripple_phos" id="tripple_phos" className="urineInput urineSmInput"
                                value={formData.tripple_phos} onChange={e => handleChange(e)}>
                                <option value="NF">Nil</option>
                                <option value="A few">A few</option>
                                <option value="(+)">(+)</option>
                                <option value="(++)">(++)</option>
                                <option value="(+++)">(+++)</option>
                                <option value="(++++)">(++++)</option>
                            </select>
                        </div>
                    ) : (<div></div>)}

                    &nbsp; <input type="checkbox" name="casts_cb" id="casts_cb" checked={formData.casts_cb != 0}
                        onChange={e => handleChecked(e)} />
                    <label htmlFor="casts_cb"> <u>Casts:</u> </label>

                    {formData.casts_cb != 0 ? (<div className="urineFormRow cast_crystal_row">
                        <label htmlFor="epi_cast">Epi Cast:</label>
                        <input type="text" className="urineInput urineSmInput" name='epi_cast' id="epi_cast"
                            onChange={e => handleChange(e)} value={formData.epi_cast} />
                        <label htmlFor="wbc_cast">WBC Cast:</label>
                        <input type="text" className="urineInput urineSmInput" name='wbc_cast' id="wbc_cast"
                            onChange={e => handleChange(e)} value={formData.wbc_cast} />
                        <label htmlFor="rbc_cast">RBC Cast:</label>
                        <input type="text" className="urineInput urineSmInput" name='rbc_cast' id="rbc_cast"
                            onChange={e => handleChange(e)} value={formData.rbc_cast} />
                        <label htmlFor="cgranu_cast">C.Granu Cast:</label>
                        <input type="text" className="urineInput urineSmInput" name='cgranu_cast' id="cgranu_cast"
                            onChange={e => handleChange(e)} value={formData.cgranu_cast} />
                        <label htmlFor="fgranu_cast">F.Granu Cast:</label>
                        <input type="text" className="urineInput urineSmInput" name='fgranu_cast' id="fgranu_cast"
                            onChange={e => handleChange(e)} value={formData.fgranu_cast} />
                        <label htmlFor="hyaline_cast">Hyal. Cast:</label>
                        <input type="text" className="urineInput urineSmInput" name='hyaline_cast' id="hyaline_cast"
                            onChange={e => handleChange(e)} value={formData.hyaline_cast} />
                        <label htmlFor="waxy_cast">Waxy Cast:</label>
                        <input type="text" className="urineInput urineSmInput" name='waxy_cast' id="waxy_cast"
                            onChange={e => handleChange(e)} value={formData.waxy_cast} />
                        <label htmlFor="fatty_cast">Fatty Cast:</label>
                        <input type="text" className="urineInput urineSmInput" name='fatty_cast' id="fatty_cast"
                            onChange={e => handleChange(e)} value={formData.fatty_cast} />
                    </div>) : (<div></div>)}

                    &nbsp; <input type="checkbox" name="sperm_cb" id="sperm_cb" checked={formData.sperm_cb != 0}
                        onChange={e => handleChecked(e)} />
                    <label htmlFor="sperm_cb"> <u>Sperm:</u>  </label>

                    {formData.sperm_cb != 0 ? (<span>
                        <select type="text" className="urineInput " name='sperm' id="sperm"
                            onChange={e => handleChange(e)} value={formData.sperm} >
                            <option value="NF">Nil</option>
                            <option value="A few">A few</option>
                            <option value="(+)">(+)</option>
                            <option value="(++)">(++)</option>
                            <option value="(+++)">(+++)</option>
                            <option value="(++++)">(++++)</option>
                        </select> <br />
                    </span>) : (<div></div>)}

                    &nbsp; <input type="checkbox" name="yeast_cb" id="yeast_cb" checked={formData.yeast_cb != 0}
                        onChange={e => handleChecked(e)} />
                    <label htmlFor="yeast_cb"> <u>Yeast:</u>  </label>

                    {formData.yeast_cb != 0 ? (<span>
                        <select className="urineInput" name='yeast' id="yeast"
                            onChange={e => handleChange(e)} value={formData.yeast} >
                            <option value="NF">Nil</option>
                            <option value="A few">A few</option>
                            <option value="(+)">(+)</option>
                            <option value="(++)">(++)</option>
                            <option value="(+++)">(+++)</option>
                            <option value="(++++)">(++++)</option>
                        </select>
                        <br />
                    </span>) : (<div></div>)}

                    &nbsp; <input type="checkbox" name="others_cb" id="others_cb" checked={formData.others_cb != 0}
                        onChange={e => handleChecked(e)} />
                    <label htmlFor="others_cb"> <u>Others:</u>  </label>

                    {formData.others_cb != 0 ? (
                        <div className="urineFormRow cast_crystal_row">
                            <label htmlFor="other_1">Other-1:</label>
                            <input type="text" className="urineInput urineLgInput" name='other_1' id="other_1"
                                onChange={e => handleChange(e)} value={formData.other_1} placeholder='Substance name : value' />
                            <label htmlFor="other_2">Other-2:</label>
                            <input type="text" className="urineInput urineLgInput" name='other_2' id="other_2"
                                onChange={e => handleChange(e)} value={formData.other_2} placeholder='Substance name : value' /><br />
                        </div>
                    ) : (<div></div>)}
                    <div>
                        <label htmlFor="reporter_id" >Reporter:</label>
                        <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                            value={reporter} className="urineInput urineLgInput" >
                            <option value="">Select reporter</option>
                            {reporters.map((el) =>
                                <option key={el.id} value={el.id}>{el.reporter_name}</option>
                            )}
                        </select>
                        <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label>
                            <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                                value={reporter2} className="urineInput urineLgInput" >
                                <option value="">Select reporter 2</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select>
                    </div><br />
                    <div className="urineFormRow cast_crystal_row">
                        <input type="submit" value="Update" className='my_btn submit_btn' onClick={e => handleSubmit(e)} />
                        {waitMsg !== '' ? <span className="waitMsgDiv"> {waitMsg}</span> : <></>}

                    </div>
                </>}
            </form>
        </div>

    )
}

export default UrineRE