import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import { DateFormat } from '../../services/DateFormat';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2'
import './refferrerStyle.css'
import { Audio } from 'react-loader-spinner';
import Pagination from '../../services/Pagination';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';

const ReffererPatients = () => {
  const base_Url = baseUrl();
  const authToken = getToken();
  const { id } = useParams();
  const { refferer_name } = useParams();
  const [fetchedPatients, setFetchedPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paidPcPatients, setPaidPcPatients] = useState([]);
  const [duePcPatients, setDuePcPatients] = useState([]);
  const [showWaitModal, setshowWaitModal] = useState(false)
  ///Pagination---------------------Start
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 15;
  const lastElementIndex = currentPage * elementsPerPage;
  const firstElementIndex = lastElementIndex - elementsPerPage;
  const paginatedData = fetchedPatients.slice(firstElementIndex, lastElementIndex)
  ///Pagination --------------------End

  const fetchPatients = async () => {
    try {
      const res = await axios.get(`${base_Url}/refferer_patients/${id}`, {
        headers: {
          "Accept": 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
      setFetchedPatients(res.data)
      setPaidPcPatients(res.data.filter(elem => {
        return elem.ref_pc_paid === 1
      }))
      setDuePcPatients(res.data.filter(elem => {
        return elem.ref_pc_paid === 0
      }))
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const pcNARequest = async pid => {
    setshowWaitModal(true)
    try {
      const res = await axios.put(`${base_Url}/update_ref_pc_pay_status/${pid}`, { 'pay_status': 2 }, {
        headers: {
          "Accept": 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
      setshowWaitModal(false)
      swal.fire({
        'text': res.data.message,
        'icon': res.data.status
      })
      fetchPatients()
    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
    }
  }


  const pcNAHandler = pid => {
    Swal.fire({
      icon: 'warning',
      text: 'This action is not reversible. Are you sure?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Change'
    }).then((result) => {
      if (result.isConfirmed) {
        pcNARequest(pid)
      }
    })
  }
  useEffect(() => {
    fetchPatients()
  }, [])
  return (
    <div className='mainWindow'>
      {showWaitModal ? <Modal data='Please wait' title='wait' /> : <></>}
      <div className="my_container">
        {loading ? <Audio color='cadetblue' height={30} /> : <>
          <h2>Patients ref. by : {refferer_name}
            <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
          </h2>
          {fetchedPatients.length < 1 ? <h3 className='notFound'>No patient found</h3> : <>
            <div className="refferer_summary">
              <div className="transactionSummary">
                <div className="transactionSummarySingle">
                  Total patients : {fetchedPatients.length}
                </div>
                <div className="transactionSummarySingle">
                  Total PC : {fetchedPatients.reduce((a, b) => {
                    return a + parseInt(b.refferer_commision)
                  }, 0)}
                </div>
                <div className="transactionSummarySingle">
                  Paid PC : {paidPcPatients.reduce((a, b) => {
                    return a + parseInt(b.refferer_commision)
                  }, 0)}
                </div>
                <div className="transactionSummarySingle">
                  Due PC : {duePcPatients.reduce((a, b) => {
                    return a + parseInt(b.refferer_commision)
                  }, 0)}
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Patient name - age</th>
                  <th>Total Fee</th>
                  <th>Discount</th>
                  <th>Paid</th>
                  <th>Due</th>
                  <th>Ref. PC</th>
                  <th>PC Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((elem, i) =>
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{DateFormat(elem.patient_date)}</td>
                    <td>{`${elem.patient_name} - ${elem.patient_age} ${elem.patient_age_unit}`}</td>
                    <td>{elem.total_amount}</td>
                    <td>{elem.discount_amount}</td>
                    <td>{elem.paid_amount}</td>
                    <td>{elem.due_amount}</td>
                    <td>{elem.refferer_commision}</td>
                    <td>{elem.ref_pc_paid == 0 ? 'Due' : elem.ref_pc_paid == 2 ? 'Not applicable' : 'Paid'}
                    </td>
                    <td>
                      {elem.ref_pc_paid !== 2 ?
                        <button style={{ 'padding': '0 0.2rem', 'fontSize': 'small' }} onClick={() => pcNAHandler(elem.id)} className='my_btn blueBtn'>PC N/A</button>
                        : ''}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              elementsPerPage={elementsPerPage}
              totaElements={fetchedPatients.length}
              pageNo={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </>}

        </>}
      </div>
    </div>
  )
}

export default ReffererPatients