import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import Modal from '../modal/Modal';

const ChangeProfile = () => {
  const navigate = useNavigate();
  const base_url = baseUrl();
  const authToken = getToken()
  const [imageData, setImageData] = useState('');
  const [showWaitModal, setshowWaitModal] = useState(false)
  const handleImage = e => {
    setImageData(e.target.files[0])
  }
  const handleSubmit = async e => {
    e.preventDefault();
    setshowWaitModal(true)
    const form_data = new FormData();
    form_data.append('profile_pic', imageData);
    try {
      const res = await axios.post(`${base_url}/change_profile_picture`,
        form_data,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        })
      setshowWaitModal(false)
      console.log(res.data)
      Swal.fire({
        'icon': res.data.status,
        'text': res.data.message
      })
      if(res.data.status === 'success'){
        navigate(-1)
      }
    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
      if (error.response.status === 422) {
        Swal.fire({
          'icon': 'info',
          'text': error.response.data.message
        })
      }
    }
  }
  return (
    <div className='mainWindow'>
      {showWaitModal ? <Modal data='Uploading image' title='wait' /> : <></>}
      <div className="profileWrapper changeProfileWrapper">
        <form className="profilePicForm" encType='multipart/form-data' onSubmit={handleSubmit} >
          <h2>Change profile picture</h2>
          <input type="file" onChange={handleImage} required />
          <input type="submit" value="Upload" className="my_btn submit_btn" />
        </form>
      </div>
    </div>
  )
}

export default ChangeProfile