import React, { useEffect, useState } from 'react'
import { DateFormat } from '../../services/DateFormat'
import { AiOutlineDelete } from 'react-icons/ai'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import Modal from '../modal/Modal'
import { GetDate } from '../../services/Date'

const SingleDoctorSchedule = () => {
    const today = GetDate()
    const base_url = baseUrl();
    const authToken = getToken();
    const [loading, setloading] = useState(true)
    const [showWaitModal, setshowWaitModal] = useState(false)
    const [addedSchedules, setAddedSchedules] = useState([])
    const [assistants, setassistants] = useState([])
    const [formData, setformData] = useState({
        'schedule_date': '',
        'chamber_name': '',
        'assistant_id': '',
        'new_patient_fee': '',
        'follow_up_fee': ''
    })
    const deleteOldSchedules = async () => {
        try {
            const res = await axios.get(`${base_url}/delete_single_doctor_old_schedules/${today}`, 
            {
                headers : {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            fetchAddedSchedule()
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            console.log(error)
        }
    }
    const fetchAddedSchedule = async () => {
        try {
            const res = await axios.get(`${base_url}/show_single_doctor_added_schedules`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            setAddedSchedules(res.data.dbData)
            setassistants(res.data.assistants)
            let old_schedules = res.data.dbData.filter((el)=> el.schedule_date < today)
            if(old_schedules.length > 0){
                deleteOldSchedules()
            }
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        if(formData.schedule_date < today){
            Swal.fire({
                icon : 'info',
                text : 'Schedule date can not be less than today..!'
            })
            return false;
        }
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/add_single_doctor_schedule`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                setformData({
                    'schedule_date': '',
                    'chamber_name': '',
                    'assistant_id': '',
                    'new_patient_fee': '',
                    'follow_up_fee': ''
                })
                fetchAddedSchedule()
            }
        } catch (error) {
            setshowWaitModal(false)
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'info',
                    text: error.response.data.message
                })
            }
            console.log(error)
        }
    }
    const deleteRequest = async id => {
        setshowWaitModal(true)
        try {
            const res = await axios.get(`${ base_url}/delete_single_doctor_schedule/${id}`,{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            if (res.data.status === 'success') {
                fetchAddedSchedule()
            }            
            Swal.fire({
                icon : res.data.status,
                text : res.data.message
            })
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
        }
    }
    const handleDelete = (id) => {
        Swal.fire({
            icon : 'question',
            text : 'Delete this schedule and patients..?',
            showConfirmButton : true,
            showCancelButton : true,
            confirmButtonText : 'Delete',
            confirmButtonColor : 'tomato'
        }).then((result)=> {
            if(result.isConfirmed){
                deleteRequest(id)
            }
        })
    }
    useEffect(() => {
        fetchAddedSchedule()
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal title='wait' data='Please wait...' /> : <></>}
            {loading ? <Audio height={30} color='cadetblue' /> :
                <div className="doctorScheduleWrapper">
                    <h2>Schedules (Add / Delete)</h2>
                    <form className="doctorScheduleForm" onSubmit={handleSubmit}>
                        <label htmlFor="schedule_date">Date:</label>
                        <input type="date" className="my_field" style={{ 'width': '8rem', 'padding': '0.2rem 0.1rem' }} name='schedule_date' id='schedule_date' value={formData.schedule_date} onChange={handleChange} />
                        <label htmlFor="assistant_id">Assistant:</label>
                        <select name="assistant_id" onChange={handleChange} style={{ 'width': '8rem', 'padding': '0.2rem 0.1rem' }} id="assistant_id" className='my_field smallFont'>
                            <option value="">Select assistant</option>
                            {assistants.map(el => <option key={el.id} value={el.id}>{el.name}</option>)}
                        </select><br />
                        <label htmlFor="chamber_name">Chamber name:</label>
                        <input type="text" className="my_field" style={{ 'width': '15rem', 'padding': '0.2rem 0.1rem' }} name='chamber_name' id='chamber_name' value={formData.chamber_name} onChange={handleChange} />
                        <br />
                        <label htmlFor="new_patient_fee">Fee (new):</label>
                        <input type="number" style={{ 'width': '4rem', 'padding': '0.2rem 0.1rem' }} className="my_field smallFont" name='new_patient_fee' id='new_patient_fee' value={formData.new_patient_fee} onChange={handleChange} />
                        <label htmlFor="follow_up_fee">Fee (Follow-up):</label>
                        <input type="number" style={{ 'width': '4rem', 'padding': '0.2rem 0.1rem' }} className="my_field smallFont" name='follow_up_fee' id='follow_up_fee' value={formData.follow_up_fee} onChange={handleChange} />
                        <input type="submit" value="Add" className="my_btn submit_btn" />
                    </form>
                    <div className="addedScheduleDiv">
                        <h3>Added schedules</h3>
                        {addedSchedules.length < 1 ? <h1 className='failed'>No schedule found</h1> :
                            <table className='smallFont'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Chamber</th>
                                        <th>Assistant</th>
                                        <th>Fee</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {addedSchedules.map((elem, i) =>
                                        <tr key={elem.id}>
                                            <td>{i + 1}</td>
                                            <td>{DateFormat(elem.schedule_date)}</td>
                                            <td>{elem.chamber_name}</td>
                                            <td>{elem.assistant_name}</td>
                                            <td>{elem.new_patient_fee} / {elem.follow_up_fee}</td>
                                            <td className='action_td'>
                                                <button className='no_border_btn' onClick={() => handleDelete(elem.id)}><AiOutlineDelete size={20} color='tomato' /></button>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default SingleDoctorSchedule