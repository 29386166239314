import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner';
import Modal from '../../modal/Modal';

const SingleField = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [testName, setTestName] = useState('');
    const navigate = useNavigate()
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setloading] = useState(true);
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [reporters, setreporters] = useState([])
    const [formData, setformData] = useState({
        'test_result' : ''
    })
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))

    const fetchResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_single_test_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            console.log(res.data)
            if (res.data.status === 'success') {
                if(res.data.test_result !== 'Not inserted yet.!'){
                    setformData({
                        ...formData,
                        'test_result': res.data.test_result
                    })
                } 
                if(res.data.reporter_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter).length < 1){
                        setReporter('')
                    }
                }else{
                    setReporter(res.data.reporter_id)
                }
                if(res.data.reporter_2_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter2).length < 1){
                        setReporter2('')
                    }
                }else{
                    setReporter2(res.data.reporter_2_id)
                }              
                setreporters(res.data.reporters)
            }
            else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
           

        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    

    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if(formData.test_result == null || formData.test_result == ''){
            autoAlert("Please enter result..!")
            return false;
        }
        if(reporter == '' || reporter === null){
            autoAlert("Please select a reporter")
            return false
        }
        if(reporter ==  reporter2){
            autoAlert("2 reporters can not be same.!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setshowWaitModal(true);
        try {
            const res = await axios.post(`${base_url}/add_single_test_result/${id}`, 
            {...formData, 'reporter': reporter, 'reporter_2' : reporter2}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false);
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate(-1)
            }
        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        if (searchParams.get('test_name')) {
            setTestName(searchParams.get('test_name'))
        }
        fetchResult()
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal title='wait' data='Updating result' /> : <></>}
            <div className="resultFormWrapper">
                <h2>{testName} for patient id : {patient_id}
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                {loading ? <Audio color='cadetblue' height='30' /> :
                    <form className="singleFieldForm" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="test_result">Result:</label><br />
                            <input id='test_result' name='test_result' type="text" className='my_field' onChange={handleChange}
                                value={formData.test_result} autoFocus />
                        </div>
                        <div>
                            <label htmlFor="reporter_id" >Reporter:</label><br />
                            <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                                value={reporter} className="my_field" >
                                <option value="">Select reporter</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select> <br />
                            <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label><br />
                            <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                                value={reporter2} className="my_field" >
                                <option value="">Select reporter 2</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select>
                        </div>
                        <br />
                        <input type="submit" value='Update' className='my_btn submit_btn' />
                    </form>}
            </div>
        </div>
    )
}

export default SingleField