import React from 'react'
import './modalStyle.css'
import { TailSpin } from 'react-loader-spinner'

const Modal = ({ data, title , setShowModal }) => {
    return (
        <div className='modalFull'>
            <div className="modal_box">
                <div className="modal_title">
                    {data}
                </div>
                <div className="modal_body">
                    {title === 'wait' ? <TailSpin
                        height={40} color='cadetblue' /> : <></>}
                </div>                

            </div>

        </div>
    )
}

export default Modal