import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppContext } from '../../contex/appContext'
import { getToken } from '../../localStorageManage/localStore'
import { baseUrl } from '../../services/baseUrl'
import { GetDate } from '../../services/Date'
import './newPatientStyle.css'
import swal from 'sweetalert2'
import { BsQrCode } from 'react-icons/bs'
import QrReader from 'react-qr-reader-es6'

const NewPatient = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const authToken = getToken();
    const base_url = baseUrl();
    const today = GetDate()
    const { loading, userData } = useAppContext();
    const { user_type } = userData;
    useEffect(() => {
        if (user_type !== 'admin' && user_type !== 'manager' && user_type !== 'reception') {
            navigate('/')
        }
    }, [loading])
    const [formData, setFormData] = useState({
        'patient_id_no': '',
        'patient_date': today,
        'patient_name': '',
        'patient_age': '',
        'patient_age_unit': 'Year(s)',
        'patient_sex': 'F',
        'patient_ref_by': '',
        'patient_phone': ''
    })
    const [fieldErrors, setFieldErrors] = useState({
        'patient_id_no': '',
        'patient_date': '',
        'patient_name': '',
        'patient_age': '',
        'patient_ref_by': '',
    })
    const [submitBtnValue, setSubmitBtnValue] = useState('Add');
    const [resAlert, setResAlert] = useState('');
    const [refferers, setRefferers] = useState([]);
    const [showRefferers, setShowRefferers] = useState(false);
    const [showQRDiv, setshowQRDiv] = useState(false)

    ///function for QR code reader================Start
    const handleScan = (data) => {
        if (data !== null) {
            setFormData({
                ...formData,
                'patient_name': JSON.parse(data).p_n,
                'patient_age': JSON.parse(data).age,
                'patient_age_unit': JSON.parse(data).age_u,
                'patient_sex': JSON.parse(data).sex,
                'patient_ref_by': JSON.parse(data).d_n,
                'patient_phone': JSON.parse(data).phon,
            })
            setshowQRDiv(false)
        }

    }
    const handleError = (err) => {
        console.log(err)
    }

    ///function for QR code reader================end
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleReffererChange = async (e) => {
        setFormData({
            ...formData,
            'patient_ref_by': e.target.value
        })
        try {
            const res = await axios.post(`${base_url}/show_refferers`, { data: e.target.value }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setRefferers(res.data)
        } catch (error) {
            console.log(error)

        }
    }
    const handleSelectedRefferer = (name) => {
        setFormData({
            ...formData,
            'patient_ref_by': name
        })
        setShowRefferers(false)
    }
    const handleFocusRefferer = () => {
        setShowRefferers(true)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setSubmitBtnValue('Please wait..')
        setResAlert('');
        setFieldErrors({
            'patient_id_no': '',
            'patient_date': '',
            'patient_name': '',
            'patient_age': '',
            'patient_ref_by': '',
        })
        localStorage.setItem('rahmatlabSolution_patient_id', formData.patient_id_no)
        try {
            const res = await axios.post(`${base_url}/add_new_patient`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })

            setSubmitBtnValue('Add')
            swal.fire({
                'icon': res.data.status,
                'text': res.data.message,
                timer: 1000
            })
            if (res.data.status === 'success') {
                navigate(`/services_for_patients/${res.data.patient_id}`, { state: { redir_form: pathname } })
            }

        } catch (error) {
            setSubmitBtnValue('Add')
            if (error.response.status === 422) {
                setResAlert('failed')
                setFieldErrors({
                    'patient_id_no': error.response.data.errors.patient_id_no,
                    'patient_date': error.response.data.errors.patient_date,
                    'patient_name': error.response.data.errors.patient_name,
                    'patient_age': error.response.data.errors.patient_age,
                    'patient_ref_by': error.response.data.errors.patient_ref_by,
                })
            }
        }
    }
    useEffect(()=> {
        if(localStorage.getItem('rahmatlabSolution_patient_id')){
            setFormData({
                ...formData,
                patient_id_no : parseInt(localStorage.getItem('rahmatlabSolution_patient_id')) + 1
            })
        }
    },[])
    return (
        <div className='mainWindow'>
            <div className="my_container">
                <h2>
                    <button className="my_btn h2_left no_border_btn" onClick={() => setshowQRDiv(!showQRDiv)}><BsQrCode size={20} /></button>
                    Add new patient
                    {userData.user_type === 'reception' ? <> </> :
                        <Link className='h2_right_btn cancelBtn' to='/patient_management'>&times;</Link>
                    }
                </h2>
                {showQRDiv ?
                    <div className="qr_reader_div">
                        <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '80%' }}
                        />
                    </div> : <></>}


                <form onSubmit={e => handleSubmit(e)}>
                    <div className="my_form_row">
                        <div className="my_input_group">
                            <label htmlFor="patient_id_no"> Id:</label>
                            <input type="text" className="my_field" id="patient_id_no" name='patient_id_no'
                                onChange={e => handleChange(e)} value={formData.patient_id_no} />
                            {fieldErrors.patient_id_no !== '' && fieldErrors.patient_id_no ?
                                <div className={resAlert}>{fieldErrors.patient_id_no}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="patient_date"> Date:</label>
                            <input type="date" className="my_field" id="patient_date" name='patient_date'
                                onChange={e => handleChange(e)} value={formData.patient_date} />
                            {fieldErrors.patient_date !== '' && fieldErrors.patient_date ?
                                <div className={resAlert}>{fieldErrors.patient_date}</div> : ''}
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="my_form_row">
                        <div className="my_input_group">
                            <label htmlFor="patient_name"> Patient name:</label>
                            <input type="text" className="my_field" id="patient_name" name='patient_name'
                                onChange={e => handleChange(e)} value={formData.patient_name} />
                            {fieldErrors.patient_name !== '' && fieldErrors.patient_name ?
                                <div className={resAlert}>{fieldErrors.patient_name}</div> : ''}
                        </div>

                        <div className="my_input_group">
                            <label htmlFor="patient_age"> Age:</label>
                            <input type="text" className="my_field " id="patient_age" name='patient_age'
                                onChange={e => handleChange(e)} value={formData.patient_age} />
                            {fieldErrors.patient_age !== '' && fieldErrors.patient_age ?
                                <div className={resAlert}>{fieldErrors.patient_age}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="patient_age_unit"> Age unit:</label>
                            <select className="my_field " id="patient_age_unit" name='patient_age_unit'
                                onChange={e => handleChange(e)} value={formData.patient_age_unit} >
                                <option value="Year(s)">Years</option>
                                <option value="Month(s)">Months</option>
                                <option value="Day(s)">Days</option>
                            </select>
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="patient_sex"> Sex:</label>
                            <select className="my_field " id="patient_sex" name='patient_sex'
                                onChange={e => handleChange(e)} value={formData.patient_sex} >
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="3rd">3rd</option>
                            </select>
                        </div>
                    </div>
                    <div className="my_form_row">
                        <div className="my_input_group">
                            <label htmlFor="patient_ref_by"> Refferd by:</label>
                            <input type="text" className="my_field " id="patient_ref_by" name='patient_ref_by'
                                onChange={e => handleReffererChange(e)} value={formData.patient_ref_by}
                                onFocus={handleFocusRefferer} autoComplete="off" />
                            {fieldErrors.patient_ref_by !== '' && fieldErrors.patient_ref_by ?
                                <div className={resAlert}>{fieldErrors.patient_ref_by}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="patient_phone">Patient phone:</label>
                            <input type="text" className="my_field " id="patient_phone" name='patient_phone'
                                onChange={e => handleChange(e)} value={formData.patient_phone}
                                autoComplete="off" />
                        </div>
                        <div className='my_input_group'>
                            <br />
                            <input type="submit" style={{ 'marginTop': '0.2rem', 'padding': '0.5rem' }} value={submitBtnValue} className='my_btn submit_btn' />
                        </div>
                    </div>
                    <div className='my_form_row'>
                        <div className={showRefferers ? 'refferersDiv showRefferers' : 'refferersDiv hideRefferers'}>
                            {refferers.map(el =>
                                <div key={el.id} onClick={() => { handleSelectedRefferer(el.refferer_name) }}>
                                    {el.refferer_name}
                                </div>)}
                        </div>
                        <div>

                        </div>
                    </div>

                </form>
            </div >
        </div >
    )
}

export default NewPatient