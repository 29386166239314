import React from 'react'
import { Link } from 'react-router-dom'

const DoctorForm = (props) => {
  return (
    <form className='doctor_form' onSubmit={props.handleSubmit}>
      <h2>{props.title}
        <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
      </h2>
      <label htmlFor="doctor_name">Doctor Name:</label><br />
      <input type="text" id="doctor_name" className="my_field" name='doctor_name'
        onChange={props.handleChange} value={props.doctorData.doctor_name} /> <br />
      <label htmlFor="doctor_degree">Doctor Degree:</label><br />
      <input type="text" id="doctor_degree" className="my_field" name='doctor_degree'
        onChange={props.handleChange} value={props.doctorData.doctor_degree} /> <br />
      <label htmlFor="doctor_details">Doctor Details:</label><br />
      <textarea rows={5} type="text" id="doctor_details" className="my_field" name='doctor_details'
        onChange={props.handleChange} value={props.doctorData.doctor_details} > </textarea><br />
      <div className="flexDiv" style={{'justifyContent': 'start', 'marginLeft': '1rem'}}>
        <div className="">
          <label htmlFor="doctor_new_fee">Fee (New):</label><br />
          <input type="number" id="doctor_new_fee" className="my_field" name='doctor_new_fee'
            onChange={props.handleChange} value={props.doctorData.doctor_new_fee} />
        </div>
        <div className="">
          <label htmlFor="doctor_f_up_fee">Fee (Follow_Up):</label><br />
          <input type="number" id="doctor_f_up_fee" className="my_field" name='doctor_f_up_fee'
            onChange={props.handleChange} value={props.doctorData.doctor_f_up_fee} />
        </div>
      </div>
      <input type="submit" value={props.submitBtnValue} className='my_btn submit_btn' />
    </form>
  )
}

export default DoctorForm