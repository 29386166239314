import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore'
import axios from 'axios'
import { useAppContext } from '../../contex/appContext'
import { Link } from 'react-router-dom'
import { Audio } from 'react-loader-spinner'
import Pagination from '../../services/Pagination'
import { AiFillDelete } from 'react-icons/ai'
import Swal from 'sweetalert2'
import Modal from '../modal/Modal'

const MasterAdminDashboard = () => {
  const { userData } = useAppContext();
  const base_url = baseUrl();
  const authToken = getToken();
  const [showWaitModal, setshowWaitModal] = useState(false)
  const [fetchedData, setfetchedData] = useState({
    'master_users': []
  })
  const [loading, setloading] = useState(true)
  const fetchSiteDetails = async () => {
    try {
      const res = await axios.get(`${base_url}/show_master_admin_details`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
      setloading(false)
      setfetchedData(res.data)
    } catch (error) {
      console.log(error)
    }
  }
  ///Pagination---------------------Start
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 5;
  const lastElementIndex = currentPage * elementsPerPage;
  const firstElementIndex = lastElementIndex - elementsPerPage;
  const paginatedData = fetchedData.master_users.slice(firstElementIndex, lastElementIndex)
  ///Pagination---------------------End
  const deleteUserRequest = async (id) => {
    setshowWaitModal(true)
    try {
      const res = await axios.delete(`${base_url}/delete_master_user/${id}`,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        })
      setshowWaitModal(false)
      Swal.fire({
        icon: res.data.status,
        text: res.data.message
      })
      fetchSiteDetails();
    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
    }
  }
  const handleDelete = (id, user) => {
    Swal.fire({
      icon: 'question',
      text: 'Delete ' + user + ' ?',
      showConfirmButton: true,
      confirmButtonColor: 'tomato',
      confirmButtonText: 'Delete',
      showCancelButton: true,
    }).then((result) => {
      deleteUserRequest(id)
    })
  }
  useEffect(() => {
    fetchSiteDetails()
  }, [])
  return (
    <div className='master_admin_wrapper'>
      {showWaitModal ? <Modal data='Deleting master user' title='wait' /> : <></>}
      {loading ? <Audio color='cadetblue' height={30} /> :
        <div className="flexDiv">
          <div className="master_admin_left">
            <h2>Site details</h2>
            <table>
              <tbody>
                <tr>
                  <td>Address</td>
                  <td>{fetchedData.site_details.address}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{fetchedData.site_details.phone_no}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{fetchedData.site_details.email}</td>
                </tr>
                <tr>
                  <td>Facebook</td>
                  <td>{fetchedData.site_details.facebook}</td>
                </tr>
                <tr>
                  <td>Message</td>
                  <td>{fetchedData.site_details.message}</td>
                </tr>
              </tbody>
            </table>
            <br />
            {userData.user_type === 'master_admin' ?
              <Link className='my_btn blueBtn' state={fetchedData.site_details} to='/master_admin/edit_site_details'>
                Edit
              </Link> : <></>}

          </div>
          <div className="master_admin_right">
            <h2>Dashboard</h2>
            <div className="dasboardTilesWrapper">
              <div className="dashBoardTiles">
                <h3>Total clinics</h3>
                <hr />
                <div className="masterAdminTilesInner">
                  {fetchedData.total_clinic_admins}
                </div>
              </div>
              <div className="dashBoardTiles">
                <h3>Total clinic fee</h3>
                <hr />
                <div className="masterAdminTilesInner">
                  {fetchedData.total_clinic_fee}/-
                </div>
              </div>
              <div className="dashBoardTiles">
                <h3>Expired active clinics</h3>
                <hr />
                <div className="masterAdminTilesInner">
                  {fetchedData.expired_active_admins}
                </div>
              </div>
              <div className="dashBoardTiles">
                <h3>Disabled clinics</h3>
                <hr />
                <div className="masterAdminTilesInner">
                  {fetchedData.disabled_clinic_admins}
                </div>
              </div>
              <div className="dashBoardTiles">
                <h3>Active doctors</h3>
                <hr />
                <div className="masterAdminTilesInner">
                  {fetchedData.total_single_doctors}
                </div>
              </div>
              <div className="dashBoardTiles">
                <h3>Total doctor fee</h3>
                <hr />
                <div className="masterAdminTilesInner">
                  {fetchedData.total_doctor_fee}/-
                </div>
              </div>
              <div className="dashBoardTiles">
                <h3>Expired active doctors</h3>
                <hr />
                <div className="masterAdminTilesInner">
                  {fetchedData.expired_active_doctors}
                </div>
              </div>
              <div className="dashBoardTiles">
                <h3>Disabled doctors</h3>
                <hr />
                <div className="masterAdminTilesInner">
                  {fetchedData.disabled_single_doctors}
                </div>
              </div>
            </div>
            {userData.user_type === 'master_admin' ? <>

              <div className="master_admin_users">
                <h2>
                  <Link className='h2_left my_btn addBtn' to='/master_admin/add_master_user'>Add new</Link>
                  Users
                </h2>
                {fetchedData.master_users.length > 0 ?
                  <>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Role</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedData.map(el =>
                          <tr key={el.id}>
                            <td>{el.name}</td>
                            <td>{el.email}</td>
                            <td>{el.phone}</td>
                            <td>{el.user_type}</td>
                            <td>
                              <button className="no_border_btn" onClick={() => handleDelete(el.id, el.name)}>
                                <AiFillDelete color='red' size={18} />
                              </button>
                            </td>
                          </tr>)}
                      </tbody>
                    </table>
                    <Pagination
                      elementsPerPage={elementsPerPage}
                      totaElements={fetchedData.master_users.length}
                      pageNo={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </>
                  : <h3 className='notFound'>No user found</h3>}
              </div>
            </> : <></>}
          </div>
        </div>

      }


    </div >
  )
}

export default MasterAdminDashboard
