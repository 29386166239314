import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner'

const SemenAnalysis = () => {
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const [waitMsg, setWaitMsg] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const [reporters, setreporters] = useState([])
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))

    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }
    const fetchTestResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_semen_analysis_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setLoading(false)
            if (res.data.status === 'success') {
                setFormData(res.data.db_data)
                if(res.data.db_data.reporter_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter).length < 1){
                        setReporter('')
                    }
                }else{
                    setReporter(res.data.db_data.reporter_id)
                }
                if(res.data.db_data.reporter_2_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter2).length < 1){
                        setReporter2('')
                    }
                }else{
                    setReporter2(res.data.db_data.reporter_2_id)
                }
                setreporters(res.data.reporters)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [formData, setFormData] = useState({

    });

    const handleSubmit = async e => {
        e.preventDefault();
        if (reporter == '' || reporter === null) {
            autoAlert("Please select a reporter")
            return false
        }
        if (reporter == reporter2) {
            autoAlert("2 reporters can not be same..!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setWaitMsg('Please wait...')
        try {
            const res = await axios.post(`${base_url}/update_semen_analysis_result/${id}`,
                { ...formData, 'reporter_id': reporter, 'reporter_2_id': reporter2 }, {
                headers: {
                    Accept: 'application/json',
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,

                }
            })
            if (res.data.status === 'success') {
                navigate(-1)
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status,
                    timer: 800
                })
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
            }
        } catch (error) {
            console.log(error.response.data.message)
        }
    }

    useEffect(() => {
        fetchTestResult()
    }, [])
    return (
        <div className='mainWindow'>
            <form className="semen_analysis_form" >
                {loading ? <Audio color='cadetblue' height={30} /> : <>
                    <h2>Semen analysis for Id : {patient_id} <Link to={-1} className='h2_right_btn cancelBtn'>&times;</Link></h2>

                    <span><b><u>General info:</u></b></span>
                    <div className="urineFormRow">
                        <label htmlFor="abstention">Sexual abstention:</label>
                        <input type="text" className="urineInput" name='abstention' id="abstention"
                            onChange={e => handleChange(e)} value={formData.abstention} />
                        <label htmlFor="ejaculation_time">Ejaculation Time:</label>
                        <input type="text" className="urineInput" name='ejaculation_time' id="ejaculation_time"
                            onChange={e => handleChange(e)} value={formData.ejaculation_time} />
                        <label htmlFor="liquifaction_time">Liquifaction Time:</label>
                        <input type="text" className="urineInput urineLgInput" name='liquifaction_time' id="liquifaction_time"
                            onChange={e => handleChange(e)} value={formData.liquifaction_time} />
                    </div>

                    <span><b><u>Physical Exam:</u></b></span>
                    <div className="urineFormRow">
                        <label htmlFor="volume">Volume:</label>
                        <input type="text" className="urineInput" name='volume' id="volume"
                            onChange={e => handleChange(e)} value={formData.volume} />
                        <label htmlFor="color">Color:</label>
                        <input type="text" className="urineInput" name='color' id="color"
                            onChange={e => handleChange(e)} value={formData.color} />
                        <label htmlFor="viscosity">Viscosity:</label>
                        <input type="text" className="urineInput" name='viscosity' id="viscosity"
                            onChange={e => handleChange(e)} value={formData.viscosity} />
                    </div>
                    <span><b><u>Chemical Exam:</u></b></span>
                    <div className="urineFormRow">
                        <label htmlFor="fructose">Fructose:</label>
                        <input type="text" className="urineInput" name='fructose' id="fructose"
                            onChange={e => handleChange(e)} value={formData.fructose} />
                        <label htmlFor="ph">P<sup>H</sup>:</label>
                        <input type="text" className="urineInput" name='ph' id="ph"
                            onChange={e => handleChange(e)} value={formData.ph} />
                    </div>

                    <span><b><u>Microscopic Exam:</u></b></span>
                    <div className="urineFormRow">
                        <label htmlFor="total_count">Total sperm count:</label>
                        <input type="text" className="urineInput urineLgInput" name='total_count' id="total_count"
                            onChange={e => handleChange(e)} value={formData.total_count} />
                        <label htmlFor="pus_cell">Pus cell:</label>
                        <input type="text" className="urineInput urineLgInput" name='pus_cell' id="pus_cell"
                            onChange={e => handleChange(e)} value={formData.pus_cell} />
                    </div>
                    <label htmlFor="motility">Motility:</label><br />
                    <textarea type="text" className="urineInput" name='motility' id="motility"
                        onChange={e => handleChange(e)} value={formData.motility} > </textarea>
                    <br />
                    <label htmlFor="morphology">Morphology:</label><br />
                    <textarea type="text" className="urineInput" name='morphology' id="morphology"
                        onChange={e => handleChange(e)} value={formData.morphology} > </textarea>
                    <label htmlFor="others">Others:</label><br />
                    <textarea type="text" className="urineInput" name='others' id="others"
                        onChange={e => handleChange(e)} value={formData.others} > </textarea>
                    <label htmlFor="comment">Comment:</label><br />
                    <textarea type="text" className="urineInput" name='comment' id="comment"
                        onChange={e => handleChange(e)} value={formData.comment} > </textarea>

                    <div>
                        <label htmlFor="reporter_id" >Reporter:</label>
                        <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                            value={reporter} className="urineInput urineLgInput" >
                            <option value="">Select reporter</option>
                            {reporters.map((el) =>
                                <option key={el.id} value={el.id}>{el.reporter_name}</option>
                            )}
                        </select> <br />
                        <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label>
                        <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                            value={reporter2} className="urineInput urineLgInput" >
                            <option value="">Select reporter 2</option>
                            {reporters.map((el) =>
                                <option key={el.id} value={el.id}>{el.reporter_name}</option>
                            )}
                        </select>
                    </div>
                    <br />
                    <input type="submit" value="Update" className='my_btn submit_btn' onClick={e => handleSubmit(e)} />
                    {waitMsg !== '' ? <span className="waitMsgDiv"> {waitMsg}</span> : <></>}
                </>}

            </form>
        </div>
    )
}

export default SemenAnalysis