import React, {  useRef} from 'react';
import JoditEditor from 'jodit-react';

const TextEditor = ({value,setdataEdited}) => {
    const editor = useRef(null);
    return (
        <JoditEditor
			ref={editor}
			value={value}
			tabIndex={1} // tabIndex of textarea	
            onChange={(content)=>setdataEdited(content)}
		/>
    )
}

export default TextEditor