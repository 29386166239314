import axios from 'axios';
import React, { useState } from 'react'
import { baseUrl } from '../../services/baseUrl';
import { Link } from 'react-router-dom';

const ForgetPassword = () => {
    const base_url = baseUrl()
    const [formData, setFormData] = useState('');
    const [submitBtnValue, setSubmitBtnValue] = useState('Get reset link');
    const [resMsg, setResMsg] = useState('');
    const [resAlert, setResAlert] = useState('');

    const handleChange = e => {
        setFormData(e.target.value)
    }
    const handleSubmit = async e => {
        e.preventDefault()
        setSubmitBtnValue('Please wait..')
        try {
            const res = await axios.post(`${base_url}/reset_password_req`, { email: formData })
            setSubmitBtnValue('Get reset link')
            setResAlert(res.data.status)
            setResMsg(res.data.message)

        } catch (error) {
            setSubmitBtnValue('Get reset link')
            console.log(error)
            if (error.response.status === 422) {
                setResAlert('failed')
                setResMsg(error.response.data.errors.email)
                setTimeout(() => {
                    setResAlert('')
                    setResMsg('')
                }, 2500)
            }
        }
    }
    return (
        <div className='fullScreen'>

            <span style={{ color: 'transparent' }}>.</span>
            <form className="forget_password_form" onSubmit={e => handleSubmit(e)}>
                <h2>MTLab - BD</h2>
                <h3>Please enter your email</h3>
                <input type="email" className="my_field" style={{ width: '16rem' }} placeholder="Email"
                    name='email' value={formData} onChange={e => handleChange(e)} />
                <div className={resAlert}>{resMsg}</div><br />
                <input type="submit" className='my_btn submit_btn' value={submitBtnValue} />
                <Link to={'/login'} > Login</Link>
            </form>
        </div>
    )
}

export default ForgetPassword