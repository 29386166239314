import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import DoctorForm from './DoctorForm'
import { useAppContext } from '../../contex/appContext';

const AddDoctor = () => {
    const {userData, loading} = useAppContext()
    const navigate = useNavigate()
    const base_url = baseUrl();
    const auth_token = getToken()
    const [doctorData, setDoctorData] = useState({
        'doctor_name': '',
        'doctor_degree': '',
        'doctor_details': '',
        'doctor_new_fee': '',
        'doctor_f_up_fee': '',
    });
    const [submitBtnValue, setSubmitBtnValue] = useState('Add');


    const handleChange = (e) => {
        setDoctorData({
            ...doctorData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitBtnValue('Please wait...')
        try {
            const res = await axios.post(`${base_url}/add_doctor`, doctorData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${auth_token}`
                }
            })
            setSubmitBtnValue('Add')
            console.log(res.data)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            navigate(-1)
        } catch (error) {
            setSubmitBtnValue('Add')
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'error',
                    text: error.response.data.message
                })
            } else {
                console.log(error)
            }
        }

    }
    useEffect(()=> {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager' ) {
            navigate('/')
        }
    },[loading])

    return (
        <div className='mainWindow'>

            <DoctorForm
                title='Add doctor'
                doctorData={doctorData}
                submitBtnValue={submitBtnValue}
                handleChange={(e) => handleChange(e)}
                handleSubmit={(e) => handleSubmit(e)}
            />


        </div>
    )

}

export default AddDoctor