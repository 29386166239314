import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';
import { useAppContext } from '../../contex/appContext';

const AddMasterUser = () => {
    const {userData} = useAppContext()
    const navigate = useNavigate();
    const base_url = baseUrl();
    const authToken = getToken()
    const [showWaitModal, setshowWaitModal] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        user_type: 'master_admin_assist',
    });
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/add_master_user`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate(-1)
            }

        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'info',
                    text: error.response.data.message
                })
            }
        }


    }
    useEffect(() => {
        if(userData.user_type !== 'master_admin'){
            navigate(-1)
        }
       
    }, [])
    return (
        <div className='master_admin_wrapper'>
            {showWaitModal ? <Modal data='Adding user' title='wait' />  : <></>}
                <form className="add_user_form" >
                    <h2>Add master user
                        <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                    </h2>
                    <div className="my_form_group">
                        <label htmlFor="name">Name:</label><br />
                        <input type="text" className="my_field" style={{ width: '16rem' }} id="name"
                            name='name' value={formData.name} onChange={e => handleChange(e)} /><br />
                    </div>
                    <div className="my_form_group">
                        <label htmlFor="email">Email:</label><br />
                        <input type="email" className="my_field" style={{ width: '16rem' }} id="email"
                            name='email' value={formData.email} onChange={e => handleChange(e)} /><br />
                    </div>
                    <div className="my_form_group">
                        <label htmlFor="phone">Phone no.:</label><br />
                        <input type="text" className="my_field" style={{ width: '16rem' }} id="phone"
                            name='phone' value={formData.phone} onChange={e => handleChange(e)} /><br />
                    </div>
                    <div className="my_form_group">
                        <label htmlFor="password">Password:</label><br />
                        <input type="text" className="my_field" style={{ width: '16rem' }} id="password"
                            name='password' value={formData.password} onChange={e => handleChange(e)} /><br />
                    </div>
                    <div className="my_form_group">
                        <label htmlFor="password_confirmation">Confirm Password:</label><br />
                        <input type="text" className="my_field" style={{ width: '16rem' }} id="password_confirmation"
                            name='password_confirmation' value={formData.password_confirmation} onChange={e => handleChange(e)} /><br />

                    </div>

                    <div className="my_form_group">
                        <label htmlFor="user_type">User role:</label><br />
                        <select className="my_field" style={{ width: '16rem' }} id="user_type"
                            name='user_type' value={formData.user_type} onChange={e => handleChange(e)} >
                            <option value="master_admin_assist">Assistant</option>
                            <option value="prescription_entry">Prescription entry</option>
                        </select><br />
                    </div>
                    <div className="my_form_group" >
                        <input type="submit" value='Add' className="my_btn submit_btn"  onClick={e => handleSubmit(e)} />
                    </div>
                </form>
        </div>
    )
}

export default AddMasterUser