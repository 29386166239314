import { baseUrl } from "../../services/baseUrl";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getToken, setToken } from "../../localStorageManage/localStore";
import { useNavigate, Link } from "react-router-dom";
import './authStyle.css'
import Swal from 'sweetalert2'
import Modal from '../../components/modal/Modal'
import { GrFormView } from "react-icons/gr";
import { FaRegEyeSlash } from "react-icons/fa";



const Login = () => {
  const [showWaitModal, setshowWaitModal] = useState(false)
  const [showLogin, setshowLogin] = useState(true)
  const [showPassword, setshowPassword] = useState(false)
  const authToken = getToken();
  const navigate = useNavigate()
  useEffect(() => {
    if (authToken) {
      navigate('/')
    }
  }, [])

  const baseurl = baseUrl()
  const [submitBtnValue, setSubmitBtnValue] = useState('Login');
  const [responseMsg, setResponseMsg] = useState('');
  const [fieldErrors, setFieldErrors] = useState({
    'email': null,
    'password': null
  })
  const [alertType, setAlertType] = useState();
  const [formData, setFormData] = useState({
    'password': '',
    'email': ''
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const [regFormData, setregFormData] = useState({
    'name': '',
    'email': '',
    'phone': '',
    'address': '',
    'registration_type': ''
  })
  const [regFieldErrors, setregFieldErrors] = useState({
    'name': '',
    'email': '',
    'phone': '',
    'registration_type': ''
  })
  const handleRegChange = e => {
    setregFormData({
      ...regFormData,
      [e.target.name]: e.target.value
    })
  }
  const handleSubmit = async e => {
    e.preventDefault();    
    setSubmitBtnValue('Please wait..')
    setResponseMsg('')
    setFieldErrors({
      'email': null,
      'password': null
    })
    try {
      const res = await axios.post(`${baseurl}/login`, formData)
      setSubmitBtnValue('Login')
      setResponseMsg(res.data.message)
      setAlertType(res.data.status)
      //Storing Token.......
      if (res.data.status === 'success') {
        setToken(res.data.token)
        navigate(0)
      }
      setTimeout(() => {
        setResponseMsg('')
        setAlertType('')
      }, 2500)
    } catch (error) {
      setSubmitBtnValue('Login')
      if (error.response.status === 422) {
        setFieldErrors({
          'email': error.response.data.errors.email,
          'password': error.response.data.errors.password
        })
        setTimeout(() => {
          setFieldErrors({
            'email': null,
            'password': null
          })
        }, 2500)
      }

    }
  }
  const handleRegistrationReq = async e => {
    e.preventDefault()
    setregFieldErrors({
      'name': '',
      'email': '',
      'phone': '',
      'registration_type': '',
    })
    setshowWaitModal(true)
    try {
      const res = await axios.post(`${baseurl}/registration_request`, regFormData)
      setshowWaitModal(false)
      Swal.fire({
        icon: res.data.status,
        text: res.data.message
      })
      if (res.data.status === 'success') {
        setregFormData({
          'name': '',
          'email': '',
          'phone': '',
          'address': '',
          'registration_type': ''
        })
      }
    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
      if (error.response.status === 422) {
        setregFieldErrors({
          'name': error.response.data.errors.name,
          'email': error.response.data.errors.email,
          'phone': error.response.data.errors.phone,
          'registration_type': error.response.data.errors.registration_type,
        })
      }
    }
  }
  return (
    <div className="fullScreen">
      {showWaitModal ? <Modal data='Please wait..' title='wait' /> : <></>}
      <span style={{ color: 'transparent' }}>.</span>
      <div className="loginRegMain">
        <form className={showLogin ? "loginRegForm show" : 'loginRegForm'} onSubmit={e => handleSubmit(e)}>
          <h2>MTLab - BD</h2>
          <h3>User login</h3>
          <input type="email" className='my_field font_sm' style={{ width: '16rem' }} name='email' onChange={e => handleChange(e)}
            placeholder='Email' value={formData.email} />
          <br />
          <div className="passFormGroup">
            <input type={showPassword ? "text" : "password"} className='my_field font_sm' style={{ width: '16rem' }} name='password' onChange={e => handleChange(e)}
              value={formData.password} placeholder='Password' />
            <label className="viewPasslabel" htmlFor="showPassCB">{showPassword ? < FaRegEyeSlash size={18} color="black"/> : <GrFormView size={20} color="gray" />}</label>
            <input type="checkbox" className="hidden" id="showPassCB" onChange={() => setshowPassword(!showPassword)} />
          </div>
          <br />
          <div className={alertType}>{responseMsg}</div>
          {fieldErrors.email !== null ? <div className='failed'>{fieldErrors.email}</div> : ''}
          {fieldErrors.password !== null ? <div className='failed'>{fieldErrors.password}</div> : ''}

          <input type="submit" value={submitBtnValue} style={{ marginTop: "0.5rem" }} className='my_btn submit_btn' />
          <br />
          <Link to='/forget_password'>Forgot password.?</Link>
          <br />
          <p>Don't have an account..? <button type="button" className="my_btn addBtn" onClick={() => setshowLogin(false)} >Register</button></p>

        </form>
        <form className={!showLogin ? "loginRegForm show" : 'loginRegForm'}>
          <h2>MTLab - BD</h2>
          <h3>Registration</h3>

          <input type="text" className='my_field font_sm' style={{ width: '16rem' }} name='name' onChange={handleRegChange}
            placeholder='Name' value={regFormData.name} />
          {regFieldErrors.name !== '' && regFieldErrors.name !== null ? <div className='error'>{regFieldErrors.name}</div> : ''}

          <input type="email" className='my_field font_sm' style={{ width: '16rem' }} name='email' onChange={handleRegChange}
            placeholder='Email' value={regFormData.email} />
          {regFieldErrors.email !== '' && regFieldErrors.email !== null ? <div className='error'>{regFieldErrors.email}</div> : ''}

          <input type="text" className='my_field font_sm' style={{ width: '16rem' }} name='phone' onChange={handleRegChange}
            placeholder='Phone' value={regFormData.phone} />
          {regFieldErrors.phone !== '' && regFieldErrors.phone !== null ? <div className='error'>{regFieldErrors.phone}</div> : ''}

          <select type="text" className='my_field font_sm' style={{ width: '16rem' }} name='registration_type' onChange={handleRegChange}
            placeholder='Phone' value={regFormData.registration_type} >
            <option value="">Registration type</option>
            <option value="clinic_admin">Clinic admin</option>
            <option value="single_doctor">Single doctor</option>
          </select>
          {regFieldErrors.registration_type !== '' && regFieldErrors.registration_type !== null ? <div className='error'>{regFieldErrors.registration_type}</div> : ''}
          <textarea name="address" onChange={handleRegChange} value={regFormData.address} placeholder="Address" rows="4"></textarea>
          <br />
          <input type="submit" onClick={handleRegistrationReq} value="Send request" className="my_btn submit_btn" />
          <br /> <br />

          <p>Already registerd..? <button type="button" className="my_btn addBtn" onClick={() => setshowLogin(true)} >Login</button></p>
        </form>


      </div>


    </div>
  )
}

export default Login