import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import { useAppContext } from '../../contex/appContext';

const AssignDoctorAsUser = () => {
  const {userData} = useAppContext()
  const navigate = useNavigate()
  const { id, doctor_name } = useParams()
  const [loading, setLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState(true);
  const [selectedUser, setSelectedUser] = useState('');
  const [submitBtnValue, setSubmitBtnValue] = useState('Assign');
  const base_url = baseUrl();
  const auth_token = getToken();

  const fetch_users = async () => {
    try {
      const res = await axios.get(`${base_url}/show_users`, { headers: { 'Authorization': `Bearer ${auth_token}` } })
      setLoading(false)
      const doctors = res.data.filter(elem => {
        return elem.user_type === 'doctor'
      })
      setFilteredUsers(doctors)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = (e) => {
    setSelectedUser(e.target.value)
  }

  const handleSubmit = async e => {
    setSubmitBtnValue('Please wait..')
    e.preventDefault();
    try {
      const res = await axios.post(`${base_url}/assign_doctor_as_user/${id}` , {'user':selectedUser},
      { headers: { 'Authorization': `Bearer ${auth_token}`, Accept : 'application/json' } })
      setSubmitBtnValue('Assign')
      if(res.data.status === 'success'){
        Swal.fire({
          icon : res.data.status,
          text : res.data.message
        })
        navigate(-1)
      }else{
        Swal.fire({
          icon : res.data.status,
          text : res.data.message
        })
      }
     
    } catch (error) {
      setSubmitBtnValue('Assign')
      if(error.response.status === 422){
        Swal.fire({
          icon : 'info',
          text : error.response.data.message
        })
        
      }
     console.log(error)
    }
  }
  useEffect(() => {
    if(userData.user_type !== 'admin'){
      navigate(-1)
    }
    fetch_users()
  }, [])
  return (
    <div className='mainWindow'>
      {loading ? 'Loading...' :
        <form className="assign_doctor_as_user_form" onSubmit={handleSubmit}>
          <h2>
            Assign {doctor_name} as user
            <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
          </h2>
          <select name="user_name" id="user_name" className="my_field" 
          value={selectedUser} onChange={handleChange}>
            <option value="">Select an user</option>
            {filteredUsers.map(elem =>
              <option key={elem.id} value={elem.id}>{elem.name}</option>
            )}
          </select>
          <input type="submit" value={submitBtnValue} className="my_btn submit_btn" />
        </form>
      }
    </div>
  )
}

export default AssignDoctorAsUser