import React from 'react'
import { PieChart, Pie, Sector, Cell } from 'recharts';

const Pie_Chart = ({ data, COLORS }) => {    
  
    let renderLabel = function (entry) {
        return entry.name;
    }

    return (
        <>
            <PieChart width={300} height={190} style={{'margin': 'auto'}}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label ={renderLabel}

                >

                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </>
    )
}

export default Pie_Chart