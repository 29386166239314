import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';

const AddAssistant = () => {
    const navigate = useNavigate()
    const base_url = baseUrl();
    const authToken = getToken();
    const [showWaitMsg, setshowWaitMsg] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        user_type: 'single_doctor_assistant',
    });

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitMsg(true);
        try {
            const res = await axios.post(`${base_url}/register`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setshowWaitMsg(false)
            Swal.fire({
                icon : res.data.status,
                text : res.data.message
            })
            if(res.data.status === 'success'){
                navigate('/doctor/single_doctor_assistant')
            }
        } catch (error) {
            setshowWaitMsg(false)
            console.log(error)
            if(error.response.status === 422){
                Swal.fire({
                    icon : 'info',
                    text : error.response.data.message
                })
            }
        }

    }
    return (
        <div>
            {showWaitMsg ? <Modal title='wait' data='Adding assistant.' /> : <></>}
            <form className="add_user_form" onSubmit={handleSubmit}>
                <h2>Add assistant
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                <div className="my_form_group">
                    <label htmlFor="name">Name:</label><br />
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="name"
                        name='name' value={formData.name} onChange={e => handleChange(e)} />

                </div>
                <div className="my_form_group">
                    <label htmlFor="email">Email:</label><br />
                    <input type="email" className="my_field" style={{ width: '16rem' }} id="email"
                        name='email' value={formData.email} onChange={e => handleChange(e)} />

                </div>
                <div className="my_form_group">
                    <label htmlFor="phone">Phone no.:</label><br />
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="phone"
                        name='phone' value={formData.phone} onChange={e => handleChange(e)} />
                </div>
                <div className="my_form_group">
                    <label htmlFor="password">Password:</label><br />
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="password"
                        name='password' value={formData.password} onChange={e => handleChange(e)} />
                </div>
                <div className="my_form_group">
                    <label htmlFor="password_confirmation">Confirm Password:</label><br />
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="password_confirmation"
                        name='password_confirmation' value={formData.password_confirmation} onChange={e => handleChange(e)} /><br />

                </div>
                <div className="my_form_group" >
                    <input type="submit" value='Add' className="my_btn submit_btn" />
                </div>
            </form>
        </div>
    )
}

export default AddAssistant