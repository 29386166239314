import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../../../services/baseUrl'
import { getToken } from '../../../localStorageManage/localStore'
import Swal from 'sweetalert2'
import Modal from '../../modal/Modal'
import Pagination from '../../../services/Pagination'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { Audio } from 'react-loader-spinner'
import { useAppContext } from '../../../contex/appContext'

const OELists = () => {
  const { userData } = useAppContext()
  const { user_type } = userData;
  const base_url = baseUrl();
  const authToken = getToken();
  const [loading, setloading] = useState(true)
  const [showEditForm, setshowEditForm] = useState(false)
  const [showWaitModal, setshowWaitModal] = useState(false)
  const [fetchedData, setfetchedData] = useState([]);
  const [dataToShow, setdataToShow] = useState([]);
  const [formData, setformData] = useState({
    'exam': '',
    'unit': ''
  })
  const [formDataEdit, setformDataEdit] = useState({
    'id': '',
    'exam': '',
    'unit': ''
  })

  ///Pagination---------------------Start
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 15;
  const lastElementIndex = currentPage * elementsPerPage;
  const firstElementIndex = lastElementIndex - elementsPerPage;
  const paginatedData = dataToShow.slice(firstElementIndex, lastElementIndex)
  ///Pagination---------------------End


  const fetchAddedDurations = async () => {
    try {
      const res = await axios.get(`${base_url}/show_exams`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      setloading(false)
      setfetchedData(res.data)
      setdataToShow(res.data)
    } catch (error) {
      console.log(error)
    }
  }
  const handleChange = e => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  const handleChangeEdit = e => {
    setformDataEdit({
      ...formDataEdit,
      [e.target.name]: e.target.value
    })
  }

  const addNewDrug = async e => {
    e.preventDefault();
    setshowWaitModal(true)
    try {
      const res = await axios.post(`${base_url}/add_o_e_from_master_admin`, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      setshowWaitModal(false)
      Swal.fire({
        icon: res.data.status,
        text: res.data.message
      })
      if (res.data.status === 'success') {
        setformData({
          'exam': '',
          'unit': '',
        })
        fetchAddedDurations();
      }

    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
      if (error.response.status === 422) {
        Swal.fire({
          icon: 'info',
          text: error.response.data.message
        })
      }
    }
  }
  const handleSearch = (e) => {
    let filteredData = fetchedData.filter(el =>
      el.exam.toLowerCase().includes(e.target.value.toLowerCase()) 
    )
    setdataToShow(filteredData)
  }
  const handleEditBtn = (data) => {
    setshowEditForm(true)
    setformDataEdit(data)
  }
  const updateDrug = async e => {
    e.preventDefault();
    setshowWaitModal(true)
    try {
      const res = await axios.post(`${base_url}/update_o_e_from_master_admin`, formDataEdit, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      setshowWaitModal(false)
      Swal.fire({
        icon: res.data.status,
        text: res.data.message
      })
      if (res.data.status === 'success') {
        setformDataEdit({
          'id': '',
          'exam': '',
          'unit': '',
        })
        setshowEditForm(false)
        fetchAddedDurations();
      }

    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
      if (error.response.status === 422) {
        Swal.fire({
          icon: 'info',
          text: error.response.data.message
        })
      }
    }
  }
  const deleteReq = async id => {
    setshowWaitModal(true)
    try {
      const res = await axios.delete(`${base_url}/delete_o_e_form_master_admin/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      setshowWaitModal(false)
      Swal.fire({
        icon: res.data.status,
        text: res.data.message
      })
      if (res.data.status === 'success') {
        fetchAddedDurations();
      }
    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
    }
  }
  const handleDelete = (i, name) => {
    Swal.fire({
      icon: 'warning',
      text: `Delete ${name}..?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: 'tomato'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq(i)
      }
    })
  }

  useEffect(() => {
    fetchAddedDurations();
  }, [])
  return (
    <div className='prescriptionDataInner'>
      {showWaitModal ? <Modal data='Please wait..' title='wait' /> : <></>}
      <div className="p_d_leftCol">
        {!showEditForm ?
          <form className='smallForm' onSubmit={addNewDrug} >
            <h2> Add Exam</h2>
            <table className='form_table'>
              <tbody>
                <tr>
                  <td className='label_td'>
                    <label htmlFor='brand_name' > Exam </label>
                  </td>
                  <td>
                    <input type="text" className="table_field" name='exam' id='brand_name'
                      value={formData.exam} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td className='label_td'>
                    <label htmlFor='generic_name' >Unit</label>
                  </td>
                  <td>
                    <input type="text" className="table_field" name='unit' id='generic_name'
                      value={formData.unit} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <input type="submit" className='my_btn submit_btn' value="Add" />
                  </td>
                </tr>
              </tbody>
            </table>

          </form>
          :
          <form className='smallForm' onSubmit={updateDrug} >
            <h2> Edit Exam</h2>
            <table className='form_table'>
              <tbody>
                <tr>
                  <td className='label_td'>
                    <label htmlFor='brand_name' > Exam </label>
                  </td>
                  <td>
                    <input type="text" className="table_field" name='exam' id='brand_name'
                      value={formDataEdit.exam} onChange={handleChangeEdit} />
                  </td>
                </tr>
                <tr>
                  <td className='label_td'>
                    <label htmlFor='generic_name' > Unit</label>
                  </td>
                  <td>
                    <input type="text" className="table_field" name='unit' id='generic_name'
                      value={formDataEdit.unit} onChange={handleChangeEdit} />
                  </td>
                </tr>


                <tr>
                  <td></td>
                  <td className='flexDiv'>
                    <button type="button" onClick={() => setshowEditForm(false)} className='my_btn cancelBtn'> Cancel </button>
                    <input type="submit" className='my_btn submit_btn' value="Update" />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        }
      </div>
      <div className="p_d_rightCol">
        <h2>
          <span className="h2_left">
            <input className='h2_field' onChange={handleSearch} placeholder='Search' type="text" id="searchKey" />
          </span>
          Added Exams</h2>
        {loading ? <Audio color='cadetblue' height={30} /> : <>
          {dataToShow.length < 0 ? <h3>No data found..</h3> : <>
            <table className='smallFont'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Exam</th>
                  <th>Unit</th>
                  {user_type === 'master_admin' || user_type === 'master_admin_assist'
                    || user_type === 'prescription_entry' ?
                    <th>Action</th> : <></>}
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((el, i) =>
                  <tr key={el.id}>
                    <td>{i + 1}</td>
                    <td>{el.exam}</td>
                    <td>{el.unit}</td>
                    {user_type === 'master_admin' || user_type === 'master_admin_assist'
                    || user_type === 'prescription_entry' ?
                    <td>
                      <button onClick={() => handleEditBtn(el)} className="no_border_btn">
                        <AiOutlineEdit size={16} color='cadetblue' />
                      </button>
                      <button onClick={() => handleDelete(el.id, el.exam)} className="no_border_btn">
                        <AiOutlineDelete size={16} color='tomato' />
                      </button>
                    </td> : <></> }
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              elementsPerPage={elementsPerPage}
              totaElements={dataToShow.length}
              pageNo={currentPage}
              setCurrentPage={setCurrentPage} />
          </>}
        </>}
      </div>
    </div>
  )
}

export default OELists