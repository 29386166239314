import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../../services/Pagination';
import { DateFormat } from '../../../services/DateFormat';
import Modal from '../../modal/Modal';
import { useAppContext } from '../../../contex/appContext';

const SearchTransaction = () => {
  const navigate = useNavigate()
  const { userData, loading } = useAppContext();
  const base_url = baseUrl();
  const { pathname } = useLocation()
  const authToken = getToken();
  const [fetchedData, setfetchedData] = useState([]);
  const [showWaitModal, setshowWaitModal] = useState(false);
  const [searched, setsearched] = useState(false)
  const [showDate, setshowDate] = useState({

  })
  const [formData, setformData] = useState({
    'from_date': '',
    'to_date': ''
  })
  ///Pagination---------------------Start
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 15;
  const lastElementIndex = currentPage * elementsPerPage;
  const firstElementIndex = lastElementIndex - elementsPerPage;
  const paginatedData = fetchedData.slice(firstElementIndex, lastElementIndex)
  ///Pagination---------------------End
  const handleChange = e => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  const autoAlert = (text) => {
    Swal.fire({
      icon: 'info',
      text: text
    })
  }
  const handleSubmit = async e => {
    e.preventDefault();
    if (formData.from_date == '' || formData.to_date == '') {
      autoAlert('Please fill both field')
      return false;
    }
    if (formData.from_date > formData.to_date) {
      autoAlert('From date can not be greater than to date..!')
      return false;
    }
    setshowWaitModal(true)
    try {
      const res = await axios.get(`${base_url}/search_transactions/${formData.from_date}/${formData.to_date}`,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        })
      setshowWaitModal(false)
      if (res.data.status === 'success') {
        setsearched(true)
        setfetchedData(res.data.db_data)
        setshowDate({
          'from_date': res.data.from_date,
          'to_date': res.data.to_date
        })
      } else {
        Swal.fire({
          icon: res.data.status,
          text: res.data.message
        })
      }
    } catch (error) {
      setshowWaitModal(false)
      console.log(error)
    }
  }
  useEffect(() => {
    if (userData.user_type !== 'admin' && userData.user_type !== 'manager' &&
      userData.user_type !== 'reception') {
      navigate('/')
    }
  }, [loading])
  return (
    <div className='mainWindow'>
      {showWaitModal ? <Modal data='Please wait' title='wait' /> : <></>}
      <div className="my_container_fulid">
        <form className="searchTransactionForm" onSubmit={handleSubmit}>
          <label htmlFor="from_date">From:</label>
          <input type="date" name="from_date" id="from_date" className="my_field"
            value={formData.from_date} onChange={handleChange} />
          <label htmlFor="to_date">From:</label>
          <input type="date" name="to_date" id="to_date" className="my_field"
            value={formData.to_date} onChange={handleChange} />
          <input type="submit" value="Search" className="my_btn submit_btn" />
          <Link style={{ 'top': '1.6rem' }} className='h2_right_btn cancelBtn' to={-1} >&times;</Link>
        </form>
        {searched ?
          <div>
            <h2>Transactions from {DateFormat(showDate.from_date)} to {DateFormat(showDate.to_date)}

            </h2>

            {fetchedData.length < 1 ? <> <p className='notFound'>No transaction found</p></> : <>
              <div className="transactionWrapper">
                <div className="transactionSummary">
                  <div className="transactionSummarySingle">
                    Total amount:  {fetchedData.reduce((a, b) => {
                      return a + parseInt(b.total_amount)
                    }, 0)}/=
                  </div>
                  <div className="transactionSummarySingle">
                    Total discount:  {fetchedData.reduce((a, b) => {
                      return a + parseInt(b.discount_amount)
                    }, 0)}/=
                  </div>
                  <div className="transactionSummarySingle">
                    Total payable:  {fetchedData.reduce((a, b) => {
                      return a + parseInt(b.total_amount - b.discount_amount)
                    }, 0)}/=
                  </div>
                  <div style={{ "color": 'green' }} className="transactionSummarySingle">
                    Total paid:  {fetchedData.reduce((a, b) => {
                      return a + parseInt(b.paid_amount)
                    }, 0)}/=
                  </div>
                  <div style={{ "color": 'red' }} className="transactionSummarySingle">
                    Total due:  {fetchedData.reduce((a, b) => {
                      return a + parseInt(b.due_amount)
                    }, 0)}/=
                  </div>
                </div>
                <div className="transactionTableDiv">
                  <table className='transactionTable'>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Patient Id</th>
                        <th>Patient name</th>
                        <th>Total amount</th>
                        <th>Discount amount</th>
                        <th>Payable amount</th>
                        <th>Paid amount</th>
                        <th>Due amount</th>
                        <th>Due collection/refund remarks</th>
                        <th>Tests</th>
                      </tr>
                    </thead>

                    <tbody>
                      {<>
                        {paginatedData.map((elem, id) => <tr key={id}>
                          <td>{DateFormat(elem.patient_date)}</td>
                          <td>{elem.patient_id_no}</td>
                          <td>{elem.patient_name}</td>
                          <td>{elem.total_amount}</td>
                          <td>{elem.discount_amount}</td>
                          <td>{elem.total_amount - elem.discount_amount}</td>
                          <td>{elem.paid_amount}</td>
                          <td>{elem.due_amount}</td>
                          <td>
                            {elem.due_collection_history.length > 0 ? <>
                              <u><h6>Due collection</h6></u>
                              {elem.due_collection_history.map((el) => <p className='historyRow' key={el.id}>
                                <span>{DateFormat(el.collection_date)}</span> - <span>{el.collection_amount}/=</span>
                              </p>)}
                              <hr />
                              <p className='historyRow' >
                                <span>Total :{elem.due_collection_history.reduce((a, b) => {
                                  return a + b.collection_amount;
                                }, 0)}/=
                                </span>
                                <span>(Final due : {elem.due_amount - elem.due_collection_history.reduce((a, b) => {
                                  return a + b.collection_amount;
                                }, 0)}/=)</span>
                              </p> </> : <></>}
                            {elem.refund_history.length > 0 ? <>
                              <u><h6>Refund</h6></u>
                              {elem.refund_history.map((el) => <p className='historyRow' key={el.id}>
                                <span>{DateFormat(el.refund_date)}</span> - <span>{el.refund_amount}/=</span>
                              </p>)}
                              <hr />
                              <p className='historyRow' >
                                <span>Total :{elem.refund_history.reduce((a, b) => {
                                  return a + b.refund_amount;
                                }, 0)}/=
                                </span>
                                <span>(Final paid : {elem.paid_amount - elem.refund_history.reduce((a, b) => {
                                  return a + b.refund_amount;
                                }, 0)}/=)</span>
                              </p> 
                            </> : <></>}
                          </td>
                          <td><Link to={`/services_for_patients/${elem.patient_id}`} state={{ redir_form: pathname }}>Tests</Link></td>
                        </tr>)}
                      </>}

                    </tbody>
                  </table>
                </div>
                <Pagination
                  elementsPerPage={elementsPerPage}
                  totaElements={fetchedData.length}
                  pageNo={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </>}
          </div>
          : <><h1 style={{ 'textAlign': 'center' }}>Select date range and click 'Search' button.</h1></>}
      </div>
    </div>
  )
}

export default SearchTransaction