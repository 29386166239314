import React, { useState, useEffect, useRef } from 'react'
import './invoiceStyle.css'
import axios from 'axios';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl, baseUrlMain } from '../../services/baseUrl';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DateFormat } from '../../services/DateFormat';
import ReactToPrint from 'react-to-print';
import { BsPrinterFill } from 'react-icons/bs'
import Barcode from 'react-barcode';
import swal from 'sweetalert2'
import { useAppContext } from '../../contex/appContext';

const Invoice = () => {
    const appContext = useAppContext();
    const { userData } = appContext;
    const navigate = useNavigate()
    const componentRef = useRef();
    const { state } = useLocation();
    const { id } = useParams()
    const [clinicDetails, setClinicDetails] = useState();
    const authToken = getToken();
    const base_url = baseUrl();
    const base_url_main = baseUrlMain();
    const [loading, setLoading] = useState(true);
    const [testsLoading, setTestsLoading] = useState(true);
    const [testsData, setTestsData] = useState([]);
    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/show_clinic_details`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                })
            setClinicDetails(res.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchPatientTests = async () => {
        try {
            const res = await axios.get(`${base_url}/show_payment_tests_for_patient/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            });
            setTestsLoading(false)
            // console.log(res.data)
            if (res.data.status === 'success') {
                setTestsData(res.data.db_data)
            } else {
                swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
                navigate('/')
            }

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager' &&
            userData.user_type !== 'reception') {
            navigate('/')
        }
        fetchData();
        fetchPatientTests();
    }, [appContext.loading])
    return (
        <div className='mainWindow'>
            <ReactToPrint
                trigger={() => <button className='invoice_print_btn'><BsPrinterFill size={20} color='gray' /></button>}
                content={() => componentRef.current}
                onAfterPrint={() => {
                    navigate('/')
                }} />
            {userData.user_type === 'admin' || userData.user_type === 'manager' ?
                <Link className='cancelPrint' to='/patient_management'>&times;</Link> :
                <Link className='cancelPrint' to='/'>&times;</Link>}
            <div className="invoiceWrapper">
                <div className='invoiceBtnDiv'>
                </div>
                {loading ? 'Please wait...' :
                    <div className="inVoicePrint" ref={componentRef}>
                        <div className="clinicCopyInvoice copyInvoice">
                            <div className="cornerText">
                                Clinic Copy
                            </div>
                            <div className="barCode">
                                <Barcode value={state.patientData.patient_id_no} />
                            </div>

                            <div className="patientDetails">
                                <p className='patient_id_no' style={{ marginBottom: '10px' }}>
                                    <span><b>Patient Id : {state.patientData.patient_id_no}</b></span>
                                    <span><b>Date :</b> {DateFormat(state.patientData.patient_date)}</span>
                                </p>
                                <p className='patient_id_no'>
                                    <span>{state.patientData.patient_name} ({state.patientData.patinet_age} {state.patientData.patient_age_unit}) - {state.patientData.patient_sex}</span>
                                    <span> Phone : {state.patientData.patient_phone}</span>
                                </p>
                                <p>Ref By : {state.patientData.refferer_name}</p>
                            </div>

                            {testsLoading ? '...' :
                                <div className="testDetails">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Test Name</th>
                                                <th>Fee</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {testsData.map((elem, i) =>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{elem.test_name}</td>
                                                    <td>{elem.test_fee}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            <div className="paymentDetails">
                                <p> Total : <span>{state.formData.total_amount} /-</span></p>
                                <p> Discount : <span>{state.formData.discount_amount} /-</span></p>
                                <p> Payable : <span>{state.formData.payable_amount} /-</span></p>
                                <p> Paid : <span>{state.formData.paid_amount} /-</span></p>
                                <p> Due : <span>{state.formData.due_amount} /-</span></p>
                            </div>
                            <div className="receivedBy">
                                Received By
                            </div>
                        </div>
                        <div className="patientCopyInvoice copyInvoice">
                            <div className="invoiceHeader" >
                                <div className="cornerText">
                                    Patient Copy
                                </div>
                                <div className="logo">
                                    <img className='logoImage' src={`${base_url_main}/${clinicDetails.logo_image}`} alt="logo_image" />
                                </div>
                                <div className="flexDiv">
                                    <div className="barCode">
                                        <Barcode value={state.patientData.patient_id_no} />
                                    </div>
                                    <div className="clinicDetails" >
                                        <strong>{clinicDetails.clinic_name}</strong>
                                        <p>{clinicDetails.clinic_address}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="patientDetails">
                                <p className='patient_id_no' style={{ marginBottom: '10px' }}>
                                    <span><b>Patient Id : {state.patientData.patient_id_no}</b></span>
                                    <span><b>Date :</b> {DateFormat(state.patientData.patient_date)}</span>
                                </p>
                                <p className='patient_id_no'>
                                    <span>{state.patientData.patient_name} ({state.patientData.patinet_age} {state.patientData.patient_age_unit}) - {state.patientData.patient_sex}</span>
                                    <span> Phone : {state.patientData.patient_phone}</span>
                                </p>
                                <p>Ref By : {state.patientData.refferer_name}</p>
                            </div>
                            {testsLoading ? '...' :
                                <div className="testDetails">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Test Name</th>
                                                <th>Fee</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {testsData.map((elem, i) =>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{elem.test_name}</td>
                                                    <td>{elem.test_fee}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                            <div className="paymentDetails">
                                <p> Total : <span>{state.formData.total_amount} /-</span></p>
                                <p> Discount : <span>{state.formData.discount_amount} /-</span></p>
                                <p> Payable : <span>{state.formData.payable_amount} /-</span></p>
                                <p> Paid : <span>{state.formData.paid_amount} /-</span></p>
                                <p> Due : <span>{state.formData.due_amount} /-</span></p>
                            </div>
                            <div className="receivedBy">
                                Received By
                            </div>
                            <div className="invoiceFooter">
                                {loading ? '' : <p>{clinicDetails.slogan}</p>}
                            </div>
                        </div>

                    </div>
                }

            </div>
        </div>
    )
}

export default Invoice