import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner';
import Modal from '../../modal/Modal';

const CrossMatch = () => {
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [waitMsg, setWaitMsg] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const [reporters, setreporters] = useState([])
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))

    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }

    const [formData, setFormData] = useState({

    });

    const fetchTestResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_cross_match_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            if (res.data.status === 'success') {
                setLoading(false)
                setFormData(res.data.db_data)
                if(res.data.db_data.reporter_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter).length < 1){
                        setReporter('')
                    }
                }else{
                    setReporter(res.data.db_data.reporter_id)
                }
                if(res.data.db_data.reporter_2_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter2).length < 1){
                        setReporter2('')
                    }
                }else{
                    setReporter2(res.data.db_data.reporter_2_id)
                }
                setreporters(res.data.reporters)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if(reporter == '' || reporter === null){
            autoAlert("Please select a reporter")
            return false
        }
        if(reporter == reporter2){
            autoAlert("2 reporter can not be same..!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setshowWaitModal(true);
        try {
            const res = await axios.post(`${base_url}/update_cross_match_result/${id}`, 
            {...formData, 'reporter_id': reporter, 'reporter_2_id' : reporter2}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false);
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate(-1)
            }
        } catch (error) {
            console.log(error)
        }
    }
    

    useEffect(() => {
        fetchTestResult()
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal title='wait' data='Updating cross matching result' /> : <></>}
            {loading ? <Audio color='cadetblue' height='30' /> : (
                <div className="resultFormWrapper">
                    <form className="cross_match_form">
                        <h2>Cross match for ID : {patient_id} <Link to={-1} className='h2_right_btn cancelBtn'>&times;</Link></h2>

                        <div className="form_row">
                            <div className="formRowInnerDiv">
                                <label htmlFor="donor_name">Donor Name:</label><br />
                                <input autoFocus type="text" className="my_field md_field" name='donor_name' id="donor_name"
                                    onChange={e => handleChange(e)} value={formData.donor_name} />
                            </div>
                            <div className="formRowInnerDiv">
                                <label htmlFor="donor_age">Donor Age:</label><br />
                                <input type="text" className="my_field sm_field" name='donor_age' id="donor_age"
                                    onChange={e => handleChange(e)} value={formData.donor_age} />
                            </div>
                            <div className="formRowInnerDiv">
                                <label htmlFor="donor_phone">Donor Phone:</label><br />
                                <input type="text" className="my_field md_field" name='donor_phone' id="donor_phone"
                                    onChange={e => handleChange(e)} value={formData.donor_phone} />
                            </div>
                            <div className="formRowInnerDiv">
                                <label htmlFor="bg">Blood Group:</label><br />
                                <select className="my_field" name='bg' id="bg"
                                    onChange={e => handleChange(e)} value={formData.bg} >
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="AB">AB</option>
                                    <option value="O">O</option>
                                </select>
                                <select className="my_field" name='rh' id="rh"
                                    onChange={e => handleChange(e)} value={formData.rh} >
                                    <option value="Positive">Positive</option>
                                    <option value="Negative">Negative</option>
                                </select>
                            </div>
                            <div className="formRowInnerDiv">
                                <label htmlFor="bag_id">Bag Id:</label><br />
                                <input type="text" className="my_field sm_field" name='bag_id' id="bag_id"
                                    onChange={e => handleChange(e)} value={formData.bag_id} />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="reporter_id" >Reporter:</label><br />
                            <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                                value={reporter} className="my_field" >
                                <option value="">Select reporter</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select><br />
                            <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label><br />
                            <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                                value={reporter2} className="my_field" >
                                <option value="">Select reporter 2</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select>
                        </div>
                        <br />
                        <div className="form_row ">
                            &nbsp;&nbsp;
                            <input type="submit" value="Update" className='my_btn submit_btn' onClick={e => handleSubmit(e)} />
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}

export default CrossMatch