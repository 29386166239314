import axios from "axios"
import { getToken } from "../../localStorageManage/localStore";
import { baseUrl } from "../../services/baseUrl"
const base_url = baseUrl();
const authToken = getToken()
export const logOutReq = axios.create({
    method : 'POST',
    baseURL: `${base_url}/logout`,
    headers : {
        "content-Type" : 'application/json',
        'accept' : 'application/json',
        'Authorization' : `Bearer ${authToken}`
    }
})
