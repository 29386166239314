import React, { useEffect, useState } from 'react'
import TextEditor from '../../../services/TextEditor'
import { useNavigate, useParams, useSearchParams, Link } from 'react-router-dom';
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner';

const Others = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { id, patient_id } = useParams();
    const [testName, setTestName] = useState('');
    const [dataEdited, setdataEdited] = useState('')
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [reporters, setreporters] = useState([])
    const base_url = baseUrl();
    const authToken = getToken();
    const [waitMsg, setWaitMsg] = useState('');
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))
    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }


    const fetchTestResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_others_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            console.log(res.data)
            setLoading(false)
            if (res.data.status === 'success') {
                if (res.data.test_result != 'Not inserted yet.!') {
                    setdataEdited(res.data.db_data.test_result)
                }
                if (res.data.db_data.reporter_id == null) {
                    if (res.data.reporetrs.filter(el => el.id == reporter).length < 1) {
                        setReporter('')
                    }
                } else {
                    setReporter(res.data.db_data.reporter_id)
                }
                if (res.data.db_data.reporter_2_id == null) {
                    if (res.data.reporetrs.filter(el => el.id == reporter2).length < 1) {
                        setReporter2('')
                    }
                } else {
                    setReporter2(res.data.db_data.reporter_2_id)
                }
                setreporters(res.data.reporetrs)
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (reporter == '' || reporter === null) {
            autoAlert("Please select a reporter")
            return false
        }
        if (reporter == reporter2) {
            autoAlert("2 reporters can not be same..!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setWaitMsg('Please wait...')
        try {
            const res = await axios.post(`${base_url}/update_others_result/${id}`, {
                'test_result': dataEdited,
                'reporter_id': reporter,
                'reporter_2_id': reporter2,
            }, {
                headers: {
                    Accept: 'application/json',
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,

                }
            })
            if (res.data.status === 'success') {
                navigate(-1)
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status,
                    timer: 800
                })
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
            }
        } catch (error) {
            console.log(error.response.data.message)
        }
    }
    useEffect(() => {
        fetchTestResult()
        if (searchParams.get('test_name')) {
            setTestName(searchParams.get('test_name'))
        }
    }, [])
    return (
        <div className='mainWindow'>
            <div className="other_result_wrapper">
                <h2>{testName} for Id: {patient_id}
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>


                {loading ? <Audio color='cadetblue' height='30' /> : <>
                    <TextEditor setdataEdited={setdataEdited} value={dataEdited} />


                </>}
            </div>
            <div>
                <label style={{ 'marginLeft': '17%' }} htmlFor="reporter_id" >Reporter:</label>
                <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                    value={reporter} className="my_field" >
                    <option value="">Select reporter</option>
                    {reporters.map((el) =>
                        <option key={el.id} value={el.id}>{el.reporter_name}</option>
                    )}
                </select>
                <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label>
                <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                    value={reporter2} className="my_field" >
                    <option value="">Select reporter 2</option>
                    {reporters.map((el) =>
                        <option key={el.id} value={el.id}>{el.reporter_name}</option>
                    )}
                </select>
                {waitMsg !== '' ? <span className="waitMsgDiv"> {waitMsg}</span> : <></>}
                <br />
                <button style={{ 'marginLeft': '17%' }} className='my_btn submit_btn' onClick={handleSubmit}>
                    Enter result
                </button>
            </div>

        </div>
    )
}

export default Others