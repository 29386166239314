import React from 'react'
import { Watch } from 'react-loader-spinner'

const Loading = () => {
  return (
    <div className='loadingPage'>
        <Watch
        size={40}/>
    </div>
  )
}

export default Loading