import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import Swal from 'sweetalert2';
import { GrFormView } from "react-icons/gr";
import { FaRegEyeSlash } from "react-icons/fa";

const ResetPassword = () => {
  const navigate = useNavigate()
  const baseurl = baseUrl()
  const { token } = useParams();
  const [submitBtnValue, setSubmitBtnValue] = useState('Change');
  const [showPassword, setshowPassword] = useState(false)
  const [showPassword2, setshowPassword2] = useState(false)
  const [responseMsg, setResponseMsg] = useState();
  const [alertType, setAlertType] = useState();
  const [formData, setFormData] = useState({
    'password': '',
    'password_confirmation': ''
  });
  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }
  const handleSubmit = async e => {
    e.preventDefault();
    if(formData.password !== formData.password_confirmation){
      Swal.fire({
        icon : 'info',
        text : 'Password confirmation does not match..!'
      })
      return false;
    }
    setSubmitBtnValue('Please wait..')
    try {
      const res = await axios.post(`${baseurl}/reset_password/${token}`, formData)
      console.log(res.data)
      setSubmitBtnValue('Change')
      Swal.fire({
        icon: res.data.status,
        text: `${res.data.message}, redirecting to login page..`,
        timer: 3000
      })
      setTimeout(() => {
        navigate('/login')
      }, 2500)
      console.log(res.data)
    } catch (error) {
      console.log(error)
      if (error.response.status === 422) {
        Swal.fire({
          icon: 'info',
          text: error.response.data.errors.password,
        })

      }
      setSubmitBtnValue('Change')
    }
  }
  return (
    <div className="fullScreen">
      <span style={{ color: 'transparent' }}>.</span>
      <form className="changePasswordForm" onSubmit={e => handleSubmit(e)}>
        <h2>MTLab - BD</h2>
        <h3>Reset password</h3>
        <div className="passFormGroup">
          <input type={showPassword ? "text" : "password"} className='my_field font_sm' style={{ width: '16rem' }} name='password' onChange={e => handleChange(e)}
            placeholder='New password' value={formData.password} />
          <label className="viewPasslabel" htmlFor="showPassCB">{showPassword ? < FaRegEyeSlash size={18} color="black" /> : <GrFormView size={22} color="gray" />}</label>
          <input type="checkbox" className="hidden" id="showPassCB" onChange={() => setshowPassword(!showPassword)} />
        </div>
        <br />
        <div className="passFormGroup">
          <input type={showPassword2 ? "text" : "password"} className='my_field' style={{ width: '16rem' }} name='password_confirmation' onChange={e => handleChange(e)}
            value={formData.password_confirmation} placeholder='Confirm new password' />
             <label className="viewPasslabel" htmlFor="showPassCB2">{showPassword2 ? < FaRegEyeSlash size={18} color="black" /> : <GrFormView size={22} color="gray" />}</label>
          <input type="checkbox" className="hidden" id="showPassCB2" onChange={() => setshowPassword2(!showPassword2)} />
        </div>
        <br />
        <small className='nb'> Your password must contain at least 8 characters (at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character i.e: @, #, $, %, &)</small>
        <br />
        <input type="submit" value={submitBtnValue} style={{ marginTop: "0.5rem" }} className='my_btn submit_btn' />
      </form>
    </div>
  )
}

export default ResetPassword