import { useEffect } from 'react';
import { useSearchParams, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { DepartmentsProvider } from '../../contex/departmentsContex';
import { IncludedServicesProvider } from '../../contex/includedServicesContext';
import { getToken } from '../../localStorageManage/localStore';
import ClinicHeading from '../layouts/ClinicHeading';
import SideBar from '../layouts/SideBar';
import { useAppContext } from '../../contex/appContext';
import Loading from '../Loading';

const Home = () => {
  const { loading, userData } = useAppContext()
  const authToken = getToken()
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()
  useEffect(() => {
    if (userData.user_status == 0) {
      navigate('/inactive_user')
    } else if (userData.admin_status == 0) {
      navigate('/inactive_admin')
    }
    if (userData.user_type === 'doctor' || userData.user_type === 'single_doctor' ) {
        navigate('/doctor')
    }
    if (searchParams.get('token')) {
      const token = searchParams.get('token');
      navigate(`reset_password/${token}`)
    } else {
      if (!authToken) {
        navigate('login')
      }
    }

  }, [loading])
  if (loading) {
    return <Loading />
  }
  return (

    <DepartmentsProvider>
      <IncludedServicesProvider>
        <div className='fullScreen display_flex'>
          <SideBar />
          <ClinicHeading />
          <Outlet />
        </div>
      </IncludedServicesProvider>
    </DepartmentsProvider>

  )
}

export default Home