import React from 'react'
import { Link } from 'react-router-dom'

const DoctorPatientSerialForm = ({setShowEditPaitent, showCancelBtn, formData, handleChange, title, btnValue, handleSubmit }) => {
    return (
        <form className="doctorPatientSerialForm" onSubmit={handleSubmit} id='doctorSerialForm'>
            <h2>{title}  Patient    </h2>
            <div className="input_wrapper">
                <input type="text" className="my_field ph_field" name='patient_name' id='patient_name'
                    value={formData.patient_name} onChange={handleChange} placeholder="Name" />
                <div className="input_group">
                    <input type="text" className="my_field" name='patient_age' placeholder='Age'
                        style={{ 'width': '3rem' }} value={formData.patient_age} onChange={handleChange} />
                    <select className="my_field" name='patient_age_unit'
                        style={{ 'width': '6rem' }} value={formData.patient_age_unit} onChange={handleChange}  >
                        <option value="Year(s)">Year(s)</option>
                        <option value="Month(s)">Month(s)</option>
                        <option value="Day(s)">Day(s)</option>
                    </select>
                    <select className="my_field" name='patient_sex'
                        style={{ 'width': '6rem' }} value={formData.patient_sex} onChange={handleChange}  >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    <select className="my_field" name='patient_type'
                        style={{ 'width': '6rem' }} value={formData.patient_type} onChange={handleChange}  >
                        <option value="new">New</option>
                        <option value="f_up">Follow-Up</option>
                    </select>
                </div>

                <input type="text" className="my_field" name='patient_phone' placeholder='Phone'
                    value={formData.patient_phone} onChange={handleChange} />
                <input type="text" className="my_field" name='patient_address' placeholder='Address'
                    style={{ 'fontSize': 'small', 'paddingLeft': '2px' }} value={formData.patient_address} onChange={handleChange} />
                <br />
                <input  type="submit" value={btnValue} className="my_btn submit_btn" />
                <br />
                {showCancelBtn ?
                <button type="button" className='my_btn cancelBtn' onClick={()=>setShowEditPaitent(false)}>Cancel</button>
                :<></>}
            </div>


        </form>
    )
}

export default DoctorPatientSerialForm