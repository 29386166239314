import {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

const Reload = () => {
    const navigate = useNavigate()
   useEffect(()=> {
    navigate(-1)
   },[])
  return (
    <div>Please wait...</div>
  )
}

export default Reload