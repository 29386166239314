import React from 'react'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const Bar_Chart = ({data}) => {
    
    return (
        <BarChart
            width={300}
            height={190}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Payable" fill="#299dcf" activeBar={<Rectangle fill="gold" stroke="purple" />} />
            <Bar dataKey="Paid" fill="green" activeBar={<Rectangle fill="pink" stroke="blue" />} />
            
        </BarChart>
    )
}

export default Bar_Chart