import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '../../services/Pagination';
import swal from 'sweetalert2'
import { useAppContext } from '../../contex/appContext';
import { Audio } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { FaHistory } from "react-icons/fa";
import { FaWheelchair } from "react-icons/fa";
import { FaMoneyCheckAlt } from "react-icons/fa";
import Modal from '../modal/Modal';

const ReffererList = () => {
    const navigate = useNavigate()
    const { userData } = useAppContext()
    const base_url = baseUrl();
    const authToken = getToken();
    const [fetchedRefferers, setFetchedRefferers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showWaitMsg, setshowWaitMsg] = useState(false)

    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 15;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedRefferers = fetchedRefferers.slice(firstElementIndex, lastElementIndex)
    ///Pagination --------------------End
    const fetchRefferers = async () => {
        try {
            const res = await axios.get(`${base_url}/get_all_refferers`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setLoading(false)
            if (res.data.status === 'success') {
                setFetchedRefferers(res.data.db_data)
            } else {
                swal.fire({
                    'text': res.data.message,
                    'icon': res.data.status
                })
            }

        } catch (error) {
            console.log(error)
        }
    }
    const changeRCStatusRequest = async (id, rcs) => {
        setshowWaitMsg(true)
        try {
            const res = await axios.post(`${base_url}/change_rc_status`, {
                id: id, rcs: rcs
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setshowWaitMsg(false)
            if (res.data.status === 'success') {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message,
                })
                fetchRefferers();
            }
        } catch (error) {
            setshowWaitMsg(false)
            console.log(error)
        }
    }
    const handleRCStatus = (id, rcs, name) => {
        Swal.fire({
            icon: 'warning',
            text: `Change RC status of ${name}?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Change'
        }).then((result) => {
            if (result.isConfirmed) {
                changeRCStatusRequest(id, rcs)
            }
        })
    }
    useEffect(() => {
        if (userData.user_type === 'admin' || userData.user_type === 'manager') {
            fetchRefferers();
        } else {
            navigate('/')
        }


    }, [])
    return (
        <div className='mainWindow'>
            {showWaitMsg ? <Modal data='Please wait' title='wait' /> : <></>}
            <div className="refferersWrapper my_container">
                <h2>Refferers List</h2>
                {loading ? <Audio color='cadetblue' height={30} /> : <>
                    {fetchedRefferers.length > 0 ? <>
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>RC Status</th>
                                    <th>Total due</th>
                                    <th>Payment History</th>
                                    <th>Patients</th>
                                    <th>Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedRefferers.map((elem, i) =>
                                    <tr key={elem.id}>
                                        <td>{i + 1 + (currentPage * elementsPerPage) - elementsPerPage}</td>
                                        <td>{elem.refferer_name}</td>
                                        <td>{elem.rc_status == 1 ? 'On' : 'Off'}
                                            &nbsp;
                                            <button className='no_border_btn blueBtn' onClick={() => handleRCStatus(elem.id, elem.rc_status, elem.refferer_name)}>Ch</button>
                                        </td>
                                        <td className={elem.total_due > 0 ? 'danger_row_td' : ''}>{elem.total_due} /-</td>
                                        <td>
                                            <Link to={`/payment_history/${elem.id}/${elem.refferer_name}`} ><FaHistory color='#222925' size={18} /></Link>
                                        </td>
                                        <td>
                                            <Link to={`/refferer_patients/${elem.id}/${elem.refferer_name}`}><FaWheelchair color='#222725' size={18} /></Link>
                                        </td>
                                        <td>{elem.total_due > 0 ? <Link to={`/refferer_payment/${elem.id}/${elem.refferer_name}`}><FaMoneyCheckAlt color='#224725' size={18} /></Link> : ''}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                        <Pagination
                            elementsPerPage={elementsPerPage}
                            totaElements={fetchedRefferers.length}
                            pageNo={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </> : <h3 className='failed'>No refferer found</h3>}

                </>}
            </div>

        </div>
    )
}

export default ReffererList