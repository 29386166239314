import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import DoctorPatientSerialForm from './DoctorPatientSerialForm';
import { DateFormat } from '../../services/DateFormat'
import axios from 'axios';
import Swal from 'sweetalert2'
import { Audio, TailSpin } from 'react-loader-spinner';
import { BiEdit } from 'react-icons/bi'
import { AiFillDollarCircle, AiOutlineDelete } from 'react-icons/ai'
import { useAppContext } from '../../contex/appContext';
import ReactToPrint from 'react-to-print';
import { BsPrinterFill } from 'react-icons/bs';

const DoctorPatientSerial = () => {
    const { userData } = useAppContext()
    const navigate = useNavigate()
    const { id, doctor_name, date } = useParams();
    const base_url = baseUrl();
    const auth_token = getToken();
    const [addedPatients, setAddedPatients] = useState([]);
    const [ShowEditPaitent, setShowEditPaitent] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showWait, setShowWait] = useState(false);
    const [formData, setFormData] = useState({
        'patient_name': '',
        'patient_age': '',
        'patient_age_unit': 'Year(s)',
        'patient_sex': 'F',
        'patient_phone': '',
        'patient_address': '',
        'patient_type': 'new'
    })

    const [submitBtnValue, setSubmitBtnValue] = useState('Add');

    const fetchAddedPatients = async () => {
        try {
            const res = await axios.get(`${base_url}/show_schedule_patients/${id}`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${auth_token}`
                    }
                })
            setLoading(false)
            if (res.data.status === 'success') {
                setAddedPatients(res.data.dbData)
            } else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }

        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitBtnValue('Please wait..');
        try {
            const res = await axios.post(`${base_url}/add_doctor_patient_serial/${id}`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${auth_token}`
                }
            })
            setSubmitBtnValue('Add');
            if (res.data.status === 'success') {
                setFormData({
                    'patient_name': '',
                    'patient_age': '',
                    'patient_age_unit': 'Year(s)',
                    'patient_sex': 'F',
                    'patient_phone': '',
                    'patient_address': '',
                    'patient_type': 'new'
                })
            }
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            fetchAddedPatients()
        } catch (error) {
            setSubmitBtnValue('Add');
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'info',
                    text: error.response.data.message
                })
            }
            console.log(error.response)
        }
    }
    ///functions for edit doctor patient
    const [editFormData, setEditFormData] = useState({})
    const handleEditBtnClick = (patient_data) => {
        setEditFormData(patient_data)
        setShowEditPaitent(true)
    }
    const handleEditChange = (e) => {
        setEditFormData({
            ...editFormData,
            [e.target.name]: e.target.value
        })
    }
    const [updateBtnValue, setUpdateBtnValue] = useState('Update');

    const updatePatient = async e => {
        e.preventDefault()
        setUpdateBtnValue('Please wait...')
        try {
            const res = await axios.post(`${base_url}/update_doctor_patient`, editFormData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${auth_token}`
                }
            })
            setUpdateBtnValue('Update')
            if (res.data.status === 'success') {
                fetchAddedPatients()
                setShowEditPaitent(false)
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
            else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
        } catch (error) {
            setUpdateBtnValue('Update')
            console.log(error.response)
        }
    }
    ///functions for edit doctor patient ends
    async function deletePatient(i) {
        setShowWait(true)
        try {
            const res = await axios.delete(`${base_url}/delete_doctor_patient/${i}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${auth_token}`
                }
            })
            setShowWait(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                fetchAddedPatients()
            }
        } catch (error) {
            setShowWait(false)
            console.log(error.response)
        }
    }
    const componentRef = useRef();

    const handleDelete = id => {
        Swal.fire({
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            text: 'Delete this patient ..?',
            confirmButtonColor: 'tomato'
        }).then((result) => {
            if (result.isConfirmed) {
                deletePatient(id);
            } else if (result.isDenied) {
                Swal.fire({
                    icon: 'info',
                    text: 'Not Deleted'
                })
            }
        })
    }
    const changePaymentStatus = async (id) => {
        setShowWait(true)
        try {
            const res = await axios.get(`${base_url}/change_serail_patient_pay/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${auth_token}`
                }
            })
            fetchAddedPatients();
            setShowWait(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message,
                timer: 2000
            })
        } catch (error) {
            setShowWait(false)
            console.log(error)
        }
    }

    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager' &&
            userData.user_type !== 'reception') {
            navigate('/')
        }
        fetchAddedPatients()
    }, [])
    return (
        <div className='mainWindow'>
            <h2 className='doctor_serial_heading' >
                <ReactToPrint
                    trigger={() => <button className='h2_left no_border_btn' ><BsPrinterFill size={20} /></button>}
                    content={() => componentRef.current} />

                {doctor_name} Patients - {DateFormat(date)}
                <Link className='cancelBtn h2_right_btn' to={-1}>&times;</Link>
            </h2>
            <div className="doctorPatientSerialDiv flexDiv">
                {ShowEditPaitent ? <DoctorPatientSerialForm
                    formData={editFormData}
                    handleChange={handleEditChange}
                    title='Edit'
                    btnValue={updateBtnValue}
                    handleSubmit={updatePatient}
                    showCancelBtn={true}
                    setShowEditPaitent={setShowEditPaitent}
                /> :
                    <DoctorPatientSerialForm
                        setShowEditPaitent={setShowEditPaitent}
                        formData={formData}
                        handleChange={handleChange}
                        title='Add'
                        btnValue={submitBtnValue}
                        handleSubmit={handleSubmit}
                        showCancelBtn={false}
                    />
                }

                <div className="showAddedDoctorPatientsDiv my_box">
                    {showWait ?
                        <div className="loader" >
                            <TailSpin
                                height="30"
                                width="40"
                                radius="5"
                                color="cadetblue"
                                ariaLabel=""
                                wrapperStyle
                                wrapperClass
                            />
                        </div> : <></>}
                    <h2>Added patients</h2>
                    {loading ? <Audio
                        height="30"
                        width="40"
                        radius="5"
                        color="cadetblue"
                        ariaLabel=""
                        wrapperStyle
                        wrapperClass
                    /> : <>
                        <div className="flexDiv">
                            <span>Total patients : {addedPatients.length}</span>
                            <span>Total fee : {addedPatients.reduce((a, b) => { return a + b.doctor_fee }, 0)}</span>
                        </div>
                        {addedPatients.length > 0 ?
                            <div className="x_overflow serial_div" ref={componentRef}>
                                <span className="print_only">
                                    <u>{doctor_name} Patients - {DateFormat(date)}</u>
                                </span>
                                < table style={{ fontSize: 'small' }} >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name (Age) - Sex</th>
                                            <th>Phone</th>
                                            <th>Address</th>
                                            <th>Visit</th>
                                            <th>Fee</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addedPatients.map((el, i) =>
                                            <tr key={el.id}>
                                                <td>{i + 1}</td>
                                                <td>{`${el.patient_name} (${el.patient_age} ${el.patient_age_unit}) - ${el.patient_sex}`}</td>
                                                <td>{el.patient_phone}</td>
                                                <td>{el.patient_address}</td>
                                                <td>{el.patient_type}</td>
                                                <td>{el.doctor_fee}</td>
                                                <td>
                                                    <button className='no_border_btn' onClick={() => handleEditBtnClick(el)} ><BiEdit size='20' /></button>
                                                    <button className='no_border_btn' onClick={() => handleDelete(el.id)} ><AiOutlineDelete size='20' color='tomato' /></button>
                                                    <button className='no_border_btn' onClick={() => changePaymentStatus(el.id)}><AiFillDollarCircle size='20' /></button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            : <h3 className='failed'>No patient added</h3>}</>
                    }
                </div>
            </div>


        </div >
    )
}

export default DoctorPatientSerial