import React, { useEffect, useState } from 'react'
import './masterAdminStyles.css'
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import { Audio } from 'react-loader-spinner';
import { DateFormat } from '../../services/DateFormat';
import { GetDate } from '../../services/Date';
import Pagination from '../../services/Pagination'
import Modal from '../modal/Modal'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'

const ClinicDoctors = () => {
    const navigate = useNavigate();
    const today = GetDate()
    const base_url = baseUrl();
    const authToken = getToken();
    const [fetchedData, setfetchedData] = useState([]);
    const [datatoShow, setdatatoShow] = useState([])
    const [loading, setloading] = useState(true);
    const [showWaitmodal, setshowWaitmodal] = useState(false);
    const status = ['Disabled', 'Active'];
    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 12;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedData = datatoShow.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End
    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/get_clinic_doctors`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setfetchedData(res.data)
            setdatatoShow(res.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error.response)
        }
    }
    const assignSingleRequest = async i => {
        try {
            const res = await axios.post(`${base_url}/assign_doctor_as_single`, {
                'id': i,
                'validity': today
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate('/master_admin/single_doctors')
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleAssignSingle = (id, name) => {
        Swal.fire({
            icon: 'warning',
            text: `Assign ${name} as a single doctor..?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Assign'
        }).then((result) => {
            if (result.isConfirmed) {
                assignSingleRequest(id)
            }
        })
    }
    const handleSearchData = e => {
        let filtered_data = fetchedData.filter(el =>
            el.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            el.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            el.phone.includes(e.target.value)
        )
        setdatatoShow(filtered_data)
    }
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className='master_admin_wrapper'>
            {showWaitmodal ? <Modal data='Changing user status' title='wait' /> : <></>}
            <input type="text" style={{ width: '100%' }} className='search_field' placeholder='Search name, phone or email..' onChange={handleSearchData} />
            <h2>
                <Link className='h2_left addBtn my_btn' to='/master_admin/add_single_doctor'>Add New</Link>
                Clinic Doctors</h2>
            {loading ? <Audio height={30} color='cadetblue' /> : <div>                
                <div className="patient_tiles_wrapper">
                    {paginatedData.map(elem =>
                        <div className="masterAdminTiles" key={elem.id}>
                            <h4>{elem.name}</h4>
                            <p>Email : {elem.email}</p>
                            <p>Phone : {elem.phone}</p>
                            <p>Admin : {elem.admin_email}</p>
                            <p className="flexDiv">
                                <span className={elem.user_status == 1 ? 'success' : 'failed'}>
                                    Status : {status[elem.user_status]}
                                </span>
                            </p>
                            <hr />
                            <div className="tiles_btn_row">
                                <button className='blueBtn' onClick={() => handleAssignSingle(elem.id, elem.name)}>Assign single</button>
                            </div>

                        </div>
                    )}
                </div>
                <Pagination
                    elementsPerPage={elementsPerPage}
                    totaElements={fetchedData.length}
                    pageNo={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>}

        </div>
    )
}

export default ClinicDoctors