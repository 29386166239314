import axios from 'axios';
import { createContext, useContext, useEffect, useState, useReducer } from 'react';
import { getToken } from '../localStorageManage/localStore';
import { baseUrl } from '../services/baseUrl';

const DepartmentsContext = createContext();

//use of reducer
const initialState = {
    loading: true,
    departmentsData: [],
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'LOADED':
            return {
                ...state,
                loading: false,
                departmentsData: action.payload.data,
            }
        default:
            return {
                state
            }
    }
}

//use of reducer

const DepartmentsProvider = ({ children }) => {
    const [value, dispatch] = useReducer(reducer, initialState);

    const authToken = getToken();
    const base_url = baseUrl();


    const fetchDepartmentsData = async () => {
        try {
            const res = await axios.get(`${base_url}/show_departments`, {
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            dispatch({
                type: "LOADED", payload: res
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (authToken) {
            fetchDepartmentsData()
        }
    }, [])

    return <DepartmentsContext.Provider value={value}>{children}</DepartmentsContext.Provider>


}
const useDepartmentContext = () => {
    return useContext(DepartmentsContext)
}
export { DepartmentsContext, DepartmentsProvider, useDepartmentContext }