import React from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const Mix_BarChart = ({data}) => {

  return (

    <BarChart
      width={300}
      height={190}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />      
      <Bar dataKey="Payable" fill="#299dcf" />
      <Bar dataKey="Paid" stackId="a" fill="green" />
      <Bar dataKey="Due" stackId="a" fill="tomato" />
    </BarChart>
  )
}

export default Mix_BarChart