import React, { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { logOutReq } from '../auth/logout';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './doctorAssistantStyle.css'
import logo from '../../Images/logo.PNG'

const DoctorAssistantMenubar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate()
    const logout = () => {
        logOutReq();
        localStorage.removeItem('rahmatLabSolutionAuthToken')
        navigate('/login')
    }
    return (
        <div className='doctorMenuBar'>
            <div className="menuIconDiv">
                <span onClick={() => setShowMenu(!showMenu)} className={showMenu ? 'menuBtn menuBtnWhite' : 'menuBtn'}><GiHamburgerMenu /></span>
            </div>

            <div className="doctorMenuBarLeft">
                <Link to='/'>
                    <img className='logoImage' alt='logoImage' src={logo} />
                </Link>
            </div>
            <div className={showMenu ? 'menuBarShow doctorMenuBarRight' : 'doctorMenuBarRight'}>

                <ul>
                    <li><NavLink to={'/single_doctor_assistant'} onClick={() => setShowMenu(!showMenu)}>Home</NavLink></li>
                    <li><NavLink to={'/single_doctor_assistant/schedules'} onClick={() => setShowMenu(!showMenu)}>Schedules</NavLink></li>
                    <li><NavLink to={'/single_doctor_assistant/profile'} onClick={() => setShowMenu(!showMenu)}>Profile</NavLink></li>
                    <li><NavLink to={'/single_doctor_assistant/change_password'} onClick={() => setShowMenu(!showMenu)}>Change password</NavLink></li>
                    <li><button onClick={() => logout()} className='logoutBtn' >Log Out</button></li>
                </ul>
            </div>
        </div>
    )
}

export default DoctorAssistantMenubar