import axios from 'axios';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { getToken } from '../localStorageManage/localStore';
import { baseUrl } from '../services/baseUrl';
import { useAppContext } from './appContext';

const CLinicDetailsContext = createContext();

//use of reducer
const initialState = {
    loading: true,
    clinicDetailsData: {},
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'LOADED':
            return {
                ...state,
                loading: false,
                clinicDetailsData: action.payload.data,
            }
        default:
            return {
                state
            }
    }
}

//use of reducer

const CLinicDetailsProvider = ({ children }) => {
    const [value, dispatch] = useReducer(reducer, initialState);
    const authToken = getToken();
    const base_url = baseUrl();
    const fetchCLinicDetailsData = async () => {      
        try {
            const res = await axios.get(`${base_url}/show_clinic_details`, {
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })

            dispatch({
                type: "LOADED", payload: res
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (authToken) {
            fetchCLinicDetailsData()
        }
    }, [])

    return <CLinicDetailsContext.Provider value={value}>{children}</CLinicDetailsContext.Provider>


}
const useCLinicDetailsContext = () => {
    return useContext(CLinicDetailsContext)
}
export { CLinicDetailsContext, CLinicDetailsProvider, useCLinicDetailsContext }