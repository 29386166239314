import axios from 'axios';
import { createContext, useContext, useEffect, useState, useReducer } from 'react';
import { getToken } from '../localStorageManage/localStore';
import { baseUrl } from '../services/baseUrl';

const AppContext = createContext();

//use of reducer
const initialState = {
    loading: true,
    userData: {},
    logOut : false
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'LOADED':
            return {
                ...state,
                loading: false,
                userData: action.payload.data,
            }
        default:
            return {
                state
            }
    }
}

//use of reducer

const AppProvider = ({ children }) => {
    const [value, dispatch] = useReducer(reducer, initialState);

    const authToken = getToken();
    const base_url = baseUrl();

    const create_logged_user_req = axios.create({
        method: 'GET',
        baseURL: `${base_url}/logged_user`,
        headers: {
            "content-Type": 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    })
    const fetchUserData = async () => {
        try {
            const res = await create_logged_user_req();
            dispatch({
                type: "LOADED", payload: res
            })
        } catch (error) {
            console.log(error)
            localStorage.removeItem('rahmatLabSolutionAuthToken')            
        }
    }
    useEffect(() => {
        if (authToken) {
            fetchUserData()
        }
    },[])

    return <AppContext.Provider value={ value }>{children}</AppContext.Provider>


}
const useAppContext = () => {
    return useContext(AppContext)
}
export { AppContext, AppProvider, useAppContext }