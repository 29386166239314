import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner';
import Modal from '../../modal/Modal';

const Cbc = () => {
    const navigate = useNavigate()
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setloading] = useState(true);
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [reporters, setreporters] = useState([])
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))

    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }
    const [formData, setformData] = useState({
        'hb': '',
        'esr': '',
        'total_wbc': '',
        'neuto': '00',
        'lympho': '00',
        'eosino': '00',
        'mono': '00',
        'baso': '00',
        'dc_atypical': '00',
        'dc_others_cb': false,
        'dc_others_1': '00',
        'dc_others_2': '00',
        'dc_others_3': '00',
        'dc_others_4': '00',
        'dc_others_5': '00',
        'tce': '',
        'rbc_panel_cb': false,
        'platelet_count': '',
        'total_rbc': '',
        'pcv': '',
        'mcv': '',
        'mch': '',
        'mchc': '',
        'rdw_sd': '',
        'rdw_cv': '',
        'platelet_panel_cb': false,
        'mpv': '',
        'pdw': '',
        'pct': '',
        'p_lcr': '',
        'p_lcc': '',
        'pbf_cb': false,
        'pbf_rbc': '',
        'pbf_wbc': '',
        'pbf_platelet': '',
        'pbf_comment': '',
        'pbf_adv': '',
    })
    const fetchResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_cbc_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            if (res.data.status === 'success') {
                setformData(res.data.db_data)
                if(res.data.db_data.reporter_id === null){
                    if(res.data.reporters.filter(el=>el.id == reporter).length < 1){
                        setReporter('')
                    }
                }else{
                    setReporter(res.data.db_data.reporter_id)
                }
                if(res.data.db_data.reporter_2_id === null){
                    if(res.data.reporters.filter(el=>el.id == reporter2).length < 1){
                        setReporter2('')
                    }
                }else{
                    setReporter2(res.data.db_data.reporter_2_id)
                }
                setreporters(res.data.reporters)
            }
            else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }

        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleCheck = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.checked
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        if (!formData.dc_others_1.includes(':')) {
            Swal.fire({
                icon: 'info',
                text: "'Other cell 1' field is missing a ':' sign..!"
            })
            return false
        }
        if (!formData.dc_others_2.includes(':')) {
            Swal.fire({
                icon: 'info',
                text: "'Other cell 2' field is missing a ':' sign..!"
            })
            return false
        }
        if (!formData.dc_others_3.includes(':')) {
            Swal.fire({
                icon: 'info',
                text: "'Other cell 3' field is missing a ':' sign..!"
            })
            return false
        }
        if (!formData.dc_others_4.includes(':')) {
            Swal.fire({
                icon: 'info',
                text: "'Other cell 4' field is missing a ':' sign..!"
            })
            return false
        }
        if (!formData.dc_others_5.includes(':')) {
            Swal.fire({
                icon: 'info',
                text: "'Other cell 5' field is missing a ':' sign..!"
            })
            return false
        }
        if(reporter == '' || reporter === null){
            autoAlert("Please select a reporter")
            return false
        }
        if(reporter == reporter2){
            autoAlert("2 reporters can not be same..!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setshowWaitModal(true);
        try {
            const res = await axios.post(`${base_url}/update_cbc_result/${id}`,
             {...formData, reporter_id:reporter, reporter_2_id: reporter2}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false);
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate(-1)
            }
        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {        
        fetchResult()
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal title='wait' data='Updating CBC result' /> : <></>}
            <div className="my_container_fulid">
                <h2>CBC for patient id : {patient_id}
                    <Link to={-1} className='h2_right_btn cancelBtn' >&times;</Link>
                </h2>
                {loading ? <Audio color='cadetblue' height='30' /> :
                    <form className="cbc_form" onSubmit={handleSubmit}>
                        
                        <div className="cbc_form_grid">
                            <div className="cbc_form_group">
                                <label htmlFor="hb" >Hb:</label>
                                <input type="text" id='hb' name='hb' onChange={handleChange} value={formData.hb} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="esr" >ESR:</label>
                                <input type="text" id='esr' name='esr' onChange={handleChange} value={formData.esr} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="tlc" >Total WBC:</label>
                                <input type="text" id='tlc' name='total_wbc' onChange={handleChange} value={formData.total_wbc} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="neuto" >Neutro:</label>
                                <input type="text" id='neuto' name='neuto' onChange={handleChange} value={formData.neuto} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="lympho" >Lymph:</label>
                                <input type="text" id='lympho' name='lympho' onChange={handleChange} value={formData.lympho} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="eosino" >Eosino:</label>
                                <input type="text" id='eosino' name='eosino' onChange={handleChange} value={formData.eosino} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="mono" >Mono:</label>
                                <input type="mono" id='mono' name='mono' onChange={handleChange} value={formData.mono} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="baso" >Baso:</label>
                                <input type="text" id='baso' name='baso' onChange={handleChange} value={formData.baso} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="dc_atypical" >Atypic:</label>
                                <input type="text" id='dc_atypical' name='dc_atypical' onChange={handleChange} value={formData.dc_atypical} className="cbc_field" />
                            </div>
                        </div>
                        <div className="cbc_form_grid">
                            <div className="cbc_form_group">
                                <label htmlFor="dc_others_cb" >Other cells:</label>
                                <input type="checkbox" checked={formData.dc_others_cb != 0 } style={{ width: '20px' }} id='dc_others_cb' name='dc_others_cb' onChange={handleCheck} className="cbc_field" />
                            </div>
                            {formData.dc_others_cb != 0 ?
                                <>
                                    <div className="cbc_form_group">
                                        <label htmlFor="dc_others_1" >Other 1:</label>
                                        <input type="text" id='dc_others_1' name='dc_others_1' style={{ width: '100%' }} placeholder='Cell name : %'
                                            onChange={handleChange} value={formData.dc_others_1} className="cbc_field" />
                                    </div>
                                    <div className="cbc_form_group">
                                        <label htmlFor="dc_others_2" >Other 2:</label>
                                        <input type="text" id='dc_others_2' name='dc_others_2' style={{ width: '100%' }} placeholder='Cell name : %'
                                            onChange={handleChange} value={formData.dc_others_2} className="cbc_field" />
                                    </div>
                                    <div className="cbc_form_group">
                                        <label htmlFor="dc_others_3" >Other 3:</label>
                                        <input type="text" id='dc_others_3' name='dc_others_3' style={{ width: '100%' }} placeholder='Cell name : %'
                                            onChange={handleChange} value={formData.dc_others_3} className="cbc_field" />
                                    </div>
                                    <div className="cbc_form_group">
                                        <label htmlFor="dc_others_4" >Other 4:</label>
                                        <input type="text" id='dc_others_4' name='dc_others_4' style={{ width: '100%' }} placeholder='Cell name : %'
                                            onChange={handleChange} value={formData.dc_others_4} className="cbc_field" />
                                    </div>
                                    <div className="cbc_form_group">
                                        <label htmlFor="dc_others_5" >Other 5:</label>
                                        <input type="text" id='dc_others_5' name='dc_others_5' style={{ width: '100%' }} placeholder='Cell name : %'
                                            onChange={handleChange} value={formData.dc_others_5} className="cbc_field" />
                                    </div>
                                </>
                                : <> </>
                            }
                            <div className="cbc_form_group">
                                <label htmlFor="total" >Total:</label>
                                <input type="text" readOnly id='total' name='tce' onChange={handleChange} className="cbc_field"
                                    value={parseInt(formData.neuto) + parseInt(formData.lympho) + parseInt(formData.eosino) +
                                        + parseInt(formData.mono) + parseInt(formData.baso) + parseInt(formData.dc_atypical) +
                                        parseInt(formData.dc_others_1.slice(formData.dc_others_1.indexOf(':') + 1))
                                        + parseInt(formData.dc_others_2.slice(formData.dc_others_2.indexOf(':') + 1)) +
                                        parseInt(formData.dc_others_3.slice(formData.dc_others_3.indexOf(':') + 1)) +
                                        parseInt(formData.dc_others_4.slice(formData.dc_others_4.indexOf(':') + 1)) +
                                        parseInt(formData.dc_others_5.slice(formData.dc_others_5.indexOf(':') + 1))} />
                            </div>
                        </div>
                        <div className="cbc_form_grid">
                            <div className="cbc_form_group">
                                <label htmlFor="tce" >TCE:</label>
                                <input type="text" id='tce' name='tce' onChange={handleChange} value={formData.tce} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="platelet_count" >PC:</label>
                                <input type="text" id='platelet_count' name='platelet_count' onChange={handleChange} value={formData.platelet_count} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="rbc_panel_cb" >RBC Panel:</label>
                                <input type="checkbox" checked={formData.rbc_panel_cb != 0} id='rbc_panel_cb' style={{ width: '20px' }} name='rbc_panel_cb' onChange={handleCheck} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="platelet_panel_cb" >Platelet Panel:</label>
                                <input type="checkbox" checked={formData.platelet_panel_cb != 0} id='platelet_panel_cb' style={{ width: '20px' }} name='platelet_panel_cb' onChange={handleCheck} className="cbc_field" />
                            </div>
                            <div className="cbc_form_group">
                                <label htmlFor="pbf_cb" >PBF:</label>
                                <input type="checkbox" checked={formData.pbf_cb != 0} id='pbf_cb' style={{ width: '20px' }} name='pbf_cb' onChange={handleCheck} className="cbc_field" />
                            </div>
                        </div>
                        {formData.rbc_panel_cb != 0?
                            <div className="cbc_form_grid">
                                <div className="cbc_form_group">
                                    <label htmlFor="total_rbc" >Total RBC:</label>
                                    <input type="text" id='total_rbc' name='total_rbc' onChange={handleChange} value={formData.total_rbc} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="pcv" >PCV:</label>
                                    <input type="text" id='pcv' name='pcv' onChange={handleChange} value={formData.pcv} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="mcv" >MCV:</label>
                                    <input type="text" id='mcv' name='mcv' onChange={handleChange} value={formData.mcv} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="mch" >MCH:</label>
                                    <input type="text" id='mch' name='mch' onChange={handleChange} value={formData.mch} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="mchc" >MCHC:</label>
                                    <input type="text" id='mchc' name='mchc' onChange={handleChange} value={formData.mchc} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="rdw_sd" >RDW SD:</label>
                                    <input type="text" id='rdw_sd' name='rdw_sd' onChange={handleChange} value={formData.rdw_sd} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="rdw_cv" >RDW CV:</label>
                                    <input type="text" id='rdw_cv' name='rdw_cv' onChange={handleChange} value={formData.rdw_cv} className="cbc_field" />
                                </div>
                            </div>
                            : <></>
                        }
                        {formData.platelet_panel_cb != 0  ?
                            <div className="cbc_form_grid">
                                <div className="cbc_form_group">
                                    <label htmlFor="mpv" >MPV:</label>
                                    <input type="text" id='mpv' name='mpv' onChange={handleChange} value={formData.mpv} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="pdw" >PDW:</label>
                                    <input type="text" id='pdw' name='pdw' onChange={handleChange} value={formData.pdw} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="pct" >PCT:</label>
                                    <input type="text" id='pct' name='pct' onChange={handleChange} value={formData.pct} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="p_lcr" >P-LCR:</label>
                                    <input type="text" id='p_lcr' name='p_lcr' onChange={handleChange} value={formData.p_lcr} className="cbc_field" />
                                </div>
                                <div className="cbc_form_group">
                                    <label htmlFor="p_lcc" >P-LCC:</label>
                                    <input type="text" id='p_lcc' name='p_lcc' onChange={handleChange} value={formData.p_lcc} className="cbc_field" />
                                </div>
                            </div>
                            : <></>
                        }
                        {formData.pbf_cb != 0?
                            <div className="pbf_input_group">
                                <h4>Peripheral blood film</h4>
                                <label htmlFor="pbf_rbc">RBC:</label>
                                <textarea name="pbf_rbc" value={formData.pbf_rbc} onChange={handleChange} id="pbf_rbc" rows="2"></textarea>
                                <label htmlFor="pbf_wbc">WBC:</label> <br />
                                <textarea name="pbf_wbc" value={formData.pbf_wbc} onChange={handleChange} id="pbf_wbc" rows="2"></textarea>
                                <label htmlFor="pbf_platelet">Platelet:</label> <br />
                                <textarea name="pbf_platelet" value={formData.pbf_platelet} onChange={handleChange} id="pbf_platelet" rows="1"></textarea>
                                <label htmlFor="pbf_comment">Comment:</label> <br />
                                <textarea name="pbf_comment" value={formData.pbf_comment} onChange={handleChange} id="pbf_comment" rows="1"></textarea>
                                <label htmlFor="pbf_adv">Advice:</label> <br />
                                <textarea name="pbf_adv" value={formData.pbf_adv} onChange={handleChange} id="pbf_adv" rows="1"></textarea>
                            </div> : <></>
                        }
                        <div><br />
                            <label htmlFor="reporter_id" >Reporter:</label>
                            <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                                value={reporter} className="my_field" >
                                <option value="">Select reporter</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select>
                            <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label>
                            <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                                value={reporter2} className="my_field" >
                                <option value="">Select reporter 2</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select>
                        </div>
                        <br />
                        <input type="submit" value='Update' className='my_btn submit_btn' />
                    </form>}
            </div>
        </div>
    )
}

export default Cbc