import React, { useEffect, useState } from 'react'
import './dueCollectionStyle.css'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore'
import axios from 'axios'
import { GetDate } from '../../services/Date'
import Swal from 'sweetalert2'
import { DateFormat } from '../../services/DateFormat'
import Modal from '../modal/Modal'
import { Audio } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from '../../contex/appContext'

const Refund = () => {
    const navigate = useNavigate();
    const { userData } = useAppContext();
    const today = GetDate();
    const base_url = baseUrl();
    const authToken = getToken();
    const [showCollectionForm, setshowCollectionForm] = useState(false);
    const [showwaitmodal, setshowwaitmodal] = useState(false)
    const [loading, setloading] = useState(false)
    const [formdata, setformdata] = useState({
        'patient_id': '',
        'previous_paid': 0,
        'refund_amount': 0,
        'paid_after_refund': 0,
        'refund_date': today
    })
    const [refundHistory, setrefundHistory] = useState([])
    const handleChange = e => {
        setformdata({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }
    const handleRefundChange = e => {
        setformdata({
            ...formdata,
            'refund_amount': parseInt(e.target.value),
            'paid_after_refund': formdata.previous_paid - e.target.value
        })
    }

    const handleSearch = async e => {
        e.preventDefault();
        setloading(true)
        try {
            const res = await axios.get(`${base_url}/get_transaction_by_patient_id_no/${formdata.patient_id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            if (res.data.status === 'success') {
                setshowCollectionForm(true)
                if (res.data.refund_history.length < 1) {
                    setformdata({
                        ...formdata,
                        'previous_paid': parseInt(res.data.transaction_data.paid_amount),
                        'refund_amount': 0,
                        'paid_after_refund': ''
                    })
                } else {
                    setformdata({
                        ...formdata,
                        'refund_amount': 0,
                        'paid_after_refund': '',
                        'previous_paid': parseInt(res.data.refund_history[res.data.refund_history.length - 1].paid_after_refund)
                    })
                    setrefundHistory(res.data.refund_history)
                }
            } else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
            setloading(false)
        } catch (error) {
            console.log(error)
        }
    }
    const saveChangeRequest = async () => {
        setshowwaitmodal(true)
        try {
            const res = await axios.post(`${base_url}/refund`, formdata, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowwaitmodal(false)
            setshowCollectionForm(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowwaitmodal(false)
            console.log(error)
        }
    }
    const handleSubmit = e => {
        e.preventDefault();
        if (formdata.refund_amount === 0 || formdata.refund_amount == null) {
            Swal.fire({
                icon: 'info',
                text: 'Refund amount can not be zero..!'
            })
            return false
        }
        if (parseInt(formdata.refund_amount) > parseInt(formdata.previous_paid)) {
            Swal.fire({
                icon: 'info',
                text: 'Refund amount can not be more than previous paid..!'
            })
            return false
        }
        Swal.fire({
            icon: 'question',
            text: 'Save changes.? This action can not be reversed..!',
            showConfirmButton: true,
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                saveChangeRequest();
            }
        })
    }
    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager'
            && userData.user_type !== 'reception') {
            navigate('/')
        }
    }, [])
    return (
        <div className='mainWindow'>
            {showwaitmodal ? <Modal title='wait' data='Saving data..' /> : <></>}
            <div className="due_collection_container">
                <h2>
                    Refund
                </h2>
                {!showCollectionForm ?
                    <form className='dueForm' onSubmit={handleSearch}>
                        <input type="text" value={formdata.patient_id} required name='patient_id' className="my_field" placeholder='Patinet Id' onChange={handleChange} />
                        <input type="submit" value='Search' className="my_btn submit_btn" />
                        {loading ? <Audio height={30} color='cadetblue' /> : <></>}
                    </form> : <>
                        <h3 className='due_collection_heading'> <u>Patient Id No.: {formdata.patient_id}</u></h3>
                        <div className="flexDiv">
                            <form className="collectionForm" onSubmit={handleSubmit}>
                                <label htmlFor="refund_date">Refund date:</label>
                                <input type="date" value={formdata.refund_date} onChange={handleChange}
                                    className="my_field" name='refund_date' id="refund_date" /> <br />
                                <label htmlFor="previous_paid">Previous paid:</label>
                                <input type="number" value={formdata.previous_paid} readOnly
                                    className="my_field" name='previous_paid' id="previous_paid" /> <br />
                                <label htmlFor="refund_amount">Refund amount:</label>
                                <input type="number" value={formdata.refund_amount} onChange={handleRefundChange}
                                    className="my_field" name='refund_amount' id="refund_amount" /> <br />
                                <label htmlFor="paid_after_refund">Paid after refund:</label>
                                <input type="number" value={formdata.paid_after_refund} readOnly
                                    className="my_field" name='paid_after_refund' id="paid_after_refund" /> <br />
                                <button type='button' className="my_btn blueBtn" onClick={() => setshowCollectionForm(false)}>Cancel</button>
                                <input type="submit" value="Save" className="my_btn submit_btn" />
                            </form>
                            <div className='collection_history'>
                                <h4><u>Refund history:</u></h4>
                                {refundHistory.length > 0 ? <>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Date</th>
                                                <th>Prev. paid</th>
                                                <th>Refund amount</th>
                                                <th>Paid after refund</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {refundHistory.map((el, i) =>
                                                <tr key={1}>
                                                    <td>{i + 1}</td>
                                                    <td>{DateFormat(el.refund_date)}</td>
                                                    <td>{el.previous_paid}</td>
                                                    <td>{el.refund_amount}</td>
                                                    <td>{el.paid_after_refund}</td>
                                                </tr>)}
                                        </tbody>
                                    </table>

                                </> : <> <h4 className="failed">No refund history found for this patient.</h4></>}
                            </div>


                        </div>
                    </>}
            </div>
        </div>
    )
}

export default Refund