import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getToken } from '../../../localStorageManage/localStore';
import { baseUrl } from '../../../services/baseUrl';
import '../resultForm.css'
import Modal from '../../modal/Modal';

const SingleSelect = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [testName, setTestName] = useState('');
    const navigate = useNavigate()
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setloading] = useState(true);
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [reporters, setreporters] = useState([])
    const [formData, setformData] = useState({
        'test_result' : ''
    })
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))
    const fetchResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_single_test_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            if (res.data.status === 'success') {
                if(res.data.test_result !== 'Not inserted yet.!'){
                    setformData({
                        ...formData,
                        'test_result': res.data.test_result
                    })
                }
                if(res.data.reporter_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter).length < 1){
                        setReporter('')
                    }
                }else{
                    setReporter(res.data.reporter_id)
                }
                if(res.data.reporter_2_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter2).length < 1){
                        setReporter2('')
                    }
                }else{
                    setReporter2(res.data.reporter_2_id)
                }              
                setreporters(res.data.reporters)
            }
            else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
           

        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    
    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        if(formData.test_result == null || formData.test_result == ''){
            autoAlert("Please select a result..!")
            return false;
        }
        if(reporter == '' || reporter === null){
            autoAlert("Please select a reporter")
            return false
        }
        if(reporter ==  reporter2){
            autoAlert("2 reporters can not be same.!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setshowWaitModal(true);
        try {
            const res = await axios.post(`${base_url}/add_single_test_result/${id}`, 
            {...formData, 'reporter': reporter, 'reporter_2' : reporter2}, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            console.log(res.data)
            setshowWaitModal(false);
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate(-1)
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        fetchResult()
        if (searchParams.get('test_name')) {
            setTestName(searchParams.get('test_name'))
        }
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal title='wait' data='Updating result..' /> : <></>}
            <div className="resultFormWrapper">
                <h2>{testName} for Id : {patient_id}
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                <form className="singleFieldForm" onSubmit={e => handleSubmit(e)}>

                    {loading ? 'Loading...' : (
                        <>
                            <div>
                                <label htmlFor='test_result'>Select Result:</label> <br/>
                                <select className='my_field' onChange={(e) => handleChange(e)}
                                    name='test_result' id='test_result' autoFocus value={formData.test_result} >
                                    <option value="Not inserted yet.!">Select result</option>
                                    <option value="Negative">Negative</option>
                                    <option value="Positive">Positive</option>
                                </select>
                            </div>
                            <div>
                            <label htmlFor="reporter_id" >Reporter:</label><br />
                            <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                                value={reporter} className="my_field" >
                                <option value="">Select reporter</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select> <br />
                            <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label><br />
                            <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                                value={reporter2} className="my_field" >
                                <option value="">Select reporter 2</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select>
                        </div>
                            <input type="submit" value="Enter" className='my_btn submit_btn' />
                           


                        </>
                    )}

                </form>
            </div>
        </div>
    )
}

export default SingleSelect