import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import { useAppContext } from '../../contex/appContext';
import Modal from '../modal/Modal';
import Swal from 'sweetalert2';

const EditUser = (props) => {
    const navigate = useNavigate()
    const {userData} = useAppContext();
    const base_url = baseUrl();
    const authToken = getToken();
    const { id } = useParams();
    const { state } = useLocation();
    const [showWaitModal, setshowWaitModal] = useState(false)
    const [formData, setformData] = useState({
        name: '',
        email: '',
        phone: '',
        user_type: 'manager',
    })
    const [resMsg, setResMsg] = useState('');
    const [resAlert, setResAlert] = useState('');
    const [feildErrors, setFieldErrors] = useState({
        name: '',
        email: '',
        user_type: '',
        phone : ''
    });

    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault()
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/update_user/${id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            Swal.fire({
                icon : res.data.status,
                text : res.data.message
            })
            if(res.data.status === 'success'){
                navigate('/user')
            }
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if (error.response.status === 422) {
                setResAlert('failed')
                setFieldErrors({
                    name: error.response.data.errors.name,
                    email: error.response.data.errors.email,
                    phone: error.response.data.errors.phone,
                })
            }
        }
    }
    useEffect(() => {
        if(userData.user_type !== 'admin'){
            navigate('/')
        }
        if (state) {
            setformData(state)
        }
        
    },[])

    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Updating user..' title='wait'/> : <></> }
            <form className="add_user_form" onSubmit={e => handleSubmit(e)} id='addUserForm'>
                <h2>Edit user
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                <div className="my_form_group">
                    <label htmlFor="name">Name:</label>
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="name"
                        name='name' value={formData.name} onChange={e => handleChange(e)} /><br />
                    {feildErrors.name !== '' && feildErrors.name ? <div className='failed'>{feildErrors.name}</div> : ''}

                </div>
                <div className="my_form_group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" className="my_field" style={{ width: '16rem' }} id="email"
                        name='email' value={formData.email} onChange={e => handleChange(e)} /><br />
                    {feildErrors.email !== '' && feildErrors.email ? <div className={resAlert}>{feildErrors.email}</div> : ''}

                </div>
                <div className="my_form_group">
                    <label htmlFor="phone">Phone no.:</label>
                    <input type="text" className="my_field" style={{ width: '16rem' }} id="phone"
                        name='phone' value={formData.phone} onChange={e => handleChange(e)} /><br />
                    {feildErrors.phone !== '' && feildErrors.phone ? <div className={resAlert}>{feildErrors.phone}</div> : ''}
                </div>


                <div className="my_form_group">
                    <label htmlFor="user_type">User role:</label>
                    <select className="my_field" style={{ width: '16rem' }} id="user_type"
                        name='user_type' value={formData.user_type} onChange={e => handleChange(e)} >
                        <option value="manager">Manager</option>
                        <option value="reception">Reception</option>
                        <option value="pathology">Pathology</option>
                        <option value="x_ray">X-ray</option>
                        <option value="usg">USG</option>
                        <option value="doctor">Doctor</option>
                    </select><br />
                </div>
                <div className={resAlert + 'my_form_group'}>{resMsg}</div><br />
                <div className="my_form_group" >
                    <input type="submit" value='Update' className="my_btn submit_btn" />
                </div>
            </form>
        </div>
    )
}

export default EditUser