import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../contex/appContext'
import { useNavigate, Link } from 'react-router-dom';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import Swal from 'sweetalert2';
import axios from 'axios';
import { GetDate } from '../../services/Date';
import { DateFormat } from '../../services/DateFormat';
import { Audio } from 'react-loader-spinner'
import Modal from '../modal/Modal';
import { TailSpin } from 'react-loader-spinner'


const DoctorWaitingPatients = () => {
    const today = GetDate();
    const { userData } = useAppContext();
    const navigate = useNavigate();
    const [fetchedWaitingPatients, setWaitnigPatients] = useState([]);
    const [loading, setloading] = useState(true);
    const base_url = baseUrl();
    const authToken = getToken();
    const [showWaitModal, setshowWaitModal] = useState(false)

    const fetchAddedPatients = async () => {
        try {
            const res = await axios.get(`${base_url}/show_schedule_patients/${userData.id}`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
            setloading(false)
            if (res.data.status === 'success') {
                setWaitnigPatients(res.data.dbData)
            } else {
                
            }

        } catch (error) {
            console.log(error)
        }
    }
    const handleClick = async (i) => {
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/update_doctor_patient`, { 'id': i.id }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            if (res.data.status === 'success') {
                navigate('/doctor', { state: { waitingPatientData: i } })
                // fetchAddedPatients()
            }
            else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
        } catch (error) {
            setshowWaitModal(false)
            console.log(error.response)
        }
    }
    useEffect(() => {
        if (userData.user_type != 'doctor') {
            navigate('/')
        }
        fetchAddedPatients();
        setInterval(() => {
            fetchAddedPatients()
        }, 30000)
    }, [])
    return (
        <div className='prescriptionMain'>
            <br />
            <div className="my_container">
                <h2>Waiting patients | {DateFormat(today)} | {userData.name}
                    <Link className='h2_right_btn cancelBtn' to={-1} > &times; </Link>
                </h2>
                <div className="waitingPatientsDiv">
                    {loading ? <Audio height="30"
                        width="40"
                        radius="5"
                        color="cadetblue"
                        ariaLabel=""
                        wrapperStyle
                        wrapperClass /> : <>


                        {fetchedWaitingPatients.length > 0 ?
                            < table style={{ fontSize: 'small' }} >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name (Age) - Sex</th>
                                        <th>Phone</th>
                                        <th>Address</th>
                                        <th>Visit</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fetchedWaitingPatients.map((el, i) =>
                                        <tr key={el.id} className={el.patient_status === 1 ? 'success' : 'failed'}>
                                            <td>{i + 1}</td>
                                            <td>{`${el.patient_name} (${el.patient_age} ${el.patient_age_unit}) - ${el.patient_sex}`}</td>
                                            <td>{el.patient_phone}</td>
                                            <td>{el.patient_address}</td>
                                            <td style={{ 'textTransform': 'capitalize' }}>{el.patient_type}</td>
                                            <td>
                                                <button className='no_border_btn submit_btn' onClick={() => handleClick(el)}> Enter</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            : <h3 className='failed'>No waiting patient found</h3>}
                    </>}
                </div>
            </div>
            {showWaitModal ? <Modal data='Please wait' title='wait' /> : <></>}
        </div>
    )
}

export default DoctorWaitingPatients