import React, { useEffect, useRef, useState } from 'react'
import { GetDate } from '../../services/Date'
import { DateFormat } from '../../services/DateFormat'
import Swal from 'sweetalert2'
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios';
import { Audio } from 'react-loader-spinner';
import { AiFillPrinter } from "react-icons/ai";
import ReactToPrint from 'react-to-print';
import { BsPrinterFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const SearchExpenditure = () => {
    const componentRef = useRef()
    const base_url = baseUrl();
    const authToken = getToken();
    const today = GetDate();
    const [fetchedData, setfetchedData] = useState([])
    const [loading, setloading] = useState(true)
    const [formData, setformData] = useState({
        'search_from': today,
        'search_to': today,
    })
    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const fetchData = async (from_date, to_date) => {
        try {
            const res = await axios.get(`${base_url}/search_expenses/${from_date}/${to_date}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            setfetchedData(res.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (formData.search_from > formData.search_to) {
            Swal.fire({
                icon: 'info',
                text: 'Search to date must be greater than or equal to search from date'
            })
            return false
        }
        setloading(true)
        fetchData(formData.search_from, formData.search_to)
    }

    useEffect(() => {
        fetchData(today, today)
    }, [])
    return (
        <div className='mainWindow'>
            <div className="my_container">
                <Link to={-1} className='container_right_btn cancelBtn' >&times;</Link>
                <form className="search_date_form" onSubmit={handleSubmit}>
                    <label htmlFor="search_from">Date from : </label>
                    <input type="date" name='search_from' className="my_field" id="search_from"
                        value={formData.search_from} onChange={handleChange} />
                    <label htmlFor="search_to">Date to : </label>
                    <input type="date" name='search_to' className="my_field" id="search_to"
                        value={formData.search_to} onChange={handleChange} />
                    <input type="submit" value="Search" className="my_btn submit_btn" />
                </form>
                {loading ? <Audio color='cadetblue' height={30} /> : <>
                    <div className="expenditureWrapper">
                        <div className="printExpenditure" ref={componentRef}>
                            <ReactToPrint
                                trigger={() => <button className='print_expenditure_btn no_print no_border_btn'><BsPrinterFill size={20} color='gray' /></button>}
                                content={() => componentRef.current}
                            />
                            <h3 className='text_center'>
                                Expenditures from {DateFormat(formData.search_from)} to {DateFormat(formData.search_to)}
                                &nbsp; &nbsp;Total : {fetchedData.reduce((a, b) => {
                                    return a + parseInt(b.amount)
                                }, 0)} /-
                            </h3>
                            <hr />
                            <table className='cbcTable'>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Expenditure type</th>
                                        <th>Amount</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fetchedData.map(el =>
                                        <tr key={el.id}>
                                            <td>{DateFormat(el.date)}</td>
                                            <td>{el.expenditure_type}</td>
                                            <td>{el.amount}/-</td>
                                            <td>{el.remarks}</td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default SearchExpenditure