import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import './doctorHomeStyle.css'
import DoctorMenuBar from './DoctorMenuBar'
import { PrescriptionProvider } from '../../contex/prescriptionContext';
import { getToken } from '../../localStorageManage/localStore';
import { useAppContext } from '../../contex/appContext';

const DoctorHome = () => {
  const { userData, loading } = useAppContext()
  const auth_token = getToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth_token === null) {
      navigate('/login')
    }
    if(userData.user_type !== 'doctor' && userData.user_type !== 'single_doctor'){
      navigate('/')
    }
    if (userData.user_status == 0) {
      navigate('/inactive_user')
    } else if (userData.admin_status == 0) {
      navigate('/inactive_admin')
    }
  }, [loading])
  return (
    <PrescriptionProvider>
      <div className='doctor_full_page'>
        <DoctorMenuBar />
        <div className="doctorMain">
          <Outlet />
        </div>

      </div>
    </PrescriptionProvider>
  )
}

export default DoctorHome