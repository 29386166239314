import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getToken } from '../../../localStorageManage/localStore';
import { baseUrl } from '../../../services/baseUrl';
import { GetDate } from '../../../services/Date';
import { Link, useLocation } from 'react-router-dom'
import './transactionStyle.css'
import swal from 'sweetalert2'
import Pagination from '../../../services/Pagination';
import { Audio } from 'react-loader-spinner';
import { MdOutlineScreenSearchDesktop } from "react-icons/md";

const TodaysTransaction = () => {
    const { pathname } = useLocation()
    const [loading, setLoading] = useState(true);
    const [fetchedData, setfetchedData] = useState([]);
    const base_url = baseUrl();
    const authToken = getToken();
    const today = GetDate();
    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 15;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedData = fetchedData.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End
    const fetchTodaysTransactions = async () => {
        try {
            const res = await axios.get(`${base_url}/show_transactions/${today}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                })
            setLoading(false)

            if (res.data.status === 'success') {
                setfetchedData(res.data.db_data)

            } else {
                swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchTodaysTransactions();
    }, [])
    return (
        <div className='mainWindow'>
            <div className="my_container">
                {loading ? <Audio color='cadetblue' height={30} /> : <>
                    <h2>Today's Transactions
                        <Link className='h2_right_btn' to='/search_transaction' ><MdOutlineScreenSearchDesktop color='black' size={18}/></Link>
                    </h2>
                    <div className="transactionSummary">
                        <div className="transactionSummarySingle">
                            Total amount:  {fetchedData.reduce((a, b) => {
                                return a + parseInt(b.total_amount)
                            }, 0)}/=
                        </div>
                        <div className="transactionSummarySingle">
                            Total discount:  {fetchedData.reduce((a, b) => {
                                return a + parseInt(b.discount_amount)
                            }, 0)}/=
                        </div>
                        <div className="transactionSummarySingle">
                            Total payable:  {fetchedData.reduce((a, b) => {
                                return a + parseInt(b.total_amount - b.discount_amount)
                            }, 0)}/=
                        </div>
                        <div style={{ "color": 'green' }} className="transactionSummarySingle">
                            Total paid:  {fetchedData.reduce((a, b) => {
                                return a + parseInt(b.paid_amount)
                            }, 0)}/=
                        </div>
                        <div style={{ "color": 'red' }} className="transactionSummarySingle">
                            Total due:  {fetchedData.reduce((a, b) => {
                                return a + parseInt(b.due_amount)
                            }, 0)}/=
                        </div>
                    </div>
                    {fetchedData.length < 1 ? <> <p className='notFound'>No transaction found</p></> : <>
                        <div className="transactionWrapper">
                            <table className='transactionTable'>
                                <thead>
                                    <tr>
                                        <th>Patient Id</th>
                                        <th>Patient name</th>
                                        <th>Total amount</th>
                                        <th>Discount amount</th>
                                        <th>Payable amount</th>
                                        <th>Paid amount</th>
                                        <th>Due amount</th>
                                        <th>Tests</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {<>
                                        {paginatedData.map((elem, id) => <tr key={id}>
                                            <td>{elem.patient_id_no}</td>
                                            <td>{elem.patient_name}</td>
                                            <td>{elem.total_amount}</td>
                                            <td>{elem.discount_amount}</td>
                                            <td>{elem.total_amount - elem.discount_amount}</td>
                                            <td>{elem.paid_amount}</td>
                                            <td>{elem.due_amount}</td>
                                            <td><Link to={`/services_for_patients/${elem.patient_id}`} state={{ redir_form: pathname }}>Tests</Link></td>
                                        </tr>)}
                                    </>}

                                </tbody>
                            </table>
                            <Pagination
                                elementsPerPage={elementsPerPage}
                                totaElements={fetchedData.length}
                                pageNo={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </>}

                </>}
            </div>
        </div>
    )
}

export default TodaysTransaction