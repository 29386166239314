import React, { useEffect, useState } from 'react'
import './doctorSearchPatient.css'
import { BsCalendarDay } from 'react-icons/bs'
import { BsQrCode } from 'react-icons/bs'
import { AiOutlineSearch } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { DateFormat } from '../../services/DateFormat'
import QrReader from 'react-qr-reader-es6'
import axios from 'axios'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore'
import { Audio } from 'react-loader-spinner'

const DoctorSearchPatients = () => {
  const base_url = baseUrl();
  const authToken = getToken();
  const [loading, setloading] = useState(true)
  const [waiting, setwaiting] = useState(false)
  const [showPhoneSearchForm, setshowPhoneSearchForm] = useState(false);
  const [showDateSearchForm, setshowDateSearchForm] = useState(false);
  const [showQrSearchForm, setshowQrSearchForm] = useState(false);
  const [filteredpatients, setfilteredpatients] = useState([]);

  const handlePhoneBtnClick = () => {
    setshowPhoneSearchForm(true);
    setshowDateSearchForm(false)
    setshowQrSearchForm(false)
  }

  const handleDateBtnClick = () => {
    setshowPhoneSearchForm(false);
    setshowDateSearchForm(true)
    setshowQrSearchForm(false)
  }
  const handleQRBtnClick = () => {
    setshowPhoneSearchForm(false);
    setshowDateSearchForm(false)
    setshowQrSearchForm(true)
  }
  const handlePhoneChange = async (e) => {
    if (e.target.value !== '' && e.target.value !== null) {
      setwaiting(true)
      try {
        const res = await axios.get(`${base_url}/search_doctor_prev_data/${e.target.value.toLowerCase()}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        })
        setloading(false)
        setwaiting(false)
        setfilteredpatients(res.data)
      } catch (error) {
        setloading(false)
        console.log(error)
      }
    } else {
      setfilteredpatients([])
    }

  }
  const handleDateChange = async e => {
    if (e.target.value !== '' && e.target.value !== null) {
      setwaiting(true)
      try {
        const res = await axios.get(`${base_url}/search_doctor_prev_data_by_date/${e.target.value.toLowerCase()}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        })
        setloading(false)
        setwaiting(false)
        setfilteredpatients(res.data)
      } catch (error) {
        setloading(false)
        console.log(error)
      }
    } else {
      setfilteredpatients([])
    }
  }


  const handleScan = async (data) => {
    if (data !== null) {
      setwaiting(true)
      try {
        const res = await axios.post(`${base_url}/search_doctor_prev_data_by_qr`, {
          patient_name: JSON.parse(data).p_n,
          patinet_phone: JSON.parse(data).phon,
          patinet_date: JSON.parse(data).date,
        }, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        })
        setloading(false)
        setwaiting(false)
        setfilteredpatients(res.data)
      } catch (error) {
        setloading(false)
        console.log(error)
      }
      setshowQrSearchForm(false)
    }

  }
  const handleError = (err) => {
    console.log(err)
  }

  useEffect(() => {

  }, [])
  return (
    <div className='doctorsSearchPatientMain'>
      <div className="searchPatientLeftCol">
        <div className="buttonAll" style={{ marginBottom: '0.6rem' }}>
          <button className="my_btn submit_btn" onClick={handlePhoneBtnClick}><AiOutlineSearch size={30} /></button>
          <button className="my_btn submit_btn" onClick={handleDateBtnClick}><BsCalendarDay size={30} /></button>
          <button className="my_btn submit_btn" onClick={handleQRBtnClick}><BsQrCode size={30} /></button>
        </div>
        {showPhoneSearchForm ?
          <div className="searchFormsAll">
            <input type="text" className="my_field" autoFocus placeholder='Name, phone no or address' onChange={handlePhoneChange} />
          </div>
          : <></>}
        {showDateSearchForm ?
          <div className="searchFormsAll">
            <input type="date" className="my_field" onChange={handleDateChange} />
          </div>
          : <></>}
        {showQrSearchForm ?
          <div className="searchFormsAll">
            <QrReader
              delay={1000}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '80%' }}
            />
          </div>
          : <></>}

      </div>
      <div className="searchPatientRightCol">
        <h2>
          {waiting ? <span className='h2_left'> <Audio height={20} color='black' /></span> : <></>}
          Search results</h2>
        <div className="filteredPatientsDiv">
          {!loading && !waiting ? <>
            {filteredpatients.length > 0 ?
              <table style={{ fontSize: 'small' }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Visit</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredpatients.map((el, i) =>
                      <tr key={i}>
                        <td>{JSON.parse(el.patient_details).patient_name}</td>
                        <td>{JSON.parse(el.patient_details).patient_age} &nbsp;
                          {JSON.parse(el.patient_details).patient_age_unit}</td>
                        <td>{JSON.parse(el.patient_details).patient_phone}</td>
                        <td>{JSON.parse(el.patient_details).patient_address}</td>
                        <td>{JSON.parse(el.patient_details).patient_type}</td>
                        <td>{DateFormat(JSON.parse(el.patient_details).patient_date)}</td>
                        <td><Link to={'/doctor'}
                          state={{
                            waitingPatientData: {
                              'patient_name': JSON.parse(el.patient_details).patient_name,
                              'patient_age': JSON.parse(el.patient_details).patient_age,
                              'patient_age_unit': JSON.parse(el.patient_details).patient_age_unit,
                              'patient_phone': JSON.parse(el.patient_details).patient_phone,
                              'patient_age': JSON.parse(el.patient_details).patient_age,
                              'patient_address': JSON.parse(el.patient_details).patient_address,
                              'patient_type': JSON.parse(el.patient_details).patient_type,
                              'overwrite': false
                            }, prevData: {
                              'drugs': el.drugs,
                              'history': el.history,
                              'c_c': el.c_c,
                              'o_e': el.o_e,
                              'advice': el.advice,
                              'd_d': el.d_d,
                              'tests': el.tests,
                              'diagnosis': el.diagnosis,
                            }
                          }}>Enter</Link> &nbsp; | &nbsp;
                          <Link to={'/doctor'}
                          state={{
                            waitingPatientData: {
                              'patient_name': JSON.parse(el.patient_details).patient_name,
                              'patient_age': JSON.parse(el.patient_details).patient_age,
                              'patient_age_unit': JSON.parse(el.patient_details).patient_age_unit,
                              'patient_phone': JSON.parse(el.patient_details).patient_phone,
                              'patient_age': JSON.parse(el.patient_details).patient_age,
                              'patient_address': JSON.parse(el.patient_details).patient_address,
                              'patient_type': JSON.parse(el.patient_details).patient_type,
                              'overwrite': el.id
                            }, prevData: {
                              'drugs': el.drugs,
                              'history': el.history,
                              'c_c': el.c_c,
                              'o_e': el.o_e,
                              'advice': el.advice,
                              'd_d': el.d_d,
                              'tests': el.tests,
                              'diagnosis': el.diagnosis,
                            }
                          }}>Overwrite</Link></td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
              : <><h1>No result found</h1></>}
          </> : <></>}
        </div>

      </div>
    </div>
  )
}

export default DoctorSearchPatients
