import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios'
import './dashboardStyle.css'
import Pie_Chart from '../charts/PieChart';
import Line_Chart from '../charts/Line_Chart';
import { GetDate } from '../../services/Date'
import { DateFormat } from '../../services/DateFormat'
import Bar_Chart from '../charts/Bar_Chart';
import Mix_BarChart from '../charts/Mix_BarChart';
import { FaPhoneSquareAlt } from "react-icons/fa";
import { AiOutlineFacebook, AiOutlineMail } from "react-icons/ai";
import { Audio } from "react-loader-spinner";

const AdminDashboard = () => {
  const today = GetDate();
  const base_url = baseUrl();
  const authToken = getToken();
  const [dbdata, setdbdata] = useState({});
  const [loading, setloading] = useState(true)
  const fetchData = async () => {
    try {
      const res = await axios.get(`${base_url}/show_summary`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      })
      setloading(false)
      setdbdata(res.data)
    } catch (error) {
      console.log(error)
    }
  }


  const weekData = [

    {
      name: DateFormat(dbdata.day5_before_yesterday),
      Payable: dbdata.gross_day5_before_yesterday - dbdata.discount_day5_before_yesterday,
      Paid: dbdata.paid_day5_before_yesterday,
    },
    {
      name: DateFormat(dbdata.day4_before_yesterday),
      Payable: dbdata.gross_day4_before_yesterday - dbdata.discount_day4_before_yesterday,
      Paid: dbdata.paid_day4_before_yesterday,
    },
    {
      name: DateFormat(dbdata.day3_before_yesterday),
      Payable: dbdata.gross_day3_before_yesterday - dbdata.discount_day3_before_yesterday,
      Paid: dbdata.paid_day3_before_yesterday,
    },
    {
      name: DateFormat(dbdata.day2_before_yesterday),
      Payable: dbdata.gross_day2_before_yesterday - dbdata.discount_day2_before_yesterday,
      Paid: dbdata.paid_day2_before_yesterday
    },
    {
      name: DateFormat(dbdata.day_before_yesterday),
      Payable: dbdata.gross_day_before_yesterday - dbdata.discount_day_before_yesterday,
      Paid: dbdata.paid_day_before_yesterday,
    },
    {
      name: 'Yesterday',
      Payable: dbdata.total_gross_amount_yesterday - dbdata.total_discount_yesterday,
      Paid: dbdata.total_paid_yesterday,
    },
    {
      name: 'Today',
      Payable: dbdata.total_gross_amount_today - dbdata.total_discount_today,
      Paid: dbdata.total_paid_today,
    },
  ];
  const monthData = [
    {
      name: 'Before last',
      Paid: dbdata.total_paid_month_before_last,
      Payable: dbdata.total_gross_amount_month_before_last - dbdata.total_discount_month_before_last,
    },
    {
      name: 'Last month',
      Paid: dbdata.total_paid_last_month,
      Payable: dbdata.total_gross_amount_last_month - dbdata.total_discount_last_month,
    },
    {
      name: 'This month',
      Paid: dbdata.total_paid_this_month,
      Payable: dbdata.total_gross_amount_this_month - dbdata.total_discount_this_month,
    }

  ];
  const monthRCData = [
    {
      name: 'Before last',
      Paid: dbdata.pc_paid_month_before_last,
      Payable: dbdata.total_pc_month_before_last
    },
    {
      name: 'Last month',
      Paid: dbdata.pc_paid_last_month,
      Payable: dbdata.total_pc_last_month,
    },
    {
      name: 'This month',
      Paid: dbdata.pc_paid_this_month,
      Payable: dbdata.total_pc_this_month,
    }

  ];

  const todayData = [
    {
      name: 'Yesterday',
      Payable: dbdata.total_gross_amount_yesterday - dbdata.total_discount_yesterday,
      Paid: dbdata.total_paid_yesterday,
      Due: dbdata.total_due_yesterday,
    },
    {
      name: 'Today',
      Payable: dbdata.total_gross_amount_today - dbdata.total_discount_today,
      Paid: dbdata.total_paid_today,
      Due: dbdata.total_due_today,
    },

  ];

  useEffect(() => {
    fetchData();
  }, [])
  return (
    <div className='mainWindow dashboard_mainWindow'>
      {loading ? <Audio color='cadetblue' height={30} /> : <>
        <div className="dasboard_wrapper">
          <div className="dashboard_tiles">
            <Mix_BarChart data={todayData} />
            <h3>Pay status comparison</h3>
          </div>
          {dbdata.total_gross_amount_today > 0 ?
            <div className="dashboard_tiles">
              <Pie_Chart data={[
                { name: 'Discount', value: parseInt(dbdata.total_discount_today) },
                { name: 'Paid', value: parseInt(dbdata.total_paid_today) },
                { name: 'Due', value: parseInt(dbdata.total_due_today )}
              ]}
                COLORS={['#0088FE', 'green', 'tomato']} />
              <h3>Today's ratio</h3>
            </div>
            :
            <div className="dashboard_tiles">
              <Pie_Chart data={[
                { name: 'Discount', value: parseInt(dbdata.total_discount_yesterday) },
                { name: 'Paid', value: parseInt(dbdata.total_paid_yesterday) },
                { name: 'Due', value: parseInt(dbdata.total_due_yesterday) }
              ]}
                COLORS={['#0088FE', 'green', 'tomato']} />
              <h3>Yesterday's ratio</h3>
            </div>
          }
          <div className="dashboard_tiles">
            <Line_Chart data={weekData} />
            <h3>Comparison of days</h3>
          </div>

          <div className="dashboard_tiles">
            <Bar_Chart data={monthData} />
            <h3>Comparison of months</h3>
          </div>
          <div className="dashboard_tiles">
            <Bar_Chart data={monthRCData} />
            <h3>RC comparison of months</h3>
          </div>
          <div className="dashboard_tiles">
            <div className="dashboard_table_wrapper">

            <table className='smallFont'>
              <thead>
                <tr>
                  <th> Values</th>
                  <th>Today</th>
                  <th>Yesterday</th>
                  <th>This month</th>
                  <th>Last month</th>
                  <th>This year</th>
                  <th>Last year</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> <b>Patients</b></td>
                  <td>{dbdata.no_of_patients_today}</td>
                  <td>{dbdata.no_of_patients_yesterday}</td>
                  <td>{dbdata.no_of_patients_this_month}</td>
                  <td>{dbdata.no_of_patients_last_month}</td>
                  <td>{dbdata.no_of_patients_this_year}</td>
                  <td>{dbdata.no_of_patients_last_year}</td>
                </tr>
                <tr>
                  <td> <b>Net</b></td>
                  <td>{dbdata.total_gross_amount_today - dbdata.total_discount_today }</td>
                  <td>{dbdata.total_gross_amount_yesterday - dbdata.total_discount_yesterday}</td>
                  <td>{dbdata.total_gross_amount_this_month - dbdata.total_discount_this_month}</td>
                  <td>{dbdata.total_gross_amount_last_month - dbdata.total_discount_last_month}</td>
                  <td>{dbdata.total_gross_amount_this_year - dbdata.total_discount_this_year}</td>
                  <td>{dbdata.total_gross_amount_last_year - dbdata.total_discount_last_year}</td>
                </tr>
                <tr>
                  <td> <b>Paid</b></td>
                  <td>{dbdata.total_paid_today }</td>
                  <td>{dbdata.total_paid_yesterday }</td>
                  <td>{dbdata.total_paid_this_month }</td>
                  <td>{dbdata.total_paid_last_month }</td>
                  <td>{dbdata.total_paid_this_year }</td>
                  <td>{dbdata.total_paid_last_year }</td>
                </tr>
                <tr>
                  <td> <b>Due</b></td>
                  <td>{dbdata.total_due_today }</td>
                  <td>{dbdata.total_due_yesterday }</td>
                  <td>{dbdata.total_due_this_month }</td>
                  <td>{dbdata.total_due_last_month }</td>
                  <td>{dbdata.total_due_this_year }</td>
                  <td>{dbdata.total_due_last_year }</td>
                </tr>
                <tr>
                  <td> <b>RC</b></td>
                  <td>{dbdata.total_pc_today }</td>
                  <td>{dbdata.total_pc_yesterday }</td>
                  <td>{dbdata.total_pc_this_month }</td>
                  <td>{dbdata.total_pc_last_month }</td>
                  <td>{dbdata.total_pc_this_year }</td>
                  <td>{dbdata.total_pc_last_year }</td>
                </tr>
                <tr>
                  <td> <b>Paid RC</b></td>
                  <td>{dbdata.pc_paid_today }</td>
                  <td>{dbdata.pc_paid_yesterday }</td>
                  <td>{dbdata.pc_paid_this_month }</td>
                  <td>{dbdata.pc_paid_last_month }</td>
                  <td>{dbdata.pc_paid_this_year }</td>
                  <td>{dbdata.pc_paid_last_year }</td>
                </tr>
                <tr>
                  <td> <b>Due RC</b></td>
                  <td>{dbdata.pc_due_today }</td>
                  <td>{dbdata.pc_due_yesterday }</td>
                  <td>{dbdata.pc_due_this_month }</td>
                  <td>{dbdata.pc_due_last_month }</td>
                  <td>{dbdata.pc_due_this_year }</td>
                  <td>{dbdata.pc_due_last_year }</td>
                </tr>
                <tr>
                  <td> <b>Expense</b></td>
                  <td>{dbdata.expense_today }</td>
                  <td>{dbdata.expense_yesterday }</td>
                  <td>{dbdata.expense_this_month }</td>
                  <td>{dbdata.expense_last_month }</td>
                  <td>{dbdata.expense_this_year }</td>
                  <td>{dbdata.expense_last_year }</td>
                </tr>
                <tr>
                  <td><b>Due collection</b></td>
                  <td>{dbdata.due_collection_today}</td>
                  <td>{dbdata.due_collection_yesterday}</td>
                  <td>{dbdata.due_collection_this_month}</td>
                  <td>{dbdata.due_collection_last_month}</td>
                  <td>{dbdata.due_collection_this_year}</td>
                  <td>{dbdata.due_collection_last_year}</td>
                </tr>
                <tr>
                  <td><b>Refund</b></td>
                  <td>{dbdata.refund_today}</td>
                  <td>{dbdata.refund_yesterday}</td>
                  <td>{dbdata.refund_this_month}</td>
                  <td>{dbdata.refund_last_month}</td>
                  <td>{dbdata.refund_this_year}</td>
                  <td>{dbdata.refund_last_year}</td>
                </tr>
                <tr>
                  <td><b>Balance</b></td>
                  <td>
                    {(parseInt(dbdata.total_paid_today)+parseInt(dbdata.due_collection_today))
                    -(parseInt(dbdata.expense_today)+parseInt(dbdata.refund_today))}
                  </td>
                  <td>
                    {(parseInt(dbdata.total_paid_yesterday)+parseInt(dbdata.due_collection_yesterday))
                    -(parseInt(dbdata.expense_yesterday)+parseInt(dbdata.refund_yesterday))}
                  </td>
                  <td>
                    {(parseInt(dbdata.total_paid_this_month)+parseInt(dbdata.due_collection_this_month))
                    -(parseInt(dbdata.expense_this_month)+parseInt(dbdata.refund_this_month))}
                  </td>
                  <td>
                    {(parseInt(dbdata.total_paid_last_month)+parseInt(dbdata.due_collection_last_month))
                    -(parseInt(dbdata.expense_last_month)+parseInt(dbdata.refund_last_month))}
                  </td>
                  <td>
                    {(parseInt(dbdata.total_paid_this_year)+parseInt(dbdata.due_collection_this_year))
                    -(parseInt(dbdata.expense_this_year)+parseInt(dbdata.refund_this_year))}
                  </td>
                  <td>
                    {(parseInt(dbdata.total_paid_last_year)+parseInt(dbdata.due_collection_last_year))
                    -(parseInt(dbdata.expense_last_year)+parseInt(dbdata.refund_last_year))}
                  </td>                  
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
        <footer className='dashboardFooter'>
          {dbdata.site_details.message ?
            <marquee > {dbdata.site_details.message}</marquee> : <></>}
          <div className="address">
            <h4>Provider address:</h4>
            <p>{dbdata.site_details.address}</p>
          </div>
          <div className="contact">
            <h4>Provider contact:</h4>
            <p> <FaPhoneSquareAlt color='green' size={22} />
              {dbdata.site_details.phone_no} </p>
            <p> <AiOutlineMail color='indigo' size={22} />
              {dbdata.site_details.email}</p>
            <p> <AiOutlineFacebook color='blue' size={22} />
              {dbdata.site_details.facebook}</p>
          </div>
        </footer>

      </>}
    </div>
  )
}

export default AdminDashboard