import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useDepartmentContext } from '../../contex/departmentsContex';
import { useServicesContext } from '../../contex/includedServicesContext';
import './addServicesForPatientsStyle.css'
import axios from 'axios'
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import PaymentForm from './PaymentForm';
import { useAppContext } from '../../contex/appContext';
import Swal from 'sweetalert2';
import Modal from '../../components/modal/Modal'
import { AiFillDelete } from 'react-icons/ai'
import { Audio } from 'react-loader-spinner';
import { BsPrinter, BsPrinterFill } from 'react-icons/bs';

const AddServicesForPatients = () => {
    const { pathname, state } = useLocation();
    const { userData } = useAppContext();
    const { user_type } = userData;
    const base_url = baseUrl();
    const authToken = getToken();
    const { id } = useParams();
    const { departmentsData } = useDepartmentContext();
    const { includedServicesData } = useServicesContext();
    const [filteredServices, setFilteredServices] = useState([]);
    const [addedServices, setAddedServices] = useState([]);
    const [addedServicesLoader, setAddedServicesLoader] = useState(true);
    const [resAlert, setResAlert] = useState(' ');
    const [loading, setLoading] = useState(true);
    const [totalFee, setTotalFee] = useState(0);
    const [showPayment, setShowPayment] = useState(false);
    const [showPaymentBtn, setShowPaymentBtn] = useState(false);
    const [showWaitModal, setshowWaitModal] = useState(false)
    const [showWaitModalForAdd, setshowWaitModalForAdd] = useState(false)
    const [patientData, setPatientData] = useState({
        'patient_name': '',
        'patinet_age': '',
        'patient_id_no': '',
        'patient_ref_by': '',
        'refferer_id': '',
        'id': ''

    });
    const [formData, setFormData] = useState({
        'patientId': id,
        'serviceId': ''
    });

    const [checkedServices, setcheckedServices] = useState([])
    const checkd = [];
    const handleCheck = e => {
        let { value, checked } = e.target
        if (checked) {
            if (localStorage.getItem('rahmat_web_dev_temp_services') === null) {
                localStorage.setItem('rahmat_web_dev_temp_services', '[]')
            }
            let arry = JSON.parse(localStorage.getItem('rahmat_web_dev_temp_services'))
            arry.push(value)
            checkd.push(value)
            localStorage.setItem('rahmat_web_dev_temp_services', JSON.stringify(arry))
            setcheckedServices(JSON.parse(localStorage.getItem('rahmat_web_dev_temp_services')))
        } else {
            if (localStorage.getItem('rahmat_web_dev_temp_services') !== null) {
                let arry = JSON.parse(localStorage.getItem('rahmat_web_dev_temp_services'));
                let index = arry.indexOf(value)
                arry.splice(index, 1)
                let index1 = checkd.indexOf(value)
                checkd.splice(index, 1)
                localStorage.setItem('rahmat_web_dev_temp_services', JSON.stringify(arry))
                setcheckedServices(JSON.parse(localStorage.getItem('rahmat_web_dev_temp_services')))
            }
        }

    }

    const fetchPatientData = async () => {
        try {
            const res = await axios.get(`${base_url}/single_patient/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            setPatientData({
                'patient_name': res.data.patient_name,
                'patinet_age': res.data.patient_age,
                'patient_age_unit': res.data.patient_age_unit,
                'patient_sex': res.data.patient_sex,
                'patient_id_no': res.data.patient_id_no,
                'patient_ref_by': res.data.patient_ref_by,
                'refferer_id': res.data.refferer_id,
                'id': id,
                'refferer_name': res.data.refferer_name,
                'patient_date': res.data.patient_date,
                'patient_phone': res.data.patient_phone,
            })
            setLoading(false)
            
        } catch (error) {
            console.log(error)
        }
    }

    const changeDeptHandler = (dept) => {
        if (dept.target.value !== '') {
            let filtered = includedServicesData.filter(elem => {
                return elem.department == dept.target.value
            })
            setFilteredServices(filtered)
        } else {
            setFilteredServices([])
        }

    }
    const showAddedServices = async () => {
        setAddedServicesLoader(true)
        try {
            const res = await axios.get(`${base_url}/show_tests_for_patient/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                }
            })
            console.log(res.data)
            if(res.data.dataArray.length < 1 && res.data.completed_pay > 0){                
                setShowPayment(true)
            }
            let addedServices = []
            res.data.dataArray.map(el => {
                addedServices.push(el.test_id)
            })
            localStorage.setItem('rahmat_web_dev_temp_services', JSON.stringify(addedServices))
            setcheckedServices(JSON.parse(localStorage.getItem('rahmat_web_dev_temp_services')))
            let total_payment_pending_service = res.data.dataArray.filter((elem) => {
                return elem.payment_completion != 1
            })
            if (total_payment_pending_service.length > 0) {
                setShowPaymentBtn(true)
            } else {
                setShowPaymentBtn(false)
            }
            setAddedServicesLoader(false)
            setTotalFee(res.data.total_fee)

            if (userData.user_type === 'pathology') {
                let servicesForUser = res.data.dataArray.filter((elem) => {
                    return elem.department == 1
                })
                setAddedServices(servicesForUser)
            } else if (userData.user_type === 'x_ray') {
                let servicesForUser = res.data.dataArray.filter((elem) => {
                    return elem.department == 2
                })
                setAddedServices(servicesForUser)
            } else if (userData.user_type === 'ecg') {
                let servicesForUser = res.data.dataArray.filter((elem) => {
                    return elem.department == 3
                })
                setAddedServices(servicesForUser)
            } else if (userData.user_type === 'usg') {
                let servicesForUser = res.data.dataArray.filter((elem) => {
                    return elem.department == 4
                })
                setAddedServices(servicesForUser)
            } else {
                setAddedServices(res.data.dataArray)
            }
        } catch (error) {
            console.log(error)

        }
    }

    const addServicesForPatient = async (e) => {
        e.preventDefault();
        setshowWaitModalForAdd(true)
        setShowPayment(false)
        try {
            const res = await axios.post(`${base_url}/add_tests_for_patient/${id}`,
                { 'patient_id': id, 'selected_tests': JSON.parse(localStorage.getItem('rahmat_web_dev_temp_services')) },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                })
            let tests_added = res.data.test_added.filter(el => el === true).length;
            setshowWaitModalForAdd(false)
            Swal.fire({
                icon: res.data.status,
                text: `${tests_added} test(s) added..!`
            })
            showAddedServices();


        } catch (error) {
            setshowWaitModalForAdd(false)
            console.log(error.response.data)
            if (error.response.status === 422) {
                setResAlert(error.response.data.message)
                setTimeout(() => {
                    setResAlert('')
                }, 2000)
            }

        }
    }

    const patient_seen_status_change = async () => {
        try {
            const res = await axios.get(`${base_url}/patient_seen_status_change/${id}/${user_type}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }


    const [selectedAddedArry, setselectedAddedArry] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const handleAddedCheck = e => {
        setIsCheckAll(false)
        let { value, checked } = e.target
        if (checked) {
            setselectedAddedArry([...selectedAddedArry, value])
        } else {
            setselectedAddedArry(selectedAddedArry.filter(item => item !== value))
        }
    }
    const confirmDelete = async (id) => {
        setshowWaitModal(true)
        try {
            const res = await axios.delete(`${base_url}/delete_service_for_patient/${selectedAddedArry}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                })
            setshowWaitModal(false)
            if (res.data.status === 'success') {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
                showAddedServices()
                setselectedAddedArry([])
                setIsCheckAll(false)
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
            }
            if(addedServices.length < 1){
                setShowPayment(true)
            }
        } catch (error) {
            setshowWaitModal(false)
            console.log(error.response.data)
        }
    }
    const handleSelectedDelete = (length) => {
        setShowPayment(false)
        let num = 'services'
        if (length == 1) {
            num = 'service'
        }
        Swal.fire({
            icon: 'warning',
            'text': `Do you want to delete ${length} ${num}.?`,
            'showCancelButton': true,
            'confirmButtonText': 'Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete();
            }
        })
    }
    const handleSelectAllService = (e) => {
        setIsCheckAll(e.target.checked)
        if (e.target.checked) {
            setselectedAddedArry(addedServices.map(el => el.id.toString()))
        } else {
            setselectedAddedArry([])
        }
    }


    const [search_key, setsearch_key] = useState('')
    useEffect(() => {
        if (state) {
            if (state.searchKey) {
                setsearch_key(state.searchKey)
            }

        }
        fetchPatientData()
        showAddedServices()
        if (localStorage.getItem('rahmat_web_dev_temp_services') !== null) {
            setcheckedServices(JSON.parse(localStorage.getItem('rahmat_web_dev_temp_services')))
        }

        if (user_type === 'pathology' || user_type === 'usg' ||
            user_type === 'ecg' || user_type === 'x_ray') {
            patient_seen_status_change()
        }

    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Please wait' title='wait' /> : <></>}
            {showWaitModalForAdd ? <Modal data='Adding tests..' title='wait' /> : <></>}
            {loading ? <Audio color='cadetblue' height={30} /> : <>
                <h2><Link className='h2_left printAllReport' to={`/report/${id}/${patientData.patient_id_no}`} state={{ redir_form: pathname }}><BsPrinterFill color='#000' /></Link>
                    Services for - {patientData.patient_id_no} ({patientData.patient_name}/{patientData.patinet_age})
                    <Link className="h2_right_btn cancelBtn" state={{ searchKey: search_key }} to={state.redir_form}>&times;</Link>
                </h2>
                <div className="servicesForPatientsWrapper">
                    {userData.user_type === 'admin' || userData.user_type === 'manager' || userData.user_type === 'reception' ? <>
                        <div className="leftCol" style={{ textAlign: 'right' }}>
                            <h3 className='servicesHeading'>Add Services</h3>
                            <form onSubmit={addServicesForPatient}>
                                <select name="department" id='department' onChange={(dept) => changeDeptHandler(dept)} >
                                    <option value="">Select department</option>
                                    {
                                        departmentsData.map(el =>
                                            <option key={el.department_id} value={el.department_id}>{el.department_name}</option>)
                                    }
                                </select>
                                <div className={filteredServices.length > 0 ? 'tests_grid' : ''}>
                                    {filteredServices.map((el, i) =>
                                        <div key={el.id}>
                                            <input type='checkbox' id={`test_${el.id}`} value={el.id} onChange={handleCheck}
                                                checked={checkedServices.includes(el.id.toString())} />
                                            <label htmlFor={`test_${el.id}`}>{el.test_name}</label>
                                        </div>
                                    )}
                                </div>

                                <input type="submit" value='Add' className="my_btn submit_btn" />

                            </form>

                        </div>
                    </> : ''}
                    <div className='rightCol'>                        
                            <h3 className='servicesHeading'>Added Services
                                {selectedAddedArry.length > 0 ? <>
                                    <div className="servicesHeadingRightDiv">
                                        <Link className='printSelectedBtn' to={`/print_selected_report/${patientData.id}/${patientData.patient_id_no}`} state={selectedAddedArry}><BsPrinter color='#000' /></Link>
                                        {userData.user_type === 'admin' || userData.user_type === 'manager' || userData.user_type === 'reception' ? 
                                        <button onClick={() => handleSelectedDelete(selectedAddedArry.length)} className='no_border_btn'>
                                            <AiFillDelete color='red' size={22} />
                                        </button>
                                        : <></>}
                                    </div>
                                </> : <></>}
                            </h3>
                        
                        {addedServices.length < 1 ? <h3 style={{ color: 'tomato', textAlign: 'center' }}>No Service added..!</h3> :
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Service</th>
                                        <th>Result</th>
                                        {userData.user_type === 'admin' || userData.user_type === 'manager' || userData.user_type === 'reception' ?
                                            <>
                                                <th>
                                                    Fee
                                                </th>
                                            </> : <></>
                                        }
                                        <th>
                                            <input type="checkbox" id="select_all" checked={isCheckAll} onChange={handleSelectAllService} />
                                            &nbsp;<label htmlFor="select_all">All</label>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {addedServices.map((elem, id) =>
                                        <tr key={elem.id}>
                                            <td>{id + 1}</td>
                                            <td>{elem.test_name}</td>
                                            <td className={elem.test_result === 'Not inserted yet.!' ? 'failed' : 'success'}>
                                                {userData.user_type === 'pathology' || userData.user_type === 'admin' || userData.user_type === 'x_ray' || userData.user_type === 'usg' ?
                                                    <Link to={`/${elem.input_type}/${elem.id}/${patientData.patient_id_no}?test_name=${elem.test_name}`} >{elem.test_result}</Link>
                                                    : elem.test_result}
                                            </td>
                                            {userData.user_type === 'admin' || userData.user_type === 'manager' || userData.user_type === 'reception' ?
                                                <><td>{elem.test_fee}</td>
                                                </> : <></>
                                            }
                                            <td>
                                                <input type="checkbox" checked={selectedAddedArry.includes(elem.id.toString())} value={elem.id} onChange={handleAddedCheck} />
                                            </td>

                                        </tr>)}
                                    <tr>
                                        <td colSpan={5}>
                                            {addedServicesLoader ? 'Please wait..' : ''}
                                        </td>
                                    </tr>

                                    {userData.user_type === 'admin' || userData.user_type === 'manager' || userData.user_type === 'reception' ?
                                        <>
                                            <tr>
                                                <td colSpan={5}>Total Fee : {totalFee} &nbsp;
                                                    <button className={showPaymentBtn ? 'failed my_btn' : 'success my_btn'} onClick={() => setShowPayment(!showPayment)}>Payment</button>
                                                </td>
                                            </tr>
                                        </> : <></>

                                    }


                                </tbody>
                            </table>}

                        {showPayment ? <PaymentForm
                            total_fee={totalFee}
                            patient_data={patientData}
                        /> : ""}


                    </div>
                </div>
            </>}

        </div>
    )
}

export default AddServicesForPatients