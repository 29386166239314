import React, { useEffect, useState } from 'react'
import { GetDate } from '../../services/Date'
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios';
import { Audio, TailSpin } from 'react-loader-spinner';
import { BiEdit } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { DateFormat } from '../../services/DateFormat';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Pagination from '../../services/Pagination';

const TodaysPatients = () => {
    const today = GetDate();
    const base_url = baseUrl();
    const authToken = getToken();
    const [loading, setloading] = useState(true);
    const [addedPatients, setaddedPatients] = useState([]);
    const [showWait, setshowWait] = useState(false)

    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/show_single_doctor_schedule_patients/${today}`, {
                headers: {
                    Accept: 'app;ication/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            setaddedPatients(res.data.db_data)
        } catch (error) {
            console.log(error)
        }
    }
    const deleteRequest = async (id) => {
        setshowWait(true)
        try {
            const res = await axios.get(`${base_url}/delete_single_doctor_serial_patient/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWait(false)
            fetchData()
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowWait(false)
            console.log(error)
        }
    }
    const handleDelete = (id, name) => {
        Swal.fire({
            icon: 'question',
            text: `Delete ${name} ..?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: 'tomato'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRequest(id)
            }
        })
    }

    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 12;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedData = addedPatients.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className='mainWindow'>
            <div className="showAddedDoctorPatientsDiv my_container">
                {showWait ?
                    <div className="loader" >
                        <TailSpin
                            height="30"
                            width="40"
                            radius="5"
                            color="red"
                            ariaLabel=""
                            wrapperStyle
                            wrapperClass
                        />
                    </div> : <></>
                }
                <h2>
                    <Link to='/single_doctor_assistant/add_new' state={{ date: today }} className='h2_left my_btn addBtn' >Add New</Link>
                    Patient serial ( {DateFormat(today)} )</h2>
                {loading ? <Audio
                    height="30"
                    width="40"
                    radius="5"
                    color="cadetblue"
                    ariaLabel=""
                    wrapperStyle
                    wrapperClass
                /> : <>
                    {addedPatients.length > 0 ?
                        <>
                            < table style={{ fontSize: 'small' }} >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name (Age) - Sex</th>
                                        <th>Phone</th>
                                        <th>Address</th>
                                        <th>Visit</th>
                                        <th>Fee</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map((el, i) =>
                                        <tr key={el.id}>
                                            <td>{i + 1}</td>
                                            <td>{`${el.patient_name} (${el.patient_age} ${el.patient_age_unit}) - ${el.patient_sex}`}</td>
                                            <td>{el.patient_phone}</td>
                                            <td>{el.patient_address}</td>
                                            <td>{el.patient_type}</td>
                                            <td>{el.patient_doctor_fee}</td>
                                            <td>
                                                <Link className='no_border_btn' to={`/single_doctor_assistant/edit_patient/${el.id}`} state={el}  >
                                                    <BiEdit size='18' color='grey' />
                                                </Link>
                                                <button className='no_border_btn' onClick={() => handleDelete(el.id, el.patient_name)} ><AiOutlineDelete size='18' color='tomato' /></button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                elementsPerPage={elementsPerPage}
                                totaElements={addedPatients.length}
                                pageNo={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </>

                        : <h3 className='failed'>No patient added</h3>}</>
                    }
            </div>
        </div>
    )
}

export default TodaysPatients