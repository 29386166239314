import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl, baseUrlMain } from '../../services/baseUrl';
import './clinicDetailsStyle.css';
import { Audio } from 'react-loader-spinner';
import { useAppContext } from '../../contex/appContext';

const ShowClinicDetails = () => {
    const { userData } = useAppContext()
    const authToken = getToken();
    const base_url = baseUrl();
    const base_url_main = baseUrlMain();
    const [loading, setLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState({
        'clinic_name': '',
        'clinic_address': '',
        'slogan': '',
        'logo_image': ''
    })
    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/show_clinic_details`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                })
            setFetchedData(res.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className='mainWindow'>
                {loading ? <Audio height={30} color='cadetblue' /> : <>

                    <div className="clinicDetailsDiv">
                        <h2>Clinic details  </h2>
                        <br />
                        <img src={`${base_url_main}/${fetchedData.logo_image}`} alt="logo" /><br /> <hr />
                        <h1> <u>Clinic name:</u> <br /> {fetchedData.clinic_name} </h1><br /><hr />
                        <h3 className='banglaFont'> <u>Address: </u> <br />{fetchedData.clinic_address}</h3><br /><hr />
                        <p className='banglaFont'> <b><u>Slogan:</u></b> <br /> {fetchedData.slogan} </p> <br /><hr />
                        <br />
                        {userData.user_type === 'admin' ?
                            <Link to='/edit_clinic_details' state={{ fetchedData: fetchedData }} className='my_btn'>Edit Details</Link>
                            : <></>}
                    </div>
                </>}
        </div>
    )
}

export default ShowClinicDetails