import React, { useState } from 'react'
import { useParams ,Link } from 'react-router-dom'
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';

const CustomMail = () => {
    const base_url = baseUrl();
    const authToken = getToken();
    const { email, name } = useParams();
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [formData, setformData] = useState({
        'subject': '',
        'message': '',
        'email': email,
        'name' : name
    })
    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/mail_from_master_admin`, formData , {
                headers : {
                    Accept : 'application/json',
                    Authorization : `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if(error.response.status === 422){
                Swal.fire({
                    icon : 'info',
                    text : error.response.data.message
                })
            }
        }
    }
    return (
        <div className='my_card'>
            {showWaitModal ? <Modal data='Sending mail..' title='wait'/> : <></>}
            <h2>Send mail to : {name}
            <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
            </h2>
            <label htmlFor="subject">Subject:</label>
            <form className="largeForm" onSubmit={handleSubmit}>
                <textarea name="subject" id="subject" value={formData.subject} onChange={handleChange} rows="1">
                </textarea>
                <label htmlFor="message">Message:</label>
                <textarea name="message" id="message" value={formData.message} onChange={handleChange} rows="6">
                </textarea>
                <input type="submit" className='my_btn submit_btn' value="Send" />
            </form>
        </div>
    )
}

export default CustomMail