import React, { useEffect, useState } from 'react'
import { usePrescriptionContext } from '../../../contex/prescriptionContext';
import axios from 'axios';
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';

const PrescriptionDrugs = (props) => {
    const base_url = baseUrl();
    const authToken = getToken();
    const { prescriptionData, loading } = usePrescriptionContext()
    const [fileterdDrug, setFilteredDrug] = useState([]);
    const [filterdDoses, setfilterdDoses] = useState([]);
    const [filterdDoses2, setfilterdDoses2] = useState([]);
    const [filterdDuration, setfilterdDuration] = useState([]);
    const [filterdDuration2, setfilterdDuration2] = useState([]);
    const [filterdRules, setfilterdRules] = useState([]);
    const [filterdRules2, setfilterdRules2] = useState([]);
    const [drugSearchKeyWord, setDrugSearchKeyWord] = useState('');
    const [dosesSearchKeyWord, setdosesSearchKeyWord] = useState('');
    const [doses2SearchKeyWord, setdoses2SearchKeyWord] = useState('');
    const [durationSearchKeyWord, setdurationSearchKeyWord] = useState('');
    const [duration2SearchKeyWord, setduration2SearchKeyWord] = useState('');
    const [rulesSearchKeyWord, setrulesSearchKeyWord] = useState('');
    const [rules2SearchKeyWord, setrules2SearchKeyWord] = useState('');
    const [showDoseDuration, setshowDoseDuration] = useState(false);
    const [showDose2, setshowDose2] = useState(false);

    const handleDrugInput = (e) => {
        setDrugSearchKeyWord(e.target.value);
        let filtered_drug = prescriptionData.drugs.filter((el) => {
            return el.brand_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                el.generic_name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setFilteredDrug(filtered_drug)
        if (e.target.value === '' || e.target.value === null) {
            setFilteredDrug([])
        }

    }
    const selectDrugHandler = (d) => {
        setDrugSearchKeyWord(d)
        setFilteredDrug([])
    }

    const handleDoseInput = (e) => {
        setdosesSearchKeyWord(e.target.value)
        let filtered_doses = prescriptionData.drug_doses.filter((el) => {
            return el.dose.includes(e.target.value) || el.dose_english.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setfilterdDoses(filtered_doses)
        if (e.target.value === '' || e.target.value === null) {
            setfilterdDoses([])
        }
    }
    const selectDoseHandler = (d) => {
        setdosesSearchKeyWord(d)
        setfilterdDoses([])
    }
    const handleDose2Input = (e) => {
        setdoses2SearchKeyWord(e.target.value)
        let filtered_doses = prescriptionData.drug_doses.filter((el) => {
            return el.dose.includes(e.target.value) || el.dose_english.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setfilterdDoses2(filtered_doses)
    }
    const selectDoseHandler2 = (d) => {
        setdoses2SearchKeyWord(d)
        setfilterdDoses2([])
    }
    const handleDurationInput = (e) => {
        setdurationSearchKeyWord(e.target.value)
        let filtered_duration = prescriptionData.drug_duration.filter((el) => {
            return el.duration.includes(e.target.value) || el.duration_english.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setfilterdDuration(filtered_duration)
        if (e.target.value === '' || e.target.value === null) {
            setfilterdDuration([])
        }
    }

    const selectDurationHandler = (d) => {
        setdurationSearchKeyWord(d)
        setfilterdDuration([])
    }
    const handleDuration2Input = (e) => {
        setduration2SearchKeyWord(e.target.value)
        let filtered_duration = prescriptionData.drug_duration.filter((el) => {
            return el.duration.includes(e.target.value) || el.duration_english.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setfilterdDuration2(filtered_duration)
        if (e.target.value === '' || e.target.value === null) {
            setfilterdDuration2([])
        }
    }

    const selectDuration2Handler = (d) => {
        setduration2SearchKeyWord(d)
        setfilterdDuration2([])
    }
    const handleRulesInput = (e) => {
        setrulesSearchKeyWord(e.target.value)
        let filtered_rules = prescriptionData.drug_rules.filter((el) => {
            return el.rule.includes(e.target.value) || el.rule_english.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setfilterdRules(filtered_rules)
        if (e.target.value === '' || e.target.value === null) {
            setfilterdRules([])
        }
    }
    const selectRulesHandler = (d) => {
        setrulesSearchKeyWord(d)
        setfilterdRules([])
    }
    const handleRules2Input = (e) => {
        setrules2SearchKeyWord(e.target.value)
        let filtered_rules = prescriptionData.drug_rules.filter((el) => {
            return el.rule.includes(e.target.value) || el.rule_english.toLowerCase().includes(e.target.value.toLowerCase())
        })
        setfilterdRules2(filtered_rules)
        if (e.target.value === '' || e.target.value === null) {
            setfilterdRules2([])
        }
    }
    const selectRules2Handler = (d) => {
        setrules2SearchKeyWord(d)
        setfilterdRules2([])
    }
    const addNewDataToDB = async (url, data) => {
        props.setwaitMsg(true)
        try {
            const res = await axios.post(`${base_url}/${url}`, {
                'data': data
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            props.setwaitMsg(false)
        } catch (error) {
            console.log(error.response)
        }
    }
    const handleSubmit = e => {
        e.preventDefault();
        setshowDoseDuration(false)
        let check_drug = prescriptionData.drugs.filter((el) => {
            return el.brand_name.toLowerCase().includes(drugSearchKeyWord.toLowerCase())
        })
        if (check_drug.length < 1) {
            addNewDataToDB('add_doctor_drug', drugSearchKeyWord)
        }
        let check_doses = prescriptionData.drug_doses.filter((el) => {
            return el.dose.includes(dosesSearchKeyWord)
        })
        if (check_doses.length < 1) {
            addNewDataToDB('add_doctor_doses', dosesSearchKeyWord)
        }
        let check_doses2 = prescriptionData.drug_doses.filter((el) => {
            return el.dose.includes(doses2SearchKeyWord)
        })
        if (check_doses2.length < 1) {
            addNewDataToDB('add_doctor_doses', doses2SearchKeyWord)
        }
        let check_duration = prescriptionData.drug_duration.filter((el) => {
            return el.duration.includes(durationSearchKeyWord)
        })
        if (check_duration.length < 1) {
            addNewDataToDB('add_doctor_durations', durationSearchKeyWord)
        }
        let check_duration2 = prescriptionData.drug_duration.filter((el) => {
            return el.duration.includes(durationSearchKeyWord)
        })
        if (check_duration2.length < 1) {
            addNewDataToDB('add_doctor_durations', duration2SearchKeyWord)
        }
        let check_rule = prescriptionData.drug_rules.filter((el) => {
            return el.rule.includes(rulesSearchKeyWord)
        })
        if (check_rule.length < 1) {
            addNewDataToDB('add_doctor_drug_rules', rulesSearchKeyWord)
        }
        let check_rule2 = prescriptionData.drug_rules.filter((el) => {
            return el.rule.includes(rulesSearchKeyWord)
        })
        if (check_rule2.length < 1) {
            addNewDataToDB('add_doctor_drug_rules', rules2SearchKeyWord)
        }

        //====================
        if (localStorage.getItem('rahmatewbdev_prescription_drugs') === null) {
            localStorage.setItem('rahmatewbdev_prescription_drugs', '[]')
        }
        let arry = JSON.parse(localStorage.getItem('rahmatewbdev_prescription_drugs'));
        arry.push({
            'drug_name': drugSearchKeyWord,
            'dose': dosesSearchKeyWord,
            'rule': rulesSearchKeyWord,
            'duration': durationSearchKeyWord,
            'dose2': doses2SearchKeyWord,
            'rule2': rules2SearchKeyWord,
            'duration2': duration2SearchKeyWord
        })
        localStorage.setItem('rahmatewbdev_prescription_drugs', JSON.stringify(arry))
        showDrugs()
        setDrugSearchKeyWord('')
        setdosesSearchKeyWord('')
        setdurationSearchKeyWord('')
        setrulesSearchKeyWord('')
        setdoses2SearchKeyWord('')
        setduration2SearchKeyWord('')
        setrules2SearchKeyWord('')
        setshowDose2(false)
    }
    const [addedDrugs, setAddedDrugs] = useState([]);

    function showDrugs() {
        if (localStorage.getItem('rahmatewbdev_prescription_drugs') != null) {
            setAddedDrugs(JSON.parse(localStorage.getItem('rahmatewbdev_prescription_drugs')))
        }
    }
    const blurDrugNameHandler = (e) => {
        if (e.target.value === '') {
            setshowDoseDuration(false)
        }
    }
    const removeDrugHandler = (i) => {
        let arry = JSON.parse(localStorage.getItem('rahmatewbdev_prescription_drugs'));
        arry.splice(i, 1);
        localStorage.setItem('rahmatewbdev_prescription_drugs', JSON.stringify(arry));
        showDrugs()
        setFilteredDrug([])
        setfilterdDoses([])
        setfilterdRules([])
        setfilterdDuration([])
    }
    useEffect(() => {
        showDrugs()
    }, [props.stat])
    return (
        <div className='prescriptionDrugs'>
            R<sub>x</sub>

            <div className="add_drug_div">
                <form className='add_drug_form' onSubmit={handleSubmit}>
                    <div className="add_drug_form_left">
                        <div className="add_drug_form_group drug_name_group">
                            <input onFocus={() => setshowDoseDuration(true)} onBlur={blurDrugNameHandler} type="text" className="drug_field" onChange={handleDrugInput}
                                placeholder='Click here to add drug...' value={drugSearchKeyWord} required />
                            <div className="filterdDrugDIv">
                                {fileterdDrug.map((el, i) =>
                                    <div className='filterdDrugRow' onClick={() => selectDrugHandler(el.brand_name)} key={el.id}>{el.brand_name}
                                        <div className="drugDetailsHints">
                                            <span><b>{el.brand_name}</b></span><br />
                                            <span>{el.generic_name}</span><br />
                                            <span>Format : {el.format}</span><br />
                                            <span>{el.manufacturer}</span><br />
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        {showDoseDuration ? <>
                            <div className="flex">
                                <div className="add_drug_form_group">
                                    <input type="text" className="drug_field banglaFont" onChange={handleDoseInput}
                                        placeholder='Dose' value={dosesSearchKeyWord} required />
                                    <div className="filterdDrugDIv banglaFont">
                                        {filterdDoses.map((el, i) =>
                                            <div className='filterdDrugRow' onClick={() => selectDoseHandler(el.dose)} key={el.id}>{el.dose}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="add_drug_form_group">
                                    <input type="text" className="drug_field banglaFont" onChange={handleDurationInput}
                                        placeholder='Duration' value={durationSearchKeyWord} />
                                    <div className="filterdDrugDIv banglaFont">
                                        {filterdDuration.map((el, i) =>
                                            <div className='filterdDrugRow' onClick={() => selectDurationHandler(el.duration)} key={el.id}>{el.duration}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="add_drug_form_group">
                                    <input type="text" className="drug_field banglaFont" onChange={handleRulesInput}
                                        placeholder='Rule' value={rulesSearchKeyWord} />
                                    <div className="filterdDrugDIv banglaFont">
                                        {filterdRules.map((el, i) =>
                                            <div className='filterdDrugRow' onClick={() => selectRulesHandler(el.rule)} key={el.id}>{el.rule}</div>
                                        )}
                                    </div>
                                </div>
                                <input type="checkbox" id="titration_cb" onChange={() => setshowDose2(!showDose2)} />
                                <label htmlFor="titration_cb">Titration</label>
                            </div>
                            {showDose2 ?
                                <div className='flex'>
                                    <div className="add_drug_form_group">
                                        <input type="text" className="drug_field banglaFont" onChange={handleDose2Input}
                                            placeholder='Dose 2' value={doses2SearchKeyWord} />
                                        <div className="filterdDrugDIv banglaFont">
                                            {filterdDoses2.map((el, i) =>
                                                <div className='filterdDrugRow' onClick={() => selectDoseHandler2(el.dose)} key={el.id}>{el.dose}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="add_drug_form_group">
                                        <input type="text" className="drug_field banglaFont" onChange={handleDuration2Input}
                                            placeholder='Duration 2' value={duration2SearchKeyWord} />
                                        <div className="filterdDrugDIv banglaFont">
                                            {filterdDuration2.map((el, i) =>
                                                <div className='filterdDrugRow' onClick={() => selectDuration2Handler(el.duration)} key={el.id}>{el.duration}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="add_drug_form_group">
                                        <input type="text" className="drug_field banglaFont" onChange={handleRules2Input}
                                            placeholder='Rule 2' value={rules2SearchKeyWord} />
                                        <div className="filterdDrugDIv banglaFont">
                                            {filterdRules2.map((el, i) =>
                                                <div className='filterdDrugRow' onClick={() => selectRules2Handler(el.rule)} key={el.id}>{el.rule}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                : <></>}

                        </> : <></>}
                    </div>
                    <div className="add_drug_form_right">
                        <input type="submit" className='add_drug_btn' value="Add" />
                    </div>
                </form>
            </div>
            <div className="addedDrugsDiv">
                <table className='drug_table'>
                    <tbody>
                        {addedDrugs.map((el, i) =>
                            <tr key={i} >
                                <td className='drugSerial' style={{ padding: '0.1rem 0.6rem 0 0' }}> <span>{i + 1}. &nbsp;</span> </td>
                                <td >
                                    <h4>{el.drug_name}</h4>
                                    <p className='banglaFont'>{el.dose}  </p>
                                    <p className='banglaFont'>{el.dose2 !== "" ? <span>তারপর </span> : ''}
                                        {el.dose2} </p>
                                </td>
                                <td className='banglaFont'>
                                    <br />
                                    {el.duration} <br />
                                    {el.duration2}
                                </td>
                                <td className='banglaFont'>
                                    <br />
                                    {el.rule} <br />
                                    {el.rule2}
                                </td>
                                <td className='drugSerial'>
                                    <button className='remove_btn' onClick={() => removeDrugHandler(i)}>&times;</button>
                                </td>

                            </tr>
                        )}
                    </tbody>
                </table>

                {props.advices.length > 0 ? <>
                    <div className='prescriotionPrintLeft_divs banglaFont adviceDiv'>
                        <h4><u>উপদেশঃ</u></h4>
                        <ul>
                            {
                                props.advices.map((el, i) =>
                                    <li key={i}>{el} ।
                                        <button onClick={() => props.removeAdviceHandler(i)} className='remove_btn'>&times;</button>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </> : <></>}
            </div>
        </div>
    )
}

export default PrescriptionDrugs