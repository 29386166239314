import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAppContext } from '../../contex/appContext';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import './doctorsManagementStyle.css'
import { FiEdit } from 'react-icons/fi'
import { AiFillDelete, AiOutlineSchedule, AiOutlineUser } from 'react-icons/ai'
import { Audio } from 'react-loader-spinner';
import Pagination from '../../services/Pagination';
import Modal from '../../components/modal/Modal'

const DoctorsList = () => {
    const { userData } = useAppContext();
    const navigate = useNavigate()
    const [doctorsList, setDoctorsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const base_url = baseUrl();
    const authToken = getToken();
    const [showWaitModal, setshowWaitModal] = useState(false)
    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 9;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedTests = doctorsList.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End


    const fetchDoctorsList = async () => {
        try {
            const res = await axios.get(`${base_url}/show_doctors_list`, {
                headers: {
                    Accept: 'applocation/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setLoading(false)
            if (res.data.status === 'success') {
                setDoctorsList(res.data.dbData)
            } else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }

        } catch (error) {
            console.log(error)
        }
    }
    const deleteDoctor = async (i) => {
        setshowWaitModal(true)
        try {
            const res = await axios.delete(`${base_url}/delete_doctor/${i}`, {
                headers: {
                    Accept: 'applocation/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            Swal.fire({
                text: res.data.message,
                icon: res.data.status
            });
        } catch (error) {
            setshowWaitModal(false)
            console.log(error.response)
        }
    }
    const handleDeleteClick = (id) => {

        Swal.fire({
            text: "Delete this doctor permanently..? You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "cadetblue",
            cancelButtonColor: "tomato",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDoctor(id)
            }
        });
    }

    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager' &&
            userData.user_type !== 'reception') {
            navigate('/')
        }
        fetchDoctorsList();
    }, [])
    return (
        <div className='mainWindow'>
            <div className="my_container_fulid">
                {showWaitModal ? <Modal data='Please wait..' title='wait' /> : <></>}
                <h2>Doctors List
                    {userData.user_type === 'manager' || userData.user_type === 'admin' ?
                        <Link to='/add_doctor' className="h2_left my_btn addBtn">Add New</Link> : <></>}
                </h2>

                {loading ? <Audio
                    height="30"
                    width="40"
                    radius="5"
                    color="cadetblue"
                    ariaLabel=""
                    wrapperStyle
                    wrapperClass
                /> : <>

                    {doctorsList.length < 1 ? <h1 className='failed'>No record found</h1> : <>
                        <div className="doctorsTilesWrapper">
                            {paginatedTests.map(elem =>
                                <div key={elem.id} className='doctorsTile'>
                                    <h3>{elem.doctor_name}</h3>
                                    <p>{elem.doctor_degree}</p>
                                    <p className='doctor_details'>{elem.doctor_details}</p>
                                    <hr style={{ margin: '4px 0' }} />
                                    <p style={{ 'color': 'rgb(25, 12, 10)' }} className='flexDiv'> <span>New fee : {elem.new_patient_fee}</span><span>Follow-up fee : {elem.f_up_patient_fee}</span></p>
                                    <hr style={{ margin: '4px 0' }} />
                                    <div className="tiles_btn_row">
                                        {userData.user_type === 'admin' || userData.user_type === 'manager' ? <>
                                            <Link to={`/edit_doctor/${elem.id}`} state={elem} > <FiEdit color='rgb(25, 58, 59)' size={20} /> </Link>
                                            <button className='no_border_btn' onClick={() => handleDeleteClick(elem.id)} > <AiFillDelete color='rgb(132, 51, 40)' size={20} /> </button>
                                        </> : <></>}
                                        {userData.user_type === 'admin' ? <>
                                            <Link className='no_border_btn' to={`/assign_doctor_as_user/${elem.id}/${elem.doctor_name}`} > <AiOutlineUser color={elem.user_id === null ? 'rgb(74, 66, 77)' : 'green'} size={20} /> </Link>
                                        </> : <></>}
                                        {userData.user_type === 'admin' || userData.user_type === 'manager' || userData.user_type === 'reception' ?
                                            <Link to={`/doctor_schedule/${elem.id}/${elem.doctor_name}`} className='no_border_btn'> <AiOutlineSchedule color='rgb(44, 86, 87)' size={20} /> </Link> : <></>
                                        }
                                    </div>
                                    <div className="doctor_schedule_div">

                                    </div>
                                </div>
                            )}
                        </div>
                        <Pagination
                            elementsPerPage={elementsPerPage}
                            totaElements={doctorsList.length}
                            pageNo={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </>}


                </>

                }

            </div>

        </div>
    )
}

export default DoctorsList