import React from 'react'
import './paginationStyle.css'

const Pagination = ({totaElements,elementsPerPage,pageNo,setCurrentPage}) => {
    let lastElementShow = pageNo * elementsPerPage;
    if(lastElementShow >= totaElements ){
        lastElementShow = totaElements
    }
    const pages = [];
    for(let i = 1; i <= Math.ceil(totaElements/elementsPerPage); i++){
        pages.push(i)
    }
  return (
    <div className='btn_div'>
         <p>Showing {pageNo * elementsPerPage - elementsPerPage + 1} to {lastElementShow} from {totaElements} records.</p>
        {pages.map((elem, index)=>{
            return(<button key={index} className={elem == pageNo ? 'active' : ''} onClick={()=>setCurrentPage(elem)}>{elem}</button>)
        })}       
    </div>
  )
}

export default Pagination