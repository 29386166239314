import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner';

const StoolRE = () => {
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const [waitMsg, setWaitMsg] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const [reporters, setreporters] = useState([])
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))

    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }

    const [formData, setFormData] = useState({

    });

    const fetchTestResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_stool_r_e_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            if (res.data.status === 'success') {
                setLoading(false)
                setFormData(res.data.db_data)
                if (res.data.db_data.reporter_id == null) {
                    if (res.data.reporters.filter(el => el.id == reporter).length < 1) {
                        setReporter('')
                    }
                } else {
                    setReporter(res.data.db_data.reporter_id)
                }
                if (res.data.db_data.reporter_2_id == null) {
                    if (res.data.reporters.filter(el => el.id == reporter2).length < 1) {
                        setReporter2('')
                    }
                } else {
                    setReporter2(res.data.db_data.reporter_2_id)
                }
                setreporters(res.data.reporters)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (reporter == '' || reporter === null) {
            autoAlert("Please select a reporter")
            return false
        }
        if (reporter == reporter2) {
            autoAlert("2 reporters can not be same..!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setWaitMsg('Please wait...')
        try {
            const res = await axios.post(`${base_url}/update_stool_r_e_result/${id}`,
                { ...formData, 'reporter_id': reporter, 'reporter_2_id': reporter2 }, {
                headers: {
                    Accept: 'application/json',
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,

                }
            })
            if (res.data.status === 'success') {
                navigate(-1)
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status,
                    timer: 800
                })
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
            }
        } catch (error) {
            console.log(error.response.data.message)
        }
    }
    useEffect(() => {
        fetchTestResult()
    }, [])

    const handleChecked = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked
        })
    }
    return (
        <div className='mainWindow'>
            <form className="urine_re_form">
                {loading ? <Audio color='cadetblue' height={30} /> : <>
                    <h2>Stool R/E for Id : {patient_id} <Link to={-1} className='h2_right_btn cancelBtn'>&times;</Link></h2>
                    <span><b><u>Physical:</u></b></span>
                    <div className="urineFormRow">
                        <label htmlFor="color">Color:</label>
                        <select name="color" id="color" className="urineInput"
                            value={formData.color} onChange={e => handleChange(e)}>
                            <option value="Brown">Brown</option>
                            <option value="Light Brown">Light Brown</option>
                            <option value="Black">Black</option>
                            <option value="Red">Red</option>
                            <option value="Maroon">Maroon</option>
                            <option value="Yellow">Yellow</option>
                            <option value="Green">Green</option>
                            <option value="Clay">Clay</option>
                            <option value="White">White</option>
                            <option value="Pale Yellow">Pale Yellow</option>
                        </select>
                        <label htmlFor="consistency">Consistency:</label>
                        <select name="consistency" id="consistency" className="urineInput"
                            value={formData.consistency} onChange={e => handleChange(e)}>
                            <option value="Semi solid">Semi solid</option>
                            <option value="Hard">Hard</option>
                            <option value="Liquid">Liquid</option>
                        </select>
                        <label htmlFor="mucus">Mucus:</label>
                        <select name="mucus" id="mucus" className="urineInput"
                            value={formData.mucus} onChange={e => handleChange(e)}>
                            <option value="Absent">Absent</option>
                            <option value="Present">Present</option>
                        </select>
                        <label htmlFor="frank_blood">Blood:</label>
                        <select name="frank_blood" id="frank_blood" className="urineInput"
                            value={formData.frank_blood} onChange={e => handleChange(e)}>
                            <option value="Absent">Absent</option>
                            <option value="Present">Present</option>
                        </select>
                        <label htmlFor="parasites">Parasites:</label>
                        <select name="parasites" id="parasites" className="urineInput"
                            value={formData.parasites} onChange={e => handleChange(e)}>
                            <option value="Absent">Absent</option>
                            <option value="Present">Present</option>
                        </select>

                    </div>

                    <hr />
                    <span><b><u>Chemical:</u></b></span>
                    <div className="urineFormRow">
                        <label htmlFor="reaction">Reaction:</label>
                        <select name="reaction" id="reaction" className="urineInput"
                            value={formData.reaction} onChange={e => handleChange(e)}>
                            <option value="Acidic">Acidic</option>
                            <option value="Alkaline">Alkaline</option>
                        </select>
                        <label htmlFor="reducing_substance">R/S:</label>
                        <select name="reducing_substance" id="reducing_substance" className="urineInput urineSmInput"
                            value={formData.reducing_substance} onChange={e => handleChange(e)}>
                            <option value="Absent">Absent</option>
                            <option value="Trace">Trace</option>
                            <option value="(+)">(+)</option>
                            <option value="(++)">(++)</option>
                            <option value="(+++)">(+++)</option>
                            <option value="(++++)">(++++)</option>
                        </select>
                        <label htmlFor="occult_blood">Occult blood:</label>
                        <select name="occult_blood" id="occult_blood" className="urineInput"
                            value={formData.occult_blood} onChange={e => handleChange(e)}>
                            <option value="Present">Present</option>
                            <option value="Absent">Absent</option>
                            <option value="Not done">Not done</option>
                        </select>

                    </div>

                    <hr />
                    <div className="urineFormRow">
                        <span><b>Microscopic:</b></span>
                        <label htmlFor="epithelial_cell">Epi Cell:</label>
                        <input type="text" className="urineInput" name='epithelial_cell' id="epithelial_cell"
                            onChange={e => handleChange(e)} value={formData.epithelial_cell} />
                        <label htmlFor="pus_cell">Pus Cell:</label>
                        <input type="text" className="urineInput" name='pus_cell' id="pus_cell"
                            onChange={e => handleChange(e)} value={formData.pus_cell} />
                        <label htmlFor="rbc">RBC:</label>
                        <input type="text" className="urineInput" name='rbc' id="rbc"
                            onChange={e => handleChange(e)} value={formData.rbc} />
                        <label htmlFor="macrophage">Macrophage:</label>
                        <input type="text" className="urineInput" name='macrophage' id="macrophage"
                            onChange={e => handleChange(e)} value={formData.macrophage} />

                    </div>
                    <div className="urineFormRow">
                        <label htmlFor="starch">Starch:</label>
                        <input type="text" className="urineInput" name='starch' id="starch"
                            onChange={e => handleChange(e)} value={formData.starch} />
                        <label htmlFor="vegitable_cell">Vegi. cell:</label>
                        <input type="text" className="urineInput" name='vegitable_cell' id="vegitable_cell"
                            onChange={e => handleChange(e)} value={formData.vegitable_cell} />
                        <label htmlFor="undigested_material">Undigested:</label>
                        <input type="text" className="urineInput" name='undigested_material' id="undigested_material"
                            onChange={e => handleChange(e)} value={formData.undigested_material} />
                        <label htmlFor="yeast_cells">Yeast:</label>
                        <input type="text" className="urineInput" name='yeast_cells' id="yeast_cells"
                            onChange={e => handleChange(e)} value={formData.yeast_cells} />
                    </div>

                    &nbsp; <input type="checkbox" name="trophozoite_cb" id="trophozoite_cb"
                        checked={formData.trophozoite_cb != 0} onChange={e => handleChecked(e)} />
                    <label htmlFor="trophozoite_cb"> <u>Trophozoites:</u>  </label>

                    {formData.trophozoite_cb != 0 ? (
                        <div className="urineFormRow cast_crystal_row">
                            <label htmlFor="trophozoite_1">Tropho-1:</label>
                            <input type="text" className="urineInput urineLgInput" name='trophozoite_1' id="trophozoite_1"
                                onChange={e => handleChange(e)} value={formData.trophozoite_1} placeholder='Substance name : value' />
                            <label htmlFor="trophozoite_2">Tropho-2:</label>
                            <input type="text" className="urineInput urineLgInput" name='trophozoite_2' id="trophozoite_2"
                                onChange={e => handleChange(e)} value={formData.trophozoite_2} placeholder='Substance name : value' />
                            <label htmlFor="trophozoite_3">Tropho-3:</label>
                            <input type="text" className="urineInput urineLgInput" name='trophozoite_3' id="trophozoite_3"
                                onChange={e => handleChange(e)} value={formData.trophozoite_3} placeholder='Substance name : value' />
                        </div>
                    ) : (<div></div>)}

                    &nbsp; <input type="checkbox" name="cyst_cb" id="cyst_cb"
                        checked={formData.cyst_cb != 0} onChange={e => handleChecked(e)} />
                    <label htmlFor="cyst_cb"> <u>Cysts:</u>  </label>

                    {formData.cyst_cb != 0 ? (
                        <div className="urineFormRow cast_crystal_row">
                            <label htmlFor="cyst_1">Cyst-1:</label>
                            <input type="text" className="urineInput urineLgInput" name='cyst_1' id="cyst_1"
                                onChange={e => handleChange(e)} value={formData.cyst_1} placeholder='Substance name : value' />
                            <label htmlFor="cyst_2">Cyst-2:</label>
                            <input type="text" className="urineInput urineLgInput" name='cyst_2' id="cyst_2"
                                onChange={e => handleChange(e)} value={formData.cyst_2} placeholder='Substance name : value' />
                            <label htmlFor="cyst_3">Cyst-3:</label>
                            <input type="text" className="urineInput urineLgInput" name='cyst_3' id="cyst_3"
                                onChange={e => handleChange(e)} value={formData.cyst_3} placeholder='Substance name : value' />
                        </div>
                    ) : (<div></div>)}

                    &nbsp; <input type="checkbox" name="ova_cb" id="ova_cb"
                        checked={formData.ova_cb != 0} onChange={e => handleChecked(e)} />
                    <label htmlFor="ova_cb"> <u>Ova:</u>  </label>

                    {formData.ova_cb != 0 ? (
                        <div className="urineFormRow cast_crystal_row">
                            <label htmlFor="ova_1">Ova-1:</label>
                            <input type="text" className="urineInput urineLgInput" name='ova_1' id="ova_1"
                                onChange={e => handleChange(e)} value={formData.ova_1} placeholder='Substance name : value' />
                            <label htmlFor="ova_2">Ova-2:</label>
                            <input type="text" className="urineInput urineLgInput" name='ova_2' id="ova_2"
                                onChange={e => handleChange(e)} value={formData.ova_2} placeholder='Substance name : value' />
                            <label htmlFor="ova_3">Ova-3:</label>
                            <input type="text" className="urineInput urineLgInput" name='ova_3' id="ova_3"
                                onChange={e => handleChange(e)} value={formData.ova_3} placeholder='Substance name : value' />
                        </div>
                    ) : (<div></div>)}

                    &nbsp; <input type="checkbox" name="micro_others_cb" id="micro_others_cb"
                        checked={formData.micro_others_cb != 0} onChange={e => handleChecked(e)} />
                    <label htmlFor="micro_others_cb"> <u>Others:</u>  </label>

                    {formData.micro_others_cb != 0 ? (
                        <div className="urineFormRow cast_crystal_row">
                            <label htmlFor="micro_others_1">Other-1:</label>
                            <input type="text" className="urineInput urineLgInput" name='micro_others_1' id="micro_others_1"
                                onChange={e => handleChange(e)} value={formData.micro_others_1} placeholder='Substance name : value' />
                            <label htmlFor="micro_others_2">Other-2:</label>
                            <input type="text" className="urineInput urineLgInput" name='micro_others_2' id="micro_others_2"
                                onChange={e => handleChange(e)} value={formData.micro_others_2} placeholder='Substance name : value' />
                            <label htmlFor="micro_others_3">Other-3:</label>
                            <input type="text" className="urineInput urineLgInput" name='micro_others_3' id="micro_others_3"
                                onChange={e => handleChange(e)} value={formData.micro_others_3} placeholder='Substance name : value' />
                        </div>
                    ) : (<div></div>)}
                    <div>
                        <label htmlFor="reporter_id" >Reporter:</label><br />
                        <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                            value={reporter} className="urineInput urineLgInput" >
                            <option value="">Select reporter</option>
                            {reporters.map((el) =>
                                <option key={el.id} value={el.id}>{el.reporter_name}</option>
                            )}
                        </select>
                        <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label>
                        <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                            value={reporter2} className="urineInput urineLgInput" >
                            <option value="">Select reporter 2</option>
                            {reporters.map((el) =>
                                <option key={el.id} value={el.id}>{el.reporter_name}</option>
                            )}
                        </select>
                    </div>
                    <br />
                    <input type="submit" value="Update" className='my_btn submit_btn' onClick={e => handleSubmit(e)} />
                    {waitMsg !== '' ? <span className="waitMsgDiv"> {waitMsg}</span> : <></>}
                </>}

            </form>
        </div>
    )
}

export default StoolRE