import React, { useEffect, useState } from 'react'
import './dueCollectionStyle.css'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore'
import axios from 'axios'
import { GetDate } from '../../services/Date'
import Swal from 'sweetalert2'
import { DateFormat } from '../../services/DateFormat'
import Modal from '../modal/Modal'
import { Audio } from 'react-loader-spinner'
import { useAppContext } from '../../contex/appContext'
import { useNavigate } from 'react-router-dom'

const DueCollection = () => {
  const navigate = useNavigate();
  const {userData} = useAppContext();
  const today = GetDate();
  const base_url = baseUrl();
  const authToken = getToken();
  const [showCollectionForm, setshowCollectionForm] = useState(false);
  const [showwaitmodal, setshowwaitmodal] = useState(false)
  const [loading, setloading] = useState(false)
  const [formdata, setformdata] = useState({
    'patient_id': '',
    'previous_due': 0,
    'collection_amount': 0,
    'due_after_collection': 0,
    'collection_date': today
  })
  const [collectionHistory, setcollectionHistory] = useState([])
  const handleChange = e => {
    setformdata({
      ...formdata,
      [e.target.name]: e.target.value
    })
  }
  const handleCollectionChange = e => {
    setformdata({
      ...formdata,
      'collection_amount': e.target.value,
      'due_after_collection': formdata.previous_due - parseInt(e.target.value)
    })
  }

  const handleSearch = async e => {
    e.preventDefault();
    setloading(true)
    try {
      const res = await axios.get(`${base_url}/get_transaction_by_patient_id_no/${formdata.patient_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      if (res.data.status === 'success') {
        setshowCollectionForm(true)
        if (res.data.collection_history.length < 1) {
          setformdata({
            ...formdata,
            'previous_due': parseInt(res.data.transaction_data.due_amount),
            'collection_amount' : 0,
            'due_after_collection' : ''
          })
        } else {
          setformdata({
            ...formdata,            
            'collection_amount' : 0,
            'due_after_collection' : '',
            'previous_due': parseInt(res.data.collection_history[res.data.collection_history.length - 1].due_after_collection)
          })
          setcollectionHistory(res.data.collection_history)
        }
      } else {
        Swal.fire({
          icon: res.data.status,
          text: res.data.message
        })
      }
      setloading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const saveChangeRequest = async () => {
    setshowwaitmodal(true)
    try {
      const res = await axios.post(`${base_url}/due_collection`, formdata, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      setshowwaitmodal(false)
      setshowCollectionForm(false)
      Swal.fire({
        icon: res.data.status,
        text: res.data.message
      })
    } catch (error) {
      setshowwaitmodal(false)
      console.log(error)
    }
  }
  const handleSubmit = e => {
    e.preventDefault();
    if (parseInt(formdata.collection_amount) === 0 || formdata.collection_amount == null) {
      Swal.fire({
        icon: 'info',
        text: 'Collection amount can not be zero..!'
      })
      return false
    }
    if (parseInt(formdata.collection_amount) > parseInt(formdata.previous_due)) {
      Swal.fire({
        icon: 'info',
        text: 'Collection amount can not be more than previous due..!'
      })
      return false
    }
    Swal.fire({
      icon: 'question',
      text: 'Save changes.? This action can not be reversed..!',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        saveChangeRequest();
      }
    })
  }
  useEffect(()=>{
    if(userData.user_type !== 'admin' && userData.user_type !== 'manager'
    && userData.user_type !== 'reception'){
      navigate('/')
    }
  },[])
  return (
    <div className='mainWindow'>
      {showwaitmodal ? <Modal title='wait' data='Saving data..' /> : <></>}
      <div className="due_collection_container">
        <h2>
          Due Collection
        </h2>
        {!showCollectionForm ?
          <form className='dueForm' onSubmit={handleSearch}>
            <input type="text" value={formdata.patient_id} required name='patient_id' className="my_field" placeholder='Patinet Id' onChange={handleChange} />
            <input type="submit" value='Search' className="my_btn submit_btn" />
            {loading ? <Audio height={30} color='cadetblue' /> : <></>}
          </form> : <>
            <h3 className='due_collection_heading'> <u>Patient Id No.: {formdata.patient_id}</u></h3>
            <div className="flexDiv">
              <form className="collectionForm" onSubmit={handleSubmit}>
                <label htmlFor="collection_date">Collection date:</label>
                <input type="date" value={formdata.collection_date} onChange={handleChange}
                  className="my_field" name='collection_date' id="collection_date" /> <br />
                <label htmlFor="previous_due">Previous due:</label>
                <input type="number" value={formdata.previous_due} readOnly
                  className="my_field" name='previous_due' id="previous_due" /> <br />
                <label htmlFor="collection_amount">Collection amount:</label>
                <input type="number" value={formdata.collection_amount} onChange={handleCollectionChange}
                  className="my_field" name='collection_amount' id="collection_amount" /> <br />
                <label htmlFor="due_after_collection">Due after collection:</label>
                <input type="number" value={formdata.due_after_collection} readOnly onChange={handleCollectionChange}
                  className="my_field" name='due_after_collection' id="due_after_collection" /> <br />
                <button type='button' className="my_btn blueBtn" onClick={() => setshowCollectionForm(false)}>Cancel</button>
                <input type="submit" value="Save" className="my_btn submit_btn" />
              </form>
              <div className='collection_history'>
                <h4><u>Collection history:</u></h4>
                {collectionHistory.length > 0 ? <>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Prev. due</th>
                        <th>Collection amount</th>
                        <th>Due after collection</th>
                      </tr>
                    </thead>
                    <tbody>
                      {collectionHistory.map((el, i) =>
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{DateFormat(el.collection_date)}</td>
                          <td>{el.previous_due}</td>
                          <td>{el.collection_amount}</td>
                          <td>{el.due_after_collection}</td>
                        </tr>)}
                    </tbody>
                  </table>

                </> : <> <h4 className="failed">No due collection history found for this patient.</h4></>}
              </div>


            </div>
          </>}
      </div>
    </div>
  )
}

export default DueCollection