import React, { useEffect } from 'react'
import { BiErrorCircle } from "react-icons/bi";
import { logOutReq } from '../auth/logout';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contex/appContext';

const InactiveAdmin = () => {
    const {userData, loading} = useAppContext();
    const navigate = useNavigate();
    const handleLogOut = () => {
      logOutReq();
      localStorage.removeItem('rahmatLabSolutionAuthToken')
      navigate('/login')
    }
    useEffect(()=> {
      if(userData.admin_status == 1){
        navigate('/')
      }
    },[loading])
  return (
    <div className='inactivePage'>
        <BiErrorCircle size={40} />Your admin is not active
      <button className="logoutBtn" onClick={handleLogOut}>Log out</button>
    </div>
  )
}

export default InactiveAdmin