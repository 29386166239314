import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../contex/appContext'
import { Link, useNavigate } from 'react-router-dom';
import { Audio } from 'react-loader-spinner';
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import { AiFillEdit, AiOutlineDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';

const SingleDoctorAssistant = () => {
    const { userData } = useAppContext();
    const navigate = useNavigate();
    const [fetchedUsers, setfetchedUsers] = useState([]);
    const [loading, setloading] = useState(true);
    const base_url = baseUrl();
    const authToken = getToken();
    const [showWaitModal, setshowWaitModal] = useState(false)
    const fetchUsers = async (e) => {
        try {
            const res = await axios.get(`${base_url}/get_single_doctor_assistants`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            setfetchedUsers(res.data)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    const deleteRequest = async (id) => {
        setshowWaitModal(true)
        try {
            const res = await axios.get(`${base_url}/delete_single_doctor_assistant/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })

            setshowWaitModal(false)
            fetchUsers()
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
        }
    }

    const deleteAssistant = (n, id) => {
        Swal.fire({
            icon: 'question',
            text: `Delete ${n} ..?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: 'tomato'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRequest(id)
            }
        })
    }

    useEffect(() => {
        if (userData.user_type !== 'single_doctor') {
            navigate('/')
        } else {
            fetchUsers()
        }
    }, [])
    return (
        <div className='my_container'>
            {showWaitModal ? <Modal title='wait' data='Please wait...' /> : <></>}
            <h2> <Link to={'/doctor/add_assistant'} className='h2_left addBtn my_btn' >Add new</Link>
                Assistant Management</h2>
            {loading ? <><Audio color='cadetblue' height={30} /></> : <>
                {fetchedUsers.length < 1 ?
                    <h3 className="notFound">No assistant found</h3> :
                    <>
                        <div className='patient_tiles_wrapper'>
                            {fetchedUsers.map(el =>
                                <div key={el.id} className='masterAdminTiles'>
                                    <u><h4>{el.name}</h4></u>
                                    <p><b>Email : </b>{el.email}</p>
                                    <p><b>Phone : </b>{el.phone}</p>
                                    <hr />
                                    <div className="flexDiv" style={{ 'justifyContent': 'center' }}>
                                        <Link className='no_border_btn'> <AiFillEdit color='grey' size={18} /></Link>
                                        <button className='no_border_btn' onClick={() => deleteAssistant(el.name, el.id)}><AiOutlineDelete color='red' size={18} /></button>
                                    </div>
                                </div>)}
                        </div>
                    </>}
            </>}
        </div>
    )
}

export default SingleDoctorAssistant