import React, { useEffect, useState } from 'react'
import { DateFormat } from '../../services/DateFormat'
import { AiOutlineDelete } from 'react-icons/ai'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import Swal from 'sweetalert2'
import Modal from '../modal/Modal'
import { GetDate } from '../../services/Date'
import { useNavigate } from 'react-router-dom'
const SingleDoctorWatingPatients = () => {
    const navigate = useNavigate()
    const today = GetDate()
    const base_url = baseUrl();
    const authToken = getToken();
    const [loading, setloading] = useState(true)
    const [showWaitModal, setshowWaitModal] = useState(false)
    const [addedPatients, setAddedPatients] = useState([])
    const [showChamberSelectorForm, setshowChamberSelectorForm] = useState(false)
    const [uniqueArray, setuniqueArray] = useState([])
    const [selectedChamber, setselectedChamber] = useState('')
    const [showChamberSelectionBtn, setshowChamberSelectionBtn] = useState(false)
    const fetchAddedPatients = async () => {
        try {
            const res = await axios.get(`${base_url}/show_single_doctor_schedule_patients/${today}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            let indexes = res.data.db_data.map((el) => {
                return el.chamber_name
            })
            let uniqueArray = indexes.filter((value, index, self) => {
                return self.indexOf(value) === index;
            });
            if (uniqueArray.length > 1) {
                setuniqueArray(uniqueArray);
                setshowChamberSelectionBtn(true)
            } else {
                localStorage.setItem('rahmat_web_dev_s_doctor_chamber', uniqueArray[0])
            }
            if(localStorage.getItem('rahmat_web_dev_s_doctor_chamber')){
                let filterdPatients = res.data.db_data.filter((el) => { 
                    return el.chamber_name === localStorage.getItem('rahmat_web_dev_s_doctor_chamber')                   
                })
                setAddedPatients(filterdPatients)
            }else{
                setAddedPatients(res.data.db_data)
            }
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    const handleClick = async (i) => {
        setshowWaitModal(true)
        try {
            const res = await axios.get(`${base_url}/update_single_doctor_patient_status/${i.id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            if (res.data.status === 'success') {
                navigate('/doctor', { state: { waitingPatientData: i } })
            }
            else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }
        } catch (error) {
            setshowWaitModal(false)
            console.log(error.response)
        }
    }
    const handleChange = e => {
        setselectedChamber(e.target.value)
    }
    const handleSubmit = e => {
        e.preventDefault();
        localStorage.setItem('rahmat_web_dev_s_doctor_chamber', selectedChamber)
        fetchAddedPatients()
        setshowChamberSelectorForm(false)
    }
    useEffect(() => {
        fetchAddedPatients();
        // setInterval(() => {
        //     fetchAddedPatients();
        // }, 40000)
    }, [])
    return (
        <div className='prescriptionMain'>
            {showChamberSelectorForm ?
                <div className="chamberSelectionDiv">
                    <form action="" className="chamberSelectionForm my_card" style={{ 'width': '22rem' , 'textAlign': 'center'}}>
                        <h2>Select chamber
                            <button onClick={() => setshowChamberSelectorForm(false)} className="h2_right_btn my_btn cancelBtn">&times;</button>
                        </h2>
                        <select required className="my_field" onChange={handleChange} value={selectedChamber}>
                            <option value="">Select a chamber</option>
                            {uniqueArray.map((el, i) => <option value={el} key={1}>{el}</option>)}
                        </select>
                        <input onClick={handleSubmit} type="submit" value="Select" className="my_btn submit_btn" />
                    </form>
                </div>
                : <></>}
            {showWaitModal ? <Modal title='wait' data='Prescription is loading..' /> : <></>}
            <div className="my_container">
                <h2>Waiting patients
                    {showChamberSelectionBtn ? 
                    <button onClick={() => setshowChamberSelectorForm(true)} className="h2_right_btn my_btn blueBtn">Select chamber</button>
                    : <></> }
                </h2>
                <div className="waitingPatientsDiv">
                    {loading ? <Audio height="30"
                        width="40"
                        radius="5"
                        color="cadetblue"
                        ariaLabel=""
                        wrapperStyle
                        wrapperClass /> : <>


                        {addedPatients.length > 0 ?
                            < table style={{ fontSize: 'small' }} >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name (Age) - Sex</th>
                                        <th>Phone</th>
                                        <th>Address</th>
                                        <th>Chamber</th>
                                        <th>Visit</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addedPatients.map((el, i) =>
                                        <tr key={el.id} className={el.patient_status == 1 ? 'success' : 'failed'}>
                                            <td>{i + 1}</td>
                                            <td>{`${el.patient_name} (${el.patient_age} ${el.patient_age_unit}) - ${el.patient_sex}`}</td>
                                            <td>{el.patient_phone}</td>
                                            <td>{el.patient_address}</td>
                                            <td>{el.chamber_name}</td>
                                            <td style={{ 'textTransform': 'capitalize' }}>{el.patient_type}</td>
                                            <td>
                                                <button className='no_border_btn submit_btn' onClick={() => handleClick(el)}> Enter</button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            : <h3 className='failed'>No waiting patient found</h3>}
                    </>}
                </div>
            </div>
        </div>
    )
}

export default SingleDoctorWatingPatients