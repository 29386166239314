import React, { useEffect, useState } from 'react'
import { DateFormat } from '../../services/DateFormat';
import { GetDate } from '../../services/Date'
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import { Audio } from 'react-loader-spinner';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ShowAssistantSchedules = () => {
    const today = GetDate();
    const base_url = baseUrl();
    const authToken = getToken();
    const [loading, setloading] = useState(true);
    const [addedSchedules, setaddedSchedules] = useState([])
    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/show_single_doctor_added_schedules`, {
                headers: {
                    Accept: 'app;ication/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            console.log(res.data)
            setaddedSchedules(res.data.dbData)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=> {
        fetchData();
    },[])
    return (
        <div className='mainWindow'>
            <div className="my_card">
                <h2>Added schedules</h2>
                {loading ? <Audio height={30} color='cadetblue'/> : <>
                <h3>Added schedules</h3>
                        {addedSchedules.length < 1 ? <h1 className='failed'>No schedule found</h1> :
                            <table className='smallFont'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Chamber</th>
                                        <th>Fee</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {addedSchedules.map((elem, i) =>
                                        <tr key={elem.id}>
                                            <td>{i + 1}</td>
                                            <td>{DateFormat(elem.schedule_date)}</td>
                                            <td>{elem.chamber_name}</td>
                                            <td>{elem.new_patient_fee} / {elem.follow_up_fee}</td>
                                            <td className='action_td'>
                                                <Link to='/single_doctor_assistant/add_new' state={{date:elem.schedule_date}}>Add</Link>
                                                <Link to={`/single_doctor_assistant/schedule_patients/${elem.schedule_date}`}>View list</Link>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        }</>}
            </div>
        </div>
    )
}

export default ShowAssistantSchedules