import axios from 'axios';
import { createContext, useContext, useEffect,  useReducer } from 'react';
import { getToken } from '../localStorageManage/localStore';
import { baseUrl } from '../services/baseUrl';

const IncludedServicesContext = createContext();

//use of reducer
const initialState = {
    loading: true,
    includedServicesData: [],
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'LOADED':
            return {
                ...state,
                loading: false,
                includedServicesData: action.payload.data,
            }
        default:
            return {
                state
            }
    }
}

//use of reducer

const IncludedServicesProvider = ({ children }) => {
    const [value, dispatch] = useReducer(reducer, initialState);

    const authToken = getToken();
    const base_url = baseUrl();

   
    const fetchServicesdData = async () => {
        try {
            const res = await axios.get(`${base_url}/show_included_services`, {headers:{
                'Accept': 'application/json',
                "Content-Type" : 'application/json',
                'Authorization' : `Bearer ${authToken}`
            }})
            dispatch({
                type: "LOADED", payload: res
            })
           
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (authToken) {
            fetchServicesdData()
        }
    },[])

    return <IncludedServicesContext.Provider value={ value }>{children}</IncludedServicesContext.Provider>


}
const useServicesContext = () => {
   return useContext(IncludedServicesContext)
}
export { IncludedServicesContext, IncludedServicesProvider, useServicesContext }


