import React, { useEffect, useState } from 'react'
import './masterAdminStyles.css'
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import { Audio } from 'react-loader-spinner';
import { DateFormat } from '../../services/DateFormat';
import { GetDate } from '../../services/Date';
import Pagination from '../../services/Pagination'
import Modal from '../modal/Modal'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillEdit, AiFillNotification, AiOutlinePhone, AiOutlineSearch } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import { useAppContext } from '../../contex/appContext';
import { logOutReq } from '../auth/logout';

const ClientsAdmins = () => {
    const appContext = useAppContext();
    const { userData } = appContext;
    const navigate = useNavigate();
    const today = GetDate()
    const base_url = baseUrl();
    const authToken = getToken();
    const [fetchedData, setfetchedData] = useState([]);
    const [datatoShow, setdatatoShow] = useState([])
    const [loading, setloading] = useState(true);
    const [showWaitmodalForNotification, setshowWaitmodalForNotification] = useState(false);
    const [showWaitmodal, setshowWaitmodal] = useState(false);
    const status = ['Disabled', 'Active'];
    const logout = () => {
        logOutReq();
        localStorage.removeItem('rahmatLabSolutionAuthToken')
        navigate('/login')
    }
    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 12;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedData = datatoShow.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End
    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/get_client_admins`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setfetchedData(res.data)
            setdatatoShow(res.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error.response)
        }
    }
    const changeUserStatus = async (s, id) => {
        setshowWaitmodal(true)
        try {
            const res = await axios.put(`${base_url}/update_admin_status/${id}`, {
                'data': s
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitmodal(false)
            fetchData()
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowWaitmodal(false)
            console.log(error.response)
        }

    }
    const handleUserStatusChange = (s, id, name) => {
        Swal.fire({
            icon: 'warning',
            text: `Change user status of ${name} ?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: 'tomato',
            confirmButtonText: 'Change'
        }).then((result) => {
            if (result.isConfirmed) {
                changeUserStatus(s, id)
            }
        })
    }
    const sendValidityNotification = async (email, name, validity) => {
        setshowWaitmodalForNotification(true)
        try {
            const res = await axios.post(`${base_url}/mail_from_master_admin`, {
                'name': name,
                'email': email,
                'subject': 'Subscription validity notification',
                'message': `Your subscription is valid upto ${DateFormat(validity)}. Please make your 
                payment to enjoy uninterrupted service.`
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitmodalForNotification(false)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowWaitmodalForNotification(false)
            console.log(error)
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'info',
                    text: error.response.data.message
                })
            }
        }

    }
    const handleSearchData = e => {
        let filtered_data = fetchedData.filter(el =>
            el.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            el.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
            el.phone.includes(e.target.value)
        )
        setdatatoShow(filtered_data)
    }

    const handleValidityNofiClick = (email, name, validity) => {
        Swal.fire({
            icon: 'warning',
            text: 'Send validity notification to ' + name + ' .?',
            showConfirmButton: true,
            showCancelButton: true
        }).then((result) => {
            if (result.isConfirmed === true) {
                sendValidityNotification(email, name, validity)
            }
        })
    }
    useEffect(() => {
        if (!appContext.loading) {
            if (userData.user_type !== 'master_admin' && userData.user_type !== 'master_admin_assist'
                && userData.user_type !== 'prescription_entry') {
                logout()
            }
        }
        fetchData()
    }, [])
    return (
        <div className='master_admin_wrapper'>
            {showWaitmodalForNotification ? <Modal data='Sending notification mail..' title='wait' /> : <></>}
            {showWaitmodal ? <Modal data='Changing user status' title='wait' /> : <></>}
            <input type="text" style={{ width: '100%' }} className='search_field' placeholder='Search name, phone or email..' onChange={handleSearchData} />

            <h2>
                <Link className='h2_left addBtn my_btn' to='/master_admin/add_client_admin'>Add New</Link>
                Client admins</h2>
            {loading ? <Audio height={30} color='cadetblue' /> : <div>
                <div className="patient_tiles_wrapper">
                    {paginatedData.map(elem =>
                        <div className="masterAdminTiles" key={elem.id}>
                            <h4>{elem.name}</h4>
                            <p>Email : {elem.email}</p>
                            <p className="flexDiv">
                                <span>Phone : {elem.phone}</span>
                                <span>Fee: {elem.subscription_fee}/-</span>
                            </p>
                            <p className="flexDiv">
                                <span className={elem.user_status == 1 ? 'success' : 'failed'}>
                                    {status[elem.user_status]}
                                </span>
                                <span>Doctor : {elem.active_doctors}/{elem.max_doctor_user}</span>
                                <span className={today <= elem.validity ? 'success' : 'failed'}>
                                    {DateFormat(elem.validity)}
                                </span>
                            </p>
                            <hr />
                            <div className="tiles_btn_row">
                               { console.log(userData.user_type)}
                                {userData.user_type === 'master_admin' ? <>
                                    {elem.user_status == 1 ?
                                        <button className='failed my_btn' onClick={() => handleUserStatusChange(0, elem.id, elem.name)}>Disable</button>
                                        : <button className='success my_btn' onClick={() => handleUserStatusChange(1, elem.id, elem.name)}>Activate</button>}
                                    <Link className='my_btn' to={`/master_admin/edit_client_admin/${elem.id}`} state={elem} ><AiFillEdit color='black' size={15} /></Link>
                                </> : <></>}
                                <Link className='my_btn' to={`/master_admin/send_custom_mail/${elem.email}/${elem.name}`} ><AiOutlineMail color='black' size={15} /></Link>
                                <button className="no_border_btn" onClick={() => handleValidityNofiClick(elem.email, elem.name, elem.validity)}>
                                    <AiFillNotification />
                                </button>
                                <a href={`tel:${elem.phone}`}> <AiOutlinePhone color='blue' size={18} /> </a>
                            </div>

                        </div>
                    )}
                </div>
                <Pagination
                    elementsPerPage={elementsPerPage}
                    totaElements={fetchedData.length}
                    pageNo={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>}

        </div>
    )
}

export default ClientsAdmins