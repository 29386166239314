import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { useDepartmentContext } from '../../contex/departmentsContex';
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import Modal from '../modal/Modal';
import Swal from 'sweetalert2';
import { useAppContext } from '../../contex/appContext';

const EditIncludedService = () => {
    const {userData, loading} = useAppContext()
    const { state } = useLocation();
    const navigate = useNavigate()
    const auth_token = getToken()
    const [showWaitModal, setshowWaitModal] = useState(false)
    const base_url = baseUrl();
    const { id } = useParams();
    const { departmentsData } = useDepartmentContext();
    const [formData, setFormData] = useState({
        'test_name': '',
        'test_full_name': '',
        'test_unit': '',
        'normal_range': '',
        'department': 1,
        'test_type': '',
        'sample_type': '',
        'input_type': '',
        'test_fee': '',
        'refferer_fee': '',
    });
    const [fieldErrors, setFieldErrors] = useState({
        'test_name': '',
        'test_full_name': '',
        'department': '',
        'test_type': '',
        'sample_type': '',
        'input_type': '',
        'test_fee': '',
        'refferer_fee': '',
    });
    const [resAlert, setResAlert] = useState('');
    const [resData, setResData] = useState('');


    useEffect(() => {
        if (state) {
            setFormData(state)
        }
    }, [])
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setResAlert('')
        setResData('')
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/update_service/${id}`, formData, {
                headers: {
                    'Accept': 'application/json',
                    "Authorization": `Bearer ${auth_token}`
                }
            })
            setshowWaitModal(false)
            if (res.data.status === 'success') {
                navigate('/included_services')
            } else {
                setResData(res.data.message)
                setResAlert(res.data.status)
            }
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })

        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if (error.response.status === 422) {
                setResAlert('failed')
                setFieldErrors({
                    ...fieldErrors,
                    'test_name': error.response.data.errors.test_name,
                    'test_full_name': error.response.data.errors.test_full_name,
                    'department': error.response.data.errors.department,
                    'test_type': error.response.data.errors.test_type,
                    'sample_type': error.response.data.errors.sample_type,
                    'input_type': error.response.data.errors.input_type,
                    'test_fee': error.response.data.errors.test_fee,
                    'refferer_fee': error.response.data.errors.refferer_fee,
                })
            }
        }

    }
    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager' &&
            userData.user_type !== 'reception') {
            navigate('/')
        }
    }, [loading])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Updating service...' title='wait' /> : <></>}
            <div className="my_container my_container_fluid">
                <h2>Edit Service
                    <Link to={-1} className="h2_right_btn cancelBtn">&times;</Link>
                </h2>

                <form className="includeServiceForm" onSubmit={e => handleSubmit(e)}>
                    <div className="my_form_row">
                        <div className="my_input_group">
                            <label htmlFor="test_name"> Test name:</label>
                            <input type="text" className="my_field" id="test_name" name='test_name'
                                onChange={e => handleChange(e)} value={formData.test_name} />
                            {fieldErrors.test_name !== '' && fieldErrors.test_name ?
                                <div className={resAlert}>{fieldErrors.test_name}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="test_full_name"> Test full name:</label>
                            <input type="text" className="my_field" id="test_full_name" name='test_full_name'
                                onChange={e => handleChange(e)} value={formData.test_full_name} />
                            {fieldErrors.test_full_name !== '' && fieldErrors.test_full_name ?
                                <div className={resAlert}>{fieldErrors.test_full_name}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="test_unit"> Test unit:</label>
                            <input type="text" className="my_field" id="test_unit" name='test_unit'
                                onChange={e => handleChange(e)} value={formData.test_unit} />
                            {fieldErrors.test_unit !== '' && fieldErrors.test_unit ?
                                <div className={resAlert}>{fieldErrors.test_unit}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="normal_range"> Noraml range:</label>
                            <input type="text" className="my_field" id="normal_range" name='normal_range'
                                onChange={e => handleChange(e)} value={formData.normal_range} />
                            {fieldErrors.test_unit !== '' && fieldErrors.normal_range ?
                                <div className={resAlert}>{fieldErrors.normal_range}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="department"> Department:</label>
                            <select className="my_field" id="department" name='department'
                                onChange={e => handleChange(e)} value={formData.department} >
                                {departmentsData.map(el =>
                                    <option value={el.department_id} key={el.department_id}>{el.department_name}</option>)}
                            </select>
                            {fieldErrors.department !== '' && fieldErrors.department ?
                                <div className={resAlert}>{fieldErrors.department}</div> : ''}
                        </div>
                        {formData.department == 1 ?

                            <div className="my_input_group">
                                <label htmlFor="test_type"> Test type:</label>
                                <select className="my_field" id="test_type" name='test_type'
                                    onChange={e => handleChange(e)} value={formData.test_type} >
                                    <option value="">Select test type</option>
                                    <option value="haematology">Haematology</option>
                                    <option value="biochemistry">Biochemistry</option>
                                    <option value="serology">Serology</option>
                                    <option value="microbiology">Microbiology</option>
                                    <option value="urine">Urine</option>
                                    <option value="stool">Stool</option>
                                    <option value="semen">Semen</option>
                                    <option value="others">Others</option>
                                </select>
                                {fieldErrors.test_type !== '' && fieldErrors.test_type ?
                                    <div className={resAlert}>{fieldErrors.test_type}</div> : ''}
                            </div> : ''
                        }
                        {formData.department == 1 ?
                            <div className="my_input_group">
                                <label htmlFor="sample_type"> Sample type:</label>
                                <select className="my_field" id="sample_type" name='sample_type'
                                    onChange={e => handleChange(e)} value={formData.sample_type} >
                                    <option value="">Select sample type</option>
                                    <option value="Blood">Blood</option>
                                    <option value="Urine">Urine</option>
                                    <option value="Stool">Stool</option>
                                    <option value="T/S">T/S</option>
                                    <option value="CSF">CSF</option>
                                    <option value="Pus">Pus</option>
                                    <option value="P/S">P/S</option>
                                    <option value="Sputum">Sputum</option>
                                    <option value="Skin scrap">Skin scrap</option>
                                    <option value="Others">Others</option>
                                </select>
                                {fieldErrors.sample_type !== '' && fieldErrors.sample_type ?
                                    <div className={resAlert}>{fieldErrors.sample_type}</div> : ''}
                            </div> : ''
                        }
                        <div className="my_input_group">
                            <label htmlFor="input_type"> Input type:</label>
                            <select className="my_field" id="input_type" name='input_type'
                                onChange={e => handleChange(e)} value={formData.input_type} >
                                <option value="">Select input type</option>
                                <option value="field">Field</option>
                                <option value="select">Select (Negative/Positive)</option>
                                <option value="cbc">CBC</option>
                                <option value="lipid_profile">Lipid Profile</option>
                                <option value="bg_rh">BG & Rh</option>
                                <option value="widal">Widal</option>
                                <option value="cross_match">Cross Match</option>
                                <option value="urine_re">Urine R/E</option>
                                <option value="stool_re">Stool R/E</option>
                                <option value="electrolytes">Electrolytes</option>
                                <option value="c_s">C/S</option>
                                <option value="semen_analysis">Semen analysis</option>
                                <option value="mt">MT</option>
                                <option value="usg_wa_female">USG WA(Female)</option>
                                <option value="usg_wa_male">USG WA(Male)</option>
                                <option value="usg_pp">USG PP</option>
                                <option value="usg_la_female">USG LA(Female)</option>
                                <option value="usg_la_male">USG LA(Male)</option>
                                <option value="usg_ua">USG Upper Abdomen</option>
                                <option value="cxr_p_a">CXR P/A</option>
                                <option value="cxr_ap">CXR A/P</option>
                                <option value="cxr_bv">CXR B/V</option>
                                <option value="kub_xray">KUB X-Ray</option>
                                <option value="ls_spine_xray">L/S Spine X-Ray</option>
                                <option value="others">Others</option>
                            </select>
                            {fieldErrors.input_type !== '' && fieldErrors.input_type ?
                                <div className={resAlert}>{fieldErrors.input_type}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="test_fee"> Test Fee:</label>
                            <input type="number" className="my_field" id="test_fee" name='test_fee'
                                onChange={e => handleChange(e)} value={formData.test_fee} />
                            {fieldErrors.test_fee !== '' && fieldErrors.test_fee ?
                                <div className={resAlert}>{fieldErrors.test_fee}</div> : ''}
                        </div>
                        <div className="my_input_group">
                            <label htmlFor="refferer_fee"> Refferer Fee:</label>
                            <input type="number" className="my_field" id="refferer_fee" name='refferer_fee'
                                onChange={e => handleChange(e)} value={formData.refferer_fee} />
                            {fieldErrors.refferer_fee !== '' && fieldErrors.refferer_fee ?
                                <div className={resAlert}>{fieldErrors.refferer_fee}</div> : ''}
                        </div>

                    </div>
                    <br />
                    <span className={resAlert}>{resData}</span>
                    <div className="my_form_row">

                        <div>
                            <input type="submit" value="Update" className="my_btn submit_btn" />
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default EditIncludedService