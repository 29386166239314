import React, { useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import Modal from '../../modal/Modal';

const Widal = () => {
    const navigate = useNavigate()
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setloading] = useState(true);
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [reporters, setreporters] = useState([])
    const [formData, setformData] = useState({

    })
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))

    const fetchResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_widal_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            setformData(res.data.db_data)
            if (res.data.db_data.reporter_id == null) {
                if (res.data.reporters.filter(el => el.id == reporter).length < 1) {
                    setReporter('')
                }
            } else {
                setReporter(res.data.db_data.reporter_id)
            }
            if (res.data.db_data.reporter_2_id == null) {
                if (res.data.reporters.filter(el => el.id == reporter2).length < 1) {
                    setReporter2('')
                }
            } else {
                setReporter2(res.data.db_data.reporter_2_id)
            }
            setreporters(res.data.reporters)

        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }
    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (formData.to == '' || formData.to === null || !formData.to) {
            autoAlert("Please select a 'TO' value ")
            return false
        }
        if (formData.th == '' || formData.th === null || !formData.th) {
            autoAlert("Please select a 'TH' value ")
            return false
        }
        if (formData.ah == '' || formData.ah === null || !formData.ah) {
            autoAlert("Please select a 'AH' value ")
            return false
        }
        if (formData.bh == '' || formData.bh === null || !formData.bh) {
            autoAlert("Please select a 'BH' value ")
            return false
        }
        if (reporter == '' || reporter === null) {
            autoAlert("Please select a reporter")
            return false
        }
        if (reporter == reporter2) {
            autoAlert("2 reporters can not be same..!")
            return false
        }
        setshowWaitModal(true)
        localStorage.setItem("rahmat_lab_solutions_reporter_id", reporter)
        localStorage.setItem("rahmat_lab_solutions_reporter_2_id", reporter2)
        try {
            const res = await axios.post(`${base_url}/add_widal_result/${id}`,
                { ...formData, 'reporter_id': reporter, 'reporter_2_id': reporter2 }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false);
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate(-1)
            }
        } catch (error) {
            setshowWaitModal(false);
            console.log(error)
        }
    }


    useEffect(() => {
        fetchResult();
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal title='wait' data='Updating widal result' /> : <></>}
            <div className="resultFormWrapper">
                <h2>Widal test for patient id : {patient_id}
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                <form className="single_field_form" onSubmit={handleSubmit}>
                    <div className="flexDiv">
                        <div>
                            <label htmlFor="to">TO:</label>
                            <select type="text" className="my_field" id="to" name='to' onChange={handleChange}
                                value={formData.to}>
                                <option value="">Select</option>
                                <option value="1:40">1:40</option>
                                <option value="1:80">1:80</option>
                                <option value="1:160">1:160</option>
                                <option value="1:320">1:320</option>
                                <option value="1:640">1:640</option>
                                <option value="1:1280">1:1280</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="th">TH:</label>
                            <select type="text" className="my_field" id="th" name='th' onChange={handleChange}
                                value={formData.th} >
                                <option value="">Select</option>
                                <option value="1:40">1:40</option>
                                <option value="1:80">1:80</option>
                                <option value="1:160">1:160</option>
                                <option value="1:320">1:320</option>
                                <option value="1:640">1:640</option>
                                <option value="1:1280">1:1280</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="ah">AH:</label>
                            <select type="text" className="my_field" id="ah" name='ah' onChange={handleChange}
                                value={formData.ah} >
                                <option value="">Select</option>
                                <option value="1:40">1:40</option>
                                <option value="1:80">1:80</option>
                                <option value="1:160">1:160</option>
                                <option value="1:320">1:320</option>
                                <option value="1:640">1:640</option>
                                <option value="1:1280">1:1280</option>
                            </select>
                        </div>
                    </div>
                    <div className="flexDiv">
                        <div>
                            <label htmlFor="bh">BH:</label>
                            <select type="text" className="my_field" id="bh" name='bh' onChange={handleChange}
                                value={formData.bh} >
                                <option value="">Select</option>
                                <option value="1:40">1:40</option>
                                <option value="1:80">1:80</option>
                                <option value="1:160">1:160</option>
                                <option value="1:320">1:320</option>
                                <option value="1:640">1:640</option>
                                <option value="1:1280">1:1280</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="ao">AO:</label>
                            <select type="text" className="my_field" id="ao" name='ao' onChange={handleChange}
                                value={formData.ao} >
                                <option value="">N/D</option>
                                <option value="1:40">1:40</option>
                                <option value="1:80">1:80</option>
                                <option value="1:160">1:160</option>
                                <option value="1:320">1:320</option>
                                <option value="1:640">1:640</option>
                                <option value="1:1280">1:1280</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="bo">BO:</label>
                            <select type="text" className="my_field" id="bo" name='bo' onChange={handleChange}
                                value={formData.bo} >
                                <option value="">N/D</option>
                                <option value="1:40">1:40</option>
                                <option value="1:80">1:80</option>
                                <option value="1:160">1:160</option>
                                <option value="1:320">1:320</option>
                                <option value="1:640">1:640</option>
                                <option value="1:1280">1:1280</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="reporter_id" >Reporter:</label>
                        <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                            value={reporter} className="my_field" >
                            <option value="">Select reporter</option>
                            {reporters.map((el) =>
                                <option key={el.id} value={el.id}>{el.reporter_name}</option>
                            )}
                        </select> <br />
                        <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label>
                        <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                            value={reporter2} className="my_field" >
                            <option value="">Select reporter 2</option>
                            {reporters.map((el) =>
                                <option key={el.id} value={el.id}>{el.reporter_name}</option>
                            )}
                        </select>
                    </div>
                    <br />
                    <input type="submit" style={{ width: '100%' }} className="my_btn submit_btn" value='Update' />
                </form>
            </div>
        </div>
    )
}

export default Widal