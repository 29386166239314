import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import PatientForm from './PatientForm';
import axios from 'axios';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';

const EditSingleDoctorPatient = () => {
    const { serial_id } = useParams()
    const navigate = useNavigate()
    const { state } = useLocation();
    const [showWaitModal, setshowWaitModal] = useState(false)
    const base_url = baseUrl();
    const authToken = getToken();
    const [formData, setFormData] = useState({})
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/update_single_doctor_serial_patient`,
                { ...formData, 'serial_id': serial_id }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            if (res.data.status === 'success') {
                navigate(-1)
            }
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'info',
                    text: error.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        setFormData(state)
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Updating patient' title='wait' /> : <></>}
           
                <PatientForm
                    formData={formData}
                    handleChange={handleChange}
                    title='Edit'
                    btnValue='Update'
                    handleSubmit={handleSubmit}
                    date={state.date}
                />
          

        </div>
    )
}

export default EditSingleDoctorPatient