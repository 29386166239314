import React from 'react'
import { baseUrl } from '../../services/baseUrl';
import { GetDate } from '../../services/Date'
import axios from 'axios';
import { useEffect, useState } from 'react';
import PatientTiles from './PatientTiles';
import Pagination from '../../services/Pagination';
import { Link, useLocation } from 'react-router-dom';
import { getToken } from '../../localStorageManage/localStore';
import { useAppContext } from '../../contex/appContext';
import { Audio } from 'react-loader-spinner'
import Modal from '../modal/Modal';
import { AiOutlineUser } from 'react-icons/ai';

const TodaysPatientsAll = () => {
    const { pathname } = useLocation()
    const { userData } = useAppContext()
    const { user_type } = userData
    const auth_token = getToken()
    const today = GetDate();
    const base_url = baseUrl();
    const [fetched_patients, setFetchedPatients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [unseenPatient, setunseenPatient] = useState(0);

    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 20;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedTests = fetched_patients.slice(firstElementIndex, lastElementIndex)
    ///Pagination---------------------End

    const fetchPatients = async () => {
        try {
            const res = await axios.get(`${base_url}/show_patients/${today}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${auth_token}`
                }
            });
            if (userData.user_type === 'pathology') {
                let filteredPatientForUser = res.data.filter((elem) => {
                    return elem.total_pathology_services > 0
                })
                setFetchedPatients(filteredPatientForUser)
                let useen_patients = res.data.filter((elem) => {
                    return elem.total_pathology_services > 0 && elem.patient_seen_by_pathology == 0
                })
                setunseenPatient(useen_patients.length)
            } else if (userData.user_type === 'x_ray') {
                let filteredPatientForUser = res.data.filter((elem) => {
                    return elem.total_x_ray_services > 0
                })
                setFetchedPatients(filteredPatientForUser)
                let useen_patients = res.data.filter((elem) => {
                    return elem.total_x_ray_services > 0 && elem.patient_seen_by_x_ray == 0
                })
                setunseenPatient(useen_patients.length)
            } else if (userData.user_type === 'usg') {
                let filteredPatientForUser = res.data.filter((elem) => {
                    return elem.total_usg_services > 0
                })
                setFetchedPatients(filteredPatientForUser)
                let useen_patients = res.data.filter((elem) => {
                    return elem.total_usg_services > 0 && elem.patient_seen_by_usg == 0
                })
                setunseenPatient(useen_patients.length)
            }else if (userData.user_type === 'ecg') {
                let filteredPatientForUser = res.data.filter((elem) => {
                    return elem.total_ecg_services > 0
                })
                setFetchedPatients(filteredPatientForUser)
                let useen_patients = res.data.filter((elem) => {
                    return elem.total_ecg_services > 0 && elem.patient_seen_by_ecg == 0
                })
                setunseenPatient(useen_patients.length)
            } else {
                setFetchedPatients(res.data)
            }
            setLoading(false)


        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        fetchPatients();
        if (user_type === 'pathology' || user_type === 'usg' ||
            user_type === 'ecg'|| user_type === 'x_ray') {
            setInterval(() => {
                fetchPatients();
            }, 20000)
        }
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Please wait' title='wait' /> : ''}
            <h2>
                {user_type === 'admin' || user_type === 'manager' ?
                    <>
                        <Link className="h2_left my_btn addBtn" to='/new_patient'>Add new</Link>
                    </>
                    : user_type === 'pathology' || user_type === 'x_ray'
                     || user_type === 'usg' ||user_type === 'ecg' ?
                        <>
                            <div className="h2_right_btn" style={{ right: '4rem' }}>
                                <AiOutlineUser color='black' />
                                <sup className={unseenPatient > 0 ? "failed" : 0}>{unseenPatient}</sup>
                            </div>
                        </> : <></>}
                Today's Patients
            </h2>
            {loading ? <Audio
                height="30"
                width="40"
                radius="5"
                color="cadetblue"
                ariaLabel=""
                wrapperStyle
                wrapperClass
            /> : <>
                {fetched_patients < 1 ? <p className='notFound'>No patient found</p> :
                    <>
                        <div className="patient_tiles_wrapper">

                            {paginatedTests.map((elem) => {
                                return <PatientTiles key={elem.id} data={elem}
                                    fetchPatients={fetchPatients} redir_form={pathname}
                                    setshowWaitModal={setshowWaitModal} />
                            })}
                        </div>
                        <Pagination
                            elementsPerPage={elementsPerPage}
                            totaElements={fetched_patients.length}
                            pageNo={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </>
                }
            </>}
        </div>
    )
}

export default TodaysPatientsAll