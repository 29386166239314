import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import Pagination from '../../services/Pagination';
import { FiEdit } from 'react-icons/fi'
import { Audio } from 'react-loader-spinner';
import { AiFillDelete } from 'react-icons/ai';
import Modal from '../modal/Modal';
import Swal from 'sweetalert2';
import { useAppContext } from '../../contex/appContext';

const ShowIncludedServices = () => {
    const navigate = useNavigate()
    const { userData } = useAppContext()
    const authToken = getToken()
    const base_url = baseUrl()
    const [loading, setLoading] = useState(true)
    const [includedServices, setIncludedServices] = useState([]);
    const [showWaitModal, setshowWaitModal] = useState(false)
    ///Pagination---------------------Start
    const [currentPage, setCurrentPage] = useState(1);
    const elementsPerPage = 15;
    const lastElementIndex = currentPage * elementsPerPage;
    const firstElementIndex = lastElementIndex - elementsPerPage;
    const paginatedTests = includedServices.slice(firstElementIndex, lastElementIndex)
    const deptArray = ['', 'Pathology', 'X-ray', 'ECG', 'USG', 'CT Scan']
    ///Pagination---------------------End
    const fetchIncludedServices = async () => {
        try {
            const res = await axios.get(`${base_url}/show_included_services`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Accept': 'application/json'
                }
            })
            setIncludedServices(res.data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const deleteService = async (id) => {
        setshowWaitModal(true)
        try {
            const res = await axios.delete(`${base_url}/delete_service/${id}`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                })
            setshowWaitModal(false)
            fetchIncludedServices()
            Swal.fire({
                text: res.data.message,
                icon: res.data.status
            })

        } catch (error) {
            setshowWaitModal(false)
            console.log(error.response)
        }
    }
    const handleDelete = (i) => {
        Swal.fire({
            icon: 'warning',
            'text': 'Do you want to delete this service.?',
            'showDenyButton': true,
            'showCancelButton': true,
            'confirmButtonText': 'Yes',
            'denyButtonText': "No",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteService(i);
            }
        })
    }

    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager' &&
            userData.user_type !== 'reception') {
            navigate('/')
        }
        fetchIncludedServices()
    }, [])

    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Deleting service' title='wait' /> : <></>}
            <div className="my_container my_container_fulid">
                <h2>Included services
                    <Link to={'/include_service'} className='h2_left my_btn addBtn'>Include new</Link>
                </h2>
                {loading ? < Audio color='cadetblue' height={40} /> :
                    <>
                        {paginatedTests.length > 0 ? <>
                            <table className='font_sm'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Test name</th>
                                        <th>Test full name</th>
                                        <th>Department</th>
                                        <th>Test type</th>
                                        <th>Test unit</th>
                                        <th>Normal range</th>
                                        <th>Input type</th>
                                        <th>Sample type</th>

                                        {userData.user_type === 'admin' || userData.user_type === 'manager' ?
                                            <>
                                                <th>Test fee</th>
                                                <th>Refferer fee</th>
                                                <th>Action</th>
                                            </> : <></>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedTests.map((elem, index) =>
                                        <tr key={elem.id}>
                                            <td>{index + 1}</td>
                                            <td>{elem.test_name}</td>
                                            <td>{elem.test_full_name}</td>
                                            <td>{deptArray[elem.department]}</td>
                                            <td>{elem.test_type}</td>
                                            <td>{elem.test_unit}</td>
                                            <td>{elem.normal_range}</td>
                                            <td className='capitalText'>{elem.input_type}</td>
                                            <td>{elem.sample_type}</td>

                                            {userData.user_type === 'admin' || userData.user_type === 'manager' ?
                                                <>
                                                    <td>{elem.test_fee}</td>
                                                    <td>{elem.refferer_fee}</td>
                                                    <td>
                                                        <Link to={`/edit_included_service/${elem.id}`} state={elem}><FiEdit color='gray' /></Link>
                                                        <button className="no_border_btn" onClick={() => handleDelete(elem.id)} ><AiFillDelete size={15} color='red' /></button>
                                                    </td>
                                                </> : <></>}
                                        </tr>)}
                                </tbody>

                            </table>
                            <Pagination
                                elementsPerPage={elementsPerPage}
                                totaElements={includedServices.length}
                                pageNo={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </> : <h3 className='failed'>No data found</h3>}
                    </>

                }

            </div>
        </div>
    )
}

export default ShowIncludedServices