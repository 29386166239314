import React, { useEffect, useState } from 'react'
import './searchPatients.css'
import { BsCalendarDay } from 'react-icons/bs'
import { AiFillGift, AiOutlineSearch } from 'react-icons/ai'
import axios from 'axios'
import { baseUrl } from '../../services/baseUrl'
import { getToken } from '../../localStorageManage/localStore'
import Swal from 'sweetalert2';
import PatientTiles from './PatientTiles'
import { useLocation, Link } from 'react-router-dom'
import { Audio } from 'react-loader-spinner'
import { useAppContext } from '../../contex/appContext'
import Pagination from '../../services/Pagination'
import Modal from '../modal/Modal'

const SearchPatients = () => {
  const { userData } = useAppContext()
  const { user_type } = userData
  const { pathname, state } = useLocation()
  const base_url = baseUrl();
  const authToken = getToken();
  const [showPhoneSearchForm, setshowPhoneSearchForm] = useState(false);
  const [showDateSearchForm, setshowDateSearchForm] = useState(false);
  const [searchKeyWord, setsearchKeyWord] = useState('');
  const [searchDate, setsearchDate] = useState('')
  const [searhResult, setsearhResult] = useState([]);
  const [showResults, setshowResults] = useState(false);
  const [loading, setloading] = useState(false)
  const [showWaitModal, setshowWaitModal] = useState(false)

  ///Pagination---------------------Start
  const [currentPage, setCurrentPage] = useState(1);
  const elementsPerPage = 12;
  const lastElementIndex = currentPage * elementsPerPage;
  const firstElementIndex = lastElementIndex - elementsPerPage;
  const paginatedTests = searhResult.slice(firstElementIndex, lastElementIndex)
  ///Pagination---------------------End

  const handleSearchBtnClick = () => {
    setshowPhoneSearchForm(!showPhoneSearchForm)
    setshowDateSearchForm(false)
  }
  const handleDateBtnClick = () => {
    setshowPhoneSearchForm(false)
    setshowDateSearchForm(!showDateSearchForm)
  }

  const searchPatients = async (data) => { // Search phone or name 
    setloading(true)
    setshowResults(true)
    try {
      const res = await axios.post(`${base_url}/search_patient_name_or_phone`, {
        data: data
      },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        }
      )
      setloading(false)
      if (res.data.status === 'success') {
        if (userData.user_type === 'pathology') {
          let filteredPatientForUser = res.data.search_result.filter((elem) => {
            return elem.total_pathology_services > 0
          })
          setsearhResult(filteredPatientForUser)
        } else if (userData.user_type === 'x-ray') {
          let filteredPatientForUser = res.data.search_result.filter((elem) => {
            return elem.total_x_ray_services > 0
          })
          setsearhResult(filteredPatientForUser)
        } else if (userData.user_type === 'usg') {
          let filteredPatientForUser = res.data.search_result.filter((elem) => {
            return elem.total_usg_services > 0
          })
          setsearhResult(filteredPatientForUser)
        } else {
          setsearhResult(res.data.search_result)
        }
      } else {
        Swal.fire({
          icon: res.data.status,
          text: res.data.message
        })
      }
    } catch (error) {
      setloading(false)
      console.log(error.response)
    }
  }


  const handlePhoneChange = (e) => {
    setsearchKeyWord(e.target.value)
    if (e.target.value !== '') {
      searchPatients(e.target.value)
    } else {
      setshowResults(false)
    }
  }

  const handleDateChange = (e) => {
    setsearchKeyWord(e.target.value)
    setsearchDate(e.target.value)
    if (e.target.value !== '') {
      searchPatients(e.target.value)
    } else {
      setshowResults(false)
    }
  }

  useEffect(() => {
    if (state !== null) {
      if (state.searchKey !== null) {
        setsearchKeyWord(state.searchKey)
        searchPatients(state.searchKey)
      }
    }
  }, [])
  return (
    <div className='mainWindow searchpatientMain'>
      {showWaitModal ? <Modal data='Please wait' title='wait' /> : ''}
      <div className="searchpatientLeft">
        <div className="buttonAll" style={{ marginBottom: '0.6rem' }}>
          <button className="my_btn submit_btn" onClick={handleSearchBtnClick} ><AiOutlineSearch size={30} /></button>
          <button className="my_btn submit_btn" onClick={handleDateBtnClick} ><BsCalendarDay size={30} /></button>
        </div>
        {showPhoneSearchForm ?
          <div className="searchFormsAll">
            <input type="text" value={searchKeyWord} autoFocus className="my_field" placeholder='Name, phone no or id' onChange={handlePhoneChange} />
          </div>
          : <></>}
        {showDateSearchForm ?
          <div className="searchFormsAll">
            <input type="date" value={searchDate} className="my_field" onChange={handleDateChange} />
          </div>
          : <></>}
      </div>
      <div className="searchpatientRight">
        <h2>
          {loading ?
            <span className="h2_left" style={{ marginTop: '4px' }}>
              <Audio color='lime' height={18} />
            </span>
            : <></>}
          Search Results</h2>
        {!loading ? <>
          {showResults ? <>
            {searhResult.length > 0 ?
              <>
                <div className='patient_tiles_wrapper'>
                  {paginatedTests.map((elem) => {
                    return <PatientTiles key={elem.id} data={elem}
                      fetchPatients='' redir_form={pathname}
                      searchKey={searchKeyWord}
                      searchPatients={searchPatients}
                      setshowWaitModal={setshowWaitModal} />
                  })}
                </div>
                <Pagination
                  elementsPerPage={elementsPerPage}
                  totaElements={searhResult.length}
                  pageNo={currentPage}
                  setCurrentPage={setCurrentPage} />
              </> : <h3>No patient found</h3>}

          </> : <></>}
        </> : <></>}
      </div>
    </div>
  )
}

export default SearchPatients