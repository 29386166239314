import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getToken } from '../../../localStorageManage/localStore';
import { baseUrl } from '../../../services/baseUrl';
import Swal from 'sweetalert2';

const BgRh = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [testName, setTestName] = useState('');
    const authToken = getToken();
    const base_url = baseUrl()
    const { id, patient_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [waitMsg, setWaitMsg] = useState('');
    const [formData, setFormData] = useState({
        'test_performed_id': id,
        'bg': 'A',
        'rh': 'Positive'
    });
    const [reporters, setreporters] = useState([])
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))

    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }
    const fetchTestResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_single_test_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setLoading(false)
            if (res.data.status === 'success') {
                if(res.data.reporter_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter).length < 1){
                        setReporter('')
                    }
                }else{
                    setReporter(res.data.reporter_id)
                }
                if(res.data.reporter_2_id == null){
                    if(res.data.reporters.filter(el=>el.id == reporter2).length < 1){
                        setReporter2('')
                    }
                }else{
                    setReporter2(res.data.reporter_2_id)
                } 
                setreporters(res.data.reporters)
                if (res.data.test_result != 'Not inserted yet.!') {
                    setFormData({
                        'bg': res.data.test_result.slice(1, -10),
                        'rh': res.data.test_result.slice(-8)
                    })
                }
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
            }
        } catch (error) {
            console.log(error.response)
        }
    }
   

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(reporter == '' || reporter === null){
            autoAlert("Please select a reporter")
            return false
        }
        if(reporter ==  reporter2){
            autoAlert("2 reporters can not be same.!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setWaitMsg('Please wait...')
        try {
            const res = await axios.post(`${base_url}/add_single_test_result/${id}`, {
                'test_result': `'${formData.bg}' ${formData.rh}`,
                'test_performed_id': id,
                'reporter' : reporter,
                'reporter_2' : reporter2
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            if (res.data.status === 'success') {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status,
                    timer: 800
                })
                navigate(-1)
            } else {
                Swal.fire({
                    text: res.data.message,
                    icon: res.data.status
                })
            }
            console.log(res.data)
        } catch (error) {
            console.log(error.response.data.message)
        }
    }
    useEffect(() => {
        fetchTestResult()
        if (searchParams.get('test_name')) {
            setTestName(searchParams.get('test_name'))
        }
    }, [])
    return (
        <div className='mainWindow'>
            <div className="resultFormWrapper">
                <h2>{testName} for Id : {patient_id}
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                <form className="singleFieldForm" onSubmit={e => handleSubmit(e)}>
                    {loading ? 'Loading...' : (
                        <div>
                            <select className='my_field' type="text" onChange={(e) => handleChange(e)}
                                name='bg' autoFocus value={formData.bg} >
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="O">O</option>
                                <option value="AB">AB</option>
                            </select>
                            <select className='my_field' type="text" onChange={(e) => handleChange(e)}
                                name='rh' autoFocus value={formData.rh} >
                                <option value="Positive">Positive</option>
                                <option value="Negative">Negative</option>
                            </select><br/>
                            <label htmlFor="reporter_id" >Reporter:</label><br />
                            <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                                value={reporter} className="my_field" >
                                <option value="">Select reporter</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select> <br />
                            <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label><br />
                            <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                                value={reporter2} className="my_field" >
                                <option value="">Select reporter 2</option>
                                {reporters.map((el) =>
                                    <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                )}
                            </select>
                            <input type="submit" value="Enter" className='my_btn submit_btn' />
                            {waitMsg != '' ?
                                <div className="waitMsgDiv">
                                    {waitMsg}
                                </div> : <></>}
                        </div>
                    )}
                </form>
            </div>
        </div>
    )
}

export default BgRh