import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../services/baseUrl';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { getToken } from '../../localStorageManage/localStore';
import Modal from '../modal/Modal';
import Swal from 'sweetalert2';
import axios from 'axios';
import { DateFormat } from '../../services/DateFormat';
import { useAppContext } from '../../contex/appContext';
import './summaryStyle.css';

const SearchSummary = () => {
    const navigate = useNavigate()
    const { userData, loading } = useAppContext();
    const base_url = baseUrl();
    const { pathname } = useLocation()
    const authToken = getToken();
    const [fetchedData, setfetchedData] = useState({});
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [searched, setsearched] = useState(false)
    const [formData, setformData] = useState({
        'from_date': '',
        'to_date': ''
    });
    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        if (formData.from_date == '' || formData.to_date == '') {
            autoAlert('Please fill both field')
            return false;
        }
        if (formData.from_date > formData.to_date) {
            autoAlert('From date can not be greater than to date..!')
            return false;
        }
        setshowWaitModal(true)
        try {
            const res = await axios.get(`${base_url}/search_summary/${formData.from_date}/${formData.to_date}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    }
                })
            setsearched(true)
            setshowWaitModal(false)
            if (res.data.status === 'success') {
                setfetchedData(res.data)
            }
        } catch (error) {
            setshowWaitModal(false)
            console.log(error)
        }
    }
    useEffect(() => {
        if (userData.user_type !== 'admin' && userData.user_type !== 'manager' &&
            userData.user_type !== 'reception') {
            navigate('/')
        }
    }, [loading])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal data='Please wait' title='wait' /> : <></>}
            <div className="my_container">
                <form className="searchTransactionForm" onSubmit={handleSubmit}>
                    <label htmlFor="from_date">From:</label>
                    <input type="date" name="from_date" id="from_date" className="my_field"
                        value={formData.from_date} onChange={handleChange} />
                    <label htmlFor="to_date">To:</label>
                    <input type="date" name="to_date" id="to_date" className="my_field"
                        value={formData.to_date} onChange={handleChange} />
                    <input type="submit" value="Search" className="my_btn submit_btn" />
                </form>
                {searched ?
                    <div className="summary_wrapper">
                        <h2>Summary from {DateFormat(fetchedData.from_date)} to {DateFormat(fetchedData.to_date)}

                        </h2>
                        <div className="summary_wrapper_inner">
                            <div className="patient_summary">
                                <p className="summaryRow">
                                    <span>No. of patients : </span>
                                    <span>{fetchedData.no_of_patients}</span>
                                </p>
                            </div>
                            <div className="payment_summary">
                                <p className="summaryRow">
                                    <span>Net amount : </span>
                                    <span>{fetchedData.net_amount}/=</span>
                                </p>
                                <p className="summaryRow">
                                    <span>Paid amount : </span>
                                    <span>{fetchedData.paid_amount}/=</span>
                                </p>
                                <p className="summaryRow">
                                    <span>Due amount : </span>
                                    <span>{fetchedData.due_amount}/=</span>
                                </p>
                            </div>
                            <div className="rc_summary">
                                <p className="summaryRow">
                                    <span>Payable RC : </span>
                                    <span>{fetchedData.total_rc}/=</span>
                                </p>
                                <p className="summaryRow">
                                    <span>Paid RC : </span>
                                    <span>{fetchedData.rc_paid}/=</span>
                                </p>
                                <p className="summaryRow">
                                    <span>Due RC : </span>
                                    <span>{fetchedData.rc_due}/=</span>
                                </p>
                            </div>
                            <div className="expense_summary">
                                <p className="summaryRow">
                                    <span>Expense : </span>
                                    <span>{fetchedData.total_expense}/=</span>
                                </p>
                            </div>
                            <div className="due_collection_summary">
                                <p className="summaryRow">
                                    <span>Due collection : </span>
                                    <span>{fetchedData.total_due_collection}/=</span>
                                </p>
                            </div>
                            <div className="refund_summary">
                                <p className="summaryRow">
                                    <span>Refund : </span>
                                    <span>{fetchedData.total_refund}/=</span>
                                </p>
                            </div>
                            <div className="balance_div">
                                <p className="summaryRow">
                                    <span>Total collection: </span>
                                    <span>{parseInt(fetchedData.paid_amount) + parseInt(fetchedData.total_due_collection)}/=</span>
                                </p>
                                <p className="summaryRow">
                                    <span>Expense + Refund : </span>
                                    <span>{parseInt(fetchedData.total_expense) + parseInt(fetchedData.total_refund)}/=</span>
                                </p>
                                <p className="summaryRow">
                                    <span>Balance : </span>
                                    <span>{(parseInt(fetchedData.paid_amount) + parseInt(fetchedData.total_due_collection))- (parseInt(fetchedData.total_expense) + parseInt(fetchedData.total_refund))}/=</span>
                                </p>
                            </div>

                        </div>
                    </div>
                    : <><h1 style={{ 'textAlign': 'center' }}>Select date range and click 'Search' button.</h1></>}
            </div>

        </div >
    )
}

export default SearchSummary