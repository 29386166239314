import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { baseUrl } from '../../../services/baseUrl';
import { getToken } from '../../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Audio } from 'react-loader-spinner';
import Modal from '../../modal/Modal';

const Electrolytes = () => {
    const navigate = useNavigate()
    const base_url = baseUrl();
    const authToken = getToken();
    const { id, patient_id } = useParams();
    const [loading, setloading] = useState(true);
    const [reporters, setreporters] = useState([])
    const [showWaitModal, setshowWaitModal] = useState(false);
    const [formData, setformData] = useState({
        'sodium': '',
        'potassium': '',
        'chloride': '',
        'bicarbonate': '',
    })
    const [reporter, setReporter] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_id"))
    const [reporter2, setReporter2] = useState(localStorage.getItem("rahmat_lab_solutions_reporter_2_id"))
    const handleChange = e => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleReporter = e => {
        setReporter(e.target.value)
    }
    const handleReporter2 = e => {
        setReporter2(e.target.value)
    }
    const autoAlert = (text) => {
        Swal.fire({
            icon: 'info',
            text: text
        })
    }

    const fetchResult = async () => {
        try {
            const res = await axios.get(`${base_url}/get_electrolytes_result/${id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setloading(false)
            if (res.data.status === 'success') {
                setformData(res.data.db_data)
                if (res.data.db_data.reporter_id == null) {
                    if (res.data.reporters.filter(el => el.id == reporter).length < 1) {
                        setReporter('')
                    }
                } else {
                    setReporter(res.data.db_data.reporter_id)
                }
                if (res.data.db_data.reporter_2_id == null) {
                    if (res.data.reporters.filter(el => el.id == reporter2).length < 1) {
                        setReporter2('')
                    }
                } else {
                    setReporter2(res.data.db_data.reporter_2_id)
                }
                setreporters(res.data.reporters)
            }
            else {
                Swal.fire({
                    icon: res.data.status,
                    text: res.data.message
                })
            }

        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }
    const handleSubmit = async e => {
        e.preventDefault()
        if (reporter == '' || reporter === null) {
            autoAlert("Please select a reporter")
            return false
        }
        if (reporter == reporter2) {
            autoAlert("2 reporters can not be same..!")
            return false
        }
        localStorage.setItem('rahmat_lab_solutions_reporter_id', reporter)
        localStorage.setItem('rahmat_lab_solutions_reporter_2_id', reporter2)
        setshowWaitModal(true);
        try {
            const res = await axios.post(`${base_url}/update_electrolytes_result/${id}`,
                { ...formData, 'reporter_id': reporter, 'reporter_2_id': reporter2 }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false);
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
            if (res.data.status === 'success') {
                navigate(-1)
            }
        } catch (error) {
            setshowWaitModal(false);
            console.log(error)
        }
    }
    useEffect(() => {
        fetchResult()
    }, [])
    return (
        <div className='mainWindow'>
            {showWaitModal ? <Modal title='wait' data='Updating electrolytes result' /> : <></>}
            <div className="resultFormWrapper">
                <h2>
                    S. Electrolytes for Id : {patient_id}
                    <Link className='h2_right_btn cancelBtn' to={-1}>&times;</Link>
                </h2>
                {loading ? <Audio color='cadetblue' height='30' /> : <>
                    <form onSubmit={handleSubmit} style={{'width': '500px'}}>
                        <div className="flexDiv">
                            <div>
                                <label htmlFor="sodium" >Sodium:</label><br />
                                <input type="text" id='sodium' name='sodium' onChange={handleChange} value={formData.sodium} className="my_field" />
                            </div>
                            <div>
                                <label htmlFor="potassium" >Potassium:</label><br />
                                <input type="text" id='potassium' name='potassium' onChange={handleChange} value={formData.potassium} className="my_field" />
                            </div>
                        </div>
                        <div className="flexDiv">
                            <div>
                                <label htmlFor="chloride" >Chloride:</label><br />
                                <input type="text" id='chloride' name='chloride' onChange={handleChange} value={formData.chloride} className="my_field" />
                            </div>
                            <div>
                                <label htmlFor="bicarbonate" >Bicarbonate:</label><br />
                                <input type="text" id='bicarbonate' name='bicarbonate' onChange={handleChange} value={formData.bicarbonate} className="my_field" />
                            </div>
                        </div>
                        <div className='flexDiv'>
                            <div>
                                <label htmlFor="reporter_id" >Reporter:</label><br />
                                <select type="text" id='reporter_id' name='reporter_id' onChange={handleReporter}
                                    value={reporter} className="my_field" >
                                    <option value="">Select reporter</option>
                                    {reporters.map((el) =>
                                        <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                    )}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="reporter_2_id" >Reporter 2 (Optional):</label> <br />
                                <select type="text" id='reporter_2_id' name='reporter_2_id' onChange={handleReporter2}
                                    value={reporter2} className="my_field" >
                                    <option value="">Select reporter 2</option>
                                    {reporters.map((el) =>
                                        <option key={el.id} value={el.id}>{el.reporter_name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <br />
                        <input type="submit" value="Update" className='my_btn submit_btn' />
                    </form>
                </>}


            </div>
        </div>
    )
}

export default Electrolytes