import React, { useEffect, useState } from 'react'
import { useLocation, useParams, Link, useNavigate } from 'react-router-dom'
import { GetDate } from '../../services/Date';
import { baseUrl } from '../../services/baseUrl';
import { getToken } from '../../localStorageManage/localStore';
import axios from 'axios';
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';

const EditClientAdmin = () => {
    const navigate = useNavigate()
    const base_url = baseUrl();
    const authToken = getToken()
    const { state } = useLocation();
    const today = GetDate()
    const { id } = useParams()
    const [showWaitmodal, setshowWaitModal] = useState(false)

    const [formData, setformData] = useState({
        'name': '',
        'phone': '',
        'validity': today,
        'subscription_fee' : '',
        'max_doctor_user' : ''
    })
    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = async e => {
        e.preventDefault();
        setshowWaitModal(true)
        try {
            const res = await axios.post(`${base_url}/update_user/${id}`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            })
            setshowWaitModal(false)
            navigate(-1)
            Swal.fire({
                icon: res.data.status,
                text: res.data.message
            })
        } catch (error) {
            setshowWaitModal(false)
            console.log(error.response)
            if (error.response.status === 422) {
                Swal.fire({
                    icon: 'error',
                    text: error.response.data.message
                })
            }
        }
    }
    useEffect(() => {
        if (state) {
            setformData({
                ...formData,
                'name': state.name,
                'phone': state.phone,
                'validity': state.validity,
                'subscription_fee': state.subscription_fee,
                'max_doctor_user': state.max_doctor_user,
            })
        }
    }, [])
    return (
        <div>
            {showWaitmodal ? < Modal data='Updating user' title='wait' /> : <></>}
            <form className="master_admin_form" onSubmit={handleSubmit}>
                <h2>Edit Client
                    <Link className='h2_right_btn cancelBtn' to={-1} >&times;</Link>
                </h2>
                <label htmlFor='name' className='my_label'>Name</label>
                <input type="text" id='name' className='my_field font_sm' style={{ width: '16rem' }} name='name' onChange={e => handleChange(e)}
                    value={formData.name} />
                <br />
                <label htmlFor='phone' className='my_label'>Phone</label>
                <input type="text" id='phone' className='my_field' style={{ width: '16rem' }} name='phone' onChange={e => handleChange(e)}
                    value={formData.phone} />
                <br />
                <label htmlFor='subscription_fee' className='my_label'>Fee:</label>
                <input type="number" id='subscription_fee' className='my_field' style={{ width: '16rem' }} name='subscription_fee' onChange={e => handleChange(e)}
                    value={formData.subscription_fee} />
                <br />
                <label htmlFor='max_doctor_user' className='my_label'>Max doctors:</label>
                <input type="number" id='max_doctor_user' className='my_field' style={{ width: '16rem' }} name='max_doctor_user' onChange={e => handleChange(e)}
                    value={formData.max_doctor_user} />
                <br />
                <label htmlFor='validity' className='my_label'>Validity</label>
                <input type="date" className='my_field' id='validity' style={{ width: '16rem' }} name='validity' onChange={e => handleChange(e)}
                    value={formData.validity} />
                <br /><br />
                <input type="submit" value='Update' style={{ 'width': '16rem', 'padding': '0.5rem' }} className='my_btn submit_btn' />
                <br />
            </form>
        </div>
    )
}

export default EditClientAdmin