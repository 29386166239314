import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getToken } from '../../localStorageManage/localStore';
import { baseUrl } from '../../services/baseUrl';
import './paymentFormStyle.css';
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Modal from '../modal/Modal'
import { Audio } from 'react-loader-spinner';
import {GetDate} from '../../services/Date'
import Swal from 'sweetalert2';

const PaymentForm = ({ total_fee, patient_data,no_of_test }) => {
  const base_url = baseUrl();
  const authToken = getToken();
  const navigate = useNavigate()
  const [showDiscount, setShowDisCount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showWaitModal, setshowWaitModal] = useState(false)
  const today = GetDate();

  const [formData, setFormData] = useState({
    'patient_id': patient_data.id,
    'refferer_id': patient_data.patient_ref_by,
    'total_amount': total_fee,
    'discount_amount': 0,
    'payable_amount': total_fee,
    'paid_amount': 0,
    'due_amount': total_fee,
    'discount_by': 'both'
  });

  const fetchPrevData = async () => {
    try {
      const res = await axios.get(`${base_url}/show_patient_payment/${patient_data.id}`,
        {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        })
      setLoading(false)
      if (res.data.perv_data === true) {
        if (res.data.db_data.discount_amount > 0) {
          setShowDisCount(true);
          setDiscountAmount(res.data.db_data.discount_amount)
        }
        setPaidAmount(res.data.db_data.paid_amount)
        setFormData({
          ...formData,
          'discount_amount': res.data.db_data.discount_amount,
          'payable_amount': total_fee - res.data.db_data.discount_amount,
          'paid_amount': res.data.db_data.paid_amount,
          'due_amount': res.data.db_data.due_amount,
        })

      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDiscount = (e) => {
    if(patient_data.patient_date < today){
      Swal.fire({
        icon : 'info',
        text: 'Please go to "Due collection" or "Refund" for patients older than today..'
      })
    }else{
      setDiscountAmount(e.target.value)
      setFormData({
        ...formData,
        'discount_amount': e.target.value,
        'payable_amount': total_fee - e.target.value,
        'due_amount': total_fee - e.target.value - paidAmount
      })
    }   
  }
  const handlePaid = e => {    
    if(patient_data.patient_date < today){
      Swal.fire({
        icon : 'info',
        text: 'Please go to "Due collection" or "Refund" for patients older than today..'
      })
    }else{
      setPaidAmount(e.target.value)
      setFormData({
        ...formData,
        'paid_amount': e.target.value,
        'due_amount': total_fee - formData.discount_amount - e.target.value
      })
    }    
    
  }
  const handleDiscountBy = e => {
    setFormData({
      ...formData,
      'discount_by': e.target.value
    })
  }
  const handleSubmit = async e => {
    e.preventDefault();
    setshowWaitModal(true)
    try {
      const res = await axios.post(`${base_url}/make_patient_payment`, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      })
      setshowWaitModal(false)
      swal.fire({
        text: res.data.message,
        icon: res.data.status
      })
      if (res.data.status === 'success') {
        navigate(`/invoice/${patient_data.id}`, { state: { formData: formData, patientData: patient_data } })
      }
    } catch (error) {
      setshowWaitModal(false)
      console.log(error.response)
    }

  }

  useEffect(() => {
    fetchPrevData()
  }, [])


  return (
    <div>
      {showWaitModal ?
        <Modal data='Saving payment data..' title='wait' />
        : <></>}
      {loading ? <Audio height={20} color='cadetblue' /> : <form className='payment_form'>
        Total Fee:<input type="number" className='my_field' disabled name="total_fee" id="total_fee" value={total_fee} readOnly /><br />
        Discount: <input type="checkbox" checked={showDiscount} name="discount_cb" id="discount_cb" onChange={() => setShowDisCount(!showDiscount)} />
        {showDiscount ? <div>
          Discount By:
          <input type="radio" name="discountBy" id="reffererDiscount" value='refferer'
            onChange={handleDiscountBy} />
          <label htmlFor="reffererDiscount">Refferer</label> &nbsp;
          <input type="radio" name="discountBy" id="clinicDiscount" value='clinic'
            onChange={handleDiscountBy} />
          <label htmlFor="clinicDiscount">Clinic</label> &nbsp;
          <input type="radio" name="discountBy" id="bothDiscount" value='both'
            defaultChecked onChange={handleDiscountBy} />
          <label htmlFor="bothDiscount">50-50</label><br />
          Discount:<input type="number" value={discountAmount} name="discount_amount"
            className='my_field' id="discount_amount" onChange={handleDiscount} /><br />
          Net Payable:<input type="number" value={total_fee - discountAmount} name="net_payable"
            className='my_field' id="net_payable" readOnly disabled />

        </div> : <div></div>}
        Paid: <input type="number" value={paidAmount} name="paid_amount" id="paid_amount"
          className='my_field' onChange={handlePaid} /><br />
        Due:<input type="number" value={total_fee - discountAmount - paidAmount} name="due_amount"
          className='my_field' id="due_amount" readOnly disabled />
        <br /><input type="submit" className='my_btn submit_btn' value="Confirm Payment" onClick={handleSubmit} />
      </form>}

    </div>
  )
}

export default PaymentForm